import { Component, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SCNotification } from '@sc/types';

@Component({
  selector: 'sc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {
  @Input() banner: SCNotification;
  @Output() closeBanner: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('bannerElm') bannerElm: { nativeElement: HTMLVideoElement };

  constructor() {}

  cssVar(name: string, value?: string) {
    if (value) this.bannerElm.nativeElement.style.setProperty(`--${name}`, value);
    else this.bannerElm.nativeElement.style.removeProperty(`--${name}`);
    return getComputedStyle(this.bannerElm.nativeElement).getPropertyValue(`--${name}`);
  }

  async ngOnChanges(changes) {
    if (!this.bannerElm?.nativeElement) {
      await new Promise((resolve) => setTimeout(resolve, 0));
      this.ngOnChanges(changes);
      return;
    }
    if (changes.banner?.currentValue?.bannerOptions) {
      const { backgroundColor, backgroundImage, buttonBackgroundColor, buttonBorderColor, buttonTextColor, textColor } =
        changes.banner.currentValue.bannerOptions;
      if (backgroundColor) this.cssVar('background-color', backgroundColor);
      else this.cssVar('background-color', 'transparent');
      if (backgroundImage) this.cssVar('background-image', `url(${backgroundImage})`);
      else this.cssVar('background-image', 'none');
      if (buttonBackgroundColor) this.cssVar('button-background-color', buttonBackgroundColor);
      else this.cssVar('button-background-color', 'transparent');
      if (buttonBorderColor) this.cssVar('button-border-color', buttonBorderColor);
      else this.cssVar('button-border-color', 'transparent');
      if (buttonTextColor) this.cssVar('button-text-color', buttonTextColor);
      else this.cssVar('button-text-color', 'transparent');
      if (textColor) this.cssVar('text-color', textColor);
      else this.cssVar('text-color', 'transparent');
    }
  }

  close() {
    this.closeBanner.emit();
  }
}
