import { Directive, OnDestroy, AfterViewInit, OnChanges, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import tippy, { Instance, Props } from 'tippy.js';

import { Themes } from '@sc/types';
import { ThemeService } from '../../services/theme/theme.service';
// Tooltip directive using Tippy, inspired by: https://stackoverflow.com/a/67876329

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[tooltip],[tooltipOptions]' })
export class TooltipDirective implements OnDestroy, AfterViewInit, OnChanges {
  @Input() tooltip: string;
  @Input() tooltipOptions: Partial<Props>;

  themeSub: Subscription;

  defaultTooltipOptions: Partial<Props> = {
    theme: 'light',
    animation: 'shift-away',
    zIndex: 9998,
    delay: 400,
    duration: 100,
  };

  private instance: Instance<Props> = null;

  constructor(private readonly el: ElementRef, private themeService: ThemeService) {}

  ngAfterViewInit() {
    this.instance = tippy(this.el.nativeElement as Element, {});

    this.updateProps();

    this.themeSub = this.themeService.theme$.subscribe((theme) => {
      this.updateProps();
    });
  }

  ngOnChanges() {
    this.updateProps();
  }

  ngOnDestroy() {
    this.instance?.destroy();
    this.instance = null;
    this.themeSub?.unsubscribe();
  }

  private async updateProps() {
    const props = {
      ...this.tooltipOptions,
      content: this.tooltip,
    };

    if (this.themeService.theme$.value === Themes.DARK) this.defaultTooltipOptions.theme = 'dark';
    else this.defaultTooltipOptions.theme = 'light';

    if (this.instance) {
      this.instance.setProps(this.normalizeOptions(props));
      if (!props.content) {
        this.instance.disable();
      } else {
        this.instance.enable();
      }
    }
  }

  private normalizeOptions = (props: Partial<Props>): Partial<Props> => ({
    ...{ ...this.defaultTooltipOptions, ...props },
    duration: props?.duration ?? [50, 50],
  });
}

const jsonEqual = <T>(aa: T, bb: T): boolean => JSON.stringify(aa) === JSON.stringify(bb);
