import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

import { Plan } from '@sc/types';
import { ConfirmSubscriptionPage } from '../../modals/confirm-subscription/confirm-subscription.page';
import { CancelSubscriptionPage } from '../../modals/cancel-subscription/cancel-subscription.page';
import { ReactivateSubscriptionPage } from '../../modals/reactivate-subscription/reactivate-subscription.page';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CanceledService } from '../../services/canceled/canceled.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { TrialService } from '../../services/trial/trial.service';
import { CookiesService } from '../../services/cookies/cookies.service';
import { RoleLimits } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { ShowsService } from '../../services/shows/shows.service';
import { TeamsService } from '../../services/teams/teams.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

import * as dayjs from 'dayjs';
import { ActionConfirmPage } from '../../modals/action-confirm/action-confirm.page';
import { AmplionService } from '../../services/amplion/amplion.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { Sale } from '@sc/types';
import { SalesService } from '../../services/sales/sales.service';
import { escapeHTML } from '../../util/escapeHTML';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { DescriptService } from '../../services/descript/descript.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnDestroy {
  @Input() plan: Plan;
  @Input() appearance: 'account' | 'compact' | 'upgrade';
  @Input() compactState: 'no-plan' | 'claim' | 'upgrade' | 'account';
  @Input() overrideClaim: boolean;
  @Input() disabled: boolean;
  @Input() sale: Sale;
  @Input() yearly: boolean;
  @Output() claimCallback = new EventEmitter<boolean>();

  reactivatingSubscription = false;

  plan$ = this.walletService.dashboardPlan$;
  shows$ = this.showsService.availableShows$;
  members$ = this.teamsService.team$;
  activeInvites$ = this.teamsService.orgInvites$;
  currentSale$ = this.salesService.currentSale$;
  saleCoupons$ = this.salesService.currentSaleCoupons$;
  salePrice: string;

  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;

  canceledStatus$ = this.canceledService.canceledStatus$;
  trialStatus$ = this.trialService.trialStatus$;
  customer$ = this.walletService.customer$;

  hasSquadCastPlan = false;

  subs: Array<Subscription> = [];

  constructor(
    private amplionService: AmplionService,
    private analyticsService: AnalyticsService,
    private canceledService: CanceledService,
    private cfs: CloudFunctionsService,
    private cookiesService: CookiesService,
    private descriptService: DescriptService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private renderer: Renderer2,
    private rolesService: RolesService,
    private salesService: SalesService,
    private showsService: ShowsService,
    private teamsService: TeamsService,
    private toastrService: ToastrService,
    private trialService: TrialService,
    private userService: UserService,
    private walletService: WalletService
  ) {}

  ngOnInit() {
    this.setupCookies();
    this.setupSalePrice();
  }

  async select(plan: Plan) {
    if (this.teamsService.myInvites$.value.length > 0) {
      const proceed = await new Promise((resolve) => {
        const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
          `You have pending organization invites. Are you sure you want to continue with this plan?`,
          null,
          {
            progressBar: false,
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
            toastComponent: GeneralToastComponent,
          }
        );

        toast.toastRef.componentInstance.buttons = [
          {
            label: 'Show Invites',
            handler: () => {
              const invites = [...this.teamsService.myInvites$.value];
              this.teamsService.myInvites$.next(null);
              this.teamsService.myInvites$.next(invites);
              resolve(false);
            },
          },
          {
            label: 'Continue',
            handler: () => {
              resolve(true);
            },
          },
        ];
      });
      if (proceed !== true) return;
    }

    // const passLimits = await this.teamsService.checkLimits(plan);
    // if (!passLimits) return;

    if (this.appearance === 'upgrade') {
      if (!this.walletService.customer$.value) {
        const org = this.organizationsService.dashboardOrg$.value;
        const resp = await this.amplionService.addCustomer({
          email: org.billingEmail ?? this.userService.activeUser$.value.email,
          metadata: { orgID: org.orgID },
          description: org.orgName,
        });
        const customerID = resp.customer.id;
        this.walletService.setCustomerID(customerID);
        await this.walletService.customerID$.nextExistingValue();
      }
      await this.walletService.refreshStripeCustomer();
      await this.walletService.customer$.nextExistingValue();
      const modal = await this.modalController.create({
        component: ConfirmSubscriptionPage,
        componentProps: {
          selectedPlan: plan,
          page: this.appearance,
        },
        showBackdrop: true,
        backdropDismiss: false,
        animated: true,
        cssClass: 'confirm-subscription-modal',
      });
      await modal.present();
    } else {
      await this.analyticsService.track('selected plan', { selectedPlanID: plan.id });
      window.location.href = 'https://www.descript.com/pricing?utm_source=squadcast'; // TODO: Update URL?
    }
  }

  async manageSubscription() {
    await this.analyticsService.track('descript manage subscription');
    window.location.href = `${environment.descript.webDomain}/view/settings/account?active=subscription&drive_id=${this.descriptService.descriptDriveID$.value}&utm_source=squadcast`; // TODO: Update URL?
  }

  async syncSubscription() {
    const driveID = this.descriptService.descriptDriveID$.value;
    const orgID = this.organizationsService.dashboardOrg$.value.orgID;
    await this.cfs.post('descript-sync', {
      driveID,
      orgID,
      manualSync: true,
    });
    await this.analyticsService.track('descript drive manual sync');
    this.toastrService.success(`Synced!`, `Successfully synced your Subscription with Descript`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
  }

  async reactivateSubscription() {
    await this.analyticsService.track('selected reactivate subscription');
    window.location.href = `${environment.descript.webDomain}/use-squadcast?redirect_origin=squadcast-reactivate&utm_source=squadcast`; // TODO: Update URL? @Kemal redirect to our new checkout?
  }

  async redeemOffer() {
    if (this.overrideClaim) this.claimCallback.emit(true);
    else {
      await this.analyticsService.track('selected redeem descript offer');
      window.location.href = `${environment.descript.webDomain}/use-squadcast?redirect_origin=squadcast-redeem&utm_source=squadcast`; // TODO: Update URL?
    }
  }

  async openCancelSubscriptionModal() {
    const modal = await this.modalController.create({
      component: CancelSubscriptionPage,
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'cancel-subscription-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened cancel subscription');
  }

  async openReactivateSubscriptionModal() {
    this.reactivatingSubscription = true;
    const subscriptions = this.customer$.value?.subscriptions?.data;
    const updates = subscriptions.map((subscription: any) => {
      // If a shadow plan, need to remove cancel_at instead
      if (subscription.plan.interval === 'monthly') {
        return this.amplionService.updateSubscription({ cancel_at: null }, subscription.id);
      } else {
        return this.amplionService.updateSubscription({ cancel_at_period_end: false }, subscription.id);
      }
    });

    await Promise.all(updates);
    await this.analyticsService.track('reactivated subscription');
    await this.walletService.refreshStripeCustomer();
    this.toastrService.success(`Thank You!`, `Successfully reactivated your Subscription`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
    this.reactivatingSubscription = false;
    //updated subscription email is triggered from stripe to zapier

    const modal = await this.modalController.create({
      component: ReactivateSubscriptionPage,
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'reactivate-subscription-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened reactivate subscription');
  }

  async openRemoveBenefitsModal() {
    const subscriptions = this.customer$.value?.subscriptions?.data;
    const primarySub = subscriptions.find((s) => s.plan.amount);
    const modal = await this.modalController.create({
      component: ActionConfirmPage,
      componentProps: {
        title: 'Remove Benefits Now',
        message: `Your subscription is already canceled. You will not have any further charges but you still have benefits until your next billing date <b>${dayjs(
          primarySub.current_period_end * 1000
        ).format(
          'MMM D, YYYY'
        )}</b>. Are you sure you want to remove your benefits and close the account? <br> <b>YOU WILL NOT BE REFUNDED FOR THE LOST TIME!</b>`,
        enableHTML: true,
        iconSrc: 'assets/icons/16px/exclamation-solid.svg',
        buttons: [
          {
            label: 'Cancel',
            handler: () => {
              modal.dismiss();
            },
          },
          {
            label: 'Remove Benefits Now',
            handler: async () => {
              for (const subscription of subscriptions) {
                await this.amplionService.cancelSubscription(this.customer$.value.id, subscription.id);
              }
              await this.analyticsService.track('closed subscription manually');
              modal.dismiss();
              await this.walletService.refreshStripeCustomer();
            },
            type: 'danger',
          },
        ],
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'action-confirm-modal',
    });

    modal.present();
  }

  async deleteOrganization() {
    const modal = await this.modalController.create({
      component: ActionConfirmPage,
      componentProps: {
        title: 'Delete Organization',
        message: `Are you sure you want to delete the organization <b>${escapeHTML(
          this.organizationsService.dashboardOrg$.value.orgName
        )}</b>? You can't undo this action. By deleting the organization, you will also delete all the <b>SHOWS</b>, <b>SESSIONS</b>, <b>RECORDINGS</b>, and all the data associated with the organization.`,
        enableHTML: true,
        iconSrc: 'assets/icons/16px/exclamation-solid.svg',
        buttons: [
          {
            label: 'DELETE PERMEMANTLY',
            handler: async () => {
              await this.organizationsService.deleteOrg(this.organizationsService.dashboardOrg$.value.orgID);
              await this.analyticsService.track('deleted organization', {
                orgID: this.organizationsService.dashboardOrg$.value.orgID,
              });
              modal.dismiss();
            },
            type: 'danger',
          },
          {
            label: 'Cancel',
            handler: () => {
              modal.dismiss();
            },
          },
        ],
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'action-confirm-modal',
    });

    modal.present();
  }

  setupSalePrice() {
    this.saleCoupons$.subscribe((coupons) => {
      let found = false;
      coupons.forEach((coupon) => {
        if (coupon.plans.includes(this.plan.id)) {
          this.salePrice = String(this.plan.price - (this.plan.price * coupon.discount) / 100);
          found = true;
        }
      });
      if (!found) this.salePrice = null;
    });
  }

  replacePlanWithSale() {
    const getIndieYearlySlot: HTMLElement = document.getElementById('price_1KKYeIKP34R6C976gwqdkwSQ');
    getIndieYearlySlot.remove();
    const saleContainer: HTMLElement = document.getElementById('sales-card');
    const childSalesContainer = saleContainer.children[0];
    this.renderer.setStyle(childSalesContainer, 'background-color', this.sale.style.backgroundColor);
    this.renderer.setStyle(childSalesContainer, '--card-color', this.sale.style.backgroundColor);
    this.renderer.setStyle(childSalesContainer, 'background-image', `url('${this.sale.style.backgroundPattern}')`);
  }

  setupCookies() {
    if (!this.cookiesService.check('sc_plan')) {
      return;
    }

    const planID: string = this.cookiesService.get('sc_plan');

    if (this.plan.id !== planID) {
      return;
    }

    // if (this.appearance !== 'checkout') {
    //   return;
    // }
    this.select(this.plan);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
