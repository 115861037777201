<ion-row>
  <ion-col>
    <h3 class="ion-text-center">Set Backwards Compatibility</h3>

    <p class="ion-text-center">
      Some integrations are unable to use multi-step factorization.<br />
      You can specify which Show this API Key should be associated with.
    </p>
    <p class="ion-text-center">
      This is a fallback options for integrations to pick which Show to manage. We will always attempt to respect the
      Show that you pass in the API Routes.
    </p>
  </ion-col>
</ion-row>
<!-- *ngIf="(role$ | async) >= roleLimits.SHOW_READ && showSelectToggled" -->
<ion-row>
  <ion-col>
    <ion-list lines="none">
      <ion-label position="stacked"> <h3>Select a Show</h3> </ion-label>
      <ion-item class="input-large" lines="none">
        <ng-select
          [(ngModel)]="show"
          (change)="changeShow()"
          [clearable]="false"
          placeholder="Select a Show"
          appearance="outline"
          notFoundText=""
          appendTo="body">
          <ng-option *ngFor="let show of shows" [value]="show">
            <sc-show-list-item [show]="show"></sc-show-list-item>
          </ng-option>
        </ng-select>
      </ion-item>
      <ion-item lines="none">
        <ion-icon src="/assets/icons/24px/api-compatibility.svg" slot="start"> </ion-icon>

        <ion-label class="ion-text-wrap">
          <p>
            Since some integrations are unable to properly look up all shows for your organizations. You can specify
            which Show this API Key should be associated with. You can change this at any time.
            <sc-learn-more topic="apiCompatability" class="inline"></sc-learn-more>
            You can also check out our official
            <a href="https://developers.squadcast.fm/docs/backwards-compatibility" target="_blank">
              Developer Documents </a
            >.
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-col>
</ion-row>
<div class="buttons">
  <ion-button (click)="cancel()" expand="block" class="grey"> Cancel </ion-button>
  <ion-button (click)="confirm()" expand="block" class="red"> Confirm </ion-button>
</div>
