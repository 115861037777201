import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { TeamMember } from '@sc/types';

@Component({
  selector: 'sc-team-member-list-item',
  templateUrl: './team-member-list-item.component.html',
  styleUrls: ['./team-member-list-item.component.scss'],
})
export class TeamMemberListItemComponent implements OnInit, OnDestroy {
  @Input() member: TeamMember;

  email: string;

  subs: Array<Subscription> = [];

  constructor() {}

  async ngOnInit() {}

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
