import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DevelopersPage } from './developers.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, SquadCastComponentsModule],
  declarations: [DevelopersPage],
})
export class DevelopersPageModule {}
