import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription, switchMap } from 'rxjs';

import { UserModel } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { NotificationsMenuComponent } from '../../menus/notifications-menu/notifications-menu.component';
import { BetaFeedbackPage } from '../../modals/beta-feedback/beta-feedback.page';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { CanceledService } from '../../services/canceled/canceled.service';
import { ProfileMenuComponent } from '../../menus/profile-menu/profile-menu.component';
import { PilotProgramService } from '../../services/pilotprogram/pilotprogram.service';
import { PilotProgram } from '@sc/types';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { DescriptService } from '../../services/descript/descript.service';

@Component({
  selector: 'sc-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  plan$ = this.walletService.dashboardPlan$;
  pilotProgram$ = this.pilotProgramService.pilotProgram$;
  user: UserModel;
  accountPhotoUrl: string;
  query: string;
  unreadNotifications = false;
  canceledStatus$ = this.canceledService.canceledStatus$;
  wallet$ = this.walletService.wallet$;
  onAccountPage = false;

  mobileNavOpen$ = this.responsiveService.mobileNavOpen$;
  mobileProfileNavOpen$ = this.responsiveService.mobileProfileNavOpen$;
  descriptDrive$ = this.descriptService.descriptDrive$;

  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private userService: UserService,
    private pilotProgramService: PilotProgramService,
    private analyticsService: AnalyticsService,
    private descriptService: DescriptService,
    private modalController: ModalController,
    private notificationsService: NotificationsService,
    private walletService: WalletService,
    private canceledService: CanceledService,
    private responsiveService: ResponsiveService
  ) {}

  @HostListener('keyup', ['$event'])
  async searchBarKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  ngOnInit() {
    this.setupUser();
    this.setupNotifications();
  }

  async search() {
    const query = this.query || '';
    await this.router.navigate(['/search/' + query]);
    await this.analyticsService.track('searched', { query });
  }

  setupNotifications() {
    const sub = this.notificationsService.readNotifications$
      .pipe(
        switchMap(() => {
          return this.notificationsService.getNotifications('event');
        })
      )
      .subscribe((notifications) => {
        if (!notifications) {
          this.unreadNotifications = false;
          return;
        }

        const read = this.notificationsService.readNotifications$.value;
        this.unreadNotifications = notifications.some((notification) => {
          return !read.find((v) => v.id == notification.id);
        });
      });

    this.subs.push(sub);
  }

  async openNotifications(click: CustomEvent | any) {
    const notificationsMenu = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: NotificationsMenuComponent,
      cssClass: 'notifications-menu',
      event: click,
    });

    this.analyticsService.track('opened notifications menu');

    await notificationsMenu.present();
    await this.analyticsService.track('opened notifications');
  }

  async openBetaFeedback() {
    const modal = await this.modalController.create({
      component: BetaFeedbackPage,
      componentProps: {
        user: this.user,
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'beta-feedback-modal',
    });

    await modal.present();
    await this.analyticsService.track('opened beta feedback');
  }

  async openProfileMenu(event: MouseEvent) {
    if (this.responsiveService.constraints$.value?.width < 768) {
      this.mobileProfileNavOpen$.next(true);
      return;
    }
    const profileMenu = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: ProfileMenuComponent,
      cssClass: 'profile-menu',
      event,
    });

    await profileMenu.present();
    await this.analyticsService.track('opened profile menu');
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
