import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  doc,
  docData,
  query,
  where,
} from '@angular/fire/firestore';

import { SCSubject } from '../../util/sc-subject.class';
import { Plan } from '@sc/types';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  availablePlans$ = new SCSubject<Plan[]>();
  allPlans$ = new SCSubject<Plan[]>();

  plansCol = collection(this.firestore, 'plans') as CollectionReference<Plan>;

  constructor(private firestore: Firestore, private userService: UserService) {
    this.setupAllPlans();
    this.setupAvailablePlans();
  }

  async setupAllPlans() {
    await this.userService.activeUser$.toPromise();
    this.getAllPlans().subscribe((plans) => {
      this.allPlans$.next(plans);
    });
  }

  async setupAvailablePlans() {
    await this.userService.activeUser$.toPromise();
    this.getAvailablePlans().subscribe((plans) => {
      this.availablePlans$.next(plans);
    });
  }

  /**
   * Returns the specific plan document from the Plan Collection
   *
   * @param planID
   * @returns
   */
  getPlan(planID: string) {
    return docData(doc(this.plansCol, planID), { idField: 'id' });
  }

  getAvailablePlans() {
    return collectionData(query(this.plansCol, where('isDescriptPlan', '==', true)), { idField: 'id' });
  }

  getAllPlans() {
    return collectionData(this.plansCol, { idField: 'id' });
  }
}
