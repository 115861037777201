import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { HelpMenuComponent } from '../../menus/help-menu/help-menu.component';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'sc-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent {
  isMobile = this.deviceDetectorService.isMobile();
  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private analyticsService: AnalyticsService,
    private deviceDetectorService: DeviceDetectorService,
    private supportCenterService: SupportCenterService
  ) {}

  async open(event: MouseEvent) {
    if (!this.router.url.includes('studio')) {
      const logoMenu = await this.popoverController.create({
        ...defaultPopoverOptions,
        component: HelpMenuComponent,
        cssClass: 'help-menu',
        componentProps: {
          event,
        },
        event,
      });

      await logoMenu.present();
      await this.analyticsService.track('opened logo menu');
    } else {
      this.supportCenterService.show();
    }
  }
}
