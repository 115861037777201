<ion-row class="header">
  <ion-col>
    <h3 class="bold ion-text-center">Verify Your Account</h3>

    <h3 *ngIf="!ssoLogin" class="bold ion-text-center">Please Sign In</h3>
    <h2 *ngIf="ssoLogin" class="bold ion-text-center">Please Re-Authenticate Below</h2>
  </ion-col>
</ion-row>

<div *ngIf="emailLogin" class="ion-text-center">
  <ion-item class="input-large" lines="none">
    <ion-label position="stacked"> Email </ion-label>
    <ion-input [(ngModel)]="email" name="email" placeholder="Enter your email" required> </ion-input>
  </ion-item>
  <ion-item class="input-large" lines="none">
    <ion-label position="stacked"> Password </ion-label>
    <ion-input
      [(ngModel)]="password"
      (keyup.enter)="verifyEmailAuth()"
      type="password"
      name="password"
      placeholder="Enter your password"
      required>
    </ion-input>
  </ion-item>
  <ion-button *ngIf="emailLogin" aria-label="Verify Account" (click)="verifyEmailAuth()" color="primary" shape="round">
    Verify Account
  </ion-button>
</div>
<span *ngIf="emailLogin && ssoLogin" class="ion-text-center">OR</span>
<ion-button
  *ngIf="ssoLogin"
  class="secondary oauth-button"
  [class.google-button]="activeProvider === 'google.com'"
  size="large"
  (click)="verifySSO()">
  <img
    slot="start"
    [src]="
      providerInfo[activeProvider]?.orgLogo ||
      providerInfo[activeProvider]?.icon ||
      'assets/icons/24px/globe.svg'
    " />
  <p class="action-medium">
    Verify with {{ providerInfo[activeProvider]?.name ?? activeProvider.split('.')[0].toUpperCase() }}
  </p>
</ion-button>
