<h5>You've got options</h5>
<p class="body-regular">
  Get SquadCast as part of a Descript free plan. Pausing lets you take a break. Canceling deletes everything.
</p>

<div class="option free" [class.active]="selected === 'free'" (click)="selected = 'free'">
  <p class="action-large">Get Free Plan with Descript</p>
  <div class="content">
    <ul>
      <li>Keep your organization <b>{{ (dashboardOrg$ | async).orgName }}</b></li>
      <li>Maintain access to all your past recordings</li>
      <li>Edit and record with Descript</li>
    </ul>
    <div class="price">
      <span>FREE</span>
    </div>
  </div>
</div>

<div class="option pause" [class.active]="selected === 'pause'" (click)="selected = 'pause'">
  <p class="action-large">Pause Subscription</p>
  <div class="content">
    <ul>
      <li>
        We'll keep your organization <b>{{ (dashboardOrg$ | async).orgName }}</b> safe for you while you take a
        recording break
      </li>
      <li>All your past recordings will be accessible</li>
      <li>
        You will <b><u>NOT</u></b> be able to record while your organization is paused. All stored recording time will
        be <b><u>LOST</u></b>
      </li>
    </ul>
    <div class="price">
      <span><span class="dollar">$</span>5</span> / month
    </div>
  </div>
</div>

<div class="option cancel" [class.active]="selected === 'cancel'" (click)="selected = 'cancel'">
  <p class="action-large">Cancel Subscription</p>
  <div class="content">
    <ul>
      <li>You will have full organization access until <b>{{ cancelDateDisplay }}</b></li>
      <li>
        After <b> {{ cancelDateDisplay }} </b>, you will lose access to your entire organization
        <b> {{ (dashboardOrg$ | async).orgName }} </b>, including all its Shows, Members and Recordings
      </li>
      <li>We recommend you download all needed content before <b> {{ cancelDateDisplay }} </b></li>
    </ul>
  </div>
</div>

<div class="buttons-container">
  <ion-button class="grey" (click)="close()">Close</ion-button>
  <ion-button class="primary" [class.cancel]="selected === 'cancel'" (click)="action()">
    <!-- {{ selected === 'pause' ? 'Pause Subscription' : selected === 'cancel' ? 'Cancel Subscription' : 'Get Free Plan with Descript' }} -->
    <span *ngIf="selected === 'pause'">Pause Subscription</span>
    <span *ngIf="selected === 'cancel'">Cancel Subscription</span>
    <span *ngIf="selected === 'free'">Get Free Plan with Descript</span>
  </ion-button>
</div>
