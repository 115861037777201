import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { CancelSubscriptionPage } from './cancel-subscription.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: CancelSubscriptionPage,
      },
    ]),
    SquadCastComponentsModule,
  ],
  declarations: [CancelSubscriptionPage],
})
export class CancelSubscriptionPageModule {}
