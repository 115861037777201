import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  doc,
  docData,
  query,
  where,
  DocumentReference,
} from '@angular/fire/firestore';

import { SCSubject } from '../../util/sc-subject.class';
import { Sale, SaleCoupon, SaleInfo } from '@sc/types';
import { UserService } from '../user/user.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { limit } from 'firebase/firestore';
import { Coupon } from '@sc/types';
import { of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  public availableSales$ = new SCSubject<Sale[]>();
  public availableCoupons$ = new SCSubject<SaleCoupon[]>();
  public appSaleInfo$ = new SCSubject<SaleInfo>();
  public currentSale$ = new SCSubject<Sale>();
  public currentSaleCoupons$ = new SCSubject<SaleCoupon[]>();

  private salesCol = collection(this.firestore, 'sales');
  private salesDoc = doc(this.firestore, 'sales', 'sales');
  private currentSaleCol = collection(this.firestore, 'sales', 'sales', 'allsales');

  constructor(private firestore: Firestore, private userService: UserService) {
    this.setupAppSaleInfo();
    this.setupCurrentSaleCoupons();
    this.setupCurrentSale();
  }

  async setupAppSaleInfo() {
    this.getAppSaleInfo().subscribe((sale: SaleInfo) => {
      this.appSaleInfo$.next(sale);
    });
  }

  async setupCurrentSale() {
    this.appSaleInfo$.pipe(switchMap((saleInfo) => this.getCurrentSale())).subscribe((sale) => {
      if (this.appSaleInfo$.value.enableSales) this.currentSale$.next(sale as Sale);
      else this.currentSale$.next(null);
    });
  }

  async setupCurrentSaleCoupons() {
    this.currentSale$
      .pipe(
        switchMap((sale) => {
          if (sale) return this.getSaleCoupons(sale.saleName);
          else return of([]);
        })
      )
      .subscribe((coupons) => {
        this.currentSaleCoupons$.next(coupons as SaleCoupon[]);
      });
  }

  /**
   * Returns the current sale from the root of the sales document.
   * This is where sales can be enabled and configured for the app.
   *
   * @returns Observable<SaleInfo>
   */
  getAppSaleInfo() {
    return docData(this.salesDoc, { idField: 'saleName' });
  }

  /**
   * Return document for the current active sale.
   *
   * @returns
   */
  getCurrentSale() {
    const appSaleInfo = this.appSaleInfo$.value.currentSale;
    const currentSaleDoc = doc(this.currentSaleCol, `${appSaleInfo}`);
    return docData(currentSaleDoc, { idField: 'saleName' });
  }

  /**
   * Returns the specific sale document from the Sale Collection
   *
   * @param saleID
   * @returns
   */
  getSale(saleID: string) {
    return docData(doc(this.salesCol, saleID), { idField: 'saleName' });
  }

  getSaleNotification(saleID: string) {
    return docData(doc(this.currentSaleCol, saleID, 'notifications', 'banner'), { idField: 'id' });
  }

  checkIfPlanOnSale(planID: string) {
    const planOnSale = this.currentSale$.value.plans.includes(planID);
    return planOnSale;
  }

  getSaleCoupons(saleID: string) {
    return collectionData(collection(this.currentSaleCol, saleID, 'coupons'), { idField: 'id' });
  }

  getCouponByPlan(planID: string) {
    const couponsColRef = collection(
      this.firestore,
      'sales',
      'sales',
      'allsales',
      this.currentSale$.value.saleName,
      'coupons'
    ) as CollectionReference<SaleCoupon>;
    return collectionData<SaleCoupon>(query(couponsColRef, where('plans', 'array-contains', planID), limit(1)));
  }

  async checkIfCouponRedeemed(orgID: string) {
    await this.currentSaleCoupons$.toPromise();
    if (!this.currentSaleCoupons$.value) return false;
    const orgCouponsCol = collection(
      this.firestore,
      `organizations/${orgID}/billing/wallet/coupons`
    ) as CollectionReference<Coupon>;
    const orgCouponsDocs = collectionData<Coupon>(orgCouponsCol, { idField: 'id' });
    const orgCoupons = await firstValueFrom(orgCouponsDocs);
    const activeSaleCouponsRef = collection(
      this.firestore,
      `sales/sales/allsales/${this.currentSale$.value.saleName}/coupons`
    ) as CollectionReference<SaleCoupon>;

    return orgCoupons.some((coupon) => {
      return this.currentSaleCoupons$.value.some((saleCoupon) => {
        return coupon.code === saleCoupon.code;
      });
    });
  }
}
