import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Firestore, addDoc, collection, doc, serverTimestamp, setDoc } from '@angular/fire/firestore';

import { ApiCollections, GuestInvite, TeamMemberInvite, ZapierRequest } from '@sc/types';
import { ExampleEvent, WebhookEvent, ZapierHookTags } from '@sc/types';
import { ShowTransferEmail } from '@sc/types';
import { CloudFunctionsService } from '../cloud-functions.service';
import { DocumentData, DocumentReference, getDocs, query, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ZapierService {
  base = 'https://hooks.zapier.com/hooks/catch/2796136';
  changeSubBase = 'https://hooks.zapier.com/hooks/catch/10055834';
  headers: any = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

  constructor(private cfs: CloudFunctionsService, private http: HttpClient, private firestore: Firestore) {}

  sendShowTransferEmail(inviteID: string) {
    return this.cfs.post('zapier-email-hooks', {
      zapierUrlKey: ZapierHookTags.SHOWTRANSFER,
      showTransferEmail: true,
      inviteID,
      inviteType: 'SHOWTRANSFER',
    } as ZapierRequest);
  }

  async createShowTransferInvite(invite: ShowTransferEmail): Promise<DocumentReference<DocumentData>> {
    return addDoc(collection(this.firestore, 'invites'), invite).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(collection(this.firestore, 'invites'), error.message.split('/').at(-1));
    });
  }

  sendTeamMemberInviteEmail(inviteID: string) {
    return this.cfs.post('zapier-email-hooks', {
      zapierUrlKey: ZapierHookTags.TEAMMEMBERINVITE,
      inviteID,
      inviteType: 'TEAMMEMBERINVITE',
      showTransferEmail: null,
    } as ZapierRequest);
  }

  /**
   * This function sends an on-stage invite email via Zapier's Hooks Cloud Function
   *
   * @param {string} inviteID - The ID of the invite to send via email.
   * @returns Response from the HTTP POST request.
   * @throws Will throw an error if the HTTP POST request fails.
   */
  sendOnStageInviteEmail(inviteID: string) {
    return this.cfs.post('zapier-email-hooks', {
      zapierUrlKey: ZapierHookTags.ONSTAGEINVITE,
      inviteID,
      showTransferEmail: null,
      inviteType: 'ONSTAGEINVITE',
    } as ZapierRequest);
  }

  /**
   * This function sends a backstage invite email via Zapier's Hooks Cloud Function
   *
   * @param {string} inviteID - The ID of the invite to send via email.
   * @returns Response from the HTTP POST request.
   * @throws Will throw an error if the HTTP POST request fails.
   */
  sendBackstageInviteEmail(inviteID: string) {
    return this.cfs.post('zapier-email-hooks', {
      zapierUrlKey: ZapierHookTags.BACKSTAGEINVITE,
      inviteID,
      showTransferEmail: null,
      inviteType: 'BACKSTAGEINVITE',
    } as ZapierRequest);
  }

  sendTeamMemberShowInviteEmail(inviteID: string) {
    // return this.http.post(`${this.base}/bfueh25`, invite, this.headers);
    return this.cfs.post('zapier-email-hooks', {
      zapierUrlKey: ZapierHookTags.TEAMMEMBERSHOWINVITE,
      showTransferEmail: null,
      inviteID,
      inviteType: 'TEAMMEMBERSHOWINVITE',
    } as ZapierRequest);
  }

  /**
   * This async function creates a session invite in Firestore.
   * In case of any error while adding document to 'invites' collection,
   * it retrieves the document from 'invites' collection whose id matches the last part of the error message.
   * IF THE INVITE ALREADY EXISTS, it will NOT Recreate a new Guest Invite for that Specific SessionID
   *
   * @param {GuestInvite} invite - The invite object containing the details for the guest invite.
   * @returns {Promise<DocumentReference<DocumentData>>} A Promise that resolves with DocumentReference of the new invite added or the doc retrieved in case of error.
   * @throws Will throw an error if fails to add the document to the collection and can't retrieve the existing one.
   */
  async createGuestInvite(invite: GuestInvite): Promise<DocumentReference<DocumentData>> {
    const inviteID = await this.checkIfInviteExist(invite);
    if (inviteID) {
      return doc(collection(this.firestore, 'invites'), inviteID);
    } else {
      return addDoc(collection(this.firestore, 'invites'), invite).catch((error) => {
        // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
        return doc(collection(this.firestore, 'invites'), error.message.split('/').at(-1));
      });
    }
  }

  /**
   * This async function checks if an invite exists in Firestore.
   * It performs a query on the 'invites' collection for documents where 'toEmail' and 'sessionID' fields match
   * the values from the provided invite object.
   *
   * @param {GuestInvite} invite - The invite object containing the details for the guest invite.
   * @returns {Promise<string>} A Promise that resolves with true if invite already exist, otherwise false.
   * @throws Will throw an error if the query execution fails.
   */
  async checkIfInviteExist(invite: GuestInvite): Promise<string> {
    const inviteRef = query(
      collection(this.firestore, 'invites'),
      where('toEmail', '==', invite.toEmail),
      where('sessionID', '==', invite.sessionID)
    );
    const invites = await getDocs(inviteRef);
    if (invites.empty) return;
    const inviteData = invites.docs[0].data() as GuestInvite;
    return inviteData.inviteID;
  }

  saveExample(orgID: string, payload: ExampleEvent | WebhookEvent) {
    const docRef = doc(
      this.firestore,
      `organizations/${orgID}/settings/webhooks/${ApiCollections.EXAMPLE}/${payload.name.replace('.', '_')}`
    );
    const data = { id: docRef.id, ...payload };
    return setDoc(docRef, data);
  }
}
