<sc-banner *ngIf="banner" [banner]="banner" (closeBanner)="closeBanner()"></sc-banner>
<div class="dashboard-layout">
  <sc-shows-nav
    [bannerShown]="banner !== null && banner !== undefined"
    [class.mobile-nav-active]="mobileNavOpen$ | async"></sc-shows-nav>
  <div class="dashboard-main-col" [class.search-page]="searchBar !== undefined">
    <div *ngIf="searchBar === undefined">
      <sc-search-bar></sc-search-bar>
    </div>
    <div [class.account-page]="accountPage !== undefined" class="dashboard-page">
      <sc-account-sidebar [class.mobile-profile-nav-active]="mobileProfileNavOpen$ | async"></sc-account-sidebar>
      <div class="dashboard-page-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
