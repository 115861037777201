import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';

import { UserModel } from '@sc/types';
import { DropboxService } from '../../services/dropbox/dropbox.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'sc-dropbox',
  templateUrl: './dropbox.page.html',
  styleUrls: ['./dropbox.page.scss'],
})
export class DropboxPage implements OnInit {
  @Input() user: UserModel;
  @Input() isAuthenticated: boolean;
  hrefURL: string;
  currentURL: string;
  dropboxConnected = false;
  dropbox$ = this.dropboxService.dropbox$;

  subs: Array<Subscription> = [];

  constructor(
    public router: Router,
    public modalController: ModalController,
    private supportCenterService: SupportCenterService,
    private dropboxService: DropboxService,
    private idTokenService: IdTokenService,
    private analyticsService: AnalyticsService,
    private toastrService: ToastrService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.setupDropboxLink();
  }

  async setupDropboxLink() {
    this.currentURL = window.location.search;
    const urlParams = new URLSearchParams(this.currentURL);
    const code = urlParams.get('code');

    if (!code) {
      return await this.idTokenService.getFreshIdToken().then((idToken: string) => {
        return lastValueFrom(this.dropboxService.getDBAuthURL(idToken))
          .then((authURL: any) => {
            this.hrefURL = authURL.authURL;
          })
          .then(() => {
            this.router.navigate(['/account/integrations']);
          });
      });
    } else {
      this.dropboxConnected = true;
    }
  }

  async connectDropbox() {
    await this.analyticsService.track('connected dropbox');
    window.open(this.hrefURL, '_self');
  }

  async disconnectDropbox() {
    try {
      const idToken: string = await this.idTokenService.getFreshIdToken();
      await lastValueFrom(
        this.dropboxService.removeDropboxAccount(this.organizationsService.dashboardOrgID$.value, idToken)
      );
      await this.router.navigate(['/account/integrations'], { replaceUrl: true });
      this.toastrService.info(null, `Successfully removed your Dropbox Account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('disconnected dropbox', { success: true });
      await this.modalController.dismiss();
    } catch (error) {
      this.toastrService.error(null, `Failed to removed your Dropbox Account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('disconnected dropbox', { success: false });
    }
  }
}
