import { Injectable } from '@angular/core';
import { PopoverOptions } from '@ionic/angular';
import { SCSubject } from '../../util/sc-subject.class';
import { AppSettings, Themes } from '@sc/types';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public theme$ = new SCSubject<AppSettings['theme']>(Themes.LIGHT);
  constructor(private settingsService: SettingsService) {
    this.setupTheme();
  }

  setupTheme() {
    this.settingsService.userAppSettings$.subscribe((settings) => {
      this.theme$.next(settings.theme);
    });
  }

  toggleTheme() {
    return this.theme$.value.includes(Themes.LIGHT) ? this.setTheme(Themes.DARK) : this.setTheme(Themes.LIGHT);
  }

  setTheme(theme: AppSettings['theme']) {
    this.theme$.next(theme);
    return this.settingsService.setUserAppSettings({ theme });
  }
}

export const defaultPopoverOptions: Partial<PopoverOptions> = {
  mode: 'md',
  animated: false,
};
