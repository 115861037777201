import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './../../services/user/user.service';
import { DescriptService } from '../descript/descript.service';

@Injectable()
export class GuardService implements CanActivate {
  constructor(public userService: UserService, private router: Router, private descriptService: DescriptService) {}

  async canActivate() {
    const user = await this.userService.authUser$.toPromise();
    if (user && !user.isAnonymous) {
      if (!this.descriptService.loading$.value) this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
