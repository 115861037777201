<div
  class="account-sidebar-shadow"
  [class.mobile-nav-active]="mobileProfileNavOpen$ | async"
  (click)="mobileProfileNavOpen$.next(false)"></div>
<div
  class="account-sidebar"
  [cdkTrapFocus]="mobileProfileNavOpen$ | async"
  [cdkTrapFocusAutoCapture]="mobileProfileNavOpen$ | async"
  [class.mobile-nav-active]="mobileProfileNavOpen$ | async"
  (click)="mobileProfileNavOpen$.next(false)">
  <ion-list lines="none">
    <ion-item [routerLink]="['/dashboard']" *ngIf="router.url !== '/dashboard'" button="true" class="ion-hide-md-up">
      <ion-icon src="assets/icons/24px/home.svg" slot="start"></ion-icon>

      <ion-label> Back To Dashboard </ion-label>
    </ion-item>

    <ion-list-header mode="md"> MY INFORMATION </ion-list-header>
    <ion-item [routerLink]="['/account/profile']" [class.active]="router.url.includes('profile')" button="true">
      <sc-avatar
        [photoURL]="(activeUser$ | async)?.photoURL"
        [tooltip]="(activeUser$ | async)?.email"
        slot="start"></sc-avatar>

      <ion-label> My Profile </ion-label>
    </ion-item>

    <ion-item [routerLink]="['/account/settings']" [class.active]="router.url.includes('settings')" button="true">
      <ion-icon src="assets/icons/24px/settings.svg" slot="start"></ion-icon>

      <ion-label> My Settings </ion-label>
    </ion-item>

    <ion-item [routerLink]="['/account/sign-in']" [class.active]="router.url.includes('sign-in')" button="true">
      <ion-icon src="assets/icons/24px/lock.svg" slot="start"></ion-icon>

      <ion-label> Sign In Methods </ion-label>
    </ion-item>

    <ion-list-header mode="md">ORGANIZATION</ion-list-header>

    <ion-item
      *ngIf="(role$ | async) >= roles.ORG_ADMIN"
      [routerLink]="['/account/members']"
      [class.active]="router.url.includes('members')"
      button="true">
      <ion-icon src="assets/icons/24px/member.svg" slot="start"></ion-icon>

      <ion-label> Team Members </ion-label>
    </ion-item>

    <ion-item [routerLink]="['/account/shows']" [class.active]="router.url.includes('shows')" button="true">
      <ion-icon src="assets/icons/24px/cover-flow.svg" slot="start"></ion-icon>

      <ion-label> Shows </ion-label>
    </ion-item>

    <ion-item
      *ngIf="(role$ | async) >= roleLimits.ORG_READ"
      [routerLink]="['/account/recording-time']"
      [class.active]="router.url.includes('recording-time')"
      button="true">
      <ion-icon src="assets/icons/24px/clock.svg" slot="start"></ion-icon>

      <ion-label> Recording Time </ion-label>
    </ion-item>

    <ion-item
      [routerLink]="['/account/integrations']"
      [class.active]="router.url.includes('integrations')"
      button="true">
      <ion-icon src="assets/icons/24px/bolt.svg" slot="start"></ion-icon>

      <ion-label> Integrations </ion-label>
    </ion-item>

    <ion-item
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE"
      [routerLink]="['/account/subscription']"
      [class.active]="router.url.includes('subscription')"
      button="true">
      <ion-icon src="assets/icons/24px/text-file.svg" slot="start"></ion-icon>

      <ion-label> Subscription </ion-label>
    </ion-item>

    <ion-item
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE"
      [routerLink]="['/account/billing']"
      [class.active]="router.url.includes('billing')"
      button="true">
      <ion-icon src="assets/icons/24px/credit-card.svg" slot="start"></ion-icon>

      <ion-label> Billing </ion-label>
    </ion-item>

    <ion-item
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE"
      [routerLink]="['/dashboard/edit-organization/' + (dashboardOrg$ | async).orgID]"
      [class.active]="router.url.includes('edit-organization')"
      button="true">
      <ion-icon src="assets/icons/24px/globe.svg" slot="start"></ion-icon>

      <ion-label> Org Settings </ion-label>
    </ion-item>

    <ion-list-header mode="md">SQUADCAST</ion-list-header>
    <ion-item
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE"
      [routerLink]="['/account/developers']"
      [class.active]="router.url.includes('developers')"
      button="true">
      <ion-icon src="assets/icons/24px/developers.svg" slot="start"></ion-icon>

      <ion-label> Developers </ion-label>
    </ion-item>

    <!-- <ion-item
      [routerLink]="['/account/refer-a-friend']"
      [class.active]="router.url.includes('refer-a-friend')"
      button="true">
      <ion-icon src="assets/icons/24px/gift.svg" slot="start"></ion-icon>

      <ion-label> Refer a Friend </ion-label>
    </ion-item> -->

    <ion-item [routerLink]="['/account/about']" [class.active]="router.url.includes('about')" button="true">
      <ion-icon src="assets/icons/24px/squadcast.svg" slot="start"></ion-icon>

      <ion-label> About </ion-label>
    </ion-item>

    <ion-item (click)="logOut()" button="true">
      <ion-icon src="assets/icons/24px/leave.svg" slot="start"></ion-icon>

      <ion-label> Sign Out </ion-label>
    </ion-item>
  </ion-list>
</div>
