import { Injectable } from '@angular/core';
import { collection, doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AnalyticsService } from '../analytics/analytics.service';
import { Equipment, SessionsCollection, VideoResolutions } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { SettingsService } from '../settings/settings.service';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';
import { VideoOffService } from '../video-off/video-off.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { MuteService } from '../mute/mute.service';
interface CustomMediaRecorderOptions extends MediaRecorderOptions {
  videoKeyFrameIntervalDuration?: number;
}
@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  initialized = false;
  studioSession$ = this.sessionsService.studioSession$;
  studioOrg$ = this.sessionsService.studioOrg$;
  studioShowSettings$ = this.settingsService.studioShowSettings$;
  participantEquipment$ = this.sessionsService.participantEquipment$;
  sessionsCol = collection(this.firestore, 'sessions');
  sessionActive = false;
  deviceInfo = this.deviceDetectorService.getDeviceInfo();

  systemDevices$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
  selectedMicrophone$ = new SCSubject<MediaDeviceInfo>(); // Obverses the microphone list for changes
  selectedCamera$ = new SCSubject<MediaDeviceInfo>(); // Obverses the camera list for changes
  selectedHeadphones$ = new SCSubject<MediaDeviceInfo>(); // Obverses the headphone list for changes
  selectedEquipment$ = new SCSubject<Equipment>();
  equipmentReady$ = new Subject<boolean>();

  microphoneError$ = new Subject<boolean>();
  cameraError$ = new Subject<boolean>();

  deviceNameMap = new Map<string, string>();

  constraints$ = new SCSubject<{ audio: MediaTrackConstraints; video: false | MediaTrackConstraints }>(); // Obverses the MediaStreamConstraints for changes.
  echoCancellation$ = new SCSubject<boolean>();
  noiseReduction$ = new SCSubject<boolean>(true);
  selectedResolution$ = new SCSubject<VideoResolutions>();
  audioStoppedSendingData$ = new Subject<boolean>();

  user$ = this.userService.activeUser$;
  inStudio = false;

  hasPermission$ = new SCSubject<Array<boolean>>();
  stopAll$ = new SCSubject<boolean>();

  videoResolutions: Record<VideoResolutions, { width: number; height: number }> = {
    UHD: { width: 3840, height: 2160 },
    FHD: { width: 1920, height: 1080 },
    WXGA: { width: 1280, height: 720 },
  };

  constructor(
    private firestore: Firestore,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private deviceDetectorService: DeviceDetectorService,
    private muteService: MuteService,
    private sessionsService: SessionsService,
    private settingsService: SettingsService,
    private videoOffService: VideoOffService,
    private userService: UserService
  ) {}

  async init() {
    this.constraints$.next(null);
    this.selectedEquipment$.next(null);
    if (this.initialized) return;
    this.initialized = true;
    this.hasPermission$.next(await this.checkPermissions());
    this.setupEquipment();
    this.setupConstraints();
    this.setupStudioEchoCancellation();
    this.setupSystemDevices();
  }

  joinedStudio() {
    this.inStudio = true;
  }

  leftStudio() {
    this.inStudio = false;
  }

  async checkPermissions() {
    let result = [false, false];
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
        result = [true, true];
      })
      .catch(async (err) => {
        await navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());
            result = [true, false];
            this.videoOffService.setVideoOff(
              this.studioSession$.value.sessionID,
              this.userService.activeUser$.value.uid,
              true
            );
          })
          .catch((audioErr) => {
            if (err.name === 'NotAllowedError') {
              result = [false, false];
            } else this.handleError(audioErr);
          });
      });
    return result;
  }

  getVideoRecorder(stream: MediaStream) {
    let recorder: MediaRecorder;
    const videoSettings: CustomMediaRecorderOptions = {
      videoBitsPerSecond: 2.5e6,
      audioBitsPerSecond: 192e3,
      videoKeyFrameIntervalDuration: 4e3,
    };
    if (this.selectedResolution$.value === VideoResolutions.FHD) {
      videoSettings.videoBitsPerSecond = 5e6;
    }
    if (this.selectedResolution$.value === VideoResolutions.UHD) {
      videoSettings.videoBitsPerSecond = 20e6;
    }
    // const showSettings = this.studioShowSettings$.value;

    /// ABLE TO PREVIEW ON ALL WHEN STICHING, DURATION IS AVAILABLE IMMEDIATELY EXCEPT CHROME
    if (MediaRecorder.isTypeSupported('video/x-matroska;codecs=avc1,opus')) {
      recorder = new MediaRecorder(stream, {
        mimeType: 'video/x-matroska;codecs=avc1,opus',
        ...videoSettings,
      });
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      recorder = new MediaRecorder(stream, { mimeType: 'video/mp4', ...videoSettings });

      // Disabling PCM in video recording
      // }

      // // NOT ABLE TO PREVIEW ON SAFARI AND FIREFOX WHEN STICHING
      // else if (
      //   MediaRecorder.isTypeSupported('video/x-matroska;codecs=avc1,pcm') &&
      //   (showSettings?.rawPCM || this.participantEquipment$.value?.pcmAudio)
      // ) {
      //   recorder = new MediaRecorder(stream, {
      //     mimeType: 'video/x-matroska;codecs=avc1,pcm',
      //     ...videoSettings,
      //   });
    }

    // ABLE TO PREVIEW EVERYWHERE BUT MORE EXPENSIVE TO RE-RENDER INTO MP4 IF REQUESTED (DESCRIPT CAN HELP?)
    // TRY VP9 BITMOVIN HANDLING
    else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
      recorder = new MediaRecorder(stream, { mimeType: 'video/webm;codecs=vp8,opus', ...videoSettings });
    } else {
      recorder = new MediaRecorder(stream, videoSettings);
    }
    return recorder;
  }

  getAudioRecorder(stream: MediaStream) {
    let recorder: MediaRecorder;
    const audioSettings: MediaRecorderOptions = { audioBitsPerSecond: 192e3 };
    if (MediaRecorder.isTypeSupported('audio/mp4')) {
      recorder = new MediaRecorder(stream, { mimeType: 'audio/mp4', ...audioSettings });
    } else if (MediaRecorder.isTypeSupported('audio/webm;codecs=pcm') && this.participantEquipment$.value?.pcmAudio) {
      recorder = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=pcm' });
    } else {
      recorder = new MediaRecorder(stream, audioSettings);
    }
    return recorder;
  }

  setupStudioEchoCancellation() {
    this.studioSession$.subscribe((session) => {
      if (
        session?.sessionEchoCancellation !== null &&
        session?.sessionEchoCancellation !== undefined &&
        this.echoCancellation$.value !== session?.sessionEchoCancellation
      ) {
        this.setEchoCancellation(session.sessionEchoCancellation);
      }
    });
  }

  setupSystemDevices() {
    this.setSystemDevices();
    navigator.mediaDevices.ondevicechange = async () => {
      await this.setSystemDevices();
    };
  }

  async setSystemDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const filteredDevices = devices.filter((d) => d.deviceId && !d.label?.includes('Descript Loopback'));
    this.systemDevices$.next(filteredDevices);
  }

  /**
   * Sets actively selected equipment
   */
  async selectEquipment(equipment: Equipment) {
    const newMicrophone = this.getAvailableSystemDevice(equipment?.audioIn, 'audioinput');
    const newHeadphones = this.getAvailableSystemDevice(equipment?.audioOut, 'audiooutput');
    const newCamera = this.getAvailableSystemDevice(equipment?.videoIn, 'videoinput');

    if (newMicrophone) this.selectedMicrophone$.next(newMicrophone);
    if (newHeadphones) this.selectedHeadphones$.next(newHeadphones);
    if (newCamera) this.selectedCamera$.next(newCamera);

    this.selectedEquipment$.next({
      audioIn: newMicrophone,
      audioOut: newHeadphones,
      videoIn: newCamera,
    });
  }

  setupSelectedEquipment() {
    this.selectedMicrophone$.pipe(debounceTime(300)).subscribe((microphone) => {
      if (!microphone) return;
      if (this.systemDevices$.value.find((device) => device.deviceId === microphone.deviceId)?.label) {
        this.setMicrophone(microphone);
      }
    });
    this.selectedHeadphones$.pipe(debounceTime(300)).subscribe((headphones) => {
      if (!headphones) return;
      if (this.systemDevices$.value.find((device) => device.deviceId === headphones.deviceId)?.label) {
        this.setHeadphones(headphones);
      }
    });
    this.selectedCamera$.pipe(debounceTime(300)).subscribe((camera) => {
      if (!camera) return;
      if (this.systemDevices$.value.find((device) => device.deviceId === camera.deviceId)?.label) {
        this.setCamera(camera);
      }
    });
  }

  /**
   * Sets up initial participant equipment.
   */
  async setupEquipment() {
    this.setupSelectedEquipment();

    combineLatest([this.systemDevices$, this.participantEquipment$, this.studioSession$]).subscribe(
      ([devices, equipment, session]) => {
        this.checkDisconnectedEquipment(devices, equipment);
        if (!session || !devices.length) {
          this.selectedMicrophone$.next(null);
          this.selectedHeadphones$.next(null);
          this.selectedCamera$.next(null);
          return;
        }
        if (equipment) {
          this.selectEquipment(equipment);
          if (equipment?.videoOff === undefined && this.constraints$.value?.video) {
            this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.user$.value.uid, false);
          }
          if (equipment?.mute === undefined) {
            this.muteService.setMute(this.studioSession$.value.sessionID, this.user$.value.uid, false);
          }
          if (
            this.studioSession$.value?.sessionEchoCancellation !== null &&
            this.studioSession$.value?.sessionEchoCancellation !== undefined &&
            equipment?.echoCancellation !== this.studioSession$.value?.sessionEchoCancellation
          ) {
            this.setEchoCancellation(this.studioSession$.value.sessionEchoCancellation);
          } else if (equipment?.echoCancellation === undefined) {
            this.setEchoCancellation(false);
          } else {
            this.echoCancellation$.next(equipment.echoCancellation);
          }
          if (equipment.videoResolution !== undefined) {
            if (
              equipment.videoResolution === VideoResolutions.UHD &&
              this.deviceInfo.os === 'Windows' &&
              !this.studioSession$.value.windows4KOverride
            ) {
              this.setVideoQuality(VideoResolutions.FHD);
            } else if (
              equipment.videoResolution === VideoResolutions.UHD &&
              (this.selectedCamera$.value.label.includes('Logitech') ||
                this.selectedCamera$.value.label.includes('(046d:'))
            ) {
              this.toastrService.warning(
                'Logitech cameras have a known issue with audio/video alignment at 4K.  Because of this, we are unfortunately unable to support 4K with Logitech cameras at this time.',
                'Unsupported Resolution for This Camera',
                {
                  progressBar: true,
                  closeButton: true,
                  toastComponent: GeneralToastComponent,
                }
              );
              this.selectedResolution$.next(equipment.videoResolution);
              this.setVideoQuality(VideoResolutions.FHD);
            } else this.selectedResolution$.next(equipment.videoResolution);
          }
          if (
            equipment.pcmAudio === undefined &&
            (this.studioSession$.value?.maxQuality ||
              this.studioShowSettings$.value?.maxQuality ||
              this.studioShowSettings$.value?.rawPCM) &&
            this.deviceInfo.browser === 'Chrome'
          ) {
            this.setPCMAudio(true);
          }
        }
      }
    );

    this.systemDevices$.subscribe(async (devices) => {
      if (!devices?.length) return;
      await this.studioSession$.nextExistingValue();
      this.setAvailableDevices(devices, this.studioSession$.value.sessionID, this.user$.value.uid);
    });
  }

  checkDisconnectedEquipment(devices: MediaDeviceInfo[], equipment: Equipment) {
    if (!equipment || !this.sessionActive) return;
    if (equipment.audioIn && !devices.find((device) => device.label === equipment.audioIn.label)) {
      this.microphoneError$.next(true);
      this.toastrService.warning(this.filterDeviceName(equipment.audioIn.label), 'Microphone Disconnected', {
        progressBar: true,
        closeButton: true,
        toastComponent: GeneralToastComponent,
      });
    }
    if (equipment.audioOut && !devices.find((device) => device.label === equipment.audioOut.label)) {
      this.toastrService.warning(this.filterDeviceName(equipment.audioOut.label), 'Headphones Disconnected', {
        progressBar: true,
        closeButton: true,
        toastComponent: GeneralToastComponent,
      });
    }
    if (equipment.videoIn && !devices.find((device) => device.label === equipment.videoIn.label)) {
      this.cameraError$.next(true);
      this.toastrService.warning(this.filterDeviceName(equipment.videoIn.label), 'Camera Disconnected', {
        progressBar: true,
        closeButton: true,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  /**
   * When selecting a device, we need to make sure it's available as a system device. If not available return a fallback device.
   * Returns the first available system device for the kind that's passed in.
   *
   * @param device - MediaDeviceInfo
   * @param kind - String
   * @returns
   */
  getAvailableSystemDevice(device: MediaDeviceInfo, kind: string) {
    if (!this.systemDevices$.value || !Array.isArray(this.systemDevices$.value)) return;
    let newDevice = this.systemDevices$.value.find(
      (item) => (item.deviceId === device?.deviceId || item.label === device?.label) && item.kind === kind
    );

    if (!newDevice && kind === 'audioinput') {
      newDevice = this.systemDevices$.value.find((item) => {
        return item.deviceId === 'communications' && item.kind === kind;
      });
    }
    if (!newDevice) {
      newDevice = this.systemDevices$.value.find((item) => {
        return item.deviceId === 'default' && item.kind === kind;
      });
    }
    if (!newDevice) {
      newDevice = this.systemDevices$.value.find((item) => item.kind === kind);
    }
    return newDevice;
  }

  /**
   * Utility function to strip device address from device label, saved to a map
   *
   * @param name - string
   * @returns string
   */

  filterDeviceName(name: string) {
    if (!name) return null;
    if (!this.deviceNameMap) this.deviceNameMap = new Map();
    if (!this.deviceNameMap.has(name)) this.deviceNameMap.set(name, name.replace(/\(.{4}:.{4}\)$/, ''));
    return this.deviceNameMap.get(name);
  }

  setNoiseReduction(value) {
    this.noiseReduction$.next(value);
  }

  /**
   * Sets up the constraints for the media stream. listens to selectedEquipment$ and updates the constraints based on changes.
   */
  async setupConstraints() {
    combineLatest([this.selectedEquipment$, this.echoCancellation$]).subscribe(([equipment, echoCancellation]) => {
      if (equipment) this.setConstraints();
    });
    combineLatest([this.selectedEquipment$, this.selectedResolution$]).subscribe(([equipment, resolution]) => {
      if (equipment) this.setConstraints();
    });
  }

  async setConstraints() {
    await this.studioSession$.nextExistingValue();
    const settings = await this.studioShowSettings$.toPromise();
    let idealWidth = this.videoResolutions.WXGA.width;
    let idealHeight = this.videoResolutions.WXGA.height;

    if (this.selectedResolution$.value) {
      idealWidth = this.videoResolutions[this.selectedResolution$.value].width;
      idealHeight = this.videoResolutions[this.selectedResolution$.value].height;
    } else if (settings.maxQuality || this.studioSession$.value.maxQuality) {
      if (this.deviceInfo.os === 'Windows' && !this.studioSession$.value.windows4KOverride) {
        idealWidth = this.videoResolutions.FHD.width;
        idealHeight = this.videoResolutions.FHD.height;
      } else {
        idealWidth = this.videoResolutions.UHD.width;
        idealHeight = this.videoResolutions.UHD.height;
      }
    } else if (settings.fullHD) {
      idealWidth = this.videoResolutions.FHD.width;
      idealHeight = this.videoResolutions.FHD.height;
    }

    const idealAspectRatio = 1.7777777777777777;
    const idealFrameRate = 30;

    const videoConstraints: MediaTrackConstraints = {
      deviceId: this.selectedCamera$.value?.deviceId ? { exact: this.selectedCamera$.value.deviceId } : null,
      width: { ideal: idealWidth },
      height: { ideal: idealHeight },
      aspectRatio: { ideal: idealAspectRatio },
      frameRate: { ideal: idealFrameRate, min: 24 },
    };

    const audioConstraints: MediaTrackConstraints = {
      deviceId: this.selectedMicrophone$.value?.deviceId ? { exact: this.selectedMicrophone$.value?.deviceId } : null,
      echoCancellation: this.echoCancellation$.value ?? false,
      channelCount: 1,
    };

    const newConstraints: { audio: MediaTrackConstraints; video: false | MediaTrackConstraints } = {
      audio: audioConstraints,
      video: videoConstraints,
    };
    if (!this.selectedCamera$.value || !this.hasPermission$.value[1]) newConstraints.video = false;
    if (newConstraints.video === false && this.systemDevices$.value && this.studioSession$.value) {
      this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.user$.value.uid, true);
    }
    this.constraints$.next(newConstraints);
  }

  /**
   * Updates the echoCancellation Field in the equipment document in Firestore.
   *
   * @param echoCancellation - boolean
   * @param sessionID? - String
   * @param uid? - String
   */
  setEchoCancellation(echoCancellation: boolean, sessionID?: string, uid?: string) {
    if (echoCancellation == null) return;
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { echoCancellation },
      { merge: true }
    );
  }

  /**
   * Updates the pcmAudio Field in the equipment document in Firestore.
   *
   * @param pcmAudio - boolean
   * @param sessionID? - String
   * @param uid? - String
   */
  setPCMAudio(pcmAudio: boolean, sessionID?: string, uid?: string) {
    if (pcmAudio == null) return;
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;
    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { pcmAudio },
      { merge: true }
    );
  }

  /**
   * Updates the videoQuality Field in the equipment document in Firestore.
   *
   * @param videoQuality - VideoResolutions
   * @param sessionID? - String
   * @param uid? - String
   */
  setVideoQuality(videoResolution: VideoResolutions, sessionID?: string, uid?: string) {
    if (videoResolution == null) return;
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { videoResolution },
      { merge: true }
    );
  }

  /**
   * Updates the sampleRate Field in the equipment document in Firestore.
   *
   * @param sampleRate - number
   * @param sessionID? - String
   * @param uid? - String
   */
  setSampleRate(sampleRate: number, sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { sampleRate },
      { merge: true }
    );
  }

  /**
   * Updates the videoTrackSettings Field in the equipment document in Firestore.
   *
   * @param videoTrackSettings - MediaTrackSettings
   * @param sessionID? - String
   * @param uid? - String
   */
  setVideoTrackSettings(videoTrackSettings: MediaTrackSettings, sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { videoTrackSettings },
      { merge: true }
    );
  }

  /**
   * Updates the supportedResolutions Field in the equipment document in Firestore.
   *
   * @param supportedResolutions - Array<VideoResolutions>
   * @param sessionID? - String
   * @param uid? - String
   */
  setSupportedResolutions(supportedResolutions: VideoResolutions[], sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { supportedResolutions },
      { merge: true }
    );
  }

  /**
   * Updates the availableDevices Field in the equipment document in Firestore.
   *
   * @param devices - MediaDeviceInfo[]
   * @param sessionID? - String
   * @param uid? - String
   */
  setAvailableDevices(devices: MediaDeviceInfo[], sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    const deviceMap = devices.reduce(
      (acc, device) => {
        acc[device.kind].push({ deviceId: device.deviceId, label: this.filterDeviceName(device.label) });
        return acc;
      },
      { audioinput: [], audiooutput: [], videoinput: [] }
    );

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { availableDevices: deviceMap },
      { merge: true }
    );
  }

  /**
   * Updates the microphone audioIn Field in the equipment document in Firestore.
   *
   * @param device - MediaDeviceInfo
   * @param sessionID? - String
   * @param uid? - String
   */
  setMicrophone(device: MediaDeviceInfo, sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value?.sessionID;
    if (!sessionID) return;
    if (!uid) uid = this.userService.activeUser$.value.uid;
    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { audioIn: device ? { deviceId: device.deviceId, label: device.label } : null },
      { merge: true }
    );
  }

  /**
   * Updates the headphones audioOut Field in the equipment document in Firestore.
   *
   * @param device - MediaDeviceInfo
   * @param sessionID? - String
   * @param uid? - String
   */
  setHeadphones(device: MediaDeviceInfo, sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value?.sessionID;
    if (!sessionID) return;
    if (!uid) uid = this.userService.activeUser$.value.uid;
    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { audioOut: device ? { deviceId: device.deviceId, label: device.label } : null },
      { merge: true }
    );
  }

  /**
   * Updates the camera videoIn Field in the equipment document in Firestore.
   *
   * @param device - MediaDeviceInfo
   * @param sessionID? - String
   * @param uid? - String
   */
  setCamera(device: MediaDeviceInfo, sessionID?: string, uid?: string) {
    if (!sessionID) sessionID = this.studioSession$.value?.sessionID;
    if (!sessionID) return;
    if (!uid) uid = this.userService.activeUser$.value.uid;
    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { videoResolution: null, videoIn: device ? { deviceId: device.deviceId, label: device.label } : null },
      { merge: true }
    );
  }

  /**
   * Updates the deviceInfo field in the equipment document in Firestore.
   *
   * @param deviceInfo - DeviceInfo
   * @param sessionID - String
   * @param uid -   String
   * @returns Promise<void>
   */
  setDeviceInfo(deviceInfo: DeviceInfo, sessionID?: string, uid?: string): Promise<void> {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!uid) uid = this.userService.activeUser$.value.uid;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { deviceInfo: deviceInfo ? deviceInfo : null },
      { merge: true }
    );
  }

  /**
   *  Returns equipment document from the environment collection. This listens on any update to equipment document.
   *
   * @param userID - String
   * @param sessionID - String
   * @returns
   */
  getParticipantEquipment(userID?: string, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!userID) userID = this.userService.activeUser$.value.uid;

    return docData<Equipment>(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, userID, SessionsCollection.ENV, 'equipment')
    );
  }

  /**
   * Sets the microphone volume for a participant
   *
   * @param sessionID
   * @param uid
   * @param volume
   */
  setMicrophoneVolume(sessionID: string, uid: string, volume: number) {
    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment'),
      { volume },
      { merge: true }
    );
  }

  /**
   * Gets the microphone volume for a participant
   *
   * @param sessionID
   * @param uid
   */
  getMicrophoneVolume(sessionID: string, uid: string) {
    return docData<Equipment>(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment')
    ).pipe(map((equipment) => equipment?.volume));
  }

  setHasPermission(hasPermission: boolean[]) {
    this.hasPermission$.next(hasPermission);
  }

  getHasPermission() {
    return this.hasPermission$.asObservable();
  }

  async setEquipmentMetrics(audioTrack?: MediaStreamTrack, videoTrack?: MediaStreamTrack) {
    const sessionID = this.studioSession$.value?.sessionID;
    if (!sessionID) return;
    if (!audioTrack && !videoTrack) {
      return;
    }
    if (!this.constraints$.value) return;

    const videoTrackSettings = videoTrack?.getSettings();
    const audioTrackSettings = audioTrack?.getSettings();

    const supportedResolutions: VideoResolutions[] = [];

    if (videoTrack && 'getCapabilities' in videoTrack) {
      const videoTrackCapabilities = videoTrack.getCapabilities();
      Object.keys(this.videoResolutions).forEach((resolution) => {
        if (
          videoTrackCapabilities.height?.max >= this.videoResolutions[resolution].height ||
          videoTrackCapabilities.width?.max >= this.videoResolutions[resolution].width
        ) {
          supportedResolutions.push(resolution as VideoResolutions);
        }
      });
    } else if (videoTrackSettings) {
      Object.keys(this.videoResolutions).forEach((resolution) => {
        // Flip portrait resolutions when checking
        const height =
          videoTrackSettings.height > videoTrackSettings.width ? videoTrackSettings.width : videoTrackSettings.height;
        const width =
          videoTrackSettings.height > videoTrackSettings.width ? videoTrackSettings.height : videoTrackSettings.width;
        if (height >= this.videoResolutions[resolution].height || width >= this.videoResolutions[resolution].width) {
          supportedResolutions.push(resolution as VideoResolutions);
        }
      });
      // Check other resolutions using specific constraints
      await Promise.all(
        Object.entries(this.videoResolutions).map(
          async ([resolution, constraint]: [VideoResolutions, { width: number; height: number }]) => {
            try {
              if (supportedResolutions.includes(resolution)) return null;
              const mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                  deviceId: { exact: videoTrackSettings.deviceId },
                  width: { exact: constraint.width },
                  height: { exact: constraint.height },
                },
              });
              mediaStream.getTracks().forEach((track) => track.stop());
              supportedResolutions.push(resolution);
            } catch (error) {
              return null;
            }
          }
        )
      );
    }

    if (audioTrackSettings?.sampleRate)
      await this.setSampleRate(audioTrackSettings.sampleRate, sessionID, this.user$.value.uid);
    if (videoTrackSettings) await this.setVideoTrackSettings(videoTrackSettings, sessionID, this.user$.value.uid);
    if (supportedResolutions.length)
      await this.setSupportedResolutions(supportedResolutions, sessionID, this.user$.value.uid);

    if (videoTrackSettings?.height) this.setVideoQuality(this.getVideoTrackResolution(videoTrackSettings));

    this.equipmentReady$.next(true);
  }

  getVideoTrackResolution(settings: MediaTrackSettings) {
    // Flip portrait resolutions when checking
    const height = settings.height > settings.width ? settings.width : settings.height;
    const width = settings.height > settings.width ? settings.height : settings.width;
    if (height >= this.videoResolutions.UHD.height || width >= this.videoResolutions.UHD.width)
      return VideoResolutions.UHD;
    else if (height >= this.videoResolutions.FHD.height || width >= this.videoResolutions.FHD.width)
      return VideoResolutions.FHD;
    else return VideoResolutions.WXGA;
  }

  async handleError(error: Error, sessionID: string = null, particitpantID: string = null) {
    let message: string;

    if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
      message =
        'No Microphone is detected. Ensure your Microphone is plugged in & powered on. Allow permission to your Microphone in your Browser & OS security settings. You may also want to restart your computer, it always helps.';
    } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
      message =
        'Your Microphone is being used by another app. Close all other apps that are using your Microphone. You may also want to restart your computer, it always helps.';
    } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
      message =
        'The Microphone you have selected is no longer available Select a different Microphone. You may also want to restart your computer, it always helps.';
    } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
      message =
        'Permission to your Microphone is required to record audio. Allow permission to your Microphone in your Browser & OS security settings. You may also want to restart your computer, it always helps.';
    } else if (error.name === 'TypeError') {
      message =
        'No Media Constraints provided. Contact Support for assistance. You may also want to restart your computer, it always helps.';
    } else {
      message = 'Contact support for assistance.';
    }

    const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
      message,
      'Failed to connect to Microphone',
      {
        progressBar: false,
        closeButton: true,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: GeneralToastComponent,
      }
    );

    toast.toastRef.componentInstance.learnMoreTopic = 'browserPermissions';

    this.analyticsService.track('errored connecting to media devices', {
      name: error.name,
      message,
      audio: this.constraints$.value?.audio,
      video: this.constraints$.value?.video,
      id: sessionID,
      particitpantID,
    });
  }
}
