<ion-row>
  <ion-col>
    <h3 class="ion-text-center">Edit Webhook</h3>

    <ion-row>
      <ion-col>
        <ion-list lines="none">
          <ion-item class="input-large">
            <ion-label position="stacked" color="lightest"> Webhook URL </ion-label>

            <ion-input
              aria-label="Webhook Input"
              [(ngModel)]="webhook.url"
              (keyup.enter)="update()"
              type="text"
              name="webhook.url"
              required>
            </ion-input>
          </ion-item>
        </ion-list>

        <ion-row>
          <ion-col *ngFor="let event of events" [sizeLg]="6" [sizeXl]="6" class="ion-no-padding">
            <ion-item lines="none">
              <ion-checkbox slot="start" mode="md" [(ngModel)]="event.isChecked"> </ion-checkbox>

              <ion-label color="lightest">
                <strong> {{ event.name }} </strong><br />
                {{ event.description }}
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="cancel()" class="grey" type="button" expand="block" aria-label="Cancel"> Cancel </ion-button>
  <ion-button (click)="update()" class="primary" type="submit" expand="block" aria-label="Confirm">
    Confirm
  </ion-button>
</div>
