import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OverlayEventDetail } from '@ionic/core';

import { environment } from '../../../environments/environment';
import { WalletService } from '../../services/wallet/wallet.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { UserService } from '../../services/user/user.service';
import { VerifyAuthPage } from '../verify-auth/verify-auth.page';
import { CancelSubscriptionConfirmPage } from '../cancel-subscription-confirm/cancel-subscription-confirm.page';
import { ConfirmSubscriptionPage } from '../confirm-subscription/confirm-subscription.page';
import { PricingService } from '../../services/pricing/pricing.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { OrganizationsService } from '../../services/organizations/organizations.service';

import dayjs from 'dayjs';
import { StripeSubscriptionResponse } from '@sc/types';

@Component({
  selector: 'sc-cancel-subscription',
  templateUrl: 'cancel-subscription.page.html',
  styleUrls: ['cancel-subscription.page.scss'],
})
export class CancelSubscriptionPage implements OnInit, OnDestroy {
  user$ = this.userService.activeUser$;
  activePlan$ = this.walletService.dashboardPlan$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;

  cancelDate: number;
  cancelDateDisplay: string;
  selected: 'pause' | 'cancel' | 'free' = 'free';

  customerID: string = null;
  subscriptions: Array<StripeSubscriptionResponse> = [];

  subs: Array<Subscription> = [];

  constructor(
    private toastrService: ToastrService,
    private modalController: ModalController,
    private walletService: WalletService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private pricingService: PricingService
  ) {}

  ngOnInit() {
    this.setupCustomerID();
  }

  async close() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed cancel subscription');
  }

  action() {
    if (this.selected === 'pause') this.pauseSubscription();
    else if (this.selected === 'cancel') this.cancelSubscription();
    else this.redirectToDescript();
  }

  redirectToDescript() {
    window.location.href = 'https://www.descript.com/pricing?utm_source=squadcast'; // TODO: Update URL?
  }

  cancelSubscription() {
    if (this.subscriptions.length) {
      this.toastrService.info(`This helps to protect your account from abuse`, `Verify your Account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });

      this.close();
      this.openVerifyAuthModal();
    }
  }

  async openCancelSubscriptionModal() {
    const modal = await this.modalController.create({
      component: CancelSubscriptionConfirmPage,
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'cancel-subscription-confirm-modal',
      componentProps: { plan: this.activePlan$.value },
    });
    await modal.present();
    await this.analyticsService.track('opened cancel subscription');
  }

  async openVerifyAuthModal() {
    const modal = await this.modalController.create({
      component: VerifyAuthPage,
      componentProps: {},
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'verify-auth-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened verify authentication');
    const outcome: OverlayEventDetail = await modal.onDidDismiss();
    if (outcome && outcome.data && outcome.data.success) {
      await this.openCancelSubscriptionModal();
    }
  }

  async pauseSubscription() {
    try {
      this.close();

      const planID = environment.production ? 'price_1KxyufKP34R6C976M53Hmr3K' : 'price_1KxypRKP34R6C976RvWb5IQj';
      const selectedPlan = await firstValueFrom(this.pricingService.getPlan(planID));

      const modal = await this.modalController.create({
        component: ConfirmSubscriptionPage,
        componentProps: {
          selectedPlan,
          page: 'upgrade',
        },
        showBackdrop: true,
        backdropDismiss: false,
        animated: true,
        cssClass: 'confirm-subscription-modal',
      });
      await modal.present();
      await this.analyticsService.track('selected plan', { selectedPlanID: selectedPlan.id });
    } catch (error) {}
  }

  setupCustomerID() {
    const sub = this.walletService.customerID$.subscribe((id) => {
      if (!id) {
        return;
      }

      this.customerID = id;

      if (!this.subscriptions.length) {
        this.setupSubscription();
      }
    });

    this.subs.push(sub);
  }

  setupSubscription() {
    const sub = this.walletService.customer$.subscribe((customer) => {
      this.subscriptions = customer.subscriptions.data;
      this.subscriptions.forEach((subscription) => {
        if (!this.cancelDate || subscription.current_period_end > this.cancelDate)
          this.cancelDate = subscription.current_period_end;
        this.cancelDateDisplay = dayjs.unix(this.cancelDate).format('MMMM Do, YYYY');
      });
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
