<ion-row>
  <ion-col>
    <h5 class="bold">{{ prompt.title }}</h5>

    <h6>{{ prompt.message }}</h6>
  </ion-col>
</ion-row>

<ion-row *ngIf="prompt && prompt.buttons" class="ion-text-center">
  <ion-col *ngFor="let button of prompt.buttons">
    <ion-button (click)="button.handler(prompt)" class="text" type="button" shape="round">
      {{ button.label }}
    </ion-button>
  </ion-col>
</ion-row>
