import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { Webhook, WebhookEvent, WebhookPayload } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Show, UpdateShowDto } from '@sc/types';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { UserService } from '../../services/user/user.service';
import { ZapierService } from '../../services/zapier/zapier.service';

@Component({
  selector: 'sc-transfer-show',
  templateUrl: 'transfer-show.page.html',
  styleUrls: ['transfer-show.page.scss'],
})
export class TransferShowPage implements OnInit, OnDestroy {
  @Input() show: Show;
  transferKey: string;

  subs: Array<Subscription> = [];

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private califoneService: CalifoneService,
    private idTokenService: IdTokenService,
    private lafayetteService: LafayetteService,
    private organizationsService: OrganizationsService,
    private usersService: UserService,
    private zapierService: ZapierService
  ) {}

  ngOnInit() {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed delete show modal', { showID: this.show.showID });
  }

  async transferShow() {
    if (this.show.orgID === this.transferKey) {
      await this.modalController.dismiss();

      this.toastrService.error(`Get a Transfer Key from the new Organization`, `Failed to Transfer Show`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      return;
    }

    try {
      const { protocol, host } = window.location;
      const showMembers = this.show.memberIDs.map((uid: string) => {
        return { uid };
      });
      const show: UpdateShowDto = { ...this.show, transferOrgID: this.transferKey, showMembers };
      delete show.memberIDs;
      await this.lafayetteService.updateShow(show);

      const newOrg = await firstValueFrom(this.organizationsService.getOrg(this.transferKey));
      const newOrgOwner = await firstValueFrom(this.usersService.getUserData(newOrg.orgOwner));
      // await firstValueFrom(
      const showTransferInvite = await this.zapierService.createShowTransferInvite({
        title: `${this.usersService.activeUser$.value.email} initiated a transfer of ${this.show.showName} into ${newOrg.orgName}`,
        fromEmail: this.usersService.activeUser$.value.email,
        toEmail: newOrgOwner.email.toLowerCase(),
        showName: this.show.showName,
        showImg: this.show.showImg,
        url: `${protocol}//${host}/account/shows`,
      });
      const showTransferInviteID = showTransferInvite.id;
      await this.zapierService.sendShowTransferEmail(showTransferInviteID);
      // );
      await this.modalController.dismiss();

      this.toastrService.info(null, `Transfer Initiated`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('transferred show', { showID: this.show.showID, success: true });
    } catch (error) {
      await this.analyticsService.track('transferred show', { showID: this.show.showID, success: false });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
