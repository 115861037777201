import { Injectable } from '@angular/core';
import {
  Storage,
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
  uploadBytes,
  deleteObject,
  StorageReference,
  listAll,
} from '@angular/fire/storage';
import { getApp } from '@angular/fire/app';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  imageBucket: Storage;
  recordingBucket: Storage;
  testRecordingBucket: Storage;

  constructor() {
    this.setupBuckets();
  }

  setupBuckets() {
    this.imageBucket = getStorage(getApp(), environment.firebase.imageBucket);
    this.recordingBucket = getStorage(getApp(), environment.firebase.recordingBucket);
    this.testRecordingBucket = getStorage(getApp(), environment.firebase.testRecordingBucket);
  }

  async uploadImage(path: string, name: string, image: File | string | Blob) {
    const imgRef = ref(this.imageBucket, path);
    const img = ref(imgRef, name);
    if (typeof image === 'string') {
      await uploadString(img, image, 'data_url');
    } else {
      await uploadBytes(img, image);
    }
    return getDownloadURL(img);
  }

  async deleteImage(path: string, name: string) {
    const imgRef = ref(this.imageBucket, path);
    const img = ref(imgRef, name);
    return deleteObject(img);
  }

  captureScreenshotFromMediaStream(constraints): Promise<Blob> {
    return new Promise(async (res, rej) => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: constraints,
        audio: false,
      });
      const streamTimeout = setTimeout(() => {
        mediaStream.getTracks().forEach((track) => track.stop());
      }, 10000);

      if (!mediaStream) {
        rej('Cannot capture Blob from Media Stream: No Media Stream provided.');
        return;
      }

      const video = document.createElement('video');
      video.muted = true;
      const canvas = document.createElement('canvas');

      video.onplaying = async () => {
        // wait for the next couple frames of the video because firefox 🤬
        await new Promise((res) => setTimeout(res, 250));
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);

        canvas.toBlob((blob) => {
          clearInterval(streamTimeout);
          mediaStream.getTracks().forEach((track) => track.stop());
          res(blob);
        });
      };

      video.onloadeddata = () => {
        video.play();
      };

      video.srcObject = mediaStream;
    });
  }

  async imageUrlToBase64(URL): Promise<string> {
    return new Promise((res) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.addEventListener('load', () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext('2d').drawImage(image, 0, 0);
        res(canvas.toDataURL('image/png'));
      });
      image.src = URL;
    });
  }

  async bucketRefDeleteRecursive(bucketRef: StorageReference) {
    const files = await listAll(bucketRef);
    await Promise.all(files.items.map((file) => deleteObject(file)));
    await Promise.all(files.prefixes.map((prefix) => this.bucketRefDeleteRecursive(prefix)));
  }
}
