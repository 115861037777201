import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  DocumentReference,
  collection,
  collectionData,
  doc,
  docData,
  documentId,
  getDoc,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, of, switchMap } from 'rxjs';
import { CreateShowDto, Show, ShowMember } from '@sc/types';
import { StorageService } from '../storage/storage.service';
import { Roles } from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { LafayetteService } from '../lafayette/lafayette.service';
import { ServerResponse } from '@sc/types';
import { WalletService } from '../wallet/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class ShowsService {
  public DEFAULT_IMG =
    'https://storage.googleapis.com/squadcast-7fa10.appspot.com/squadcast-media/SC_V5_Show-Default.png';

  public availableShows$ = new SCSubject<Show[]>();
  public dashboardShow$ = new SCSubject<Show>(null);
  public dashboardShowID$ = new SCSubject<string>(null);
  public dashboardOrgShows$ = new SCSubject<Show[]>();

  private user$ = this.userService.activeUser$;
  private orgRole$ = this.organizationsService.orgRole$;

  private checkingDefault = false;

  constructor(
    private firestore: Firestore,
    private titleService: Title,
    private lafayetteService: LafayetteService,
    private storageService: StorageService,
    private organizationsService: OrganizationsService,
    private walletService: WalletService,
    private router: Router,
    private userService: UserService
  ) {
    this.setupAvailableShows();
    this.setupDashboardShow();
    this.setupDashboardOrgShows();
  }

  /**
   * Checks if a show exists from the URL params before navigating to the studio page.
   *
   * @param sessionID String
   * @returns Promise<boolean>
   */
  async checkShow(showID?: string): Promise<boolean> {
    if (!showID) return false;
    const showRef = doc(this.firestore, `shows/${showID}`);
    const showSnap = await getDoc(showRef);
    return showSnap.exists();
  }

  async uploadShowImage(showID: string, image: string) {
    const url = await this.storageService.uploadImage(showID, 'showImg', image);
    const showDoc = doc(this.firestore, `shows/${showID}`);
    await setDoc(showDoc, { showImg: url }, { merge: true }).catch((err) => {
      if (err.code === 'not-found') {
        setTimeout(() => {
          setDoc(showDoc, { showImg: url }, { merge: true });
        }, 1000);
      } else {
        throw err;
      }
    });
    return url;
  }

  getShowsByIDs(showIDs: string[]) {
    const showsRef = collection(this.firestore, 'shows') as CollectionReference<Show>;
    if (showIDs.length > 10) showIDs = showIDs.slice(0, 10);
    return collectionData(query(showsRef, where(documentId(), 'in', showIDs)), { idField: 'showID' });
  }

  getShowByID(showID: string) {
    const showRef = doc(this.firestore, `shows/${showID}`) as DocumentReference<Show>;
    return docData(showRef, { idField: 'showID' });
  }

  getShowMembers(showID: string) {
    const membersRef = collection(this.firestore, `shows/${showID}/showMembers`) as CollectionReference<ShowMember>;
    return collectionData(membersRef, { idField: 'uid' });
  }

  getShowMember(showID: string, uid?: string) {
    if (!uid) uid = this.user$.value.uid;
    const showMemberRef = doc(this.firestore, `shows/${showID}/showMembers/${uid}`) as DocumentReference<ShowMember>;
    return docData(showMemberRef, { idField: 'uid' });
  }

  editShow(showID: string) {
    this.router.navigate(['dashboard/edit-show', showID], { replaceUrl: true });
  }

  async createDefaultShow() {
    const defaultShow: CreateShowDto = {
      showName: 'Default Show',
      showSubtitle: 'Default Subtitle',
      showOwner: this.user$.value.uid,
      showMembers: [{ uid: this.user$.value.uid, role: Roles.ORG_ADMIN }],
      orgID: this.organizationsService.dashboardOrgID$.value,
    };
    const resp = (await this.lafayetteService.createShow(defaultShow)) as ServerResponse;
    this.checkingDefault = false;
    return resp.ID;
  }

  getTransferOrgShows(orgID?: string): Observable<Show[]> {
    const id = orgID || this.organizationsService.dashboardOrgID$.value;
    const showsRef = collection(this.firestore, 'shows') as CollectionReference<Show>;
    return collectionData(query(showsRef, where('transferOrgID', '==', id)), { idField: 'showID' });
  }

  setupDashboardShow() {
    this.dashboardShowID$
      .pipe(
        switchMap((showID) => {
          if (!showID) return of(null);
          return this.getShowByID(showID);
        })
      )
      .subscribe((show) => {
        if (show && !show.showImg) show.showImg = this.DEFAULT_IMG;
        this.dashboardShow$.next(show);
        if (show) this.titleService.setTitle(`${show.showName} - SquadCast`);
        else this.titleService.setTitle(`SquadCast`);
      });

    this.organizationsService.dashboardOrgID$.subscribe((orgID) => {
      this.dashboardShowID$.next(null);
    });
  }

  setDashboardShow(showID: string) {
    this.dashboardShowID$.next(showID);
  }

  async setupAvailableShows() {
    this.organizationsService.dashboardOrgID$
      .pipe(
        switchMap(() => this.orgRole$),
        switchMap((role) => {
          if (role >= Roles.ORG_ADMIN) return this.getOrgShows(this.organizationsService.dashboardOrgID$.value);
          else if (role) return this.getMemberShows(this.user$.value.uid);
          else return of(null);
        })
      )
      .subscribe((shows: Show[]) => {
        if (shows === undefined || shows === null) return;
        if (shows.length === this.availableShows$.value?.length) {
          const showIDs = new Set(shows.map((show) => show.showID));
          if (this.availableShows$.value.every((show) => showIDs.has(show.showID))) {
            return;
          }
        }

        shows = shows.map((show) => {
          if (!show.showImg) show.showImg = this.DEFAULT_IMG;
          return show;
        });
        this.availableShows$.next(shows);
        if (!shows.length && this.orgRole$.value >= Roles.ORG_ADMIN) this.checkDefaultShow();
      });
  }

  setupDashboardOrgShows() {
    this.organizationsService.dashboardOrg$.pipe(switchMap((org) => this.getOrgShows(org.orgID))).subscribe((shows) => {
      this.dashboardOrgShows$.next(shows);
    });
  }

  getMemberShows(userID?: string) {
    const uid = userID || this.user$.value.uid;
    const orgID = this.organizationsService.dashboardOrgID$.value;
    const showsRef = collection(this.firestore, 'shows') as CollectionReference<Show>;
    return collectionData(query(showsRef, where('memberIDs', 'array-contains', uid), where('orgID', '==', orgID)), {
      idField: 'showID',
    });
  }

  private async checkDefaultShow() {
    if (this.checkingDefault) return;
    this.checkingDefault = true;
    const plan = await this.walletService.dashboardPlanID$.toPromise();
    if (plan && this.availableShows$.value.length === 0) this.createDefaultShow();
  }

  private getOrgShows(orgID?: string): Observable<Show[]> {
    const id = orgID || this.organizationsService.dashboardOrgID$.value;
    const showsRef = collection(this.firestore, 'shows') as CollectionReference<Show>;
    return collectionData(query(showsRef, where('orgID', '==', id)), { idField: 'showID' });
  }
}
