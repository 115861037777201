<div class="banner" #bannerElm>
  <ion-img *ngIf="banner.bannerOptions?.icon" [src]="banner.bannerOptions.icon"></ion-img>

  <p>
    <strong *ngIf="banner.title"> {{ banner.title }}: </strong>
    <span class="ion-hide-sm-down">{{ banner.description }}</span>
  </p>

  <ion-button
    *ngIf="banner.link && banner.bannerOptions?.buttonTitle"
    class="banner-button"
    (click)="close()"
    [href]="banner.link">
    {{ banner.bannerOptions.buttonTitle }}
  </ion-button>

  <ion-button class="close" aria-label="Close" (click)="close()">
    <ion-icon src="/assets/icons/24px/close.svg" slot="icon-only"> </ion-icon>
  </ion-button>
</div>
