import { Injectable, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SCSubject } from '../../util/sc-subject.class';

interface Constraints {
  name: 'sm' | 'md' | 'lg' | 'xl';
  width: number;
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  constraints$ = new SCSubject<Constraints>();

  mobileNavOpen$ = new SCSubject<boolean>(false);
  mobileProfileNavOpen$ = new SCSubject<boolean>(false);
  searchPageFiltersOpen$ = new SCSubject<boolean>(false);

  constructor(private platform: Platform) {
    this.check();
  }

  check() {
    const width = this.platform.width();

    if (width <= 576) {
      this.constraints$.next({ name: 'sm', width });
    } else if (width > 576 && width <= 768) {
      this.constraints$.next({ name: 'md', width });
    } else if (width > 768 && width <= 992) {
      this.constraints$.next({ name: 'lg', width });
    } else if (width > 992) {
      this.constraints$.next({ name: 'xl', width });
    }

    // https://ionicframework.com/docs/layout/grid#default-breakpoints
  }

  getConstraints() {
    return this.constraints$.asObservable().pipe(debounceTime(500));
  }
}
