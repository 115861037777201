import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { environment } from '../../../environments/environment';
import { Webhook, WebhookEvent } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

@Component({
  selector: 'sc-edit-webhook',
  templateUrl: 'edit-webhook.page.html',
  styleUrls: ['edit-webhook.page.scss'],
})
export class EditWebhookPage implements OnInit, OnDestroy {
  @Input() dashboardOrg: string;
  @Input() webhook: Webhook;
  webhookEvents: WebhookEvent[];
  // webhookEvents = availableWebhooks;
  events: WebhookEvent[];

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private califoneService: CalifoneService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.events = this.califoneService.availableWebhooks;
    this.webhook.events.map((event) => {
      this.events.find((e) => e.name === event.name).isChecked = true;
    });
  }

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('cancelled edit webhook', { webhookID: this.webhook.id });
  }

  async update() {
    this.webhook.events = this.events
      .filter((event) => event.isChecked)
      .map((event) => {
        return { name: event.name };
      });

    if (!this.webhook.events.length || !this.webhook.url) {
      this.toastrService.error(`Select an event and provide a URL`, `Failed to update Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (environment.production && (this.webhook.url.includes('localhost') || this.webhook.url.includes('127.0.0.1'))) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
        `Consider using Localtunnel instead of localhost for testing`,
        `Failed to update Webhook`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.link = 'https://localtunnel.github.io/www';

      return;
    }

    if (environment.production && !this.webhook.url.includes('https')) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
        `HTTPS is required`,
        `Failed to update Webhook`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.link = 'https://www.eff.org/encrypt-the-web';

      return;
    }

    try {
      await this.califoneService.updateWebhook(this.dashboardOrg, this.webhook);

      this.toastrService.success(null, `Successfully updated Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('updated webhook', { webhookID: this.webhook.id, success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to update Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('updated webhook', { webhookID: this.webhook.id, success: false });
      throw error;
    }
  }

  ngOnDestroy() {
    this.events.forEach((event) => (event.isChecked = false));
  }
}
