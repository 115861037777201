import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { StripeCard } from 'stripe-angular';
import Stripe  from 'stripe';
import { ToastrService } from 'ngx-toastr';

import { AmplionService } from '../../services/amplion/amplion.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { ReCAPTCHAService } from '../../services/recaptcha/recaptcha.service';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '@sc/types';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { ThemeService } from '../../services/theme/theme.service';
import { Themes } from '@sc/types';

@Component({
  selector: 'sc-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent implements OnInit, OnDestroy {
  @ViewChild('stripeCard') stripeCard: StripeCard;

  saveSuccess$ = new Subject<boolean>();

  theme$ = this.themeService.theme$;
  LIGHT_THEME = Themes.LIGHT;

  invalidError: stripe.Error = { message: null, type: null, charge: null };
  cardDetailsFilledOut = false;
  name: string;
  orgName: string;
  orgID: string;
  billingEmail: string;

  user: UserModel;
  customerID: string;
  cardDetails: Stripe.Card;

  stripeCardSettingsLight: stripe.elements.ElementsOptions = {
    style: {
      base: {
        color: '#161d31', // darker
        iconColor: '#161d31', // darker
        '::placeholder': { color: '#2d3446' }, // darker tint
        fontSize: '16px',
      },
      invalid: {
        color: '#D50000', // danger
        iconColor: '#D50000', // danger
      },
    },
  };
  stripeCardSettingsDark: stripe.elements.ElementsOptions = {
    style: {
      base: {
        color: '#ededed', // darker
        iconColor: '#ededed', // darker
        '::placeholder': { color: '#999999' }, // darker tint
        fontSize: '16px',
      },
      invalid: {
        color: '#D50000', // danger
        iconColor: '#D50000', // danger
      },
    },
  };

  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private amplionService: AmplionService,
    private idTokenService: IdTokenService,
    private lafayetteService: LafayetteService,
    private organizationsService: OrganizationsService,
    private reCAPTCHAService: ReCAPTCHAService,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    private themeService: ThemeService
  ) {}

  async ngOnInit() {
    this.setupUser();
    this.setupReCAPTCHA();
    this.setupCustomerID();
    this.setupTokens();
    const org = await this.organizationsService.dashboardOrg$.toPromise();
    this.orgID = org.orgID;
    this.orgName = org.orgName;
    this.billingEmail = org.billingEmail;
    if (org.customerID) await this.walletService.refreshStripeCustomer();
    if (!this.billingEmail) {
      const user = await this.userService.activeUser$.toPromise();
      this.billingEmail = user.email;
    }
  }

  async onStripeInvalid(error: stripe.Error) {
    this.toastrService.error(null, `Failed to add Payment Info`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });

    await this.analyticsService.track('updated payment info', { success: false });
  }

  async setStripeToken(token: stripe.Token) {
    await this.checkCustomerExists();
    const resp = await this.amplionService.updateCustomer({ source: token.id }, this.customerID).catch((error) => {
      this.saveSuccess$.next(false);
      const message = error.error?.raw.message || 'Invalid Card Information';
      this.toastrService.error(message, `Failed to add Payment Info`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    });
    if (resp) {
      await this.walletService.refreshStripeCustomer();
      this.saveSuccess$.next(true);
      // this.walletService.setPaused(false);

      await this.analyticsService.track('updated payment info', { success: true });
    }
  }

  async onStripeError(error: stripe.Error) {
    this.toastrService.error(null, `Failed to add Payment Info`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
    await this.analyticsService.track('updated payment info', { success: false });
  }

  async checkCustomerExists() {
    if (this.customerID) return;

    const resp = await this.amplionService.addCustomer({
      email: this.billingEmail,
      description: this.orgName,
      metadata: { orgID: this.orgID },
    });
    this.customerID = resp.customer.id;
    this.walletService.setCustomerID(this.customerID);
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user: UserModel) => {
      this.user = user;
    });

    this.subs.push(sub);
  }

  setupCustomerID() {
    const sub = this.walletService.customerID$.subscribe((id) => {
      this.customerID = id;
    });

    this.subs.push(sub);
  }

  async setupTokens() {
    const sub = this.walletService.cardOnFile$.subscribe((card) => {
      if (card) {
        this.name = card.name;
        this.cardDetailsFilledOut = true;
        this.cardDetails = card;
      }
    });

    this.subs.push(sub);
  }

  async setupReCAPTCHA() {
    const idToken = await this.idTokenService.getFreshIdToken();
    const resp = await this.reCAPTCHAService.execute(idToken, { action: 'paymentInfo' });
    if (!resp.verification || !resp.verification.success || resp.verification.score < 0.5) {
      this.toastrService.info(
        `If you are seeing this by mistake please contact Support`,
        `It looks like you might be a Robot & that's not cool 🤖`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      await this.analyticsService.track('opened recaptcha robot warning', { score: resp.verification.score });
    }
  }

  async save() {
    await this.stripeCard.createToken({ name: this.name });
    await this.analyticsService.track('saved payment info');
  }

  async saveData() {
    await this.lafayetteService.updateOrg({
      orgID: this.orgID,
      billingEmail: this.billingEmail,
      orgName: this.orgName,
    });
    await this.amplionService.updateCustomer({ email: this.billingEmail, description: this.orgName }, this.customerID);
  }

  resetCardInfo() {
    this.name = null;
    this.cardDetails = null;
    this.cardDetailsFilledOut = false;

    this.setupReCAPTCHA();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
