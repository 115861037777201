import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Auth, updateEmail } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Rollbar from 'rollbar';

import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { UserModel } from '@sc/types';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { VerifyAuthPage } from '../verify-auth/verify-auth.page';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { FireAuthErrors } from '@sc/types';

@Component({
  selector: 'sc-edit-email',
  templateUrl: 'edit-email.page.html',
  styleUrls: ['edit-email.page.scss'],
})
export class EditEmailPage implements OnInit, OnDestroy {
  user: UserModel;
  customerID: string;
  currentEmail: string;
  password: string;
  newEmail: string;
  emailVerified: boolean;
  subs: Array<Subscription> = [];

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private userService: UserService,
    private authService: AuthService,
    private fireAuth: Auth,
    private supportCenterService: SupportCenterService,
    private walletService: WalletService,
    private idTokenService: IdTokenService,
    private analyticsService: AnalyticsService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  ngOnInit() {
    this.setupUser();
    this.setupCustomerID();
  }

  async saveEmail() {
    if (!this.currentEmail || !this.password || !this.newEmail) {
      this.toastrService.warning(null, `Double-check your Email, Password, and new Email`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      await this.idTokenService.getFreshIdToken();
      const login = await this.authService.loginWithEmail(this.currentEmail, this.password, false);
      await updateEmail(login.user, this.newEmail);
      await this.userService.setUserProfile({ email: this.newEmail });
      this.close();

      this.toastrService.success(null, `Successfully updated Email Address`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('saved email', { success: true });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        this.openVerifyAuthModal();
      } else {
        this.rollbar.error('Failed to update auth email', {
          error,
          currentEmail: this.currentEmail,
          newEmail: this.newEmail,
        });
        const niceError = FireAuthErrors[error.code] || error.message;
        this.toastrService.error(niceError, `Failed to update Email Address`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });

        await this.analyticsService.track('saved email', { success: false });
      }
    }
  }

  async openVerifyAuthModal() {
    const modal = await this.modalController.create({
      component: VerifyAuthPage,
      componentProps: {},
      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      cssClass: 'verify-auth-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened verify authentication');
    await modal.onDidDismiss().then((resp) => {
      if (resp.data.success) this.saveEmail();
    });
  }

  verifyEmail() {}

  close() {
    this.currentEmail = '';
    this.password = '';
    this.newEmail = '';
    this.customerID = '';

    this.modalController.dismiss();
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user: UserModel) => {
      this.user = user;
      this.currentEmail = this.user.email;
      this.emailVerified = this.fireAuth.currentUser.emailVerified;
    });

    this.subs.push(sub);
  }

  setupCustomerID() {
    const sub = this.walletService.customerID$.subscribe((id) => {
      this.customerID = id;
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
