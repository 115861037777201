import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SessionsService } from '../../services/sessions/sessions.service';
import { SquadshotService } from '../../services/squadshot/squadshot.service';
import { LinksService } from '../../services/links/links.service';

@Component({
  selector: 'sc-share-squadshot',
  templateUrl: './share-squadshot.page.html',
  styleUrls: ['./share-squadshot.page.scss'],
})
export class ShareSquadshotPage {
  @Input() url: string;

  studioShow$ = this.sessionsService.studioShow$;

  constructor(
    private sessionsService: SessionsService,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private linksService: LinksService
  ) {}

  shareFacebook() {
    // TODO: Implement share (this.url - squadshot image) with facebook
  }
  shareInstagram() {
    // TODO: Implement share (this.url - squadshot image) with instagram
  }
  shareDribbble() {
    // TODO: Implement share (this.url - squadshot image) with dribbble
  }
  async shareTwitter() {
    // https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/parameter-reference1
    const title = encodeURIComponent(`${this.studioShow$.value.showName} SquadShot`);
    const hashtags = 'squadshot,squadcast';
    const shareLink = await this.linksService.getSquadshotShareLink(
      this.url,
      this.sessionsService.studioSession$.value.sessionID
    );
    const link = `https://twitter.com/intent/tweet?text=${title}&url=${shareLink}&hashtags=${hashtags}`;
    window.open(link, '_blank');
    await this.analyticsService.track('shared squadshot', { platform: 'twitter', url: link });
  }

  async downloadImage() {
    window.open(this.url, '_blank');
    await this.analyticsService.track('downloaded squadshot');
  }

  async copyLink() {
    const shareLink = await this.linksService.getSquadshotShareLink(
      this.url,
      this.sessionsService.studioSession$.value.sessionID
    );
    await navigator.clipboard.writeText(shareLink);

    this.toastrService.info(`SquadShot image link copied to clipboard.`, `SquadShot`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: true,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });

    await this.analyticsService.track('downloaded squadshot', { url: this.url });
  }
}
