<ion-list lines="none">
  <ion-item (click)="takeTour()" alt="SquadCast Tour" button="true">
    <ion-icon src="/assets/icons/24px/light-on.svg" slot="start"> </ion-icon>

    <ion-label> Take Tour </ion-label>

    <ion-icon src="/assets/icons/24px/chevron-right.svg" slot="end"> </ion-icon>
  </ion-item>

  <ion-item (click)="openSupportDocs()" alt="SquadCast Docs" button="true">
    <ion-icon src="/assets/icons/24px/text-file.svg" slot="start"> </ion-icon>

    <ion-label> Support Docs </ion-label>
  </ion-item>

  <ion-item
    (click)="close()"
    href="https://www.youtube.com/watch?v=gPB5za0ZCSI&list=PLv7o5DBGCJxhcSVVizJodpreGaGKLOsjm"
    target="_blank"
    alt="SquadCast Videos"
    button="true">
    <ion-icon src="/assets/icons/24px/playback.svg" slot="start"> </ion-icon>

    <ion-label> Watch Videos </ion-label>
  </ion-item>

  <ion-item
    (click)="close()"
    href="https://www.youtube.com/watch?v=pYBn71-q-Cc"
    target="_blank"
    alt="SquadCast Remote Recording Checklist Video"
    button="true">
    <ion-icon src="/assets/icons/24px/playback.svg" slot="start"> </ion-icon>

    <ion-label> Remote Recording Checklist </ion-label>
  </ion-item>

  <ion-item
    (click)="close()"
    href="https://www.youtube.com/watch?v=hz2YY1bZzEo"
    target="_blank"
    alt="SquadCast Tips About Your Guest Video"
    button="true">
    <ion-icon src="/assets/icons/24px/playback.svg" slot="start"> </ion-icon>

    <ion-label> Tips About Your Guest </ion-label>
  </ion-item>

  <ion-item (click)="close()" href="https://squadcast.fm/podcast" target="_blank" alt="SquadCast Podcast" button="true">
    <ion-icon src="/assets/icons/24px/headphones.svg" slot="start"> </ion-icon>

    <ion-label> The SquadCast Podcast </ion-label>
  </ion-item>

  <ion-item
    (click)="close()"
    href="https://status.descript.com/"
    target="_blank"
    alt="SquadCast Service Status"
    button="true">
    <ion-icon src="/assets/icons/24px/health.svg" slot="start"> </ion-icon>

    <ion-label> Check Status </ion-label>
  </ion-item>

  <ion-item
    (click)="close()"
    href="https://squadcast.fm/community"
    target="_blank"
    alt="SquadCast Community"
    button="true">
    <ion-icon src="/assets/icons/24px/slack.svg" slot="start"> </ion-icon>

    <ion-label> Join Community </ion-label>
  </ion-item>

  <ion-item (click)="openSupportEmail()" alt="SquadCast Support" button="true">
    <ion-icon src="/assets/icons/24px/email.svg" slot="start"> </ion-icon>

    <ion-label> Contact Support </ion-label>
  </ion-item>
</ion-list>
