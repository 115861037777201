import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore, initializeFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, UserTrackingService, ScreenTrackingService } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StripeModule } from 'stripe-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';

import { environment } from '../environments/environment';
import { SquadCastComponent } from './squadcast.app.component';
import { SquadCastComponentsModule } from './components/squadcast.components.module';
import { SquadCastDirectivesModule } from './directives/squadcast.directives.module';
import { SquadCastMenusModule } from './menus/squadcast.menus.module';
import { SquadCastPipesModule } from './pipes/squadcast.pipes.module';
import { SquadCastRoutesModule } from './pages/squadcast.routes.module';

import { GuardService } from './services/guard/guard.service';
import { GuestService } from './services/guest/guest.service';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './services/rollbar/rollbar.service';

import { AboutPageModule } from './pages/about/about.module';
import { AddRecordingTimePageModule } from './modals/add-recording-time/add-recording-time.module';
import { AddSessionPageModule } from './pages/add-session/add-session.module';
import { AddShowPageModule } from './pages/add-show/add-show.module';
import { AddOrganizationPageModule } from './pages/add-organization/add-organization.module';
import { AdvancedShowSettingsModule } from './modals/advanced-show-settings/advanced-show-settings.module';
import { BetaFeedbackPageModule } from './modals/beta-feedback/beta-feedback.module';
import { BillingPageModule } from './pages/billing/billing.module';
import { CancelSubscriptionConfirmPageModule } from './modals/cancel-subscription-confirm/cancel-subscription-confirm.module';
import { ConfirmSubscriptionPageModule } from './modals/confirm-subscription/confirm-subscription.module';
import { ConnectEmailPageModule } from './modals/connect-email/connect-email.module';
import { CropPhotoModule } from './modals/crop-photo/crop-photo.module';
import { CancelSubscriptionPageModule } from './modals/cancel-subscription/cancel-subscription.module';
import { ActionConfirmModule } from './modals/action-confirm/action-confirm.module';
import { DeleteRecordingPageModule } from './modals/delete-recording/delete-recording.module';
import { DeleteWebhookPageModule } from './modals/delete-webhook/delete-webhook.module';
import { DescriptLinkModule } from './modals/descript-link/descript-link.module';
import { DevelopersPageModule } from './pages/developers/developers.module';
import { DescriptInfoPageModule } from './pages/descript-info/descript-info.module';
import { DropboxPageModule } from './modals/dropbox/dropbox.module';
import { EditEmailPageModule } from './modals/edit-email/edit-email.module';
import { EditPasswordPageModule } from './modals/edit-password/edit-password.module';
import { EditWebhookPageModule } from './modals/edit-webhook/edit-webhook.module';
import { IntegrationsPageModule } from './pages/integrations/integrations.module';
import { MembersPageModule } from './pages/members/members.module';
import { navigateAnimation } from './animations/navigate/navigate.animation';
import { NewApiKeyPageModule } from './modals/new-api-key/new-api-key.module';
import { NewWebhookPageModule } from './modals/new-webhook/new-webhook.module';
import { NotFoundPageModule } from './pages/not-found/not-found.module';
import { PasswordResetPageModule } from './modals/password-reset/password-reset.module';
import { PromptPageModule } from './modals/prompt/prompt.module';
import { ProfilePageModule } from './pages/profile/profile.module';
import { ReRenderSquadshotPageModule } from './modals/re-render-squadshot/re-render-squadshot.module';
import { ReactivateSubscriptionPageModule } from './modals/reactivate-subscription/reactivate-subscription.module';
import { RecordingTimePageModule } from './pages/recording-time/recording-time.module';
// import { ReferAFriendPageModule } from './pages/refer-a-friend/refer-a-friend.module';
import { RevokeApiKeyPageModule } from './modals/revoke-api-key/revoke-api-key.module';
import { SalesInfoPageModule } from './modals/sales-info/sales-info.module';
import { SetDefaultShowAPIModule } from './modals/set-default-show-api/set-default-show-api.module';
import { SignInMethodsPageModule } from './pages/sign-in-methods/sign-in-methods.module';
import { SettingsPageModule } from './pages/settings/settings.module';
import { ShareSquadshotModule } from './modals/share-squadshot/share-squadshot.module';
import { ShowsPageModule } from './pages/shows/shows.module';
import { SquadCasterService } from './services/squadcaster/squadcaster.service';
import { StillHerePageModule } from './modals/still-here/still-here.module';
import { SubscriptionPageModule } from './pages/subscription/subscription.module';
import { TeamInviteModule } from './modals/team-invite/team-invite.module';
import { TestEquipmentResultModule } from './modals/test-equipment-result/test-equipment-result.module';
import { TransferOrganizationComponentModule } from './modals/transfer-organization/transfer-organization.module';
import { TransferShowPageModule } from './modals/transfer-show/transfer-show.module';
import { UpdatePaymentInfoPageModule } from './modals/update-payment-info/update-payment-info.module';
import { VerifyAuthPageModule } from './modals/verify-auth/verify-auth.module';
import { WindowToken, windowProvider } from './services/window/window';
import { WorkflowActionPageModule } from './modals/workflow-action/workflow-action.module';
import { MoveSessionPageModule } from './modals/move-session/move-session.module';
import { NotificationsPageModule } from './modals/notifications/notifications.module';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = true;
  accountUrl = 'help.descript.com';
  callback(zE) {
    // You can call every command you want here
    // zE('webWidget', 'hide');
  }
}

@NgModule({
  declarations: [SquadCastComponent],
  imports: [
    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebase);
      // Uncomment following line to enable Firebase debugging
      // setLogLevel('debug');
      return app;
    }),
    provideStorage(() => getStorage()),
    provideStorage(() => getStorage(getApp(), environment.firebase.imageBucket)),
    provideFirestore(() => {
      let firestore;
      if (navigator.userAgent.indexOf('Safari') > 1) {
        firestore = initializeFirestore(getApp(), { experimentalForceLongPolling: true });
      } else {
        firestore = getFirestore();
      }

      return firestore;
    }),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    BrowserModule,
    IonicModule.forRoot({ navAnimation: navigateAnimation }),
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    StripeModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(environment.toastr),
    MatProgressSpinnerModule,
    SquadCastComponentsModule,
    SquadCastDirectivesModule,
    SquadCastMenusModule,
    SquadCastPipesModule,
    SquadCastRoutesModule,
    AboutPageModule,
    AddRecordingTimePageModule,
    AddSessionPageModule,
    AddShowPageModule,
    AddOrganizationPageModule,
    AdvancedShowSettingsModule,
    BetaFeedbackPageModule,
    BillingPageModule,
    CancelSubscriptionConfirmPageModule,
    ConfirmSubscriptionPageModule,
    ConnectEmailPageModule,
    CropPhotoModule,
    CancelSubscriptionPageModule,
    ActionConfirmModule,
    DeleteRecordingPageModule,
    DeleteWebhookPageModule,
    DescriptInfoPageModule,
    DescriptLinkModule,
    DevelopersPageModule,
    DropboxPageModule,
    EditEmailPageModule,
    EditPasswordPageModule,
    EditWebhookPageModule,
    IntegrationsPageModule,
    MembersPageModule,
    NewApiKeyPageModule,
    NewWebhookPageModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    NotificationsPageModule,
    NotFoundPageModule,
    MoveSessionPageModule,
    PasswordResetPageModule,
    PromptPageModule,
    ProfilePageModule,
    ReRenderSquadshotPageModule,
    ReactivateSubscriptionPageModule,
    RecordingTimePageModule,
    // ReferAFriendPageModule,
    RevokeApiKeyPageModule,
    SalesInfoPageModule,
    SetDefaultShowAPIModule,
    SettingsPageModule,
    ShareSquadshotModule,
    ShowsPageModule,
    SignInMethodsPageModule,
    StillHerePageModule,
    SubscriptionPageModule,
    TeamInviteModule,
    TestEquipmentResultModule,
    TransferOrganizationComponentModule,
    TransferShowPageModule,
    UpdatePaymentInfoPageModule,
    VerifyAuthPageModule,
    WorkflowActionPageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserTrackingService,
    ScreenTrackingService,
    GuardService,
    GuestService,
    SquadCasterService,
    { provide: WindowToken, useFactory: windowProvider },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [SquadCastComponent],
})
export class SquadCastModule {}
