import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { AuphonicFile, MultiTrackProductionConfig } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class AuphonicService {
  bendix = environment.microservices.bendix;

  constructor(private http: HttpClient) {}

  createMultiTrackProduction(config: MultiTrackProductionConfig) {
    const headers = new HttpHeaders().set('idToken', config.idToken);
    return this.http.post(
      `${this.bendix}/api/v5/productions`,
      {
        is_multitrack: true,
        files: config.files,
        filename: config.filename,
        metadata: {
          title: config.title,
          artist: config.showID,
          album: config.sessionID,
          track: config.take,
          publisher: config.orgID,
        },
        algorithms: config.algorithms,
      },
      { headers }
    );
  }

  startProduction(idToken: string, productionID: string, title: string, showID: string, sessionID: string) {
    const headers = new HttpHeaders().set('idToken', idToken);

    return this.http.post(
      `${this.bendix}/api/v5/productions/start`,
      {
        title,
        productionID,
        showID,
        sessionID,
      },
      { headers }
    );
  }
}
