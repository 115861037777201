import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Recording } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class BendixService {
  bendix = environment.microservices.bendix;

  constructor(private http: HttpClient) {}

  processRecording(recording: Recording, idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);

    return this.http.post(`${this.bendix}/api/v5/files`, recording, { headers });
  }

  deleteRecording(showID: string, sessionID: string, fileName: string, idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);

    const encodedFilename = encodeURIComponent(fileName);
    return this.http.delete(`${this.bendix}/api/v5/files/${showID}/${sessionID}/${encodedFilename}`, { headers });
  }
}
