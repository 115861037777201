import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

import { WalletService } from '../../services/wallet/wallet.service';
import { TrialStatus } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { PricingService } from '../pricing/pricing.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Injectable({
  providedIn: 'root',
})
export class TrialService {
  trialStatus$: SCSubject<TrialStatus> = new SCSubject({ daysLeft: 0, onTrial: false });

  constructor(private walletService: WalletService, private pricingService: PricingService) {
    this.setupTrial();
  }

  setupTrial() {
    this.walletService.primarySub$.subscribe(() => {
      this.refreshTrialStatus();
    });
  }

  async refreshTrialStatus() {
    const subscription = this.walletService.primarySub$.value;
    await this.walletService.dashboardPlan$.nextExistingValue();
    const plan = this.walletService.dashboardPlan$.value;

    if (!subscription || plan?.isDescriptPlan) {
      this.trialStatus$.next({ daysLeft: 0, onTrial: false });
      return;
    }

    const today = dayjs();
    // add one day for the "daysLeft" to be 1 based instead of 0 based, add one hour for the invoice charge to take place, add 10 minutes for the invoice to be created and other delays that are internal to Stripe
    const end = dayjs.unix(subscription.trial_end).add(1, 'day').add(1, 'hour').add(10, 'minute');
    const daysLeft = end.diff(today, 'days');
    this.trialStatus$.next({
      daysLeft: daysLeft < 0 ? 0 : daysLeft,
      onTrial: daysLeft > 0 ? true : false,
    });
  }
}
