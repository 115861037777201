<sc-avatar [photoURL]="member.photoURL"></sc-avatar>

<ion-label color="lighter">
  <strong class="micro-bold">{{ member.displayName }}</strong>

  <span id="email" class="micro-regular" [class.notVisible]="member.displayName">{{
    member.email || member.label
  }}</span>

  <sc-role-tag [role]="member.role"></sc-role-tag>
</ion-label>
