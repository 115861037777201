import { Component, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

import { FeedbackService } from '../../services/feedback/feedback.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { GeneralToastComponent } from '../general-toast/general-toast.component';

@Component({
  selector: 'sc-leave-feedback-toast',
  templateUrl: './leave-feedback-toast.component.html',
  styleUrls: ['./leave-feedback-toast.component.scss'],
  preserveWhitespaces: false,
})
export class LeaveFeedbackToastComponent extends Toast implements OnInit, OnDestroy {
  feedback = '';
  thumbsUp = true;

  progressValue = 0;
  progressInterval: NodeJS.Timeout = null;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private feedbackService: FeedbackService,
    private analyticsService: AnalyticsService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    this.progressInterval = setInterval(() => {
      this.progressValue = this.width * 0.01;
    }, 50);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();

    this.analyticsService.track('toast event', {
      event,
      label: this.title,
      message: this.message,
    });

    return false;
  }

  dontAskAgain(e) {
    const value = e.detail.checked;
    this.feedbackService.setAskFeedback(!value);
    this.analyticsService.track('selected stop asking for feedback');
  }

  submitFeedback() {
    this.feedbackService.submitFeedback(this.thumbsUp, this.feedback);

    this.remove();
    this.toastrService.success('Thank you for your feedback!', 'SquadCast Team', {
      progressBar: true,
      tapToDismiss: true,
      timeOut: 5000,
      toastComponent: GeneralToastComponent,
    });
    this.analyticsService.track('submitted feedback', { thumbsUp: this.thumbsUp, feedback: this.feedback });
  }

  async close() {
    this.remove();

    this.analyticsService.track('closed toast', {
      label: this.title,
      message: this.message,
    });
  }

  ngOnDestroy() {
    clearInterval(this.progressInterval);
  }
}
