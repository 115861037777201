export enum Roles {
  ORG_OWNER = 1000,
  ORG_ADMIN = 900,
  SHOW_MANAGER = 700,
  SHOW_TALENT = 500,
  FILE_MANAGER = 300,
  GUEST = 100,
  VIEWER = 0,
}

export const RoleDisplayNames = {
  [Roles.ORG_OWNER]: 'Org Owner',
  [Roles.ORG_ADMIN]: 'Org Admin',
  [Roles.SHOW_MANAGER]: 'Show Manager',
  [Roles.SHOW_TALENT]: 'Show Talent',
  [Roles.FILE_MANAGER]: 'File Manager',
  [Roles.GUEST]: 'Guest',
  [Roles.VIEWER]: 'Viewer',
};

export const RoleColors = {
  [Roles.ORG_OWNER]: 'accent-1',
  [Roles.ORG_ADMIN]: 'accent-1',
  [Roles.SHOW_MANAGER]: 'accent-orange-100',
  [Roles.SHOW_TALENT]: 'accent-8',
  [Roles.FILE_MANAGER]: 'accent-2',
  [Roles.GUEST]: 'accent-10',
  [Roles.VIEWER]: 'accent-10',
};

export enum RoleLimits {
  ORG_CREATE = Roles.FILE_MANAGER,
  ORG_READ = Roles.FILE_MANAGER,
  ORG_UPDATE = Roles.ORG_OWNER,
  ORG_DELETE = Roles.ORG_OWNER,

  ONE_TIME_CHARGE_CREATE = Roles.SHOW_MANAGER,

  SHOW_CREATE = Roles.ORG_ADMIN,
  SHOW_READ = Roles.SHOW_TALENT,
  SHOW_UPDATE = Roles.SHOW_MANAGER,
  SHOW_DELETE = Roles.ORG_ADMIN,

  TEAM_CREATE = Roles.SHOW_MANAGER,
  TEAM_READ = Roles.SHOW_TALENT,
  TEAM_UPDATE = Roles.SHOW_MANAGER,
  TEAM_DELETE = Roles.SHOW_MANAGER,

  SESSION_CREATE = Roles.SHOW_MANAGER,
  SESSION_READ = Roles.FILE_MANAGER,
  SESSION_UPDATE = Roles.SHOW_MANAGER,
  SESSION_DELETE = Roles.SHOW_MANAGER,

  RECORDING_CREATE = Roles.SHOW_MANAGER,
  RECORDING_READ = Roles.FILE_MANAGER,
  RECORDING_UPDATE = Roles.SHOW_MANAGER,
  RECORDING_DELETE = Roles.SHOW_MANAGER,

  PARTICIPANT_CREATE = Roles.SHOW_TALENT,
  PARTICIPANT_READ = Roles.SHOW_TALENT,
  PARTICIPANT_UPDATE = Roles.SHOW_TALENT,
  PARTICIPANT_DELETE = Roles.SHOW_TALENT,

  CHAT_GROUP_CREATE = Roles.GUEST,
  CHAT_GROUP_READ = Roles.VIEWER,
  CHAT_GROUP_UPDATE = Roles.GUEST,
  CHAT_GROUP_DELETE = Roles.GUEST,

  CAMERA_READ = Roles.VIEWER,
  CAMERA_UPDATE = Roles.GUEST,

  MICROPHONE_READ = Roles.VIEWER,
  MICROPHONE_UPDATE = Roles.GUEST,
}

