import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { UserService } from '../../services/user/user.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { ToursService } from '../../services/tours/tours.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { TourMenuComponent } from '../tour-menu/tour-menu.component';

@Component({
  selector: 'sc-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
})
export class HelpMenuComponent {
  @Input() event: MouseEvent;
  activeUser$ = this.userService.activeUser$;

  constructor(
    private popoverController: PopoverController,
    private userService: UserService,
    private supportCenterService: SupportCenterService,
    private toursService: ToursService,
    private analyticsService: AnalyticsService
  ) {}

  async takeTour() {
    await this.popoverController.dismiss();
    const popover = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: TourMenuComponent,
      cssClass: 'help-menu',
      event: this.event,
    });
    await popover.present();
    await this.analyticsService.track('opened tour menu');
    const res = await popover.onDidDismiss();
    if (res.data?.openHelpMenu) {
      const menu = await this.popoverController.create({
        ...defaultPopoverOptions,
        component: HelpMenuComponent,
        cssClass: 'help-menu',
        event: this.event,
        componentProps: {
          event: this.event,
        },
      });
      await menu.present();
    }
  }

  async openSupportDocs() {
    this.supportCenterService.show();
    await this.analyticsService.track('opened support docs');
    await this.close();
  }

  async openSupportEmail() {
    this.supportCenterService.showContactForm();
    await this.analyticsService.track('opened support email');
    await this.close();
  }

  async close() {
    await this.popoverController.dismiss();
  }
}
