import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TeamInvite } from '@sc/types';

@Component({
  selector: 'sc-team-invite',
  templateUrl: './team-invite.page.html',
  styleUrls: ['./team-invite.page.scss'],
})
export class TeamInvitePage implements OnInit {
  @Input() rejectHandler: () => void;
  @Input() claimHandler: () => void;
  @Input() invite: TeamInvite;

  constructor() {}

  ngOnInit() {}
}
