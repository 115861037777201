import { Injectable, OnDestroy } from '@angular/core';
import { Firestore, CollectionReference, collection, doc, docData } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { DropboxFile, DropboxIntegration } from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class DropboxService {
  califone = environment.microservices.califone;
  public activeUser$ = this.userService.activeUser$;
  public dropbox$ = new SCSubject<DropboxIntegration>();

  private dashboardOrg$ = this.organizationsService.dashboardOrg$;
  private scCol: CollectionReference = collection(this.firestore, 'SquadCasters');
  private orgCol: CollectionReference = collection(this.firestore, 'organizations');

  constructor(
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private http: HttpClient,
    private firestore: Firestore
  ) {
    this.setupDropbox();
  }

  setupDropbox() {
    this.dashboardOrg$
      .pipe(
        switchMap((org) => {
          return this.getDropboxAuthenticated(org.orgID);
        })
      )
      .subscribe((dropbox) => {
        // console.log(`🐛 🐞 dropbox ➡ ${JSON.stringify(dropbox, null, 2)} 🐞 🐛 `);
        this.dropbox$.next(dropbox, true);
      });
  }

  /**
   * Generates a AuthURL to use to allow dropbox to generate a user specific auth key
   *
   * @returns http get request
   */
  getDBAuthURL(idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers };
    return this.http.get(`${this.califone}/v2/dropbox/key`, options);
  }

  /**
   * Returns the authenticated dropbox client from user collection
   *
   * @param orgID : User ID
   * @returns Observable
   */
  getDropboxAuthenticated(orgID?: string): Observable<DropboxIntegration> {
    if (!orgID) orgID = this.activeUser$.value.activeOrg;
    return docData<DropboxIntegration>(doc(this.orgCol, orgID, 'integrations', 'dropbox'), { idField: 'id' });
  }

  /**
   * Verifys the generated code in the URL Params.
   *
   * @param idToken : User's idToken for Backend Verification
   * @param body : URL Code and User ID
   * @returns
   */
  setDropboxAccount(idToken: string, body: { code: string; uid: string }) {
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers };
    return this.http.post(`${this.califone}/v2/dropbox/auth`, body, options);
  }

  /**
   * Transfers selected files to User's Dropbox Account
   *
   * @param idToken : User's idToken for Backend Verification
   * @param body : DropboxFile Type
   * @returns
   */
  sendToDropbox(idToken: string, body: DropboxFile) {
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers };
    return this.http.post(`${this.califone}/v2/dropbox`, body, options).toPromise();
  }

  /**
   * Disconnects a User's Dropbox Account from SquadCast
   *
   * @param orgID : Org ID
   * @param idToken : User's idToken for Backend Verification
   * @returns
   */
  removeDropboxAccount(orgID: string, idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers, body: { orgID } };
    return this.http.delete(`${this.califone}/v2/dropbox`, options);
  }
}
