<ion-row>
  <ion-col>
    <h1 class="ion-text-center">Connect Your Email</h1>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="10" offset="1">
    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Email </ion-label>

      <ion-input [(ngModel)]="email" #emailAddr="ngModel" name="email" required="true" type="email" email> </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Password </ion-label>

      <ion-input [(ngModel)]="password" #pass="ngModel" name="password" required="true" type="password" minlength="6">
      </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Confirm Password </ion-label>

      <ion-input
        [(ngModel)]="confirmPassword"
        #confirm="ngModel"
        (keyup.enter)="saveEmail()"
        pattern="{{ password }}"
        name="password"
        required="true"
        type="password"
        minlength="6">
      </ion-input>
    </ion-item>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="close()" class="grey"> Cancel </ion-button>
  <ion-button (click)="saveEmail()" [disabled]="!emailAddr.valid || !pass.valid || !confirm.valid" class="primary">
    Save Email
  </ion-button>
</div>
