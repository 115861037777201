import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { CropPhotoPage } from '../crop-photo/crop-photo.page';
import { SlackService } from '../../services/slack/slack.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-beta-feedback',
  templateUrl: 'beta-feedback.page.html',
  styleUrls: ['beta-feedback.page.scss'],
})
export class BetaFeedbackPage implements OnInit {
  @Input() user: any;

  // Form Data & Validation
  betaFeedbackForm: UntypedFormGroup;
  formData = new FormData();
  issueTitle: string;
  issueDescription: string;
  isSubmitted = false;
  screenshotURL: string;
  screenshotBase64: string;

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private formBuilder: UntypedFormBuilder,
    private slackService: SlackService,
    private userService: UserService,
    private idTokenService: IdTokenService
  ) {
    this.setupForm();
  }

  get errorControl() {
    return this.betaFeedbackForm.controls;
  }

  async ngOnInit() {}

  /**
   * Setup Form Validators for BetaFeedback Form
   */
  setupForm(): void {
    this.betaFeedbackForm = this.formBuilder.group({
      issueTitle: [this.issueTitle, [Validators.required, Validators.minLength(5)]],
      issueDescription: [this.issueDescription, [Validators.minLength(5)]],
    });
  }

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed beta feedback');
  }

  async save() {
    this.isSubmitted = true;
    if (!this.betaFeedbackForm.valid) {
      return;
    }

    if (this.screenshotBase64) {
      this.screenshotURL = await this.userService.uploadBetaFeedbackScreenshot(this.screenshotBase64);
    }

    try {
      const newFeedback: any = {
        issueTitle: this.betaFeedbackForm.get('issueTitle').value,
        issueDescription: this.betaFeedbackForm.get('issueDescription').value,
        screenshotURL: this.screenshotURL,
      };

      await this.sendBetaFeedback(newFeedback);
      this.toastrService.success(null, `Successfully sent beta feedback`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();

      await this.analyticsService.track('sent beta feedback');
    } catch (error) {
      this.toastrService.error(null, `Failed to send beta feedback`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
    }
  }

  async photoInputChange(event: Event) {
    if (!(event.target instanceof HTMLInputElement) || event.target.files.length === 0) return;
    await this.openCropPhotoModal(event);
    event.target.value = ''; // If the user closes the modal and selects the same file, this will fix modal not re-appearing.
  }

  async openCropPhotoModal(event: any) {
    const cropOptions = {
      roundCropper: false,
      maintainAspectRatio: false,
      resizeToWidth: 0,
      resizeToHeight: 0,
    };
    const modal = await this.modalController.create({
      component: CropPhotoPage,
      componentProps: { user: this.user, event, options: cropOptions },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'crop-photo-modal',
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data?.base64) this.screenshotBase64 = this.screenshotURL = data.base64;
    });

    await modal.present();
  }

  async sendBetaFeedback(newFeedback: any) {
    const idToken = await this.idTokenService.getFreshIdToken();
    return await this.slackService.sendBetaMessage(newFeedback, idToken).toPromise();
  }
}
