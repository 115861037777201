import { Component, Input, OnInit } from '@angular/core';
import { Roles, RoleDisplayNames, RoleColors } from '@sc/types';

@Component({
  selector: 'sc-role-tag',
  templateUrl: './role-tag.component.html',
  styleUrls: ['./role-tag.component.scss'],
})
export class RoleTagComponent {
  @Input() role: number;

  roles = Roles;
  roleNames = RoleDisplayNames;
  roleColors = RoleColors;

  constructor() {}
}
