import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { StripeModule } from 'stripe-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { SquadCastComponentsModule } from '../components/squadcast.components.module';
import { SquadCastPipesModule } from '../pipes/squadcast.pipes.module';

import { AddToCalendarMenuComponent } from './add-to-calendar-menu/add-to-calendar-menu.component';
import { ApiMenuComponent } from './api-menu/api-menu.component';
import { CameraInfoMenuComponent } from './camera-info-menu/camera-info-menu.component';
import { CloudRecordingInfoMenuComponent } from './cloud-recording-info-menu/cloud-recording-info-menu.component';
import { ChatGroupMenuComponent } from './chat-group-menu/chat-group-menu.component';
import { DownloadAllMenuComponent } from './download-all-menu/download-all-menu.component';
import { DownloadLinkMenuComponent } from './download-link-menu/download-link-menu.component';
import { DownloadMenuComponent } from './download-menu/download-menu.component';
import { DolbyInfoMenuComponent } from './dolby-info-menu/dolby-info-menu.component';
import { EchoCancellationInfoMenuComponent } from './echo-cancellation-info-menu/echo-cancellation-info-menu.component';
import { EditInDescriptMenuComponent } from './edit-in-descript-menu/edit-in-descript-menu.component';
import { EditTakeNameMenuComponent } from './edit-take-name-menu/edit-take-name-menu.component';
import { HeadphonesInfoMenuComponent } from './headphones-info-menu/headphones-info-menu.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import { HideIncomingVideoInfoMenuComponent } from './hide-incoming-video-info-menu/hide-incoming-video-info-menu.component';
import { InvitationLinkMenuComponent } from './invitation-link-menu/invitation-link-menu.component';
import { InviteChatGroupMenuComponent } from './invite-chat-group-menu/invite-chat-group-menu.component';
import { LeaveSessionMenuComponent } from './leave-session-menu/leave-session-menu.component';
import { NewSessionMenuComponent } from './new-session-menu/new-session-menu.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';
import { OrganizationsMenuComponent } from './organizations-menu/organizations-menu.component';
import { MemberMenuComponent } from './member-menu/member-menu.component';
import { MicrophoneInfoMenuComponent } from './microphone-info-menu/microphone-info-menu.component';
import { SessionMenuComponent } from './session-menu/session-menu.component';
import { ShowMenuComponent } from './show-menu/show-menu.component';
import { ShowSelectMenuComponent } from './show-select-menu/show-select-menu.component';
import { TakeSelectMenuComponent } from './take-select-menu/take-select-menu.component';
import { ParticipantMenuComponent } from './participant-menu/participant-menu.component';
import { PlansMenuComponent } from './plans-menu/plans-menu.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { RecentRecordingsFilterMenuComponent } from './recent-recordings-filter-menu/recent-recordings-filter-menu.component';
import { RecordingMenuComponent } from './recording-menu/recording-menu.component';
import { RenameParticipantMenuComponent } from './rename-participant-menu/rename-participant-menu.component';
import { RolesInfoMenuComponent } from './roles-info-menu/roles-info-menu.component';
import { RolesSelectMenuComponent } from './roles-select-menu/roles-select-menu.component';
import { RecordingTimeInfoMenuComponent } from './recording-time-info-menu/recording-time-info-menu.component';
import { WebhookMenuComponent } from './webhook-menu/webhook-menu.component';
import { TourMenuComponent } from './tour-menu/tour-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    StripeModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    SquadCastComponentsModule,
    SquadCastPipesModule,
  ],
  declarations: [
    AddToCalendarMenuComponent,
    ApiMenuComponent,
    CameraInfoMenuComponent,
    ChatGroupMenuComponent,
    DownloadAllMenuComponent,
    DownloadLinkMenuComponent,
    DownloadMenuComponent,
    DolbyInfoMenuComponent,
    EchoCancellationInfoMenuComponent,
    EditInDescriptMenuComponent,
    EditTakeNameMenuComponent,
    HeadphonesInfoMenuComponent,
    HelpMenuComponent,
    HideIncomingVideoInfoMenuComponent,
    InvitationLinkMenuComponent,
    InviteChatGroupMenuComponent,
    LeaveSessionMenuComponent,
    NewSessionMenuComponent,
    NotificationsMenuComponent,
    OrganizationsMenuComponent,
    MemberMenuComponent,
    MicrophoneInfoMenuComponent,
    ParticipantMenuComponent,
    PlansMenuComponent,
    ProfileMenuComponent,
    RecentRecordingsFilterMenuComponent,
    RecordingMenuComponent,
    RecordingTimeInfoMenuComponent,
    RenameParticipantMenuComponent,
    RolesInfoMenuComponent,
    CloudRecordingInfoMenuComponent,
    RolesSelectMenuComponent,
    SessionMenuComponent,
    ShowMenuComponent,
    ShowSelectMenuComponent,
    TakeSelectMenuComponent,
    WebhookMenuComponent,
    TourMenuComponent,
  ],
  exports: [
    AddToCalendarMenuComponent,
    ApiMenuComponent,
    CameraInfoMenuComponent,
    ChatGroupMenuComponent,
    CloudRecordingInfoMenuComponent,
    DownloadAllMenuComponent,
    DownloadLinkMenuComponent,
    DownloadMenuComponent,
    DolbyInfoMenuComponent,
    EchoCancellationInfoMenuComponent,
    EditInDescriptMenuComponent,
    EditTakeNameMenuComponent,
    HeadphonesInfoMenuComponent,
    HelpMenuComponent,
    HideIncomingVideoInfoMenuComponent,
    InvitationLinkMenuComponent,
    InviteChatGroupMenuComponent,
    LeaveSessionMenuComponent,
    NewSessionMenuComponent,
    NotificationsMenuComponent,
    OrganizationsMenuComponent,
    MemberMenuComponent,
    MicrophoneInfoMenuComponent,
    ParticipantMenuComponent,
    PlansMenuComponent,
    ProfileMenuComponent,
    RecentRecordingsFilterMenuComponent,
    RecordingMenuComponent,
    RecordingTimeInfoMenuComponent,
    RenameParticipantMenuComponent,
    RolesInfoMenuComponent,
    RolesSelectMenuComponent,
    SessionMenuComponent,
    ShowMenuComponent,
    ShowSelectMenuComponent,
    TakeSelectMenuComponent,
    WebhookMenuComponent,

    NgSelectModule,
  ],
})
export class SquadCastMenusModule {}
