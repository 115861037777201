<ion-button (click)="close()" *ngIf="options.closeButton" id="close-button" class="text">
  <ion-icon src="assets/icons/24px/close.svg" slot="icon-only"> </ion-icon>
</ion-button>

<ion-progress-bar [value]="100" class="accent-progress-bar"> </ion-progress-bar>

<div id="content">
  <p class="body-bold" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }}
  </p>

  <p
    *ngIf="message && options.enableHtml"
    class="body-regular"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [innerHTML]="message"></p>

  <p
    *ngIf="(message || learnMore) && !options.enableHtml"
    class="body-regular"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message">
    {{ message }}
    <span *ngIf="learnMore" (click)="clickAction(learnMore)" class="learn-more-button">Learn More</span>
    <sc-learn-more *ngIf="learnMoreTopic" [topic]="learnMoreTopic" class="inline"></sc-learn-more>
  </p>
  <div *ngIf="recordings.length < 0">
    <p *ngFor="let recording of recordings" class="progress-wrapper">
      <span>{{ recording.fileName }}</span>
    </p>
  </div>
</div>

<div class="buttons-container">
  <ion-button aria-label="Click Action" *ngFor="let button of buttons" (click)="clickAction(button)" [class]="button.class">
    <ion-icon *ngIf="button.icon" [src]="button.icon" slot="start"> </ion-icon>

    <span>
      {{ button.label }}
    </span>
  </ion-button>
</div>

<ion-progress-bar class="option-progress-bar" [value]="progressValue" *ngIf="options.progressBar"> </ion-progress-bar>
