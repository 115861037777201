<img [src]="invite.orgLogo" [tooltip]="invite.orgName" />

<div class="body">
  <h2>You're Invited!</h2>
  <p >You have been invited to join <strong>{{ invite.orgName }}</strong> organization.</p>
</div>

<div class="buttons">
  <ion-button
    scThrottleClick
    (throttledClick)="rejectHandler()"
    class="secondary">
    Reject
  </ion-button>
  <ion-button
    scThrottleClick
    (throttledClick)="claimHandler()"
    class="primary">
    Join organization
  </ion-button>
</div>
