export interface UserProfile {
  displayName?: string;
  email?: string;
  fullName?: string;
  photoURL?: string;
  pronouns?: string;
}

export class UserData {
  uid?: string;
  activeOrg?: string;
  activeShow?: string;
  studioSession?: string;
  activeRecordingsTake?: number;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  fullName?: string;
  photoURL?: string;
  pronouns?: string;
  scAdmin?: boolean;

  organizations?: Array<string>;
  searchKeys?: Array<string>;
  shows?: Array<string>;
}

export type AnonymousUser = Partial<UserData>;

export class UserModel extends UserData {
  guest?: boolean;
  providers?: Array<any>;
  creationTime?: Date;
  lastSignInTime?: Date;
}
