import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { combineLatest, of, Subscription, switchMap } from 'rxjs';

import { UserService } from '../../services/user/user.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { Organization } from '@sc/types';
import { WalletService } from '../../services/wallet/wallet.service';
import { ShowsService } from '../../services/shows/shows.service';
import { PricingService } from '../../services/pricing/pricing.service';
import { Wallet } from '@sc/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { RoleLimits } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';

@Component({
  selector: 'sc-organizations-menu',
  templateUrl: './organizations-menu.component.html',
  styleUrls: ['./organizations-menu.component.scss'],
})
export class OrganizationsMenuComponent implements OnInit, OnDestroy {
  activeUser$ = this.userService.activeUser$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;
  availableOrgs: Array<Organization>;
  onDashboard = false;
  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private pricingService: PricingService,
    private showsService: ShowsService,
    private walletService: WalletService,
    private popoverController: PopoverController,
    private analyticsService: AnalyticsService,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    this.setupOrgPlansAndTime();
    if (this.router.url.includes('/dashboard')) this.onDashboard = true;
  }

  setupOrgPlansAndTime() {
    const sub = this.organizationsService.availableOrgs$
      .pipe(
        switchMap((orgs) => {
          this.availableOrgs = orgs;

          const plan$Array = this.availableOrgs.map((org) => {
            if (!org.planID) return of(null);
            return this.pricingService.getPlan(org.planID);
          });

          return combineLatest(plan$Array);
        })
      )
      .pipe(
        switchMap((plans) => {
          this.availableOrgs = this.availableOrgs.map((org, idx) => {
            return { ...org, plan: plans[idx] };
          });

          const wallet$Array = this.availableOrgs.map((org) => {
            return this.walletService.getWalletForOrg(org.orgID);
          });

          return combineLatest(wallet$Array);
        })
      )
      .subscribe((wallets) => {
        this.availableOrgs = this.availableOrgs.map((org, idx) => {
          const usedSeconds = this.walletService.convertTimeCodeToSeconds(wallets[idx]?.used);
          const availableSeconds = this.walletService.convertTimeCodeToSeconds(wallets[idx]?.available);
          const totalSeconds = usedSeconds + availableSeconds;
          const percentage = Math.round((usedSeconds / totalSeconds) * 100) / 100;
          const wallet: Wallet = { ...wallets[idx], percentage };

          return { ...org, wallet };
        });
      });

    this.subs.push(sub);
  }

  async setDashboardOrg(org: Organization) {
    await this.popoverController.dismiss();
    this.showsService.setDashboardShow(null);
    this.organizationsService.setDashboardOrgID(org.orgID);
    this.userService.saveActiveOrg(org.orgID);
    const customer = await this.walletService.refreshStripeCustomer(org.customerID);
    if(customer?.subscriptions?.data?.length > 0) {
      this.router.navigate(['dashboard'], { replaceUrl: true });
    } else {
      this.router.navigate(['account/subscription'], { replaceUrl: true });
    }
    await this.analyticsService.track('selected organization', { selectedOrgID: org.orgID });
  }

  async addOrganization() {
    await this.popoverController.dismiss();
    await this.router.navigate(['dashboard/add-organization'], { replaceUrl: true });
    await this.analyticsService.track('selected create organization');
  }

  async editOrg(event: MouseEvent, org: Organization) {
    event.stopPropagation();
    await this.popoverController.dismiss();
    await this.organizationsService.editOrg(org.orgID);
    await this.analyticsService.track('edited organization', { selectedOrgID: org.orgID });
  }

  async deleteOrg(event: MouseEvent, org: Organization) {
    event.stopPropagation();
    await this.popoverController.dismiss();
    await this.organizationsService.deleteOrg(org.orgID);
    await this.analyticsService.track('deleted organization with no plan', { selectedOrgID: org.orgID });
  }

  trackByOrg(index: number, org: Organization) {
    return org.orgID;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
