<div class="scrollbox">
  <div class="sticky">
    <sc-player
      *ngIf="notifications[activeIndex].modalOptions?.video"
      [url]="notifications[activeIndex].modalOptions.video"></sc-player>

    <img
      *ngIf="!notifications[activeIndex].modalOptions?.video && notifications[activeIndex].image"
      [src]="notifications[activeIndex].image" />

    <p *ngIf="notifications[activeIndex].modalOptions?.version" class="micro-bold version">
      NEW IN V{{ notifications[activeIndex].modalOptions.version }}
    </p>

    <h2 class="bold">{{ notifications[activeIndex].title }}</h2>
  </div>

  <p *ngIf="notifications[activeIndex].description">{{ notifications[activeIndex].description }}</p>

  <div class="content" [innerHTML]="notifications[activeIndex].modalOptions.content | marked"></div>

  <a *ngIf="notifications[activeIndex].link" [href]="notifications[activeIndex].link" target="_blank"> Learn More...</a>
</div>

<div class="pagination">
  <div
    *ngFor="let n of notifications; let i = index"
    [class.active]="i === activeIndex"
    (click)="setActiveIndex(i)"></div>
</div>

<div class="buttons">
  <div>
    <ion-button *ngIf="activeIndex !== notifications.length - 1" (click)="dismissAll()" class="secondary"
      >Dismiss All</ion-button
    >
  </div>
  <div>
    <ion-button *ngIf="activeIndex !== 0" (click)="setActiveIndex(activeIndex - 1)" class="secondary">Back</ion-button>
    <ion-button *ngIf="activeIndex !== notifications.length - 1" (click)="setActiveIndex(activeIndex + 1)"
      >Next</ion-button
    >
    <ion-button *ngIf="notifications[activeIndex]?.isDescriptAnnouncement && !viewDescriptAnnouncment" class="secondary">Learn More</ion-button>
    <ion-button *ngIf="notifications[activeIndex]?.isDescriptAnnouncement && !viewDescriptAnnouncment" (click)="claimDescriptOffer()"
      >Claim Offer</ion-button
    >
    <ion-button *ngIf="activeIndex === notifications.length - 1 && !notifications[activeIndex]?.isDescriptAnnouncement" (click)="done()">Done</ion-button>
  </div>
</div>
