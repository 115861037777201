<ion-progress-bar [value]="100" class="accent-progress-bar"> </ion-progress-bar>

<div id="content">
  <p class="body-bold" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }}
  </p>
  <p
    *ngIf="message"
    class="body-regular"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message">
    {{ message }}
  </p>
</div>

<sc-upload-stats [recordingID]="recordingID" [screenID]="screenID"></sc-upload-stats>
