import { Injectable } from '@angular/core';

declare const tap: (
  name: string,
  id: string,
  amount?: string | Record<string, string>,
  customer?: Record<string, string>
) => void;

@Injectable({
  providedIn: 'root',
})
export class TapfiliateService {
  accountID = '11866-2dfc77';

  constructor() {
    const interval = setInterval(() => {
      if (typeof tap !== 'undefined' && tap !== null) {
        this.setup();
        clearInterval(interval);
      }
    }, 100);
  }

  setup() {
    tap('create', this.accountID, { integration: 'stripe' });
  }

  convert(chargeID: string, orderAmount: string, customerID: string) {
    tap('conversion', chargeID, orderAmount, { customer_id: customerID });
  }

  trial(customerID: string) {
    tap('trial', customerID);
  }
}
