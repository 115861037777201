import { Component, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { StripeScriptTag } from 'stripe-angular';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { ThemeService } from './services/theme/theme.service';
import { ResponsiveService } from './services/responsive/responsive.service';
import { UserService } from './services/user/user.service';
import { RollbarService } from './services/rollbar/rollbar.service';

import * as Rollbar from 'rollbar';
import { OrganizationsService } from './services/organizations/organizations.service';
import { TeamsService } from './services/teams/teams.service';
import { GeneralToastComponent } from './toasts/general-toast/general-toast.component';
import { DescriptDrive, ServerResponse, TeamInvite, Themes } from '@sc/types';
import { AnalyticsService } from './services/analytics/analytics.service';
import { LafayetteService } from './services/lafayette/lafayette.service';
import { AuthService } from './services/auth/auth.service';
import { escapeHTML } from './util/escapeHTML';
import { TeamInvitePage } from './modals/team-invite/team-invite.page';
import { DescriptService } from './services/descript/descript.service';
import { SessionsService } from './services/sessions/sessions.service';

@Component({
  selector: 'sc-app',
  templateUrl: 'squadcast.app.component.html',
})
export class SquadCastComponent implements OnDestroy {
  user$ = this.userService.activeUser$;
  subs: Array<Subscription> = [];

  theme$ = this.themeService.theme$;
  loading$ = this.descriptService.loading$;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private descriptService: DescriptService, // Keep this here to initialize the service
    private firestore: Firestore,
    private lafayetteService: LafayetteService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private platform: Platform,
    private router: Router,
    private stripeScriptTag: StripeScriptTag,
    private teamsService: TeamsService,
    private toastrService: ToastrService,
    private userService: UserService,
    private sessionsService: SessionsService,
    public themeService: ThemeService,
    public responsiveService: ResponsiveService,

    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.setupStripe();
      this.setupUser();
      this.setupResponsive();
      this.setupOrgInvites();
      this.setupDescriptDriveSync();
      this.setupHeight();
      this.setupRouteListener();
      this.watchOrganization();
      this.setupWindowFocus();
      this.setupCheckoutRedirect();
      this.setupVerificationCheck();
    });

    this.setupTheme();
  }

  async setupUser() {
    const sub = this.user$.subscribe(async (user) => {
      const config: { payload: { person: { id: string; username: string; email: string; organization? } } } = {
        payload: {
          person: {
            id: this.user$.value.uid,
            username: this.user$.value.displayName,
            email: this.user$.value.email,
          },
        },
      };
      const orgID = this.organizationsService.dashboardOrgID$.value;
      if (orgID) config.payload.person.organization = orgID;
      this.rollbar.configure(config);
    });
    this.subs.push(sub);
  }

  setupWindowFocus() {
    window.addEventListener('focus', () => {
      this.userService.appFocused$.next(true);
    });
    window.addEventListener('blur', () => {
      this.userService.appFocused$.next(false);
    });
  }

  setupRouteListener() {
    const sub = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) return;
      this.rollbar.configure({ payload: { context: event.url } });
      if (event.url.includes('studio')) document.body.classList.add('in-studio');
      else document.body.classList.remove('in-studio');
    });

    this.subs.push(sub);
  }

  setupCheckoutRedirect() {
    const sub = this.organizationsService.dashboardOrg$.subscribe(async (org) => {
      if (this.router.url.includes('studio')) {
        const studioSession = await this.sessionsService.studioSession$.nextExistingValue();
        if (studioSession.orgID === org.orgID && !org.planID) {
          this.organizationsService.navigateOrgWithNoPlanToInfoPage();
        }
      } else if (!org.planID) {
        if (this.router.url.includes('upgrade')) return;
        this.organizationsService.navigateOrgWithNoPlanToInfoPage();
      }
    });

    this.subs.push(sub);
  }

  setupTheme() {
    const sub = this.theme$.subscribe((theme) => {
      if (theme === Themes.DARK) {
        document.body.classList.remove(Themes.LIGHT);
        document.body.classList.add(Themes.DARK);
      } else {
        document.body.classList.remove(Themes.DARK);
        document.body.classList.add(Themes.LIGHT);
      }
    });

    this.subs.push(sub);
  }

  setupOrgInvites() {
    const sub = this.teamsService.myInvites$.subscribe((invites) => {
      invites?.forEach((invite) => {
        if (invite.invitedBy === 'auto-invite') return;
        if (
          this.router.url.includes('checkout') ||
          this.router.url.includes('auth') ||
          this.router.url.includes('descript-info')
        ) {
          const modal = this.modalController.create({
            component: TeamInvitePage,
            componentProps: {
              rejectHandler: this.rejectInviteHandler.bind(this, invite),
              claimHandler: this.claimInviteHandler.bind(this, invite),
              invite,
            },

            showBackdrop: true,
            backdropDismiss: false,
            animated: true,
            cssClass: 'team-invite-modal',
          });
          modal.then((m) => m.present());
        } else {
          if (this.userService.activeUser$.value.emailVerified) {
            const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
              `<img src="${invite.orgLogo}"/><p>Join <i>${escapeHTML(invite.orgName)}</i>?</p>`,
              `You have been invited to join an Organization`,
              {
                progressBar: false,
                enableHtml: true,
                messageClass: 'invite-toast',
                closeButton: true,
                tapToDismiss: false,
                disableTimeOut: true,
                toastComponent: GeneralToastComponent,
              }
            );

            toast.toastRef.componentInstance.buttons = [
              {
                label: 'Reject',
                handler: this.rejectInviteHandler.bind(this, invite),
              },
              {
                label: 'Join',
                handler: this.claimInviteHandler.bind(this, invite),
              },
            ];
          } else {
            const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
              `<img src="${invite.orgLogo}"/><p>Verify your email so you can join <i>${escapeHTML(
                invite.orgName
              )}</i> !</p>`,
              `You have been invited to join an Organization. However, Your account's email has not been verified. 
              Please verify your email to join this Organization.`,
              {
                progressBar: false,
                enableHtml: true,
                messageClass: 'invite-toast',
                closeButton: true,
                tapToDismiss: false,
                disableTimeOut: true,
                toastComponent: GeneralToastComponent,
              }
            );

            toast.toastRef.componentInstance.buttons = [
              {
                label: 'Reject',
                handler: this.rejectInviteHandler.bind(this, invite),
              },
              {
                label: 'Verify Email',
                handler: this.sendEmailVerificationHandler.bind(this, this.userService.activeUser$.value.email),
              },
            ];
          }
        }
      });
    });

    this.subs.push(sub);
  }

  setupDescriptDriveSync() {
    const sub = this.descriptService.descriptDrive$.subscribe(async (drive: DescriptDrive) => {
      if (!drive) return;
      if (!drive.lastSynced) return await this.descriptService.syncSubscription(drive.driveID, drive.orgID);
      const timeElapsed = Date.now() - drive.lastSynced.toMillis();
      if (timeElapsed > 43200000) {
        await this.descriptService.syncSubscription(drive.driveID, drive.orgID);
      }
      return;
    });

    this.subs.push(sub);
  }

  async rejectInviteHandler(invite: TeamInvite) {
    const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
      `Reject invite to <i>${escapeHTML(invite.orgName)}</i>?`,
      `Reject Invite?`,
      {
        progressBar: false,
        enableHtml: true,
        closeButton: true,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: GeneralToastComponent,
      }
    );

    toast.toastRef.componentInstance.buttons = [
      {
        label: 'Cancel',
        handler: () => {},
      },
      {
        label: 'Reject',
        handler: this.confirmReject.bind(this, invite),
      },
    ];

    await this.analyticsService.track('rejected team member invite', {
      orgID: invite.orgID,
      orgName: invite.orgName,
      invitedBy: invite.invitedBy,
      email: invite.email,
      role: invite.role,
    });
  }
  async confirmReject(invite: TeamInvite) {
    const inviteRef = doc(this.firestore, 'invites', invite.inviteID);
    await setDoc(inviteRef, { status: 'rejected' }, { merge: true });

    this.toastrService.success(`You have canceled your invite to ${invite.orgName}`, `Invite Rejected`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 10 * 1000,
      toastComponent: GeneralToastComponent,
    });
    this.modalController.dismiss();
    await this.analyticsService.track('confirmed reject team member invite', {
      orgID: invite.orgID,
      orgName: invite.orgName,
      invitedBy: invite.invitedBy,
      email: invite.email,
      role: invite.role,
    });
  }

  async claimInviteHandler(invite: TeamInvite) {
    const user = await this.userService.activeUser$.toPromise();
    const res = (await this.lafayetteService.claimTeamInvite(invite.inviteID, user.uid)) as ServerResponse;
    if (res?.error === 'expired') {
      this.toastrService.error(`This invite has expired. Please ask to be invited again.`, `Invite Expired`, {
        closeButton: true,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: GeneralToastComponent,
      });
    } else if (res?.error === 'claimed') {
      this.toastrService.warning(
        `This invite was already claimed. Please refresh your page. If problem perisists, ask to be invited again.`,
        `Invite Already Claimed`,
        {
          closeButton: true,
          tapToDismiss: false,
          disableTimeOut: true,
          toastComponent: GeneralToastComponent,
        }
      );
    } else if (res?.error === 'canceled') {
      this.toastrService.error(`This invite was canceled. Please ask to be invited again.`, `Invite Canceled`, {
        closeButton: true,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: GeneralToastComponent,
      });
    } else {
      this.toastrService.success(`Welcome, you are now a member of ${invite.orgName}`, `Joined ${invite.orgName}!`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });

      if (!this.router.url.includes('studio')) {
        this.organizationsService.setDashboardOrgID(invite.orgID);
        this.userService.saveActiveOrg(invite.orgID);

        const org = await this.organizationsService.dashboardOrg$.nextExistingValue(
          (item) => item.orgID === invite.orgID
        );
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }

      await this.analyticsService.track('claimed team member invite', {
        orgID: invite.orgID,
        orgName: invite.orgName,
        invitedBy: invite.invitedBy,
        email: invite.email,
        role: invite.role,
      });
    }

    const inviteModal = await this.modalController.getTop();
    if (inviteModal) {
      this.modalController.dismiss();
    }
  }

  async sendEmailVerificationHandler(email: string) {
    await this.userService.verifyEmail();
    this.toastrService.success(
      `Your verification email has been sent. Please check and verify your email.`,
      `Verification Email Sent!`,
      {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      }
    );
    await this.analyticsService.track('email verification sent!', {
      email: email,
    });
    const inviteModal = await this.modalController.getTop();
    if (inviteModal) {
      this.modalController.dismiss();
    }
  }

  setupVerificationCheck() {
    const sub = this.userService.activeUser$.subscribe((user) => {
      if (user.guest) return;
      const verified = this.userService.checkUserVerification();
      if (verified) return;
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
        `Verify Your Email!`,
        `Please Don't forget to verify your email so that you have access to all SquadCast features!`,
        {
          progressBar: false,
          enableHtml: true,
          closeButton: true,
          messageClass: 'invite-toast',
          tapToDismiss: false,
          disableTimeOut: true,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.buttons = [
        {
          label: 'Send Verification Email',
          handler: this.sendEmailVerificationHandler.bind(this, this.user$.value.email),
        },
      ];
    });
    this.subs.push(sub);
  }

  watchOrganization() {
    this.organizationsService.missingOrg$.subscribe((missing) => {
      this.userService.activeUser$.nextExistingValue();
      if (missing && !this.userService.deletingUser$.value) {
        if (this.userService.activeUser$.value.organizations.length) {
          const org = this.userService.activeUser$.value.organizations[0];
          this.userService.saveActiveOrg(org);
          this.organizationsService.setDashboardOrgID(org);
        } else {
          this.authService.createDefaultOrgAndShow(this.userService.activeUser$.value);
        }
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }
    });
  }

  setupStripe() {
    this.stripeScriptTag.setPublishableKey(environment.stripe.publishableKey);
  }

  onResize(event: Event) {
    this.responsiveService.check();
    this.setupHeight();
  }

  setupResponsive() {
    this.responsiveService.check();
  }

  setupHeight() {
    const docElm = document.documentElement;
    docElm.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
