import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddOrganizationPage } from './add-organization.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: AddOrganizationPage,
      },
    ]),
    SquadCastComponentsModule,
  ],
  declarations: [AddOrganizationPage],
})
export class AddOrganizationPageModule {}
