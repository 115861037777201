import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { ActionConfirmPage } from '../../modals/action-confirm/action-confirm.page';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../services/user/user.service';
import { Subscription } from 'rxjs';
import { DescriptService } from '../../services/descript/descript.service';

@Component({
  selector: 'sc-descript-info',
  templateUrl: './descript-info.page.html',
  styleUrls: ['./descript-info.page.scss'],
})
export class DescriptInfoPage implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() buttons: {
    text: string;
    class: string;
    link: string;
  }[];

  lastOrgWithPlan$ = this.organizationsService.lastOrgWithPlan$;
  loading$ = this.descriptService.loading$;
  canDeleteUser = false;

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService,
    private modalController: ModalController,
    private userService: UserService,
    private descriptService: DescriptService
  ) {}

  async ngOnInit() {
    this.setupCanDeleteUser();
    this.setupNavExtras();
    await this.analyticsService.track('descript info page shown', {
      pageInfo: { title: this.title, description: this.description },
    });
  }

  async setupCanDeleteUser() {
    this.canDeleteUser = await this.organizationsService.canDeleteUser();
  }

  setupNavExtras() {
    const sub = this.router.events.subscribe(async () => {
      const navigationExtras = this.router.getCurrentNavigation()?.extras.state;

      // wait for the loading state to end before updating the page content
      while (this.descriptService.loading$.value?.length && navigationExtras) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      if (navigationExtras) {
        this.title = navigationExtras.title;
        this.description = navigationExtras.description;
        this.buttons = navigationExtras.buttons;
      }
    });

    this.subs.push(sub);
  }

  async goToLink(link: string) {
    console.log(link);
    await this.analyticsService.track('descript info page link', { urlInfo: { url: link } });
    window.location.href = link;
  }

  async logout() {
    await this.authService.logout();
    await this.analyticsService.track('descript info page logout');

    setTimeout(() => {
      const protocol = window.location.protocol;
      const hostName = window.location.host;
      window.location.href = `${protocol}//${hostName}/auth`;
    }, 0.5 * 1000);
  }

  async backToOrg() {
    this.userService.saveActiveOrg(this.lastOrgWithPlan$.value.orgID);
    this.organizationsService.setDashboardOrgID(this.lastOrgWithPlan$.value.orgID);
    await this.organizationsService.dashboardOrg$.nextExistingValue((org) => org.planID);
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  async showDeleteUserModal() {
    await this.analyticsService.track('descript info page delete');
    const modal = await this.modalController.create({
      component: ActionConfirmPage,
      componentProps: {
        title: 'Delete Account?',
        message: `Are you sure you want to delete <b>${this.userService.activeUser$.value.email}</b>? This will delete ALL of the organizations that this user OWNS with all of their data. This action cannot be undone.`,
        enableHTML: true,
        icon: 'delete',
        buttons: [
          {
            label: 'Delete My Account',
            handler: async () => {
              await this.authService.deleteUser();
              await this.analyticsService.track('deleted user', { uid: this.userService.activeUser$.value.uid });
              modal.dismiss();
            },
            type: 'danger',
          },
          {
            label: 'Cancel',
            handler: () => {
              modal.dismiss();
            },
          },
        ],
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'action-confirm-modal',
    });

    modal.present();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
