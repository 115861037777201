<sc-dashboard-layout accountPage class="wide">
  <form (ngSubmit)="createOrg()">
    <div class="account-settings-header sticky-blur-header">
      <div class="header-content">
        <ion-icon src="assets/icons/24px/globe.svg"></ion-icon>
        <h1 *ngIf="!(editing && editingOrg)" class="ion-no-margin bold">Create new organization</h1>
        <h1 *ngIf="editing && editingOrg" class="ion-no-margin bold">Organization Settings</h1>
      </div>
      <div class="header-buttons">
        <ion-button aria-label="Cancel" (click)="cancel()" class="text ion-margin-top"> Cancel </ion-button>
        <ion-button
          aria-label="Create Organization"
          *ngIf="!(editing && editingOrg)"
          class="primary ion-margin-top"
          [disabled]="nameCtrl.invalid"
          type="submit">
          Create Organization
        </ion-button>

        <ion-button
          aria-label="Save Changes"
          *ngIf="editing && editingOrg"
          class="primary ion-margin-top"
          [disabled]="nameCtrl.invalid"
          type="submit">
          Save<span class="ion-hide-lg-down">&nbsp;Changes</span>
        </ion-button>
      </div>
    </div>
    <div class="account-settings-content image-select-col">
      <div class="content-column">
        <ion-item class="input-large" lines="none">
          <ion-label position="stacked"> Organization Name </ion-label>

          <ion-input
            [(ngModel)]="orgName"
            name="orgName"
            #nameCtrl="ngModel"
            type="text"
            placeholder="Organization Name"
            scAutoFocus
            minlength="2"
            required>
            <div class="validation-error" *ngIf="nameCtrl.invalid && nameCtrl.touched">
              <p *ngIf="nameCtrl.errors?.required">Organization name is required</p>
              <p *ngIf="nameCtrl.errors?.minlength">Organization name should be at least 2 chars long</p>
            </div>
          </ion-input>
        </ion-item>
      </div>

      <div class="image-column">
        <p class="micro-regular ion-no-padding ion-no-margin ion-text-center">Organization Artwork</p>
        <div *ngIf="!imageExists" (click)="inputFile.click()" id="artworkIcon">
          <ion-icon src="assets/icons/24px/add.svg"> </ion-icon>
        </div>

        <ion-img *ngIf="imageExists" id="artwork" src="{{ imageSrc }}"></ion-img>
        <ion-button aria-label="Edit Image" (click)="inputFile.click()" class="line" size="small">
          <ion-icon src="assets/icons/24px/upload.svg" slot="start"> </ion-icon>

          <ion-label slot="end"> {{ !imageExists ? 'Select Image' : 'Change Image' }} </ion-label>
        </ion-button>

        <input
          #inputFile
          type="file"
          accept="image/png | image/jpeg | image/jpg"
          (change)="photoInputChange($event)"
          id="input-file"
          style="display: none" />
      </div>
    </div>
  </form>
</sc-dashboard-layout>
