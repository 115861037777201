import { Component, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { SettingsService } from '../../services/settings/settings.service';
import { Timezone } from '@sc/types';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

@Component({
  selector: 'sc-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnChanges {
  @Input() initWithToday = false;
  @Input() required = false;
  @Input() initialValue = '';
  @Input() timezone: Timezone;
  @Input() format: 'HH:mm' | 'h:mm A' | 'LT' | 'M/D/YYYY' | 'l' | 'LL' | 'lll' = 'lll';
  @Input() popoverSide: 'top' | 'left' | 'right' | 'bottom' = 'bottom';

  @Input() minDateISO = dayjs().subtract(2, 'year').toISOString();
  @Input() maxDateISO = dayjs().add(2, 'year').toISOString();

  @Input() clear = false;
  @Output() set = new EventEmitter<{ dateISO: string; displayDate: string }>();

  @ViewChild('datePopover') datePopover: IonPopover;
  @ViewChild('popoverDatetime') datetime: IonPopover;

  protected selectorDate: string = null;
  protected displayDate: string = null;
  protected timePickerMinutes = [];
  protected type: 'date' | 'time' | 'date-time' = 'date-time';

  dateISO: string = null;

  constructor(private settingsService: SettingsService) {
    this.setupTimePickerInterval();
  }

  ngOnChanges(changes) {
    if (changes.initialValue?.currentValue) {
      this.selectedDate(changes.initialValue.currentValue);
    }
    if (!changes.initialValue?.currentValue && changes.initWithToday?.currentValue) {
      this.selectedDate(dayjs().toISOString());
    }

    if (changes.clear?.currentValue) {
      this.selectedDate('');
    }

    if (changes.timezone?.currentValue && this.dateISO) {
      this.selectedDate(this.dateISO, true);
    }

    if (
      changes.format?.currentValue === 'HH:mm' ||
      changes.format?.currentValue === 'h:mm A' ||
      changes.format?.currentValue === 'LT'
    ) {
      this.type = 'time';
    } else if (
      changes.format?.currentValue === 'M/D/YYYY' ||
      changes.format?.currentValue === 'l' ||
      changes.format?.currentValue === 'LL'
    ) {
      this.type = 'date';
    } else if (changes.format?.currentValue) {
      this.type = 'date-time';
      this.format = 'lll';
    }
  }

  setupTimePickerInterval() {
    let interval = this.settingsService.userAppSettings$.value?.timePickerMinuteInterval;
    if (!interval) interval = 1;
    let i = 0;
    while (i < 60) {
      this.timePickerMinutes.push(i);
      i += interval;
    }
  }

  openDatePicker(event) {
    this.datePopover.present(event);
  }

  dateInputUpdated(e: Event) {
    const target = e.target as HTMLInputElement;
    this.selectedDate(target.value);
  }

  selectedDate(datein: string | string[] | Event, force = false) {
    const date = Array.isArray(datein) ? datein[0] : datein;
    if (this.dateISO === date && !force) return;

    let isoDate = this.parseISOStringDate(date);
    if (!isoDate) {
      if (this.required) {
        isoDate = dayjs().toISOString();
      } else {
        this.displayDate = '';
        this.dateISO = '';
        this.set.emit({ dateISO: '', displayDate: '' });
        return;
      }
    }

    const displayDate = this.timezone?.tzid ? dayjs(isoDate).tz(this.timezone.tzid) : dayjs(isoDate);
    this.displayDate = displayDate.format(this.format);
    this.selectorDate = displayDate.format('YYYY-MM-DDTHH:mm:ss');

    this.dateISO = isoDate;
    this.set.emit({ dateISO: isoDate, displayDate: this.displayDate });
  }

  parseISOStringDate(date: string | Event) {
    if (!date) return null;
    let tryDate = null;

    if (date instanceof Event) {
      date = (date.target as HTMLInputElement).value;

      if (!tryDate?.isValid() && this.timezone?.tzid) {
        try {
          tryDate = dayjs.tz(date, 'YYYY-MM-DDTHH:mm:ss', this.timezone.tzid);
        } catch (e) {
          console.error(e);
        }
      }

      if (!tryDate?.isValid()) {
        tryDate = dayjs(date, 'YYYY-MM-DDTHH:mm:ss');
      }
    }

    if (!tryDate?.isValid()) {
      tryDate = dayjs(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    }

    if (!tryDate?.isValid() && this.timezone?.tzid) {
      try {
        tryDate = dayjs.tz(date, this.format, this.timezone.tzid);
      } catch (e) {
        console.log("Couldn't parse date with timezone", e);
      }
    }

    if (!tryDate?.isValid()) {
      tryDate = dayjs(date, this.format);
    }

    if (this.type === 'time') {
      if (!tryDate?.isValid()) tryDate = dayjs(date, 'h:m A');
      if (!tryDate?.isValid()) tryDate = dayjs(date, 'h:m a');
      if (!tryDate?.isValid()) tryDate = dayjs(date, 'H:m');
      if (!tryDate?.isValid()) tryDate = dayjs(date, 'H');
    }

    if (tryDate?.isValid()) {
      return tryDate.toISOString();
    }
    return null;
  }
}
