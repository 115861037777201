import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  califone = environment.microservices.califone;

  constructor(private http: HttpClient) {}

  sendMessage(message: object, idToken?: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.post(`${this.califone}/v2/slack`, message, { headers });
  }

  sendBetaMessage(message: object, idToken?: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.post(`${this.califone}/v2/slack/beta`, message, { headers });
  }
}
