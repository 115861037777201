<form>
  <ion-row>
    <ion-col>
      <h3 class="ion-text-center">Add Recording Time</h3>

      <ion-row>
        <ion-col offset="4" size="4">
          <ion-list lines="none">
            <ion-item class="input-large" lines="none">
              <ion-label position="stacked"> Recording Hours </ion-label>
              <ion-input
                #hoursCtrl="ngModel"
                [(ngModel)]="hours"
                name="hours"
                type="number"
                min="1"
                [max]="max"
                (ionChange)="handleRangeValidation()"
                scAutoFocus
                required>
              </ion-input>

              <div class="validation-error">
                <p *ngIf="hoursCtrl.errors?.required">Hours are required</p>
                <p *ngIf="belowMin">Minimum 1 Hour</p>
                <p *ngIf="aboveMax">Maximum {{ max }} Hours</p>
              </div>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <p *ngIf="!hoursCtrl.errors?.required && !belowMin && !aboveMax" class="ion-text-center">
        You will be charged ${{ hours * 5 }}.00 today
      </p>
    </ion-col>
  </ion-row>

  <div class="buttons">
    <ion-button (click)="cancel()" class="grey" expand="block"> Cancel </ion-button>
    <ion-button
      (click)="purchaseRecordingTime()"
      [disabled]="hoursCtrl.errors?.required || belowMin || aboveMax"
      class="primary"
      expand="block">
      Confirm
    </ion-button>
  </div>
</form>
