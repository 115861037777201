import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { AtMentionPipe } from './at-mention/at-mention.pipe';
import { FileNamePipe } from './file-name/file-name.pipe';
import { GifPipe } from './gif/gif.pipe';
import { HashtagPipe } from './hashtag/hashtag.pipe';
import { TrustPipe } from './trust/trust.pipe';
import { LinkifyPipe } from './linkify/linkify.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { FilterMembersPipe } from './filter-members/filter-members.pipe';
import { FilterShowsPipe } from './filter-shows/filter-shows.pipe';
import { MarkedPipe } from './marked/marked.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, IonicModule],
  declarations: [
    AtMentionPipe,
    FileNamePipe,
    GifPipe,
    HashtagPipe,
    TrustPipe,
    LinkifyPipe,
    TimeAgoPipe,
    FilterMembersPipe,
    FilterShowsPipe,
    MarkedPipe,
  ],
  exports: [
    AtMentionPipe,
    FileNamePipe,
    GifPipe,
    HashtagPipe,
    TrustPipe,
    LinkifyPipe,
    TimeAgoPipe,
    FilterMembersPipe,
    FilterShowsPipe,
    MarkedPipe,
  ],
})
export class SquadCastPipesModule {}
