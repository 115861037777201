import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { RecaptchaResponse } from '@sc/types';

declare let grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class ReCAPTCHAService {
  amplion = environment.microservices.amplion;

  constructor(private http: HttpClient) {}

  execute(idToken: string, config?: { action: string }): Promise<RecaptchaResponse> {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(environment.recaptcha.siteKey, config)
          .then((token: any) => {
            return this.verify(idToken, token).toPromise();
          })
          .then((response: any) => {
            resolve(response);
          });
      });
    });
  }

  verify(idToken: string, token: any) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.post(`${this.amplion}/api/v2/recaptcha`, { token }, { headers });
  }
}
