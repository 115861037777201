export enum FireAuthErrors {
  'auth/app-delete' = 'The app is no longer available.',
  'auth/app-not-authorized' = 'This app is not authorized.',
  'auth/argument-error' = 'A method was invoked with invalid arguments.',
  'auth/invalid-api-key' = 'Your API key is invalid.',
  'auth/invalid-user-token' = "The user's credential is no longer valid. The user must sign in again.",
  'auth/invalid-tenant-id' = 'The tenant ID is invalid.',
  'auth/network-request-failed' = 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
  'auth/operation-not-allowed' = 'You do not have permission to complete this operation.',
  'auth/requires-recent-login' = 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
  'auth/too-many-requests' = 'Too many requests have been made with this user account.',
  'auth/unauthorized-domain' = 'This app is not authorized to use the provided domain.',
  'auth/user-disabled' = 'The user account has been disabled by an administrator.',
  'auth/user-token-expired' = 'Too many recent changes, please refresh and try again.',
  'auth/web-storage-unsupported' = 'This browser is not supported.',
  'auth/account-exists-with-different-credential' = 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
  'auth/credential-already-in-use' = 'This credential is already associated with a different user account.',
  'auth/email-already-in-use' = 'The email address is already in use by another account.',
  'auth/user-not-found' = 'There is no user record corresponding to this identifier.',
  'auth/wrong-password' = 'The password is invalid.',
  'auth/invalid-verification-code' = 'The verification code used to create the user account is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user.',
  'auth/invalid-verification-id' = 'The verification ID used to create the user account is invalid.',
  'auth/popup-closed-by-user' = 'The popup was closed before logging in.',
}

export interface ProviderInfo {
  providerId?: string;
  name?: string;
  icon?: string;
  orgID?: string;
  orgLogo?: string;
  showID?: string;
  autoInvite?: boolean;
  role?: number;
}
