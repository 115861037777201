<ion-row>
  <ion-col>
    <h1 class="ion-text-center">Update Payment Info</h1>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <sc-payment-info #paymentInfo> </sc-payment-info>

    <p class="ion-text-center stripe">
      <ion-icon src="/assets/icons/24px/lock.svg" slot="start" color="light"> </ion-icon>

      <a href="https://stripe.com/docs/security/stripe" alt="Stripe Security" target="_blank"> Secured </a>

      by

      <a href="https://stripe.com/docs/security/stripe" alt="Stripe" target="_blank"> Stripe </a>

      and

      <a href="https://policies.google.com/terms" alt="reCAPTCHA Terms of Service" target="_blank"> reCAPTCHA </a>
    </p>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-button aria-label="Close" (click)="close()" color="light" expand="block" shape="round"> Cancel </ion-button>
  </ion-col>

  <ion-col>
    <ion-button
      (click)="save()"
      color="primary"
      aria-label="Save"
      [disabled]="saving || !paymentInfo.orgName || !paymentInfo.billingEmail || !paymentInfo.cardDetailsFilledOut"
      expand="block"
      shape="round">
      Save
    </ion-button>
  </ion-col>
</ion-row>
