import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseApp } from '@angular/fire/app';
import {
  Auth,
  User as FireUser,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
  sendPasswordResetEmail,
  AuthProvider,
  SAMLAuthProvider,
  OAuthProvider,
  getAdditionalUserInfo,
  UserCredential,
  updateEmail,
  updateProfile,
  deleteUser,
} from '@angular/fire/auth';
import { Firestore, doc, getDoc, serverTimestamp, addDoc, collection } from '@angular/fire/firestore';

import * as Rollbar from 'rollbar';
import { RollbarService } from '../../services/rollbar/rollbar.service';

import { UserService } from '../user/user.service';
import { LafayetteService } from '../lafayette/lafayette.service';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '@sc/types';
import { Roles } from '@sc/types';
import { ServerResponse } from '@sc/types';
import { SettingsService } from '../settings/settings.service';
import { ZapierService } from '../zapier/zapier.service';
import { CalifoneService } from '../califone/califone.service';
import { ToursService } from '../tours/tours.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../analytics/analytics.service';
import { ProviderInfo } from '@sc/types';
import { TeamInvite } from '@sc/types';
import { AlertController } from '@ionic/angular';
import { CloudFunctionsService } from '../cloud-functions.service';
import { SupportCenterService } from '../support-center/support-center.service';

declare let profitwell: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public fireAuth: Auth,
    public firestore: Firestore,
    public firebaseApp: FirebaseApp,
    private router: Router,
    private alertController: AlertController,
    private califoneService: CalifoneService,
    private lafayetteService: LafayetteService,
    private organizationsService: OrganizationsService,
    private settingsService: SettingsService,
    private supportCenterService: SupportCenterService,
    private toastrService: ToastrService,
    private toursService: ToursService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private zapierService: ZapierService,
    private cfs: CloudFunctionsService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.setupProfitwell();
  }

  async setupUser(user: FireUser, userCred?: UserCredential) {
    const userDoc = doc(this.firestore, 'SquadCasters', user.uid);
    const userSnapshot = await getDoc(userDoc);
    const userData = userSnapshot.data() as UserModel;
    if (!userData || !userData.activeOrg) {
      if (userCred) await this.infoToProfile(user, userCred);
      this.settingsService.setDefaultAppSettings(user.uid);
      this.toursService.resetTours();
      const joined = await this.checkInvites(user);
      if (!joined) {
        const [orgID, showID] = await this.createDefaultOrgAndShow(user);
        const migrated = await this.migrateUser(user.uid, orgID, showID);
        if (!migrated) {
          this.califoneService.generateExampleWebhooks(user.uid);
          this.organizationsService.navigateOrgWithNoPlanToInfoPage();
          return;
        }
      }
    }
    this.router.navigate(['/dashboard']);
  }

  async checkInvites(user: FireUser) {
    const provID = user.providerData[0].providerId;
    if (provID.includes('oidc.') || provID.includes('saml.')) {
      const provInfo = await this.getProviderInfo(provID);
      if (provInfo?.autoInvite) {
        const newInvite: TeamInvite = {
          orgID: provInfo.orgID,
          orgName: provID,
          email: user.email,
          role: provInfo?.role ?? 300,
          invitedBy: 'auto-invite',
          status: 'pending',
          inviteTimestamp: serverTimestamp(),
        };
        if (provInfo.showID) newInvite.showID = provInfo.showID;
        const invite = await addDoc(collection(this.firestore, 'invites'), newInvite).catch((error) => {
          // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
          return doc(collection(this.firestore, 'invites'), error.message.split('/').at(-1));
        });
        await this.lafayetteService.claimTeamInvite(invite.id, user.uid);
        return true;
      }
    }
    return false;
  }

  parseProfile(user: FireUser, userCred: UserCredential) {
    let { profile } = getAdditionalUserInfo(userCred) as { profile: Record<string, string> };
    this.rollbar.info('SSO Profile', profile);
    profile = Object.keys(profile).reduce((acc, key) => {
      acc[key.toLowerCase()] = profile[key];
      return acc;
    }, {});

    const emailPattern = [
      'email',
      'emailaddress',
      'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
      'https://schemas.xmlsoap.org/claims/emailaddress',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    ];
    const namePattern = [
      'username',
      'fullname',
      'firstname',
      'givenname',
      'name',
      'https://schemas.xmlsoap.org/claims/commonname',
      'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
      'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/firstname',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
      'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/displayname',
    ];
    const photoPattern = ['photo', 'picture', 'avatar', 'http://schemas.auth0.com/picture'];
    let email: string;
    let displayName: string;
    let photoURL: string;

    if (profile) {
      emailPattern.forEach((p) => {
        if (profile[p]) email = profile[p];
      });
      namePattern.forEach((p) => {
        if (profile[p]) displayName = profile[p];
      });
      // photoPattern.forEach((p) => {
      //   if (profile[p]) photoURL = profile[p];
      // });
    }
    return { email, displayName, photoURL };
  }

  async infoToProfile(user: FireUser, userCred: UserCredential) {
    const profile: { email: string; displayName: string; photoURL: string } = this.parseProfile(user, userCred);
    if (!profile.email) profile.email = await this.emailAlert();
    const { email, displayName, photoURL } = profile;

    if (email) {
      await updateEmail(this.fireAuth.currentUser, email).catch((err) => {
        if (err.code === 'auth/email-already-in-use') {
          const title = 'Connect your account';
          const message = `Looks like you already have an account. Please login and connect your account from the sign-in settings page.`;
          this.toastrService.error(message, title, {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 10 * 1000,
            toastComponent: GeneralToastComponent,
          });
          deleteUser(user);
          this.router.navigate(['/auth/', email, { fragment: 'login' }]);
        }
        throw err;
      });
      this.userService.activeUserEmail$.next(email);
    } else {
      throw new Error('No email provided');
    }

    if (displayName) {
      await updateProfile(this.fireAuth.currentUser, { displayName }).catch(console.error);
    }
    if (photoURL) {
      await updateProfile(this.fireAuth.currentUser, { photoURL }).catch(console.error);
    }
  }

  async emailAlert(invalid = false) {
    const isEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const alert = await this.alertController.create({
      header: 'Enter an Email',
      subHeader: 'An email is required to send account communications and notifications.',
      buttons: ['OK'],
      inputs: [
        {
          placeholder: invalid ? 'Valid Email Required' : 'Email',
          cssClass: invalid ? 'placeholder-error' : '',
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    const resp = await alert.onDidDismiss();
    const email = resp.data.values[0];
    if (isEmail.test(email)) return email;
    return this.emailAlert(true);
  }

  /**
   * Checks for Firestore auth data.  If email is signed up, returns ['password']
   * If email is not signed up, returns ['']
   * If email is badly formed, returns null
   */
  async checkEmail(email: string) {
    return fetchSignInMethodsForEmail(this.fireAuth, email).catch((err) => {
      if (err.code === 'auth/invalid-email') {
        return null;
      }
      console.error(err);
    });
  }

  async signUpWithEmail(email: string, password: string) {
    const resp = await createUserWithEmailAndPassword(this.fireAuth, email, password);
    this.analyticsService.track('clicked sign up');
    await this.setupUser(resp.user);
    return resp;
  }

  async loginWithEmail(email: string, password: string, runSetup = true) {
    const resp = await signInWithEmailAndPassword(this.fireAuth, email, password);
    if (runSetup) await this.setupUser(resp.user);
    return resp;
  }

  async loginWithProvider(provider: string) {
    const prov = this.getProvider(provider);
    const resp = await signInWithPopup(this.fireAuth, prov);
    await this.setupUser(resp.user, resp);
    return resp;
  }

  async loginWithAnon() {
    return await signInAnonymously(this.fireAuth);
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.fireAuth, email);
  }

  getProvider(provider: string) {
    let prov: AuthProvider;
    switch (provider) {
      case 'google.com':
        prov = new GoogleAuthProvider();
        break;
      case 'facebook.com':
        prov = new FacebookAuthProvider();
        break;
    }
    if (!prov) {
      const provId = provider.split('.')[0];
      switch (provId) {
        case 'saml':
          prov = new SAMLAuthProvider(provider);
          break;
        case 'oidc':
          prov = new OAuthProvider(provider);
          break;
      }
    }
    return prov;
  }

  async getProviderInfo(provider: string) {
    const provDoc = doc(this.firestore, 'idproviders', provider);
    const provSnapshot = await getDoc(provDoc);
    const provData = provSnapshot.data() as ProviderInfo;
    return { ...provData, providerId: provDoc.id };
  }

  async createDefaultOrgAndShow(user: UserModel) {
    const newOrg = {
      orgName: `My Organization`,
      billingEmail: user.email,
      orgOwner: user.uid,
      orgMembers: [{ uid: user.uid, role: Roles.ORG_OWNER }],
    };

    const orgResp = (await this.lafayetteService.createOrg(newOrg)) as ServerResponse;
    this.userService.saveActiveOrg(orgResp.ID);
    this.organizationsService.setDashboardOrgID(orgResp.ID);
    const newShow = {
      showName: `My Show`,
      showOwner: user.uid,
      orgID: orgResp.ID,
      memberIDs: [user.uid],
    };

    const showResp = (await this.lafayetteService.createShow(newShow)) as ServerResponse;
    this.settingsService.setDefaultShowSettings(showResp.ID);
    return [orgResp.ID, showResp.ID];
  }

  async migrateUser(userID: string, orgID: string, showID: string) {
    this.analyticsService.track('started migration', { userID, orgID });
    const resp = await this.lafayetteService.userMigration(userID, orgID).catch((err) => {
      if (err.status === 404) {
        this.analyticsService.track('failed migration', { userID, orgID });
        return false;
      }
    });
    if (resp) {
      this.toastrService.success(`User Migration complete!`, `Migration Complete`, {
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });
      this.analyticsService.track('completed user migration', { userID, orgID });

      await this.lafayetteService.sessionsMigration(showID);
      this.toastrService.success(`Session Migration complete!`, `Migration Complete`, {
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });
      this.analyticsService.track('completed session migration', { userID, orgID });
    }
    return resp;
  }

  logout() {
    this.supportCenterService.identified = false;
    return this.fireAuth.signOut();
  }

  setupProfitwell() {
    this.userService.authUser$.subscribe((user) => {
      if (user?.email) profitwell('start', { user_email: user.email });
    });
  }

  async deleteUser() {
    this.userService.deletingUser$.next(true);
    this.cfs
      .post('user-delete', {
        uid: this.userService.activeUser$.value.uid,
      })
      .then(() => {
        window.location.reload();
      });
  }
}
