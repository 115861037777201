import { Injectable, OnDestroy } from '@angular/core';
import { Firestore, collection, deleteDoc, doc, docData, setDoc } from '@angular/fire/firestore';

import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';

import { BendixService } from '../bendix/bendix.service';
import { Recording } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class FileService implements OnDestroy {
  subs: Array<Subscription> = [];
  sessionsCol = collection(this.firestore, 'sessions');

  refreshRecordingsAfterDelete: BehaviorSubject<{ sessionID: string }> = new BehaviorSubject(null);

  constructor(private bendixService: BendixService, private firestore: Firestore) {}

  deleteFile(showID: string, sessionID: string, recording: Recording, idToken: string) {
    const promises = [];
    promises.push(deleteDoc(doc(this.sessionsCol, sessionID, 'recordings', recording.recordingID)));

    if (recording.mp4) {
      promises.push(
        firstValueFrom(this.bendixService.deleteRecording(showID, sessionID, `${recording.fileName}.mp4`, idToken))
      );
    }

    if (recording.webm) {
      promises.push(
        firstValueFrom(this.bendixService.deleteRecording(showID, sessionID, `${recording.fileName}.webm`, idToken))
      );
    }

    if (recording.wav) {
      promises.push(
        firstValueFrom(this.bendixService.deleteRecording(showID, sessionID, `${recording.fileName}.wav`, idToken))
      );
    }

    if (recording.mp3) {
      promises.push(
        firstValueFrom(this.bendixService.deleteRecording(showID, sessionID, `${recording.fileName}.mp3`, idToken))
      );
    }

    return Promise.allSettled(promises).then(() => {
      this.setRefreshRecordingsAfterDelete({ sessionID });
    });
  }

  getFile(sessionID: string, recordingID: string) {
    return docData<Recording>(doc(this.sessionsCol, sessionID, 'recordings', recordingID), { idField: 'recordingID' });
  }

  addFile(sessionID: string, recordingID: string, file: { fileName: string; take: number }) {
    return setDoc(doc(this.sessionsCol, sessionID, 'recordings', recordingID), { file }, { merge: true });
  }

  getRefreshRecordingsAfterDelete() {
    return this.refreshRecordingsAfterDelete.asObservable();
  }

  setRefreshRecordingsAfterDelete(config: { sessionID: string }) {
    this.refreshRecordingsAfterDelete.next(config);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
