import { filter, first, firstValueFrom, ReplaySubject } from 'rxjs';
import { stringify } from 'flatted';

export class SCSubject<T> extends ReplaySubject<T> {
  /**
   * Return current value or undefined if not initialized
   *
   * @returns T | undefined
   */
  public value: T | undefined;
  constructor(value?: any, force = false) {
    super(1);
    if (force || (value !== null && value !== undefined)) this.next(value);
  }

  next(value: T, force = false) {
    if (
      !force &&
      !(value instanceof MediaStream) &&
      !(value instanceof Map) &&
      stringify(value) === stringify(this.value)
    )
      return;
    this.value = value;
    super.next(value);
  }

  /**
   * Returns current value immediately or first value emitted from Observable if not initialized
   *
   * @returns Promise<T>
   */
  toPromise(): Promise<T> {
    return firstValueFrom(this);
  }

  nextExistingValue(filterFunciton?): Promise<T> {
    if (filterFunciton) return firstValueFrom(this.pipe(first(filterFunciton)));
    return firstValueFrom(this.pipe(first((item) => item !== null && item !== undefined)));
  }
}
