<ion-list id="extra-info">
  <ion-item id="organization-name">
    <ion-label> Organization Name </ion-label>

    <ion-input
      [(ngModel)]="orgName"
      name="orgName"
      id="orgName"
      #nameCtrl="ngModel"
      placeholder="My Organization"
      class="ion-text-right">
    </ion-input>
  </ion-item>
  <ion-item id="billing-email">
    <ion-label> Billing Email </ion-label>

    <ion-input
      [(ngModel)]="billingEmail"
      name="billingEmail"
      id="billingEmail"
      #nameCtrl="ngModel"
      placeholder="Billing email is required"
      class="ion-text-right"
      required
      email>
    </ion-input>
  </ion-item>
</ion-list>

<div *ngIf="cardDetails; else noCard">
  <ion-list lines="none">
    <ion-item id="card-on-file">
      <ion-label id="payment-label"> Payment Method </ion-label>

      <ion-badge id="number" class="ion-text-right">
        {{ cardDetails.brand }} ending in {{ cardDetails.last4 }}
      </ion-badge>

      <ion-button aria-label="Update Card information" (click)="resetCardInfo()" class="text" slot="end">
        Update
      </ion-button>
    </ion-item>
  </ion-list>
</div>

<ng-template #noCard>
  <ion-row id="cardholder-row">
    <ion-col>
      <ion-item id="cardholderName">
        <ion-icon src="/assets/icons/24px/member.svg" slot="start" id="cardholderIcon"> </ion-icon>

        <ion-input
          [(ngModel)]="name"
          placeholder="Cardholder name"
          name="cardholderName"
          type="text"
          scAutoFocus
          required="true">
        </ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <stripe-card
        #stripeCard
        [(invalid)]="invalidError"
        [(complete)]="cardDetailsFilledOut"
        (catch)="onStripeError($event)"
        (tokenChange)="setStripeToken($event)"
        [options]="(theme$ | async) === LIGHT_THEME ? stripeCardSettingsLight : stripeCardSettingsDark">
      </stripe-card>
    </ion-col>
  </ion-row>
</ng-template>
