import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ShowsService } from '../../services/shows/shows.service';

import { Show } from '@sc/types';

@Component({
  selector: 'sc-show-list-item',
  templateUrl: './show-list-item.component.html',
  styleUrls: ['./show-list-item.component.scss'],
})
export class ShowListItemComponent implements OnInit {
  @Input() show: Show;
  @Input() showID: string;

  constructor(public showsService: ShowsService) {}

  async ngOnInit() {
    if (this.showID) {
      this.show = await firstValueFrom(this.showsService.getShowByID(this.showID));
    }
  }
}
