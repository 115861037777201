import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ProfilePage } from './profile.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, SquadCastComponentsModule],
  declarations: [ProfilePage],
})
export class ProfilePageModule {}
