<ion-row>
  <ion-col size="10" offset="1">
    <h3 class="ion-text-center bold">Transfer Show</h3>

    <ion-img [src]="show.showImg" [tooltip]="show.showName"></ion-img>

    <p class="ion-text-center">
      You are about to Transfer your Show to a new Organization. The new Organization will have the option to Approve or
      Decline the Transfer. The Transfer can be canceled at any time. All of your Show's data and content will be
      Transferred to the new Organization.
    </p>

    <p class="ion-text-center">
      <strong>Request a Transfer Key from the new Organization if you do not already have one.</strong>
    </p>

    <ion-item lines="none" class="input-large">
      <ion-label position="stacked"> Transfer Key</ion-label>

      <ion-input [(ngModel)]="transferKey" placeholder="Paste Transfer Key"></ion-input>
    </ion-item>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button aria-label="Cancel" (click)="cancel()" class="grey" expand="block" shape="round"> Cancel </ion-button>
  <ion-button aria-label="Confirm: Transfer Show" (click)="transferShow()" class="primary" expand="block" shape="round">
    Confirm
  </ion-button>
</div>
