<div
  id="shows-nav-shadow"
  [class]="{ 'mobile-nav-active': mobileNavOpen$ | async }"
  (click)="mobileNavOpen$.next(false)"></div>

<div
  id="shows-nav"
  [cdkTrapFocus]="mobileNavOpen$ | async"
  [cdkTrapFocusAutoCapture]="mobileNavOpen$ | async"
  [class]="{ 'mobile-nav-active': mobileNavOpen$ | async }"
  [class.banner-is-open]="bannerShown">
  <button id="organization" (click)="toggleOrganizationsView($event)" tooltip="Organizations">
    <span class="sr-only">Organizations Menu</span>
    <ion-img [src]="(dashboardOrg$ | async)?.orgLogo"></ion-img>

    <div id="organization-menu">
      <ion-icon src="/assets/icons/24px/menu.svg"></ion-icon>
    </div>
  </button>
  <!-- end #organization -->

  <div
    id="home"
    tooltip="Home"
    [tooltipOptions]="{
      placement: 'right'
    }">
    <ion-button (click)="goHome()" fill="outline" aria-label="Go Home">
      <ion-icon src="/assets/icons/24px/home.svg"></ion-icon>
    </ion-button>
  </div>
  <!-- end #home -->

  <div
    id="recordings"
    tooltip="Recordings"
    [tooltipOptions]="{
      placement: 'right'
    }">
    <ion-button (click)="goSearch()" fill="outline" aria-label="View all recordings in search">
      <ion-icon *ngIf="(dashboardPlan$ | async)?.videoRecording" src="/assets/icons/24px/video-playback.svg"></ion-icon>
      <ion-icon
        *ngIf="!(dashboardPlan$ | async)?.videoRecording"
        src="/assets/icons/32px/recordings-bold.svg"></ion-icon>
    </ion-button>
  </div>

  <hr />

  <div id="shows-list">
    <div *ngIf="!shows?.length" class="no-shows">
      <ion-button
        fill="clear"
        (click)="noShows()"
        aria-label="No Shows Available, please ask an organization admin to add you to a show"
        tooltip="No Shows Available"
        [tooltipOptions]="{
          placement: 'right'
        }">
        <ion-icon src="/assets/icons/16px/exclamation-outline.svg"></ion-icon>
      </ion-button>
    </div>
    <ion-reorder-group #reorderGroup (ionItemReorder)="doReorder($event)" [disabled]="reorderDisabled">
      <ion-reorder
        *ngFor="let show of shows"
        (pointerdown)="pointerDown($event, show)"
        (pointerup)="pointerUp($event, show)"
        (mousedown)="mouseDown($event)">
        <ion-button
          fill="clear"
          (keydown.space)="selectShow(show)"
          (keydown.enter)="selectShow(show)"
          [class.Active]="(dashboardShow$ | async)?.showID === show.showID">
          <span class="sr-only">Select Show: {{ show.showName }}</span>
          <ion-img
            [src]="show.showImg"
            [tooltip]="reorderDisabled ? show.showName : 'Drag to reorder'"
            [tooltipOptions]="{
              placement: 'right'
            }"></ion-img>
        </ion-button>
      </ion-reorder>
    </ion-reorder-group>

    <div id="add-show">
      <ion-button
        aria-label="Add Show"
        *ngIf="(role$ | async) >= roleLimits.SHOW_CREATE"
        (click)="openAddShow()"
        class="add-show-button"
        tooltip="Add Show"
        [tooltipOptions]="{
          placement: 'right'
        }"
        fill="clear">
        <ion-icon src="/assets/icons/24px/add.svg"></ion-icon>
      </ion-button>
    </div>
  </div>

  <div (click)="logo()" id="logo">
    <object *ngIf="browser !== 'Safari'" tabindex="-1" data="/assets/images/SC_LogoMark-Circle_Animated.svg"></object>
    <object *ngIf="browser === 'Safari'" tabindex="-1" data="/assets/images/Gradient-Logo-80x80.png"></object>
  </div>
</div>
