<ion-row>
  <ion-col>
    <h5 class="bold">Are you sure you want to Delete this Webhook?</h5>

    <ion-text color="lighter" class="ion-text-center">
      <p>
        <ion-chip *ngFor="let event of webhook.events" color="lighter" outline="true"> {{ event.name }} </ion-chip>

        <br />

        events will no longer be sent to

        <br />

        <span class="url">{{ webhook.url }}</span>
      </p>
    </ion-text>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-button (click)="cancel()" class="line" expand="block" shape="round"> Cancel </ion-button>
  </ion-col>

  <ion-col>
    <ion-button (click)="delete()" class="danger" expand="block" shape="round"> Delete </ion-button>
  </ion-col>
</ion-row>
