<div class="container">
  <h3>Transfer Organization Ownership</h3>

  <p>Transferring organization ownership will result in the following:</p>
  <ul>
    <li><b>You</b> will be demoted to an <b>Organization Admin.</b></li>
    <li>
      <b>{{ member?.displayName || member?.email }}</b> will be promoted to the <b>Organization Owner.</b>
    </li>
    <li>
      If clear payment info checkbox is selected. Organization subscription will be canceled and the new admin will need
      to re-subscribe with new payment information.
    </li>
  </ul>

  <p>Transferring Organization Ownership To:</p>
  <div class="organization">
    <img [src]="(dashboardOrg$ | async).orgLogo" />
    <h4>{{ (dashboardOrg$ | async).orgName }}</h4>
  </div>

  <div class="members">
    <div class="member">
      <sc-avatar
        [photoURL]="(activeUser$ | async)?.photoURL"
        [name]="(activeUser$ | async)?.displayName || (activeUser$ | async)?.email"></sc-avatar>
      <p>{{ (activeUser$ | async)?.displayName || (activeUser$ | async)?.email }}</p>
    </div>

    <ion-icon src="assets/icons/24px/line-arrow-right.svg"></ion-icon>

    <div class="member">
      <sc-avatar [photoURL]="member?.photoURL" [name]="member?.displayName || member?.email"></sc-avatar>
      <p>{{ member?.displayName || member?.email }}</p>
    </div>
  </div>

  <ion-item lines="none" class="ion-text-wrap">
    <ion-checkbox slot="start" mode="md" [(ngModel)]="resetPayment"></ion-checkbox>
    <ion-label class="ion-text-wrap"
      >Clear payment information and cancel subscription. (Organization will stay active until next billing
      date)</ion-label
    >
  </ion-item>

  <div class="buttons">
    <ion-button (click)="transferOrganization()" [disabled]="loading">Transfer Organization</ion-button>
    <ion-button (click)="cancel()" class="secondary">Cancel</ion-button>
  </div>
</div>
