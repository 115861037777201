import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ToursService } from '../../services/tours/tours.service';
import { ToursCollections } from '@sc/types';
import { Router } from '@angular/router';

@Component({
  selector: 'sc-tour-menu',
  templateUrl: './tour-menu.component.html',
  styleUrls: ['./tour-menu.component.scss'],
})
export class TourMenuComponent {
  tourGroups = [
    {
      id: ToursCollections.DASHBOARD,
      name: 'Dashboard Tour',
      subtext: 'Get familiar with terminology, locations of tools, and how-tos.',
      tours: [ToursCollections.DASHBOARD],
    },
    {
      id: ToursCollections.STUDIO,
      name: 'Studio Tour',
      subtext: 'Get the ins and outs of the studio so you know exactly what you are doing.',
      tours: [ToursCollections.STUDIO, ToursCollections.GREENROOM, ToursCollections.GETTING_RECORDING],
    },
  ];

  constructor(
    private popoverController: PopoverController,
    private toursService: ToursService,
    private router: Router
  ) {}

  async startTour(tourGroup: (typeof this.tourGroups)[0]['id']) {
    this.toursService.resetTours(this.tourGroups.find((tour) => tour.id === tourGroup).tours);
    this.router.navigate(['/dashboard']);
    this.dismiss(false);
  }

  dismiss(openHelpMenu = true) {
    this.popoverController.dismiss({
      openHelpMenu,
    });
  }
}
