import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../services/auth/auth.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'sc-password-reset',
  templateUrl: 'password-reset.page.html',
  styleUrls: ['password-reset.page.scss'],
})
export class PasswordResetPage implements OnInit {
  email: string;
  subs: Array<Subscription> = [];

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {}

  async sendPasswordResetEmail() {
    if (!this.email) {
      this.toastrService.warning(null, `Enter your account's Email Address`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    await this.authService.sendPasswordResetEmail(this.email);
    await this.close();

    this.toastrService.info(`Thank You`, `Check your Email for instructions`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
    await this.analyticsService.track('sent password reset email', { email: this.email });
  }

  async close() {
    this.email = '';
    await this.modalController.dismiss();
  }
}
