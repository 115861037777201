import { Component } from '@angular/core';

@Component({
  selector: 'sc-roles-info-menu',
  templateUrl: './roles-info-menu.component.html',
  styleUrls: ['./roles-info-menu.component.scss'],
})
export class RolesInfoMenuComponent {
  osHeadphonesSelection: string;

  constructor() {}
}
