<ion-row>
  <ion-col>
    <h1 class="ion-text-center">Are you still around?</h1>

    <h2 class="ion-text-center">Automatically Leaving Session in <strong>{{ leavingIn }} Seconds</strong></h2>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-icon src="/assets/icons/24px/clock.svg" class="big"> </ion-icon>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-button (click)="yes()" class="primary" type="submit" expand="block"> Yup, I'm Still Here </ion-button>
  </ion-col>
</ion-row>
