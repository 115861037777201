import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  StripeCouponResponse,
  StripeSubscriptionDTO,
  AmplionPreviewInvoiceResponse,
  AmplionSubscriptionResponse,
  AmplionCustomerResponse,
  AmplionInvoicesResponse,
  AmplionChargesResponse,
  StripeChargeDTO,
  StripeCustomerDTO,
  StripeInvoicePreviewDTO,
} from '@sc/types';
import { HttpService } from '../http/http.service';
import { CloudFunctionsService } from '../cloud-functions.service';

@Injectable({
  providedIn: 'root',
})
export class AmplionService {
  amplion = environment.microservices.amplion;

  constructor(private httpService: HttpService, private cfs: CloudFunctionsService) {}

  addCustomer(customer: StripeCustomerDTO) {
    return this.httpService.post<AmplionCustomerResponse>(`${this.amplion}/api/v2/customers`, customer);
  }

  chargeCustomer(charge: StripeChargeDTO) {
    return this.httpService.post(`${this.amplion}/api/v2/charges`, charge);
  }

  subscribeCustomer(subscription: StripeSubscriptionDTO) {
    return this.httpService.post<AmplionSubscriptionResponse>(`${this.amplion}/api/v2/subscriptions`, subscription);
  }

  updateSubscription(subscription: StripeSubscriptionDTO, subscriptionID: string) {
    return this.httpService.put<AmplionSubscriptionResponse>(
      `${this.amplion}/api/v2/subscriptions/${subscriptionID}`,
      subscription
    );
  }

  updateCustomer(customer: StripeCustomerDTO, customerID: string) {
    return this.httpService.put(`${this.amplion}/api/v2/customers/${customerID}`, customer);
  }

  getCustomer(customerID: string) {
    return this.httpService.get<AmplionCustomerResponse>(`${this.amplion}/api/v2/customers/${customerID}`);
  }

  getCharges(customerID: string) {
    return this.httpService.get<AmplionChargesResponse>(`${this.amplion}/api/v2/charges/${customerID}`);
  }

  getInvoices(customerID: string) {
    return this.httpService.get<AmplionInvoicesResponse>(`${this.amplion}/api/v2/invoices/${customerID}`);
  }

  getPendingInvoiceItems(customerID: string) {
    return this.httpService.get(`${this.amplion}/api/v2/invoices/${customerID}/pending`);
  }

  getUnpaidInvoices(customerID: string) {
    return this.httpService.get(`${this.amplion}/api/v2/invoices/${customerID}/unpaid`);
  }

  payInvoice(customerID: string, invoiceID: string) {
    return this.httpService.post(`${this.amplion}/api/v2/invoices/${customerID}/pay`, { invoiceID });
  }

  createInvoice(customerID: string) {
    return this.httpService.post(`${this.amplion}/api/v2/invoices/`, { customerID });
  }

  finalizeInvoice(customerID: string, invoiceID: string) {
    return this.httpService.post(`${this.amplion}/api/v2/invoices/${customerID}/finalize`, { invoiceID });
  }

  previewInvoice(customerID: string, preview: StripeInvoicePreviewDTO) {
    return this.httpService.post<AmplionPreviewInvoiceResponse>(
      `${this.amplion}/api/v2/invoices/${customerID}/preview`,
      { ...preview }
    );
  }

  getCoupon(coupon: string) {
    return this.httpService.get<StripeCouponResponse>(`${this.amplion}/api/v2/coupons/${coupon}`);
  }

  balanceLedger(orgID: string) {
    return this.cfs.post('billing-balance', { orgID });
  }

  cancelSubscription(customerID: string, subscriptionID: string) {
    return this.httpService.delete<AmplionSubscriptionResponse>(
      `${this.amplion}/api/v2/subscriptions/${subscriptionID}`,
      { customerID }
    );
  }
}
