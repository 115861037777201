import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[upgrade]' })
export class UpgradeDirective implements OnInit, OnChanges, OnDestroy {
  @Input() upgrade: boolean;
  iconElement: HTMLIonIconElement | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly el: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.iconElement) {
      this.createIcon();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.iconElement) {
      this.iconElement.style.display = !this.upgrade ? 'none' : 'block';
    }
  }

  createIcon(): void {
    const anchorElement: HTMLAnchorElement = this.renderer.createElement('a');
    this.renderer.setAttribute(anchorElement, 'routerLink', '/account/subscription');
    const iconElement: HTMLIonIconElement = this.renderer.createElement('ion-icon');
    this.renderer.setAttribute(iconElement, 'src', 'assets/icons/24px/bolt.svg');

    this.iconElement = iconElement;
    this.iconElement.style.color = 'var(--sc-color-primary-110-rgba)';
    this.iconElement.style.backgroundColor = 'rgb(141, 214, 215)';
    this.iconElement.style.borderRadius = '50%';
    this.iconElement.style.boxShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
    this.iconElement.style.fontSize = '20px';
    this.iconElement.style.padding = '6px';
    this.iconElement.style.cursor = 'pointer !important';
    this.iconElement.style.zIndex = '1';
    this.iconElement.style.position = 'absolute';
    this.iconElement.style.display = !this.upgrade ? 'none' : 'flex';
    this.iconElement.style.justifyContent = 'center';
    this.iconElement.style.alignItems = 'center';
    this.iconElement.style.overflow = 'hidden';
    this.iconElement.style.top = '-16px';
    this.iconElement.style.right = '-30px';

    this.el.nativeElement.style.marginRight = '14px';
    this.el.nativeElement.style.position = 'relative';
    this.el.nativeElement.shadowRoot.innerHTML = `
      <style>
        .button-native {
          contain: inherit !important;
          overflow: visible !important;
        }
      </style>
    `;

    this.renderer.appendChild(anchorElement, iconElement);
    this.renderer.appendChild(this.el.nativeElement, anchorElement);
  }

  ngOnDestroy(): void {
    if (this.iconElement) {
      this.iconElement.remove();
    }
  }
}
