import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Auth, EmailAuthProvider, linkWithCredential } from '@angular/fire/auth';

import { UserService } from '../../services/user/user.service';
import { VerifyAuthPage } from '../verify-auth/verify-auth.page';
import { WalletService } from '../../services/wallet/wallet.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { FireAuthErrors } from '@sc/types';
import { SupportCenterService } from '../../services/support-center/support-center.service';
@Component({
  selector: 'sc-connect-email',
  templateUrl: 'connect-email.page.html',
  styleUrls: ['connect-email.page.scss'],
})
export class ConnectEmailPage implements OnInit, OnDestroy {
  customerID: string;
  email: string;
  password: string;
  confirmPassword: string;
  subs: Array<Subscription> = [];

  constructor(
    private fireAuth: Auth,
    private idTokenService: IdTokenService,
    private modalController: ModalController,
    private supportCenterService: SupportCenterService,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.setupCustomerID();
  }

  async saveEmail() {
    if (!this.email || !this.password || !this.confirmPassword) {
      this.toastrService.warning(null, `Double-check your Email, Password, and Confirmation`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (this.password !== this.confirmPassword) {
      this.toastrService.warning(null, `Password and Confirmation must match`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      const provider = EmailAuthProvider;
      const credential = provider.credential(this.email, this.password);

      await this.idTokenService.getFreshIdToken();
      await linkWithCredential(this.fireAuth.currentUser, credential);
      await this.userService.setUserProfile({ email: this.email });

      this.close();

      this.toastrService.success(null, `Successfully connected Email and Password to your account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('connected email', { success: true });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        this.openVerifyAuthModal();
      } else {
        const niceError = FireAuthErrors[error.code] || error.message;
        this.toastrService.error(niceError, `Failed to connect Email and Password to your account`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });

        await this.analyticsService.track('connected email', { success: false });
      }
    }
  }

  close() {
    this.email = '';
    this.password = '';
    this.confirmPassword = '';

    this.modalController.dismiss();
  }

  async openVerifyAuthModal() {
    const modal = await this.modalController.create({
      component: VerifyAuthPage,
      componentProps: {},
      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      cssClass: 'verify-auth-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened verify authentication');
    await modal.onDidDismiss().then((resp) => {
      if (resp.data.success) this.saveEmail();
    });
  }

  setupCustomerID() {
    const sub = this.walletService.customerID$.subscribe((id) => {
      this.customerID = id;
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
