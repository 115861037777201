import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { Webhook } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

@Component({
  selector: 'sc-delete-webhook',
  templateUrl: 'delete-webhook.page.html',
  styleUrls: ['delete-webhook.page.scss'],
})
export class DeleteWebhookPage implements OnInit {
  @Input() dashboardOrg: string;
  @Input() webhook: Webhook;

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private califoneService: CalifoneService
  ) {}

  ngOnInit() {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed delete webhook modal', { webhookID: this.webhook.id });
  }

  async delete() {
    try {
      await this.califoneService.deleteWebhook(this.dashboardOrg, this.webhook.id);

      this.toastrService.success(null, `Successfully deleted Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('deleted webhook', { webhookID: this.webhook.id, success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to delete Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('deleted webhook', { webhookID: this.webhook.id, success: false });
    }
  }
}
