import { Injectable } from '@angular/core';
import { CloudFunctionsService } from '../cloud-functions.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveCampaignService {
  constructor(private cfs: CloudFunctionsService, private userService: UserService) {}

  addTagToSquadcaster(tag: string, uid?: string) {
    if (!uid) uid = this.userService.activeUser$.value.uid;
    if (!tag || !uid) {
      console.log('No tag or no uid');
      return;
    }

    return this.cfs.post('user-add-active-campaign-tag', { uid, tag: tag });
  }

  removeTagFromSquadcaster(tag: string, uid?: string) {
    if (!uid) uid = this.userService.activeUser$.value.uid;
    if (!tag || !uid) {
      console.log('No tag or no uid');
      return;
    }

    return this.cfs.post('user-remove-active-campaign-tag', { uid, tag: tag });
  }
}
