<div class="search-container">
  <ion-button
    aria-label="Open Navigation Menu"
    size="default"
    fill="clear"
    class="ion-hide-md-up"
    (click)="mobileNavOpen$.next(true)">
    <ion-icon slot="icon-only" src="assets/icons/24px/menu.svg" class="mobile-nav-open"></ion-icon>
  </ion-button>

  <ion-button tabindex="-1" size="default" fill="clear" (click)="search()">
    <ion-icon class="search-icon" src="assets/icons/24px/search.svg"></ion-icon>
  </ion-button>
  <input type="text" [(ngModel)]="query" name="query" placeholder="Search" (keyup.enter)="search()" />
</div>

<div class="button-container">
  <div class="badges">
    <ion-button
      fill="clear"
      class="ion-no-padding frozen-button"
      aria-label="Frozen"
      *ngIf="(wallet$ | async)?.frozen"
      tooltip="Multiple invoices are past due. Please contact the Organization admin and update the payment method."
      [routerLink]="['/account/billing']">
      <ion-badge><span class="ion-hide-md-down">Account&nbsp;</span>Status: Frozen </ion-badge>
    </ion-button>

    <ion-button
      fill="clear"
      class="ion-no-padding paused-button"
      aria-label="Paused"
      *ngIf="(plan$ | async)?.name === 'Pause'"
      tooltip="This account is paused. Please contact the Organization admin to upgrade and unpause."
      [routerLink]="['/account/subscription']">
      <ion-badge><span class="ion-hide-md-down">Account&nbsp;</span>Status: Paused </ion-badge>
    </ion-button>

    <ion-button
      fill="clear"
      class="ion-no-padding"
      aria-label="Payment Method Missing"
      *ngIf="(canceledStatus$ | async)?.paymentMethodMissing && !(descriptDrive$ | async)?.driveID"
      tooltip="Payment method is missing. Click here to update the payment method."
      [routerLink]="['/account/billing']">
      <ion-badge> Payment Method Missing </ion-badge>
    </ion-button>

    <ion-button
      fill="clear"
      class="ion-no-padding canceled-button"
      aria-label="Canceled"
      *ngIf="(canceledStatus$ | async)?.canceled"
      [tooltip]="
        (canceledStatus$ | async)?.closed
          ? 'This account is closed. Please subscribe to a plan to reactivate it.'
          : (canceledStatus$ | async)?.daysLeft +
            ' days left before you lose access to this Organization. Reactivate your subscription now.'
      "
      [routerLink]="['/account/subscription']">
      <ion-badge
        ><span class="ion-hide-md-down">Account&nbsp;</span>Status:
        {{ (canceledStatus$ | async)?.closed ? 'Closed' : 'Canceled' }}
      </ion-badge>
    </ion-button>
  </div>

  <!-- <ion-button
        color="dark"
        *ngIf="(pilotProgram$ | async)?.toggle"
        aria-label="Pilot Program Feedback"
        (click)="openBetaFeedback()"
        tooltip="Pilot Program is Enabled"
        placement="bottom"
        class="pilot-feedback">
        <p>PILOT</p>
      </ion-button> -->

  <sc-help-button></sc-help-button>

  <ion-button
    fill="clear"
    (click)="openNotifications($event)"
    tooltip="Notifications"
    placement="bottom"
    aria-label="Notifications Menu"
    class="notifications-button"
    [class.shake]="unreadNotifications">
    <ion-icon *ngIf="unreadNotifications" src="/assets/icons/24px/record.svg" class="notifications-read"> </ion-icon>
    <ion-icon src="/assets/icons/24px/notification.svg" color="lighter" slot="icon-only" class="notifications-bell">
    </ion-icon>
    <span class="sr-only">Notifications</span>
  </ion-button>

  <ion-button fill="clear" (click)="openProfileMenu($event)" class="profile-button" aria-label="Profile Menu">
    <sc-avatar [photoURL]="user?.photoURL" [tooltip]="user?.email" [tooltipOptions]="{ touch: false }"></sc-avatar>
  </ion-button>
</div>
