import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

import { RoleLimits, Roles } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { AuthService } from '../../services/auth/auth.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ThemeService } from '../../services/theme/theme.service';
import { Themes } from '@sc/types';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent {
  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;
  roles = Roles;

  theme$ = this.themeService.theme$;
  lightTheme = Themes.LIGHT;

  activeUser$ = this.userService.activeUser$;

  constructor(
    public router: Router,
    private popoverController: PopoverController,
    private rolesService: RolesService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private themeService: ThemeService,
    private userService: UserService
  ) {}

  async toggleTheme() {
    await this.themeService.toggleTheme();

    await this.analyticsService.track('toggled theme', { theme: this.themeService.theme$.value });
  }

  async signOut() {
    await this.authService.logout();
    await this.analyticsService.track('logged out');
    window.location.reload();
  }

  async close() {
    await this.popoverController.dismiss();
  }
}
