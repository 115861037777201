<ion-list lines="none">
  <ion-item (click)="close()" [routerLink]="['/account/profile']" alt="Profile" button="true">
    <sc-avatar [photoURL]="(activeUser$ | async)?.photoURL" slot="start"></sc-avatar>

    <ion-label> My Profile </ion-label>
  </ion-item>

  <ion-item
    *ngIf="(role$ | async) >= roles.ORG_ADMIN"
    (click)="close()"
    [routerLink]="['/account/members']"
    alt="Members"
    button="true">
    <ion-icon src="assets/icons/24px/member.svg" slot="start"></ion-icon>

    <ion-label> Members </ion-label>
  </ion-item>

  <ion-item (click)="close()" [routerLink]="['/account/shows']" alt="Shows" button="true">
    <ion-icon src="assets/icons/24px/cover-flow.svg" slot="start"></ion-icon>

    <ion-label> Shows </ion-label>
  </ion-item>

  <ion-item (click)="close()" [routerLink]="['/account/settings']" alt="Settings" button="true">
    <ion-icon src="assets/icons/24px/settings.svg" slot="start"></ion-icon>

    <ion-label> My Settings </ion-label>
  </ion-item>

  <ion-item (click)="toggleTheme()" button="true">
    <ion-icon
      [src]="(theme$ | async) === lightTheme ? '/assets/icons/24px/light-mode.svg' : '/assets/icons/24px/dark-mode.svg'"
      slot="start">
    </ion-icon>
    <ion-label class="theme-label">
      <span>App Theme</span>
      <p *ngIf="(theme$ | async) === lightTheme">Light Mode</p>
      <p *ngIf="(theme$ | async) !== lightTheme">Dark Mode</p>
    </ion-label>

    <ion-toggle tabindex="-1" slot="end" [checked]="(theme$ | async) === lightTheme"></ion-toggle>
  </ion-item>

  <ion-item (click)="signOut()" alt="Sign Out" button="true">
    <ion-icon src="assets/icons/24px/leave.svg" slot="start"></ion-icon>

    <ion-label> Sign Out </ion-label>
  </ion-item>
</ion-list>
