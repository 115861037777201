import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { TestEquipmentResultPage } from './test-equipment-result.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: TestEquipmentResultPage,
      },
    ]),
    SquadCastComponentsModule,
  ],
  declarations: [TestEquipmentResultPage],
})
export class TestEquipmentResultModule {}
