<ion-row>
  <ion-col>
    <h2 class="bold ion-text-center">
      <ion-icon src="assets/icons/24px/pilot.svg" slot="start"> </ion-icon>
      Pilot Program Feedback
    </h2>

    <form [formGroup]="betaFeedbackForm" (ngSubmit)="save()" novalidate>
      <ion-row>
        <ion-col>
          <p class="ion-text-center">
            If you run into any issues with the Pilot Program please provide feedback by using this form. If the issues
            are causing you trouble please turn off Pilot Program to resume using SquadCast at it's stable release.
            <br />
            <br />
            You can read more about our Pilot Program and our current Change Log
            <a href="https://developers.squadcast.fm/changelog" target="_blank">here</a>.
          </p>
          <ion-list lines="none">
            <ion-item class="input-large">
              <ion-label position="stacked"> Issue Title </ion-label>
              <ion-input formControlName="issueTitle" type="text" required></ion-input>
            </ion-item>
            <p
              class="error body-small-bold ion-no-padding ion-no-margin"
              *ngIf="isSubmitted && errorControl.issueTitle.errors?.required">
              Please title this bug/issue.
            </p>
            <p
              class="error body-small-bold ion-no-padding ion-no-margin"
              *ngIf="isSubmitted && errorControl.issueTitle.errors?.minlength">
              Issue should be min 5 chars long.
            </p>

            <ion-item class="input-large">
              <ion-label position="stacked"> Issue Description </ion-label>
              <ion-textarea formControlName="issueDescription" type="text" required></ion-textarea>
            </ion-item>
            <p
              class="error body-small-bold ion-no-padding ion-no-margin"
              *ngIf="isSubmitted && errorControl.issueDescription.errors?.required">
              Issue Description is required.
            </p>
            <p
              class="error body-small-bold ion-no-padding ion-no-margin"
              *ngIf="isSubmitted && errorControl.issueDescription.errors?.minlength">
              Issue Description should be min 5 chars long.
            </p>
            <ion-item class="upload-screenshot">
              <ion-label position="stacked"> Upload Screenshot </ion-label>
              <img id="screenshot" src="{{ screenshotURL }}" />
              <ion-button (click)="inputFile.click()" class="secondary" id="change-image-button" expand="block">
                Upload
              </ion-button>
              <input
                #inputFile
                type="file"
                accept="image/png | image/jpeg | image/jpg"
                (change)="photoInputChange($event)"
                id="input-file"
                style="display: none" />
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </form>

    <ion-row>
      <ion-col size="6">
        <ion-button (click)="cancel()" color="lighter" fill="outline" shape="round" expand="block"> Cancel </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="save()" color="primary" shape="round" expand="block"> Send Feedback </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
