<div class="select-container" *ngIf="linkingDriveName$ | async">
  <p>Descript Drive</p>
  <ng-select
    [ngModel]="linkingDriveName$ | async"
    (change)="changeDrive($event)"
    aria-label="Selected Drive"
    class="border-4 small-select"
    [clearable]="false"
    tooltip="Select a Descript Drive to link with your Organization"
    appearance="outline"
    [searchable]="false"
    [disabled]="(loading$ | async)?.length > 0"
    notFoundText="">
    <ng-option [value]="linkingDriveName$ | async">{{ linkingDriveName$ | async }}</ng-option>
    <ng-option value="backToDescript">Return to Descript to change drives</ng-option>
  </ng-select>
</div>

<div class="select-container" *ngIf="showOrgSelect">
  <p>SquadCast Organization</p>
  <ng-select
    [(ngModel)]="selectedOrg"
    aria-label="Select an Organization"
    class="border-4 option-content-space-between small-select"
    (change)="changeOrg()"
    [clearable]="false"
    tooltip="Select an Organization to link with your Descript Drive"
    appearance="outline"
    [searchable]="false"
    [disabled]="(loading$ | async)?.length > 0"
    notFoundText="">
    <ng-option *ngFor="let org of availableOrgs$ | async" [value]="org" [disabled]="org.driveID">
      <span>{{ org.orgName }}</span> <span>{{ org.driveID ? 'Claimed' : '' }}</span>
    </ng-option>
  </ng-select>
</div>
