<ion-row>
  <ion-col>
    <h5 class="bold ion-text-center">We're excited to see you again!</h5>

    <h4 class="ion-text-center">
      Your subscription is set to resume as it was,<br />
      <strong> You can continue to use all the features that you love! </strong>
    </h4>
  </ion-col>
</ion-row>

<ion-list>
  <ion-item>
    <ion-label color="white" class="text-light-dark"> What brought you back to SquadCast.fm? </ion-label>

    <ion-select [value]="reason" (ionChange)="selectReason($event)" placeholder="Choose a reason" interface="popover">
      <ion-select-option *ngFor="let reason of reasons" [value]="reason"> {{ reason }} </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item *ngIf="reason === 'Best features' || reason === 'Theres no better alternative'" id="missing-features">
    <ion-label color="white" class="text-light-dark"> What feature is most important to you? </ion-label>

    <ion-select
      [value]="bestFeature"
      (ionChange)="selectBestFeature($event)"
      placeholder="Choose a feature"
      interface="popover">
      <ion-select-option *ngFor="let bestFeature of bestFeatures" [value]="bestFeature">
        {{ bestFeature }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col>
    <ion-button (click)="close()" color="light" expand="block" shape="round"> Close </ion-button>
  </ion-col>

  <ion-col>
    <ion-button (click)="submitFeedback()" color="primary" expand="block" shape="round"> Submit Feedback </ion-button>
  </ion-col>
</ion-row>
