import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

import { UserModel } from '@sc/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { APIKey } from '@sc/types';

import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

@Component({
  selector: 'sc-new-api-key',
  templateUrl: 'new-api-key.page.html',
  styleUrls: ['new-api-key.page.scss'],
})
export class NewApiKeyPage implements OnInit {
  @Input() user: UserModel;
  name = '';

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private idTokenService: IdTokenService,
    private califoneService: CalifoneService,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('cancelled create api key');
  }

  async create() {
    if (!this.name) {
      this.toastrService.error(`Provide a Key Name`, `Failed to create API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      const idToken = await this.idTokenService.getFreshIdToken();
      const response: any = await firstValueFrom(
        this.califoneService.createApiKey(
          { id: this.user.uid, orgID: this.organizationsService.dashboardOrgID$.value },
          idToken
        )
      );
      const date: string = dayjs().format('MMMM Do, YYYY');
      const apiKey: APIKey = {
        key: response.key,
        date,
        name: this.name,
      };

      await this.califoneService.addApiKey(apiKey);

      this.toastrService.success(null, `Successfully created API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('created api key', { apiKeyName: this.name, success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to create API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('created api key', { apiKeyName: this.name, success: false });
    }
  }
}
