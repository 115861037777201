import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from './../../services/user/user.service';
import { AuthService } from './../../services/auth/auth.service';

@Injectable()
export class GuestService implements CanActivate {
  constructor(public userService: UserService, public authService: AuthService) {}

  async canActivate() {
    const user = await this.userService.authUser$.toPromise();

    if (!user) {
      await this.authService.loginWithAnon();
    }
    return true;
  }
}
