<header>
  <h3 class="bold">Share SquadShot</h3>
</header>

<section>
  <div class="squadshot">
    <img [src]="url" alt="Squadshot image" />
  </div>

  <div class="share-buttons">
    <!-- <ion-fab-button (click)="shareFacebook()"><ion-icon name="logo-facebook"></ion-icon></ion-fab-button>
    <ion-fab-button (click)="shareInstagram()"><ion-icon name="logo-instagram"></ion-icon></ion-fab-button>
    <ion-fab-button (click)="shareDribbble()"><ion-icon name="logo-dribbble"></ion-icon></ion-fab-button> -->
    <ion-fab-button (click)="shareTwitter()"><ion-icon name="logo-twitter"></ion-icon></ion-fab-button>
    <ion-fab-button (click)="downloadImage()"><ion-icon name="download-outline"></ion-icon></ion-fab-button>

    <ion-button class="primary" (click)="copyLink()">
      <ion-icon slot="start" src="assets/icons/24px/copy.svg"></ion-icon>
      Copy Link
    </ion-button>
  </div>
</section>
