<div class="panel">
  <ion-row class="ion-no-padding">
    <ion-col class="ion-no-padding">
      <h1>Organizations</h1>
    </ion-col>

    <ion-col *ngIf="(role$ | async) >= roleLimits.ORG_CREATE" class="ion-no-padding">
      <ion-button (click)="addOrganization()" id="add" class="line ion-float-right" aria-label="Add Org">
        <ion-icon src="/assets/icons/24px/add.svg" slot="start"></ion-icon>

        New Org
      </ion-button>
    </ion-col>
  </ion-row>

  <div *ngIf="(role$ | async) >= roleLimits.ORG_READ">
    <div
      *ngFor="let org of availableOrgs; trackBy: trackByOrg"
      class="organization"
      [class.active]="(dashboardOrg$ | async).orgID === org.orgID"
      (click)="setDashboardOrg(org)">
      <div class="info">
        <img [src]="org.orgLogo" />
        <div class="name">
          <div class="org-option">
            <p>
              {{ org.orgName }}
            </p>
            <ion-buttons>
              <ion-button
                *ngIf="org.orgOwner === (activeUser$ | async)?.uid"
                (click)="editOrg($event, org)"
                tooltip="Edit Org">
                <span class="sr-only"
                  >{{ org.orgName }} Organization is on {{ 'the' + org.plan?.name || 'no' }} plan and has
                  {{ org.memberIDs.length }} member{{ org.memberIDs.length > 1 ? 's' : '' }}.
                  {{ org.wallet?.available || '0' }} available. Edit Org</span
                >
                <ion-icon slot="icon-only" src="assets/icons/24px/edit.svg"> </ion-icon>
              </ion-button>

              <ion-button aria-label="Delete Org" *ngIf="!org.plan" class="deleteOrg" (click)="deleteOrg($event, org)">
                <ion-icon slot="icon-only" src="assets/icons/24px/delete.svg"> </ion-icon>
              </ion-button>
            </ion-buttons>
          </div>
          <p class="plan">
            {{ org.plan?.name || 'No' }} Plan - {{ org.memberIDs.length }} Member{{
              org.memberIDs.length > 1 ? 's' : ''
            }}
          </p>
        </div>
      </div>

      <ion-progress-bar color="secondary" [value]="org.wallet?.percentage"></ion-progress-bar>

      <div class="time">
        <div>
          <p>{{ org.wallet?.used ?? '00:00:00' }}</p>
          <p class="label action-small">Recorded</p>
        </div>
        <div>
          <p>{{ org.wallet?.available ?? '00:00:00' }}</p>
          <p class="label action-small">Available</p>
        </div>
      </div>
    </div>
  </div>
</div>
