import { Injectable } from '@angular/core';
import { Organization, UpdateShowDto } from '@sc/types';
import { CreateShowDto, TransferShowDto } from '@sc/types';
import { environment } from '../../../environments/environment';
import { UserModel } from '@sc/types';
import { HttpService } from '../http/http.service';
import { SessionDTO } from '@sc/types';
import { OrgTeamMember } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class LafayetteService {
  lafayette = environment.microservices.lafayette;

  constructor(private httpService: HttpService) {}

  createSession(sessionData: SessionDTO, sessionID = '') {
    return this.httpService.post(`${this.lafayette}/api/v1/sessions/${sessionID}`, sessionData);
  }

  updateSession(sessionData: SessionDTO, sessionID: string) {
    return this.httpService.patch(`${this.lafayette}/api/v1/sessions/${sessionID}`, sessionData);
  }

  moveSession(sessionID: string, showID: string) {
    return this.httpService.patch(`${this.lafayette}/api/v1/sessions/move/${sessionID}`, { showID });
  }

  deleteSession(sessionID: string) {
    return this.httpService.delete(`${this.lafayette}/api/v1/sessions/${sessionID}`, {});
  }

  createShow(showData: CreateShowDto) {
    return this.httpService.post(`${this.lafayette}/api/v1/shows`, showData);
  }

  transferShow(showID: string, transferShowDto: TransferShowDto) {
    return this.httpService.post(`${this.lafayette}/api/v1/shows/${showID}/transfer`, transferShowDto);
  }

  deleteShow(showID: string, user: UserModel) {
    return this.httpService.delete(`${this.lafayette}/api/v1/shows/${showID}`, { user });
  }

  updateShow(show: UpdateShowDto) {
    return this.httpService.patch(`${this.lafayette}/api/v1/shows/${show.showID}`, show);
  }

  createOrg(orgData: Organization) {
    return this.httpService.post(`${this.lafayette}/api/v1/organizations`, orgData);
  }

  deleteOrg(orgID: string, user: UserModel) {
    return this.httpService.delete(`${this.lafayette}/api/v1/organizations/${orgID}`, { user });
  }

  updateOrgTeamMember(orgID: string, member: OrgTeamMember) {
    return this.httpService.patch(`${this.lafayette}/api/v1/teams/${orgID}`, member);
  }

  updateOrg(org: Organization) {
    return this.httpService.patch(`${this.lafayette}/api/v1/organizations/${org.orgID}`, org);
  }

  removeOrgTeamMember(org: Organization, teamMemberUID: string) {
    return this.httpService.delete(`${this.lafayette}/api/v1/teams/${org.orgID}/${teamMemberUID}`, {});
  }

  fixUser(userID: string) {
    return this.httpService.get(`${this.lafayette}/api/v1/teams/fix/${userID}`);
  }

  claimTeamInvite(inviteID: string, userID: string) {
    return this.httpService.post(`${this.lafayette}/api/v1/teams/invites/claim`, { inviteID, userID });
  }

  userMigration(userID: string, orgID: string) {
    return this.httpService.get(`${this.lafayette}/api/v1/migrations/user/${userID}/${orgID}`);
  }

  sessionsMigration(showID: string) {
    return this.httpService.get(`${this.lafayette}/api/v1/migrations/sessions/${showID}`);
  }

  deleteChatGroup(sessionID: string, chatGroupID: string) {
    return this.httpService.delete(`${this.lafayette}/api/v1/sessions/chat/${sessionID}/${chatGroupID}`, {});
  }

  deleteSquadshot(sessionID: string, squadshotID: string) {
    return this.httpService.delete(`${this.lafayette}/api/v1/sessions/squadshot/${sessionID}/${squadshotID}`, {});
  }
}
