import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { WorkflowAction, Workflows } from '@sc/types';
import { WorkflowService } from '../../services/workflow/workflow.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { DropboxService } from '../../services/dropbox/dropbox.service';

@Component({
  selector: 'sc-workflow-action',
  templateUrl: 'workflow-action.page.html',
  styleUrls: ['workflow-action.page.scss'],
})
export class WorkflowActionPage implements OnInit, OnDestroy {
  @Input() workflow: Workflows;
  plan$ = this.walletService.dashboardPlan$;
  dropbox$ = this.dropboxService.dropbox$;
  action: WorkflowAction;
  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private modalController: ModalController,
    private workflowService: WorkflowService,
    private walletService: WalletService,
    private dropboxService: DropboxService
  ) {}

  ngOnInit(): void {
    this.action = this.workflowService.actions[this.workflow];
  }

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('cancelled workflow action', { workflow: this.workflow });
  }

  handler() {
    if (
      (this.workflow === 'dolby' && !this.plan$.value?.dolbyMastering) ||
      (this.workflow === 'mix' && !this.plan$.value?.audioMixing)
      // ||
      // (this.workflow === 'dropbox' && !this.dropbox$.value?.id)
    ) {
      this.router.navigate(['account/subscription']);
    } else {
      this.action.handler();
    }

    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.subs.map((sub) => sub.unsubscribe);
  }
}
