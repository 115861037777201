import { Injectable } from '@angular/core';

import { SCSubject } from '../../util/sc-subject.class';
import { StudioSidebarItem } from '@sc/types';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class StudioSidebarService {
  private previouslyOpenedSidebarIndexes: number[] = [];
  private sidebarMinWidth = 316;
  canvasMinWidth = 275;

  sidebars = new Map<
    StudioSidebarItem['appearance'],
    {
      name: StudioSidebarItem['appearance'];
      icon: string;
    }
  >([
    ['settings', { name: 'settings', icon: '/assets/icons/32px/settings-bold.svg' }],
    ['chat', { name: 'chat', icon: '/assets/icons/32px/chat-bold.svg' }],
    ['participants', { name: 'participants', icon: '/assets/icons/32px/participants-bold.svg' }],
    ['recordings', { name: 'recordings', icon: '/assets/icons/32px/recordings-bold.svg' }],
    ['stats', { name: 'stats', icon: '/assets/icons/24px/nerd.svg' }],
  ]);

  // Always keep this even number of items
  defaultSidebarItems: StudioSidebarItem[] = [
    {
      open: false,
      appearance: 'settings',
    },
    null,
    null,
    {
      open: false,
      appearance: 'stats',
      hidden: true,
    },
    {
      open: false,
      appearance: 'stats',
      hidden: true,
    },
    {
      open: false,
      appearance: 'chat',
    },
    {
      open: false,
      appearance: 'participants',
    },
    {
      open: false,
      appearance: 'recordings',
    },
  ];

  sidebarItems$ = new SCSubject<StudioSidebarItem[]>(this.defaultSidebarItems);

  editingSidebars$ = new SCSubject<boolean>(false);
  allowMultipleSidebars$ = new SCSubject<boolean>(true);

  constructor(private settingsService: SettingsService) {
    this.setupUserSettings();
    this.setupOverflowingSidebars();
  }

  /**
   * toggles studio sidebar
   *
   * @param value
   */
  toggleSidebar(
    appearance?: StudioSidebarItem['appearance'],
    value?: boolean,
    index?: number,
    metadata?: StudioSidebarItem['metadata']
  ) {
    const sidebarToOpenIndex = index ?? this.sidebarItems$.value.findIndex((item) => appearance && item?.appearance === appearance);

    if (sidebarToOpenIndex !== -1) {
      this.sidebarItems$.next(
        this.sidebarItems$.value.map((item, i) => {
          if (i === sidebarToOpenIndex) {
            if (value ?? !item.open) {
              this.previouslyOpenedSidebarIndexes = this.previouslyOpenedSidebarIndexes.filter((idx) => idx !== i);
              this.previouslyOpenedSidebarIndexes.push(i);
            }
            return {
              ...item,
              open: value ?? !item.open,
              metadata: metadata ?? item.metadata ?? null,
            };
          }
          if (item?.open && !this.allowMultipleSidebars$.value) {
            return {
              ...item,
              open: false,
              metadata: metadata ?? item.metadata ?? null,
            };
          }
          return item;
        })
      );
      return;
    } else {
      this.sidebarItems$.next(
        this.sidebarItems$.value.map((item) => {
          if (item?.open) {
            return {
              ...item,
              open: false,
              metadata: metadata ?? item.metadata ?? null,
            };
          }
          return item;
        })
      );
    }
  }

  toggleStats(participantID: string) {
    const sidebarItems = this.sidebarItems$.value;

    // if participant stats sidebar exists open/close it
    for (const [idx, item] of sidebarItems.entries()) {
      if (item?.metadata?.participantID === participantID) {
        this.toggleSidebar(undefined, !item.open, idx);
        return;
      }
    }

    // if participant stats is not open, try to find closed 'stats' sidebar and open it, otherwise overwrite the last 'stats' sidebar
    let lastStatsSidebarIndex: number = null;
    for (const [idx, item] of sidebarItems.entries()) {
      if (item?.appearance === 'stats') {
        lastStatsSidebarIndex = idx;
      }

      if (item?.appearance === 'stats' && !item?.open) {
        this.toggleSidebar(undefined, true, idx, { participantID });
        return;
      }
    }
    if (lastStatsSidebarIndex !== null) this.toggleSidebar(undefined, true, lastStatsSidebarIndex, { participantID });
  }

  openChatGroup(chatGroupID?: string) {
    const sidebarItems = this.sidebarItems$.value;

    // if chat group is already open, don't do anything
    for (const [idx, item] of sidebarItems.entries()) {
      if (item?.metadata?.chatGroupID === chatGroupID && item?.open) {
        return;
      }
    }

    // if chat group is not open, try to find closed 'chat' sidebar and open it, otherwise overwrite the last 'chat' sidebar
    let lastChatSidebarIndex: number = null;
    for (const [idx, item] of sidebarItems.entries()) {
      if (item?.appearance === 'chat') {
        lastChatSidebarIndex = idx;
      }

      if (item?.appearance === 'chat' && !item?.open) {
        this.toggleSidebar(undefined, true, idx, { chatGroupID });
        return;
      }
    }
    if (lastChatSidebarIndex !== null) this.toggleSidebar(undefined, true, lastChatSidebarIndex, { chatGroupID });
  }

  addSidebarItem(index: number, item: StudioSidebarItem['appearance'] = 'chat') {
    this.sidebarItems$.next([
      ...this.sidebarItems$.value.slice(0, index),
      { open: false, appearance: item },
      ...this.sidebarItems$.value.slice(index + 1),
    ]);

    this.settingsService.setUserAppSettings({
      sidebarItems: this.sidebarItems$.value.map((item) => ({ ...item, open: false })),
    });
  }

  removeSidebarItem(index: number) {
    this.sidebarItems$.next([
      ...this.sidebarItems$.value.slice(0, index),
      null,
      ...this.sidebarItems$.value.slice(index + 1),
    ]);

    this.settingsService.setUserAppSettings({
      sidebarItems: this.sidebarItems$.value.map((item) => ({ ...item, open: false })),
    });
  }

  resetSidebarItems() {
    this.sidebarItems$.next(this.defaultSidebarItems);
    this.settingsService.setUserAppSettings({
      sidebarItems: this.sidebarItems$.value.map((item) => ({ ...item, open: false })),
    });
  }

  async setupUserSettings() {
    const userSettings = await this.settingsService.userAppSettings$.toPromise();
    if (userSettings?.allowMultipleSidebars !== undefined) {
      this.allowMultipleSidebars$.next(userSettings.allowMultipleSidebars);
    }
    if (userSettings?.sidebarItems !== undefined) {
      this.sidebarItems$.next(userSettings.sidebarItems);
    }
  }

  setupOverflowingSidebars() {
    this.sidebarItems$.subscribe(() => {
      this.closeOverflowingSidebars();
    });

    window.addEventListener('resize', () => {
      this.closeOverflowingSidebars();
    });
  }

  async closeOverflowingSidebars() {
    const openSidebarCount = this.sidebarItems$.value.filter((item) => item?.open).length;
    if (openSidebarCount === 1) return;
    if (openSidebarCount * this.sidebarMinWidth > window.innerWidth - this.canvasMinWidth) {
      const sidebarToCloseIndex = this.previouslyOpenedSidebarIndexes.find((index) => {
        return this.sidebarItems$.value[index]?.open;
      });
      if (sidebarToCloseIndex !== -1) {
        await new Promise((resolve) => setTimeout(resolve, 0));
        this.sidebarItems$.next(
          this.sidebarItems$.value.map((item, i) => {
            if (i === sidebarToCloseIndex) {
              return {
                ...item,
                open: false,
              };
            }
            return item;
          })
        );
      }
    }
  }

  toggleAllowMultipleSidebars(value?: boolean) {
    this.allowMultipleSidebars$.next(value ?? !this.allowMultipleSidebars$.value);
    if (!this.allowMultipleSidebars$.value) {
      this.toggleSidebar(undefined, true, 0);
    }
    this.settingsService.setUserAppSettings({ allowMultipleSidebars: this.allowMultipleSidebars$.value });
  }
}
