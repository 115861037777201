export enum CloudFunctionFullUrl_PROD {
  DAILY_RECORDING = 'https://us-central1-squadcast-7fa10.cloudfunctions.net/daily-recordings',
  DAILY_ROOM = 'https://us-central1-squadcast-7fa10.cloudfunctions.net/daily-room',
  DAILY_TOKENS = 'https://us-central1-squadcast-7fa10.cloudfunctions.net/daily-tokens',
  DAILY_WEBHOOKS = 'https://us-central1-squadcast-7fa10.cloudfunctions.net/daily-webhooks',
}

export enum CloudFunctionFullUrl_DEV {
  DAILY_RECORDING = 'https://us-central1-squadcast-dev.cloudfunctions.net/daily-recordings',
  DAILY_ROOM = 'https://us-central1-squadcast-dev.cloudfunctions.net/daily-room',
  DAILY_TOKENS = 'https://us-central1-squadcast-dev.cloudfunctions.net/daily-tokens',
  DAILY_WEBHOOKS = 'https://us-central1-squadcast-dev.cloudfunctions.net/daily-webhooks',
}
