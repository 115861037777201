import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '../../services/settings/settings.service';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '@sc/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { MixSettings } from '@sc/types';
import { ShowsService } from '../../services/shows/shows.service';
import { Show } from '@sc/types';
@Component({
  selector: 'sc-auphonic-settings',
  templateUrl: './auphonic-settings.component.html',
  styleUrls: ['./auphonic-settings.component.scss'],
})
export class AuphonicSettingsComponent implements OnInit, OnDestroy {
  @Input() color = 'medium';

  currentMixSettings: MixSettings;
  user: UserModel;
  show: Show;

  expandedIndividual = false;
  expandedMaster = false;
  loading = true;

  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.setupUser();
    this.setupMixSettings();
    this.setupDashboardShow();
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user: UserModel) => {
      this.user = user;
      this.loading = false;
    });

    this.subs.push(sub);
  }

  setupDashboardShow() {
    const sub = this.showsService.dashboardShow$.subscribe((show) => {
      this.show = show;
    });

    this.subs.push(sub);
  }

  setupMixSettings() {
    const sub = this.settingsService.dashboardShowMixSettings$.subscribe((settings) => {
      this.currentMixSettings = settings;
    });

    this.subs.push(sub);
  }

  //AUPHONIC
  toggleExpandedIndividual() {
    this.expandedIndividual = !this.expandedIndividual;
  }

  async toggleIndividualHipFilter(event: ICustomEvent) {
    this.currentMixSettings.individual.hipfilter = event.detail.checked;
    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: individual tracks - hip filter`, {
      enabled: event.detail.checked,
    });
  }

  async toggleIndividualDeNoise(event: ICustomEvent) {
    this.currentMixSettings.individual.denoise = event.detail.checked;
    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: individual tracks - denoise`, {
      enabled: event.detail.checked,
    });
  }

  toggleExpandedMaster() {
    this.expandedMaster = !this.expandedMaster;
  }

  async toggleMasterLeveler(event: ICustomEvent) {
    this.currentMixSettings.master.leveler = event.detail.checked;
    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: master track - leveler`, {
      enabled: event.detail.checked,
    });
  }

  async toggleMasterGate(event: ICustomEvent) {
    this.currentMixSettings.master.gate = event.detail.checked;
    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: master track - gate`, {
      enabled: event.detail.checked,
    });
  }

  async toggleMasterCrossgate(event: ICustomEvent) {
    this.currentMixSettings.master.crossgate = event.detail.checked;
    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: master track - crossgate`, {
      enabled: event.detail.checked,
    });
  }

  async setMasterLoudnessTarget(event: ICustomEvent) {
    if (this.currentMixSettings.master.loudnesstarget > -8) {
      this.currentMixSettings.master.loudnesstarget = -8;
    } else if (this.currentMixSettings.master.loudnesstarget < -31) {
      this.currentMixSettings.master.loudnesstarget = -31;
    }

    await this.settingsService.setShowMixSettings(this.currentMixSettings, this.show.showID);
    await this.analyticsService.track(`toggled auphonic mixing setting: master track - loudness target`, {
      enabled: event.detail.checked,
    });
  }

  async resetToDefaultMixingSettings() {
    const mixingSettings: MixSettings = {
      individual: {
        hipfilter: true,
        denoise: false,
      },
      master: {
        loudnesstarget: -16,
        leveler: true,
        gate: true,
        crossgate: true,
      },
    };
    await this.settingsService.setShowMixSettings(mixingSettings, this.show.showID);
    await this.analyticsService.track(`reset auphonic mixing setting to default`);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
