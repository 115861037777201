import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import Rollbar from 'rollbar';
import { Subscription } from 'rxjs';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { ShowsService } from '../../services/shows/shows.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Session } from '@sc/types';
import { Show } from '@sc/types';

@Component({
  selector: 'sc-move-session',
  templateUrl: './move-session.page.html',
  styleUrls: ['./move-session.page.scss'],
})
export class MoveSessionPage implements OnInit, OnDestroy {
  @Input() session: Session;
  @Input() popover = false;

  defaultShowImage = this.showsService.DEFAULT_IMG;

  moveToShow: Show;
  moveFromShow: Show;
  availableShows: Show[];

  subs: Array<Subscription> = [];

  constructor(
    private showsService: ShowsService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private analyticsService: AnalyticsService,
    private lafayetteService: LafayetteService,
    private toastrService: ToastrService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  ngOnInit() {
    this.setupAvailableShows();
  }

  async moveSession() {
    try {
      await this.lafayetteService.moveSession(this.session.sessionID, this.moveToShow.showID);
      this.analyticsService.track('moved session', {
        sessionID: this.session.sessionID,
        movedToShowID: this.moveToShow.showID,
        success: true,
      });

      this.toastrService.success('Session moved successfully to ' + this.moveToShow.showName, 'Success', {
        toastComponent: GeneralToastComponent,
        progressBar: true,
        closeButton: true,
      });
    } catch (error) {
      this.toastrService.error(
        'Please try refreshing the page and transferring again. Please contact support if the issue persists.',
        'Failed to Move Session',
        {
          toastComponent: GeneralToastComponent,
          progressBar: true,
          closeButton: true,
        }
      );

      this.rollbar.error('Failed to move session to a different show', {
        error,
        sessionID: this.session.sessionID,
        showID: this.moveToShow.showID,
      });

      this.analyticsService.track('moved session', {
        sessionID: this.session.sessionID,
        movedToShowID: this.moveToShow.showID,
        success: false,
      });
    }
    if (this.popover) this.popoverController.dismiss(this.moveToShow.showID);
    else this.modalController.dismiss(this.moveToShow.showID);
  }

  cancel() {
    if (this.popover) this.popoverController.dismiss();
    else this.modalController.dismiss();
    this.analyticsService.track('closed move session modal');
  }

  async setupAvailableShows() {
    const sub = this.showsService.availableShows$.subscribe((shows) => {
      this.availableShows = shows.filter((show) => show.showID !== this.session.showID);
      this.moveToShow = this.availableShows[0];
      this.moveFromShow = shows.find((show) => show.showID === this.session.showID);
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
