import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { collection, Firestore } from '@angular/fire/firestore';
import { PopoverController } from '@ionic/angular';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { combineLatest, firstValueFrom, interval, map, debounce, BehaviorSubject, switchMap } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import * as Rollbar from 'rollbar';

import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { ActiveParticipants } from '@voxeet/voxeet-web-sdk/types/events/notification';
import { Participant as DolbyParticipant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import Conference from '@voxeet/voxeet-web-sdk/types/models/Conference';
import { JoinOptions, ParticipantInfo } from '@voxeet/voxeet-web-sdk/types/models/Options';
import {
  SubscribeActiveParticipants,
  SubscribeConferenceEnded,
} from '@voxeet/voxeet-web-sdk/types/models/Notifications';
import { MediaStreamWithType } from '@voxeet/voxeet-web-sdk/types/models/MediaStream';
import ConferenceOptions from '@voxeet/voxeet-web-sdk/types/models/ConferenceOptions';
import { AudioCaptureModeOptions } from '@voxeet/voxeet-web-sdk/types/models/Audio';
import { VideoForwardingOptions } from '@voxeet/voxeet-web-sdk/types/models/VideoForwarding';
import { VideoProcessor } from '@voxeet/voxeet-web-sdk/types/models/VideoProcessor';
import ConferenceParameters from '@voxeet/voxeet-web-sdk/types/models/ConferenceParameters';

import {
  LiveDolbyParticipant,
  Locations,
  ParticipantFsDolby,
  ParticipantFS,
  Prompt,
  PromptTypes,
  SessionStats,
  AudioCaptureMode,
  NoiseReductionLevel,
  VideoProcessorType,
  AudioBitrate,
  VideoResolutions,
} from '@sc/types';
import { environment } from '../../../environments/environment';
import { SCSubject } from '../../util/sc-subject.class';
import { IdTokenService } from '../id-token/id-token.service';
import { EquipmentService } from '../equipment/equipment.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { MuteService } from '../mute/mute.service';
import { SettingsService } from '../settings/settings.service';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';
import { IPadService } from '../ipad/ipad.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { LeaveFeedbackToastComponent } from '../../toasts/leave-feedback-toast/leave-feedback-toast.component';
import { WebhookEvent, WebhookEventNames, DolbyTokenResponse, ConfTypes } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { VideoOffService } from '../video-off/video-off.service';
import { WindowToken } from '../../services/window/window';
import { CalifoneService } from '../califone/califone.service';
import { WalletService } from '../wallet/wallet.service';
import { NameService } from '../name/name.service';
import { StatsService } from '../stats/stats.service';

@Injectable({
  providedIn: 'root',
})
export class DolbyService {
  distatone = environment.microservices.distatone;
  deviceInfo: DeviceInfo;
  delayHeadphoneSelection: MediaDeviceInfo;
  dummyVideoStream: MediaStream;
  defaultForwardingNumber = 25;
  currentForwardingOptions: VideoForwardingOptions;
  sessionsCol = collection(this.firestore, 'sessions');
  studioSession$ = this.sessionsService.studioSession$;
  filterDeviceName = this.equipmentService.filterDeviceName;

  activeUser$ = this.userService.activeUser$;
  dolbyConversation$: SCSubject<Conference> = new SCSubject();
  leaving$: SCSubject<boolean> = new SCSubject();
  hideIncomingVideo$: SCSubject<boolean> = new SCSubject(false);
  screenshare$: SCSubject<ParticipantFsDolby> = new SCSubject();
  conferenceAlias: string;
  subbedAlias: string;
  autoplayBlocked$ = new SCSubject(false);

  localParticipant$ = new SCSubject<ParticipantFsDolby>();
  stageParticipants$ = new SCSubject<Map<string, ParticipantFsDolby>>(new Map<string, ParticipantFsDolby>());
  sessionParticipants$ = new BehaviorSubject<Map<string, ParticipantFS>>(new Map<string, ParticipantFS>());
  nonPrioritySpeakersArray$ = new SCSubject<Array<ParticipantFsDolby>>([]);
  nonPrioritySpeakersArrayObjectFit$ = new SCSubject<Array<ParticipantFsDolby['objectFit']>>([]);
  prioritySpeakersArray$ = new SCSubject<Array<ParticipantFsDolby>>([]);
  prioritySpeakersArrayObjectFit$ = new SCSubject<Array<ParticipantFsDolby['objectFit']>>([]);
  pinnedParticipantsArray$ = new SCSubject<Array<DolbyParticipant>>([]);
  activeParticipants$: SCSubject<ActiveParticipants> = new SCSubject();
  backstageParticipants$ = new SCSubject<Array<DolbyParticipant>>([]);
  backstageParticipantsArray$ = new SCSubject<Array<ParticipantFsDolby>>([]);
  videoProcessor$ = new BehaviorSubject<VideoProcessor>({ type: VideoProcessorType.None });
  location$ = new SCSubject<Locations>();
  disconnectedParticipants$ = new SCSubject<Map<string, ParticipantFsDolby>>(new Map<string, ParticipantFsDolby>());
  recording$ = new SCSubject<boolean>(false);

  localState = new Map<string, { minimized?: boolean; prioritySpeaker?: boolean; objectFit?: 'contain' | 'cover' }>();

  defaultStats: SessionStats = {
    lastUpdated: new Date().getTime(),
    audioOnly: false,
    network: {},
    remote: {},
    audio: {},
    video: {},
  };

  statsInterval: NodeJS.Timer;
  activeSpeakerInterval: NodeJS.Timer;
  activeSpeakers$ = new BehaviorSubject<Array<string>>([]);
  lastSpeakers: Array<string> = [];
  lastVideoDevice: string;
  lastResolution: VideoResolutions;

  activeParticipantCallback: () => void;
  conferenceEndedCallback: () => void;

  conferenceParams: ConferenceParameters = {
    liveRecording: true,
    rtcpMode: 'max',
    ttl: 0,
    videoCodec: 'H264',
    audioOnly: false,
    dolbyVoice: false,
  };

  joinOptions: JoinOptions = {
    constraints: { audio: false, video: false },
    dvwc: false,
    simulcast: true,
  };

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private http: HttpClient,
    private router: Router,
    private firestore: Firestore,
    private analyticsService: AnalyticsService,
    private califoneService: CalifoneService,
    private deviceDetectorService: DeviceDetectorService,
    private equipmentService: EquipmentService,
    private idTokenService: IdTokenService,
    private iPadService: IPadService,
    private muteService: MuteService,
    private nameService: NameService,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
    private sessionsService: SessionsService,
    private toastrService: ToastrService,
    private userService: UserService,
    private videoOffService: VideoOffService,
    private walletService: WalletService,
    private statsService: StatsService,
    @Inject(WindowToken) private window: Window
  ) {
    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // VoxeetSDK.packageUrlPrefix = window.location.origin + '/assets/wasm/';
    // this.setupMute();
    // this.setupVideoOff();
    // this.setupShowSettings();
    // this.setupSessionSettings();
    // this.onConversationEvents();
    // this.setupStageParticipants();
    // this.setupBackstageParticipants();
    // this.setupEquipment();
    // this.setupSessionParticipants();
    // this.setupSystemDevices();
    // this.watchActiveSpeakers();
    // this.setupRecording();
    // const win = window as any;
    // win.vxt = VoxeetSDK;
  }

  async initSession() {
    const initTimeout = setTimeout(() => {
      this.rollbar.error('Timeout Initializing Dolby Session', {}, locals);
      this.toastrService.error(
        `This may be due to a network issue.  Check your connection, refresh the page, and try again.`,
        `Timed out initializing the session`,
        {
          enableHtml: true,
          progressBar: false,
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      return false;
    }, 10_000);
    await this.studioSession$.nextExistingValue((session) => session.sessionID);
    const locals = {
      idToken: null,
      dolbyToken: null,
      name: null,
      sessionProps: null,
    };
    try {
      if (!this.studioSession$.value) {
        this.rollbar.error('Tried to initialize Dolby session without studio session');
        this.toastrService.error(
          `This may be due to a network issue.  Check your connection, refresh the page, and try again.`,
          `Cannot find the session`,
          {
            enableHtml: true,
            progressBar: false,
            closeButton: true,
            tapToDismiss: false,
            toastComponent: GeneralToastComponent,
            disableTimeOut: true,
          }
        );
        return false;
      }
      locals.name = await firstValueFrom(
        this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
      );
      locals.sessionProps = {
        name: locals.name,
        avatarUrl: this.activeUser$.value.photoURL,
      };
      locals.idToken = await this.idTokenService.getFreshIdToken();
      locals.dolbyToken = await firstValueFrom(this.getSessionToken(locals.idToken));
      this.initializeToken(locals.dolbyToken.access_token);

      await this.openSession(locals.sessionProps);
      clearTimeout(initTimeout);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return true;
    } catch (error) {
      this.rollbar.error('Failed to Initialize Dolby Session', error, locals);
      this.toastrService.error(
        `This may be due to a network issue.  Check your connection, refresh the page, and try again.<br/><br/><code>${error.message}</code>`,
        `Failed to initialize the session`,
        {
          enableHtml: true,
          progressBar: false,
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      clearTimeout(initTimeout);
      throw error;
    }
  }

  async setupSystemDevices() {
    this.enumerateDevices();

    VoxeetSDK.mediaDevice.on('deviceChanged', (changes) => {
      let devices = [];
      if (changes.list.audioInput) devices = devices.concat(changes.list.audioInput);
      if (changes.list.videoInput) devices = devices.concat(changes.list.videoInput);
      if (changes.list.audioOutput) devices = devices.concat(changes.list.audioOutput);
      this.setSystemDevices(devices);
    });
  }

  async enumerateDevices() {
    const devices = await VoxeetSDK.mediaDevice.enumerateDevices();
    if (devices.filter((d) => d.label).length === 0) {
      await this.equipmentService.hasPermission$.nextExistingValue((p) => p[0]);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      VoxeetSDK.mediaDevice
        .enumerateDevices()
        .then((devices) => {
          this.setSystemDevices(devices);
        })
        .finally(() => {
          stream.getTracks().forEach((track) => track.stop());
        });
    } else this.setSystemDevices(devices);
  }

  setSystemDevices(devices: MediaDeviceInfo[]) {
    const filteredDevices = devices.filter((d) => d.deviceId && !d.label?.includes('Descript Loopback'));
    this.equipmentService.systemDevices$.next(filteredDevices);
  }

  playBlockedAudio() {
    VoxeetSDK.conference.playBlockedAudio();
    this.autoplayBlocked$.next(false);
  }

  showBackstageParticipantsJoinedToast(backstageParticipants: ParticipantFsDolby[]) {
    const namesOfNewBackstageParticipants = backstageParticipants
      .filter((participant) => {
        return (
          participant.info.externalId !== this.activeUser$.value.uid &&
          !this.backstageParticipants$.value.find((p) => p.info.externalId === participant.info.externalId)
        );
      })
      .reduce((acc, participant, index) => {
        return index === 0 ? participant.info.name : `${acc}, ${participant.info.name}`;
      }, '');

    if (
      namesOfNewBackstageParticipants &&
      this.dolbyConversation$.value &&
      this.settingsService.studioShowSettings$.value?.showToastOnBackstageJoin !== false
    ) {
      this.toastrService.info(
        `${namesOfNewBackstageParticipants} has joined the backstage.`,
        `Backstage Participants`,
        {
          closeButton: true,
          tapToDismiss: true,
          progressBar: true,
          toastComponent: GeneralToastComponent,
        }
      );
    }
  }

  setupShowSettings() {
    this.settingsService.studioShowSettings$.subscribe(async (settings) => {
      if (settings.defaultConfType && this.studioSession$.value.confType === undefined) {
        this.setConfType(settings.defaultConfType);
      }
    });
  }

  async setConfType(type: ConfTypes) {
    const isVoice = type === ConfTypes.VOICE;
    if (this.conferenceParams.dolbyVoice === isVoice) return;
    this.conferenceParams.dolbyVoice = isVoice;
    // this.joinOptions.dvwc = isVoice; // DVWC always false
    this.conferenceAlias = `${this.studioSession$.value.showID}__${this.studioSession$.value.sessionID}`;
    if (isVoice) this.conferenceAlias += '__voice';
    if (VoxeetSDK.conference.current && VoxeetSDK.conference.current.params.dolbyVoice !== isVoice) {
      if (VoxeetSDK.conference.current.alias.includes('__test')) return;
      await VoxeetSDK.conference.leave();
      if (this.location$.value === Locations.STAGE) await this.joinConversation();
      else this.joinBackstage();
    }
  }

  setupSessionSettings() {
    this.studioSession$.subscribe(async (session) => {
      if (!session) return;
      if (session.confType) this.setConfType(session.confType);
    });
  }

  setupSessionParticipants() {
    this.sessionsService.studioSessionID$
      .pipe(switchMap((sessionID) => this.sessionsService.getAllParticipantsInSession(sessionID)))
      .subscribe((participants) => {
        this.sessionParticipants$.next(new Map(participants.map((p) => [p.uid, p])));
      });
  }

  setupStageParticipants() {
    this.stageParticipants$.subscribe((participants) => {
      this.updateParticipantArrays(participants);
    });
  }

  setupBackstageParticipants() {
    this.backstageParticipants$.subscribe(async (participants) => {
      await this.studioSession$.toPromise();
      if (!this.studioSession$.value) {
        this.backstageParticipantsArray$.next([]);
        return;
      }

      const fsParticipants = await firstValueFrom(
        this.sessionsService.getAllParticipantsInSession(this.studioSession$.value.sessionID)
      );

      const backstageParticipants = participants.map((dolbyParticipant) => {
        const fsParticipant = fsParticipants.find((p) => p.uid === dolbyParticipant.info.externalId);
        // So that we don't mutate this dolbyParticipant...
        const participant: ParticipantFsDolby = { ...dolbyParticipant, ...fsParticipant };

        if (this.userService.activeUser$.value.uid === dolbyParticipant.info.externalId) {
          this.localParticipant$.next(dolbyParticipant);
        }

        return participant;
      });

      this.backstageParticipantsArray$.next(backstageParticipants);
    });
  }

  setupMute() {
    this.muteService.mute$.subscribe(async (mute) => {
      if (this.location$.value === Locations.BACKSTAGE || !VoxeetSDK.conference.current) return;
      VoxeetSDK.conference.mute(VoxeetSDK.session.participant, mute);
    });
  }

  setupVideoOff() {
    this.videoOffService.videoOff$.subscribe(async (videoOff) => {
      if (!VoxeetSDK.conference.current) return;
      this.setVideo(!videoOff);
    });
  }

  async setVideo(value: boolean) {
    if (value) {
      await this.equipmentService.constraints$.nextExistingValue();
      const vidConstraints = this.equipmentService.constraints$.value.video as MediaTrackConstraints;
      this.dummyVideoStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: vidConstraints });
      return await VoxeetSDK.video.local.start({ deviceId: vidConstraints.deviceId });
    } else {
      if (this.dummyVideoStream) this.dummyVideoStream.getTracks().forEach((track) => track.stop());
      return await VoxeetSDK.video.local.stop();
    }
  }

  setupRecording() {
    this.recording$.subscribe((recording) => {
      if (!this.dolbyConversation$.value) return;
      if (recording) this.playRecordingStartAudio();
      else this.playRecordingStopAudio();
    });
  }

  getSessionToken(idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.get<DolbyTokenResponse>(`${this.distatone}/api/v5/tokens/session`, {
      headers,
    });
  }

  async refreshToken() {
    const idToken: string = await this.idTokenService.getFreshIdToken();
    const dolbyToken = await firstValueFrom(this.getSessionToken(idToken));
    return dolbyToken.access_token;
  }

  initializeToken(accessToken: string) {
    return VoxeetSDK.initializeToken(accessToken, async () => await this.refreshToken());
  }

  sessionIsOpen() {
    return VoxeetSDK.session.isOpen();
  }

  async openSession(participantInfo?: ParticipantInfo) {
    if (this.sessionIsOpen()) await VoxeetSDK.session.close();
    const session = await VoxeetSDK.session.open(participantInfo);

    this.resetDefaults();
    if (this.subbedAlias) {
      this.unsubActiveParticipants(this.subbedAlias);
      this.unsubConferenceEnded(this.subbedAlias);
    }
    this.conferenceAlias = `${this.studioSession$.value.showID}__${this.studioSession$.value.sessionID}`;
    if (this.studioSession$.value.confType === ConfTypes.VOICE) this.conferenceAlias += '__voice';
    this.subbedAlias = this.conferenceAlias;
    this.subActiveParticipants(this.subbedAlias, (data: ActiveParticipants) => {
      if (data.conferenceAlias !== this.conferenceAlias) return;
      this.activeParticipants$.next(data);

      const backstageParticipants = data.participants.filter((p) => p.type === 'listener');

      this.showBackstageParticipantsJoinedToast(backstageParticipants);
      this.backstageParticipants$.next(backstageParticipants);
    });
    this.subConferenceEnded(this.subbedAlias, () => {
      if (VoxeetSDK.conference.current === null) {
        this.resetDefaults();
      }
    });
    return session;
  }

  async createConversation(conversationConfig: ConferenceOptions) {
    return VoxeetSDK.conference.create(conversationConfig);
  }

  async fetchConversation(conversationId: string) {
    return VoxeetSDK.conference.fetch(conversationId);
  }

  subActiveParticipants(conferenceAlias: string, callback: (...args: any[]) => void) {
    const sub = [{ type: 'Conference.ActiveParticipants', conferenceAlias }] as SubscribeActiveParticipants[];
    this.activeParticipantCallback = callback;
    VoxeetSDK.notification.subscribe(sub).then(() => {
      VoxeetSDK.notification.on('activeParticipants', this.activeParticipantCallback);
    });
  }

  unsubActiveParticipants(conferenceAlias: string) {
    VoxeetSDK.notification.removeListener('activeParticipants', this.activeParticipantCallback);
    const sub = [{ type: 'Conference.ActiveParticipants', conferenceAlias }] as SubscribeActiveParticipants[];
    VoxeetSDK.notification.unsubscribe(sub);
  }

  subConferenceEnded(conferenceAlias: string, callback: (...args: any[]) => void) {
    const sub = [{ type: 'Conference.Ended', conferenceAlias }] as SubscribeConferenceEnded[];
    this.conferenceEndedCallback = callback;
    VoxeetSDK.notification.subscribe(sub).then(() => {
      VoxeetSDK.notification.on('conferenceEnded', this.conferenceEndedCallback);
    });
  }

  unsubConferenceEnded(conferenceAlias: string) {
    VoxeetSDK.notification.removeListener('conferenceEnded', this.conferenceEndedCallback);
    const sub = [{ type: 'Conference.Ended', conferenceAlias }] as SubscribeConferenceEnded[];
    VoxeetSDK.notification.unsubscribe(sub);
  }

  async joinConversation(joinOverride?: JoinOptions, confOverride?: ConferenceOptions, joinRetryCount = 0) {
    if (VoxeetSDK.conference.current) await VoxeetSDK.conference.leave();

    if (!this.sessionIsOpen()) {
      const success = await this.initSession();
      if (!success) return;
    }
    if (this.statsInterval) clearInterval(this.statsInterval);
    this.statsService.resetConnectionStats();
    if (this.activeSpeakerInterval) clearInterval(this.activeSpeakerInterval);
    this.lastSpeakers = [];

    if (VoxeetSDK.conference.current?.alias.includes('__test') && !this.activeUser$.value.guest) {
      const confSettings = await this.settingsService.userAppSettings$.toPromise();
      if (confSettings.muteOnJoin) {
        this.muteService.setMute(this.studioSession$.value.sessionID, this.userService.activeUser$.value.uid, true);
      }
    }

    this.stageParticipants$.next(new Map());

    const confOptions = {
      alias: confOverride?.alias ?? this.conferenceAlias,
      params: { ...this.conferenceParams, ...confOverride?.params },
    };
    const conversation = await this.createConversation(confOptions);

    const joinOptions = { ...this.joinOptions, ...joinOverride };

    const voiceConf = conversation.params?.dolbyVoice ?? this.conferenceParams.dolbyVoice;
    if (!voiceConf) joinOptions.audioBitrate = AudioBitrate.Bitrate128k;

    if (this.deviceDetectorService.isMobile()) joinOptions.maxVideoForwarding = 2;
    if (this.deviceDetectorService.isTablet()) joinOptions.maxVideoForwarding = 3;

    const joinTimeout = setTimeout(async () => {
      this.rollbar.error('Timed out joining session', {}, { conversation });
      this.toastrService.error(
        `This may be due to a network issue.  Check your connection, refresh the page, and try again.`,
        `Failed to connect to the Session`,
        {
          progressBar: false,
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      return;
    }, 5000);
    const info = await VoxeetSDK.conference.join(conversation, joinOptions).catch((e) => {
      if (e.name === 'OverconstrainedError') {
        throw new Error('Unsupported Device');
      }

      if (joinRetryCount > 3) throw e;
      setTimeout(() => {
        this.joinConversation(joinOverride, confOverride, joinRetryCount + 1);
      }, 1000);
    });
    clearTimeout(joinTimeout);
    if (!info || !info.params) return;
    this.defaultForwardingNumber = VoxeetSDK.conference.maxVideoForwarding;
    this.setVideoForwarding();

    if (info.alias === this.conferenceAlias) {
      this.location$.next(Locations.STAGE);
      this.dolbyConversation$.next(info);

      if (this.hideIncomingVideo$.value) {
        this.setHideIncomingVideo(this.hideIncomingVideo$.value);
      }

      if (info.params.dolbyVoice) {
        this.activeSpeakerInterval = setInterval(() => {
          this.parseActiveSpeakers();
        }, 500);
      }
    }

    const config = {
      payload: { confId: info.id, confAlias: info.alias },
    };
    this.rollbar.configure(config);

    const participantID = VoxeetSDK.session.participant.id;
    this.statsInterval = setInterval(async () => {
      const stats = await this.getStats();
      const saveStats = !info.alias.includes('__test');
      this.statsService.parseConnectionStats(stats, participantID, saveStats);
    }, 3000);

    // Browser tab crashes if video is toggled without starting audio
    const microphoneId = this.equipmentService.selectedMicrophone$.value?.deviceId;
    const constraints = { deviceId: microphoneId } as MediaTrackConstraints;
    if (!info.params.dolbyVoice) constraints.echoCancellation = this.equipmentService.echoCancellation$.value;
    await VoxeetSDK.audio.local.start(constraints);
    VoxeetSDK.conference.mute(VoxeetSDK.session.participant, this.muteService.mute$.value);

    if (!this.videoOffService.videoOff$.value) this.setVideo(true);

    if (this.equipmentService.selectedHeadphones$.value?.deviceId && !this.deviceDetectorService.isMobile()) {
      // Switching to classic can sometimes not catch non-default headphone change, this seems to fix it
      await this.updateHeadphone({ deviceId: 'default' });
      if (this.equipmentService.selectedHeadphones$.value.deviceId !== 'default') {
        setTimeout(() => {
          this.updateHeadphone(this.equipmentService.selectedHeadphones$.value);
        }, 500);
      }
    }

    this.equipmentService.setAvailableDevices(
      this.equipmentService.systemDevices$.value,
      this.sessionsService.studioSessionID$.value,
      this.activeUser$.value.uid
    );
    this.equipmentService.sessionActive = true;

    return info;
  }

  async joinBackstage() {
    const confOptions = {
      alias: this.conferenceAlias,
      params: this.conferenceParams,
    };
    const conversation = await this.createConversation(confOptions);
    const res = await VoxeetSDK.conference.listen(conversation).then(async (info) => {
      if (this.equipmentService.selectedHeadphones$.value?.deviceId) {
        await this.updateHeadphone(this.equipmentService.selectedHeadphones$.value).catch((e) => {
          if (e.name === 'DeviceChangeError')
            this.delayHeadphoneSelection = this.equipmentService.selectedHeadphones$.value;
          else throw e;
        });
      }
      const config = {
        payload: { conversationID: info.id, conversationAlias: info.alias },
      };
      this.rollbar.configure(config);
    });
    this.dolbyConversation$.next(conversation);
    this.location$.next(Locations.BACKSTAGE);
    return res;
  }

  /**
   * Sets up conference event handlers
   */
  onConversationEvents() {
    VoxeetSDK.conference.on('streamUpdated', this.handleStreamUpdated.bind(this));
    VoxeetSDK.conference.on('streamAdded', this.handleStreamAdded.bind(this));
    VoxeetSDK.conference.on('streamRemoved', this.handleStreamRemoved.bind(this));

    VoxeetSDK.conference.on('participantUpdated', this.handleParticipantUpdated.bind(this));
    VoxeetSDK.conference.on('participantAdded', this.handleParticipantAdded.bind(this));
    VoxeetSDK.conference.on('error', this.handleDolbyError.bind(this));
    VoxeetSDK.conference.on('autoplayBlocked', this.handleAutoplayBlocked.bind(this));
    VoxeetSDK.conference.on('switched', this.handleSwitched.bind(this));

    VoxeetSDK.conference.on('left', this.handleLeft.bind(this));
    VoxeetSDK.conference.on('ended', this.handleEnded.bind(this));
    VoxeetSDK.conference.on('joined', this.handleJoined.bind(this));
    VoxeetSDK.conference.on('qualityIndicators', this.handleQualityIndicators.bind(this));
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'joined'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#joined
   */
  async handleJoined() {
    // console.log('handleJoined');
    if (VoxeetSDK.conference.manager.listenOnly) return;
    const participant = VoxeetSDK.session.participant;
    const id = this.activeUser$.value.uid;
    const p = { ...participant, ...this.sessionParticipants$.value.get(id) } as LiveDolbyParticipant;
    if (this.localState.has(id)) {
      const { minimized, prioritySpeaker, objectFit } = this.localState.get(id);
      if (minimized) p.minimized = true;
      if (prioritySpeaker) p.prioritySpeaker = true;
      if (objectFit) p.objectFit = objectFit;
    }

    this.localParticipant$.next({ ...participant });
    this.stageParticipants$.next(this.stageParticipants$.value.set(id, p));
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'participantAdded'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#participantadded
   *
   * @param participant DolbyParticipant
   */
  handleParticipantAdded(participant: DolbyParticipant) {
    // console.log('handleParticipantAdded', JSON.stringify(participant));
    if (participant.status === 'Left') {
      this.disconnectedParticipants$.next(
        this.disconnectedParticipants$.value.set(participant.info.externalId, participant)
      );
      return;
    }
    if (
      participant.status !== 'Connected' &&
      participant.status !== 'Connecting' &&
      participant.status !== 'Inactive'
    ) {
      console.warn('handleParticipantAdded: Participant not connected', participant);
      return;
    }

    const id = participant.info.externalId;
    if (!id) {
      console.warn('handleParticipantAdded: Participant has no externalId', participant);
      return;
    }

    if (this.stageParticipants$.value.has(id)) {
      console.warn('handleParticipantAdded: Participant already in stage', participant);
      return;
    }

    if (participant.type === 'mixer') return;

    const p = {
      ...participant,
      ...this.sessionParticipants$.value.get(participant.info.externalId),
    } as LiveDolbyParticipant;
    if (this.localState.has(id)) {
      const { minimized, prioritySpeaker, objectFit } = this.localState.get(id);
      if (minimized) p.minimized = true;
      if (prioritySpeaker) p.prioritySpeaker = true;
      if (objectFit) p.objectFit = objectFit;
    }

    this.playJoinSessionAudio();
    this.stageParticipants$.next(this.stageParticipants$.value.set(id, p));
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'participantUpdated'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#participantupdated
   *
   * @param participant DolbyParticipant
   */
  handleParticipantUpdated(participant: DolbyParticipant) {
    // console.log('handleParticipantUpdated', JSON.stringify(participant));
    if (participant.info.externalId === this.activeUser$.value.uid) {
      this.localParticipant$.next({ ...participant });
      return;
    }
    if (participant.type === 'mixer') return;
    if (participant.status === 'Left') {
      const participants = this.stageParticipants$.value;
      participants.delete(participant.info.externalId);
      this.stageParticipants$.next(participants);
      if (
        participant.info.externalId !== this.activeUser$.value.uid &&
        !participant.info.externalId.includes('_screen')
      ) {
        this.playLeaveSessionAudio();
      }
    }
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'streamAdded'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#streamadded
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  async handleStreamAdded(participant: DolbyParticipant, stream: MediaStreamWithType) {
    if (stream.type === 'ScreenShare') {
      const p = { ...this.stageParticipants$.value.get(participant.info.externalId) };
      p.prioritySpeaker = true;
      p.objectFit = 'contain';
      p.activeStream = stream;
      const id = participant.info.externalId + '_screen';
      this.stageParticipants$.next(this.stageParticipants$.value.set(id, p));
      this.screenshare$.next(p);
    } else {
      let p = this.stageParticipants$.value.get(participant.info.externalId);
      if (!p) {
        this.handleParticipantAdded(participant);
        p = this.stageParticipants$.value.get(participant.info.externalId);
      }
      if (stream?.getVideoTracks()[0]?.readyState === 'ended') {
        this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, true);
        await this.sessionsService.participantEquipment$.nextExistingValue((eq) => (eq.videoOff = true));
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, false);
      } else {
        p.activeStream = stream;
        this.stageParticipants$.next(this.stageParticipants$.value);
      }
    }
    if (this.delayHeadphoneSelection) {
      this.updateHeadphone(this.delayHeadphoneSelection);
      this.delayHeadphoneSelection = null;
    }
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'streamUpdated'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#streamupdated
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  async handleStreamUpdated(participant: DolbyParticipant, stream: MediaStreamWithType) {
    // console.log('handleStreamUpdated', JSON.stringify(participant), JSON.stringify(stream));
    // console.log('handleStreamUpdated', participant, stream, stream.getVideoTracks(), stream.getAudioTracks());
    let p;
    if (stream.type === 'ScreenShare') {
      p = this.stageParticipants$.value.get(participant.info.externalId + '_screen');
    } else {
      p = this.stageParticipants$.value.get(participant.info.externalId);
    }
    if (stream?.getVideoTracks()[0]?.readyState === 'ended' && !this.videoOffService.videoOff$.value) {
      this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, true);
      await this.sessionsService.participantEquipment$.nextExistingValue((eq) => (eq.videoOff = true));
      await new Promise((resolve) => setTimeout(resolve, 500));
      this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, false);
    } else {
      p.activeStream = stream.getTracks().length ? stream : null;
      this.stageParticipants$.next(this.stageParticipants$.value);
    }
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'streamRemoved'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#streamremoved
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  handleStreamRemoved(participant: DolbyParticipant, stream: MediaStreamWithType) {
    // console.log('handleStreamRemoved', JSON.stringify(participant), JSON.stringify(stream));
    if (stream.type === 'ScreenShare') {
      const participants = this.stageParticipants$.value;
      participants.delete(participant.info.externalId + '_screen');
      this.stageParticipants$.next(participants);
      this.screenshare$.next(null);
    }
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'error'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#error
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  handleDolbyError(error: Error) {
    // console.log('handleDolbyError', error);
    switch (error.name) {
      case 'PeerConnectionDisconnectedError':
        if (!this.leaving$.value) this.openUnstableConnectionToast();
        break;
      case 'OverconstrainedError':
        this.openOverconstraintedErrorToast((error as OverconstrainedError).constraint);
        this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, true);
        throw error;
      default:
        throw error;
    }
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'autoplayBlocked'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#autoplayblocked
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  handleAutoplayBlocked() {
    if (this.deviceDetectorService.browser === 'Firefox' && this.deviceDetectorService.deviceType === 'desktop') {
      return;
    }
    this.autoplayBlocked$.next(true);
  }

  /**
   * Handles the Dolby VoxeetSDK Conference Event 'switched'.
   * https://docs.dolby.io/communications-apis/docs/js-client-sdk-conferenceservice#switched
   *
   * @param participant DolbyParticipant
   * @param stream MediaStreamWithType
   */
  handleSwitched() {
    this.resetDefaults();
    this.toastrService.warning(``, `You have joined the session in another tab`, {
      closeButton: true,
      tapToDismiss: true,
      disableTimeOut: true,
      toastComponent: GeneralToastComponent,
    });
    this.router.navigate(['/dashboard']);
  }

  handleLeft() {
    // console.log('handleLeft');
    this.stageParticipants$.next(new Map());
    clearInterval(this.activeSpeakerInterval);
    clearInterval(this.statsInterval);
    this.equipmentService.sessionActive = false;
  }

  handleEnded() {
    // console.log('handleEnded');
  }

  handlePermissionsUpdated() {
    // console.log('handlePermissionsUpdated');
  }

  handleQualityIndicators(qualityIndicators) {
    // console.log('handleQualityIndicators', qualityIndicators);
  }

  openUnstableConnectionToast() {
    const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
      `You may want to get closer to your Wi-Fi router or use a wired internet connection.`,
      `Your Connection is unstable`,
      {
        progressBar: false,
        closeButton: true,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: GeneralToastComponent,
      }
    );

    toast.toastRef.componentInstance.buttons = [
      {
        label: 'Hide Incoming Video',
        handler: () => {
          this.setHideIncomingVideo(true);
          this.analyticsService.track('clicked hide incoming video from unstable connection');
        },
      },
    ];

    toast.toastRef.componentInstance.learnMoreTopic = 'netConnSecurity';

    this.analyticsService.track('unstable network warning');
  }

  openOverconstraintedErrorToast(constraint: OverconstrainedError['constraint']) {
    let msg = `One of your devices is not supported, please select another device and refresh the page.`;
    if (constraint === 'width') {
      msg = `Your camera is not supported, please select another camera and refresh the page.`;
    }

    const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(msg, `Cannot connect to device`, {
      progressBar: false,
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
      toastComponent: GeneralToastComponent,
    });

    toast.toastRef.componentInstance.learnMoreTopic = 'selectEquipment';

    this.analyticsService.track('overconstrainted device conference warning');
  }

  async getStats() {
    if (!VoxeetSDK.conference.current) return;
    return VoxeetSDK.conference.localStats();
  }

  async clearVideoProcessing() {
    await VoxeetSDK.video.local.disableProcessing();
    this.videoProcessor$.next({ type: VideoProcessorType.None });
    return false;
  }

  async setVideoProcessing(type: VideoProcessorType, image?: HTMLImageElement) {
    let result = true;
    const videoProcessor: VideoProcessor = { type };
    if (type === VideoProcessorType.BackgroundReplacement && image) {
      videoProcessor.image = image;
    }
    await VoxeetSDK.video.local.setProcessor(videoProcessor).catch((e) => {
      if (e.name === 'UnsupportedError') {
        result = false;
        this.toastrService.error('Background effects are not supported by your browser.', 'Browser Not Supported', {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      } else throw e;
    });
    if (result) this.videoProcessor$.next(videoProcessor);
    else this.videoProcessor$.next(this.videoProcessor$.value);
    return result;
  }

  setupEquipment() {
    this.equipmentService.selectedCamera$.subscribe((camera) => {
      if (!camera || this.location$.value === Locations.BACKSTAGE) return;
      if (VoxeetSDK.session.participant?.status === 'Connected') {
        if (!this.dolbyConversation$.value) return;
        this.updateCamera(camera)
          .then(() => {
            this.toastrService.success(this.filterDeviceName(camera.label), `Successfully connected to Camera`, {
              progressBar: true,
              progressAnimation: 'decreasing',
              closeButton: true,
              tapToDismiss: false,
              timeOut: 5 * 1000,
              toastComponent: GeneralToastComponent,
            });
          })
          .catch((error: Error) => {
            if (error.message.includes(`Cannot read properties of null (reading 'stop')`)) return;
            let message = `Failed to connect to Camera`;
            if (this.studioSession$.value.recording) {
              message = message + '.  Your recording will need to be restarted.';
            }
            this.toastrService.error(this.filterDeviceName(camera.label), message, {
              progressBar: true,
              progressAnimation: 'decreasing',
              closeButton: true,
              tapToDismiss: false,
              timeOut: 5 * 1000,
              toastComponent: GeneralToastComponent,
            });
            throw error;
          });
      }
    });

    this.equipmentService.selectedMicrophone$.subscribe(async (microphone) => {
      if (!microphone || this.location$.value === Locations.BACKSTAGE) return;
      if (VoxeetSDK.session.participant?.status === 'Connected') {
        if (!this.dolbyConversation$.value) return;
        this.updateMicrophone(microphone)
          .then(() => {
            if (!this.conferenceParams.dolbyVoice) {
              VoxeetSDK.audio.local.applyConstraints({
                echoCancellation: this.equipmentService.echoCancellation$.value,
              });
            }
            this.toastrService.success(
              this.filterDeviceName(microphone.label),
              `Successfully connected to Microphone`,
              {
                progressBar: true,
                progressAnimation: 'decreasing',
                closeButton: true,
                tapToDismiss: false,
                timeOut: 5 * 1000,
                toastComponent: GeneralToastComponent,
              }
            );
          })
          .catch((error: Error) => {
            let message = `Failed to connect to Microphone`;
            if (this.studioSession$.value.recording) {
              message = message + '.  Your recording will need to be restarted.';
            }
            this.toastrService.error(this.filterDeviceName(microphone.label), message, {
              progressBar: true,
              progressAnimation: 'decreasing',
              closeButton: true,
              tapToDismiss: false,
              timeOut: 5 * 1000,
              toastComponent: GeneralToastComponent,
            });
            throw error;
          });
      }
    });

    this.equipmentService.selectedHeadphones$.subscribe((headphones) => {
      if (VoxeetSDK.session.participant?.status === 'Connected') {
        if (!headphones || !this.dolbyConversation$.value) return;
        this.updateHeadphone(headphones)
          .then(() => {
            this.toastrService.success(
              this.filterDeviceName(headphones.label),
              `Successfully connected to Headphones`,
              {
                progressBar: true,
                progressAnimation: 'decreasing',
                closeButton: true,
                tapToDismiss: false,
                timeOut: 5 * 1000,
                toastComponent: GeneralToastComponent,
              }
            );
          })
          .catch((error: Error) => {
            if (this.location$.value === Locations.BACKSTAGE && error.name === 'DeviceChangeError') {
              this.delayHeadphoneSelection = headphones;
            } else {
              this.toastrService.error(this.filterDeviceName(headphones.label), `Failed to connect to Headphones`, {
                progressBar: true,
                progressAnimation: 'decreasing',
                closeButton: true,
                tapToDismiss: false,
                timeOut: 5 * 1000,
                toastComponent: GeneralToastComponent,
              });
              throw error;
            }
          });
      }
    });

    this.equipmentService.echoCancellation$.subscribe((echoCancellation) => {
      if (this.dolbyConversation$.value && !this.dolbyConversation$.value.params.dolbyVoice) {
        VoxeetSDK.audio.local.applyConstraints({ echoCancellation });
      }
    });

    this.sessionsService.participantEquipment$.subscribe(async (equipment) => {
      if (
        !VoxeetSDK.conference.current ||
        !equipment?.videoIn ||
        !equipment?.videoResolution ||
        this.deviceInfo.browser === 'Safari'
      )
        return;
      if (equipment.videoIn.deviceId === this.lastVideoDevice && equipment.videoResolution === this.lastResolution)
        return;
      this.lastVideoDevice = equipment.videoIn.deviceId;
      this.lastResolution = equipment.videoResolution;
      await this.setVideo(false);
      await this.setVideo(true);
    });
  }

  updateMicrophone(microphone: MediaDeviceInfo) {
    return VoxeetSDK.mediaDevice.selectAudioInput(microphone.deviceId);
  }

  updateHeadphone(headphone: Partial<MediaDeviceInfo>) {
    if (this.deviceInfo.browser === 'Firefox') return;
    return VoxeetSDK.mediaDevice.selectAudioOutput(headphone.deviceId);
  }

  updateCamera(camera: MediaDeviceInfo) {
    return VoxeetSDK.mediaDevice.selectVideoInput(camera.deviceId);
  }

  /**
   * Sets the AudioCaptureMode for the local participant
   *
   * @param level Amount of noise reduction, 0: off, 1: low, 2: high
   * @returns
   */
  async setLocalNoiseReduction(level: 0 | 1 | 2) {
    if (!this.conferenceParams.dolbyVoice) return;
    const options: AudioCaptureModeOptions = {
      mode: level ? AudioCaptureMode.Standard : AudioCaptureMode.Unprocessed,
    };
    if (level) {
      options.modeOptions = { noiseReductionLevel: level === 1 ? NoiseReductionLevel.Low : NoiseReductionLevel.High };
    }
    await VoxeetSDK.audio.local.setCaptureMode(options);
    this.equipmentService.setNoiseReduction(!!level);
  }

  /**
   * Sets the Video Forwarding limit to 0 to hide all incoming video
   *
   * @param hideIncomingVideo True to set number of forwarded streams to zero
   * @returns
   */
  async setHideIncomingVideo(hideIncomingVideo: boolean) {
    this.hideIncomingVideo$.next(hideIncomingVideo);
    await this.setVideoForwarding(hideIncomingVideo ? 0 : this.defaultForwardingNumber);
  }

  async setVideoForwarding(max = this.defaultForwardingNumber, participants = [] as DolbyParticipant[]) {
    if (!VoxeetSDK.conference.current) return;
    if (this.hideIncomingVideo$.value && VoxeetSDK.conference.maxVideoForwarding === 0) return;

    const options: VideoForwardingOptions = { max };

    let pinned = this.pinnedParticipantsArray$.value;
    participants.forEach((participant) => {
      if (!pinned.find((p) => p.id === participant.id)) pinned.push(participant);
    });
    if (pinned.length) options.participants = pinned.slice(0, max);

    return VoxeetSDK.conference.videoForwarding(options);
  }

  watchActiveSpeakers() {
    this.activeSpeakers$.subscribe((speakers) => {
      if (!VoxeetSDK.conference.current) return;
      if (this.hideIncomingVideo$.value) return;

      if (VoxeetSDK.conference.participants.size > this.defaultForwardingNumber + 1) {
        speakers.forEach((s) => {
          if (this.lastSpeakers.includes(s)) this.lastSpeakers.splice(this.lastSpeakers.indexOf(s), 1);
          if (s !== VoxeetSDK.session.participant.id) this.lastSpeakers.unshift(s);
        });
        const participants = [];
        this.lastSpeakers.forEach((s) => {
          participants.push(VoxeetSDK.conference.participants.get(s));
        });
        this.setVideoForwarding(this.defaultForwardingNumber, participants);
      } else {
        this.lastSpeakers = [];
      }
    });
  }

  async parseActiveSpeakers() {
    if (!VoxeetSDK.conference.current) {
      clearInterval(this.activeSpeakerInterval);
      return;
    }
    if (VoxeetSDK.conference.current.params.dolbyVoice) {
      const participants = VoxeetSDK.conference.participants;
      const speakers = [];
      for (const participant of participants) {
        try {
          VoxeetSDK.conference.isSpeaking(participant[1], (isSpeaking) => {
            if (isSpeaking) {
              speakers.push(participant[0]);
            }
          });
        } catch (e) {}
      }
      this.activeSpeakers$.next(speakers);
    }
  }

  /**
   * Checks if there is room available on stage and backstage and returns user count.
   *
   * Only call this function after the conference is created, or it will wait for the conference to be created. Can be called before joining the conference.
   */
  async checkSeatCountAvailability(): Promise<{
    stageSeatAvailable: boolean;
    stageParticipantCount: number;
    backstageSeatAvailable: boolean;
    backstageParticipantCount: number;
  }> {
    const returnValue = {
      stageSeatAvailable: false,
      stageParticipantCount: 0,
      backstageSeatAvailable: false,
      backstageParticipantCount: 0,
    };

    const confOptions = {
      alias: this.conferenceAlias,
      params: this.conferenceParams,
    };
    const conversation = await this.createConversation(confOptions);
    const unlimited = this.studioSession$.value?.unlimitedParticipants;

    if (unlimited || conversation.isNew) {
      returnValue.stageSeatAvailable = true;
      returnValue.backstageSeatAvailable = true;
      return returnValue;
    }

    const activeParticipants = await this.activeParticipants$.nextExistingValue();

    returnValue.stageParticipantCount = activeParticipants?.participants.filter(
      (participant) => participant.type === 'user'
    ).length;
    returnValue.backstageParticipantCount = activeParticipants?.participants.filter(
      (participant) => participant.type === 'listener'
    ).length;

    await this.walletService.studioPlan$.toPromise();
    if (returnValue.stageParticipantCount < this.walletService.studioPlan$.value.inStudio) {
      returnValue.stageSeatAvailable = true;
    }
    if (returnValue.backstageParticipantCount < this.walletService.studioPlan$.value.backstage) {
      returnValue.backstageSeatAvailable = true;
    }
    return returnValue;
  }

  /**
   * Updates the array of participants while preventing duplicates
   */
  updateParticipantArrays(participants: Map<string, LiveDolbyParticipant>) {
    const prioritySpeakersArray = [];
    const nonPrioritySpeakersArray = [];
    const priorityObjectFit = [];
    const nonPriorityObjectFit = [];
    const pinnedParticipantsArray = [];

    participants.forEach((participant) => {
      if (!participant.minimized) {
        if (participant.prioritySpeaker) {
          prioritySpeakersArray.push(participant);
          priorityObjectFit.push(participant.objectFit);
          if (!participant.info.externalId.includes('_screen')) pinnedParticipantsArray.push(participant);
        } else {
          nonPrioritySpeakersArray.push(participant);
          nonPriorityObjectFit.push(participant.objectFit);
        }
      }
    });

    this.prioritySpeakersArray$.next(prioritySpeakersArray);
    this.nonPrioritySpeakersArray$.next(nonPrioritySpeakersArray);
    this.prioritySpeakersArrayObjectFit$.next(priorityObjectFit);
    this.nonPrioritySpeakersArrayObjectFit$.next(nonPriorityObjectFit);
    this.pinnedParticipantsArray$.next(pinnedParticipantsArray);
  }

  minimizeLocalParticipant(minimize) {
    const participants = new Map(this.stageParticipants$.value);
    const localID = this.localParticipant$.value.info.externalId;
    const participant = { ...participants.get(localID) };
    participant.minimized = minimize;
    participants.set(localID, participant);

    this.stageParticipants$.next(participants);
    this.localParticipant$.next(participant);
    if (!this.localState.get(localID)) {
      this.localState.set(localID, { minimized: minimize });
    } else this.localState.get(localID).minimized = minimize;
  }

  setPrioritySpeaker(id, priority) {
    const participants = new Map(this.stageParticipants$.value);
    const participant = { ...participants.get(id) };
    participant.prioritySpeaker = priority;
    participants.set(id, participant);

    this.stageParticipants$.next(participants);
    this.setVideoForwarding();
    if (!this.localState.get(id)) {
      this.localState.set(id, { prioritySpeaker: priority });
    } else this.localState.get(id).prioritySpeaker = priority;
  }

  setObjectFitCover(id, objectFit) {
    const participants = new Map(this.stageParticipants$.value);
    const participant = { ...participants.get(id) };
    participant.objectFit = objectFit;
    participants.set(id, participant);
    this.stageParticipants$.next(participants);
    if (!this.localState.get(id)) {
      this.localState.set(id, { objectFit });
    } else this.localState.get(id).objectFit = objectFit;
  }

  async moveToBackstage() {
    clearInterval(this.statsInterval);
    await VoxeetSDK.conference.leave();
    await this.joinBackstage();
    this.window.history.pushState('', '', this.router.url.replace(/\/guest/, '/location/backstage'));
  }

  /**
   * Prompts the participant to move between
   * on stage and backstage
   */
  async promptParticipantToMove(participant: ParticipantFsDolby, destination: 'stage' | 'backstage') {
    if (destination === Locations.BACKSTAGE) {
      await this.popoverController.dismiss();

      const prompt: Prompt = {
        title: `Silent`,
        message: `Silent`,
        creator: this.activeUser$.value.uid,
        presentAs: `silent`,
        type: PromptTypes.MOVEPARTICIPANTBACKSTAGE,
        targetParticipants: [participant.uid],
      };

      await this.sessionsService.createSessionPrompt(prompt);
      await this.analyticsService.track('moved participant', {
        destination,
        targetParticipantUID: participant.uid,
      });
    }

    if (destination === Locations.STAGE) {
      const areYouSureToast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
        `Are you sure you want to invite ${participant.name} On Stage?`,
        `Confirm Invitation`,
        {
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
        }
      );

      areYouSureToast.toastRef.componentInstance.buttons = [
        {
          label: 'Confirm',
          handler: async () => {
            const prompt: Prompt = {
              title: `${this.activeUser$.value.displayName} is Inviting You On Stage`,
              message: `Would you like to go On Stage?`,
              creator: this.activeUser$.value.uid,
              presentAs: `modal`,
              type: PromptTypes.MOVEPARTICIPANTONSTAGE,
              targetParticipants: [participant.uid],
            };

            await this.sessionsService.createSessionPrompt(prompt);
            areYouSureToast.toastRef.close();
            await this.analyticsService.track('prompted participant to move', {
              destination,
              targetParticipantUID: participant.uid,
            });
          },
        },
        {
          label: 'Cancel',
          handler: async () => {
            areYouSureToast.toastRef.close();
            await this.analyticsService.track('cancelled moving participant', {
              targetParticipantUID: participant.uid,
            });
          },
        },
      ];
    }
  }

  /**
   * Plays the zap sound when a new participant joins the recording session
   */
  playJoinSessionAudio() {
    if (this.settingsService.studioShowSettings$.value.muteConfAudioNotifications) return;
    if (this.deviceInfo.browser !== 'Safari' && this.deviceInfo.device !== 'iPhone' && !this.iPadService.check()) {
      const zap = document.querySelector('#zap') as HTMLAudioElement;
      zap.playbackRate = 1;
      zap?.play();
    }
  }

  playLeaveSessionAudio() {
    if (this.settingsService.studioShowSettings$.value.muteConfAudioNotifications) return;
    if (this.deviceInfo.browser !== 'Safari' && this.deviceInfo.device !== 'iPhone' && !this.iPadService.check()) {
      const zap = document.querySelector('#zap') as HTMLAudioElement;
      zap.playbackRate = 2;
      zap?.play();
    }
  }

  playRecordingStartAudio() {
    const startTone = this.settingsService.studioShowSettings$.value.recordingTone;
    if (startTone) {
      if (startTone === 3) {
        const alert = document.getElementById('aria-alert');
        alert.setAttribute('aria-label', 'Recording Started');
      } else if (
        this.deviceInfo.browser !== 'Safari' &&
        this.deviceInfo.device !== 'iPhone' &&
        !this.iPadService.check()
      ) {
        const start = new Audio(`assets/audio/record-start${startTone}.mp3`);
        start.play();
      }
    }
  }

  playRecordingStopAudio() {
    const stopTone = this.settingsService.studioShowSettings$.value.recordingTone;
    if (stopTone) {
      if (stopTone === 3) {
        const alert = document.getElementById('aria-alert');
        alert.setAttribute('aria-label', 'Recording Stopped');
      } else if (
        this.deviceInfo.browser !== 'Safari' &&
        this.deviceInfo.device !== 'iPhone' &&
        !this.iPadService.check()
      ) {
        const stop = new Audio(`assets/audio/record-stop${stopTone}.mp3`);
        stop.play();
      }
    }
  }

  startRecording() {
    return VoxeetSDK.recording.start();
  }

  stopRecording() {
    return VoxeetSDK.recording.stop();
  }

  startScreenShare() {
    return VoxeetSDK.conference.startScreenShare({ audio: false });
  }

  stopScreenShare() {
    return VoxeetSDK.conference.stopScreenShare();
  }

  kickFromConversation(participant: ParticipantFsDolby) {
    const dolbyParticipant: DolbyParticipant = this.activeParticipants$.value.participants.find(
      (p) => p.info.externalId === participant.uid
    );
    return VoxeetSDK.conference.kick(dolbyParticipant);
  }

  async leaveConversation(getFeedback = true, setLeaving = true) {
    if (this.statsInterval) clearInterval(this.statsInterval);
    if (this.activeSpeakerInterval) clearInterval(this.activeSpeakerInterval);
    if (this.leaving$.value) return;
    const session = this.studioSession$.value || this.sessionsService.lastSession;
    if (setLeaving) this.leaving$.next(true);
    this.resetDefaults();

    VoxeetSDK.conference.participants.forEach((participant) => {
      participant.streams.forEach((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });
    });
    if (VoxeetSDK.conference.current) await VoxeetSDK.conference.leave();
    if (VoxeetSDK.session.isOpen()) await VoxeetSDK.session.close();

    this.analyticsService.track('left session');

    if (getFeedback) {
      if (!this.userService.activeUser$.value.guest) {
        if (this.settingsService.userAppSettings$.value.askFeedback)
          this.toastrService.success(``, `Please rate the quality of your session`, {
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
            toastComponent: LeaveFeedbackToastComponent,
          });
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/auth'], {
          fragment: 'feedback',
        });
      }
    }

    const event: WebhookEvent = {
      name: WebhookEventNames.PARTICIPANT_LEFT,
      date: session.date,
      sessionTitle: session.sessionTitle,
      sessionID: session.sessionID,
      orgID: session.orgID,
      showID: session.showID,
      showName: session.showTitle,
      location: this.router.url.includes('backstage') ? 'backstage' : 'stage',
    };
    await this.califoneService.emitWebhookEvent(event, session.orgID);

    if (setLeaving) {
      setTimeout(() => {
        this.leaving$.next(false);
      }, 500);
    }
  }

  /**
   * Gets all of the participants active in the conference
   *
   * @returns Observable<ParticipantFsDolby[]>
   */
  getStageAndBackstageParticipants() {
    const allParticipants = [
      this.prioritySpeakersArray$,
      this.nonPrioritySpeakersArray$,
      this.backstageParticipantsArray$,
    ];
    return combineLatest(allParticipants).pipe(
      debounce(() => interval(500)),
      map((res) =>
        ([] as ParticipantFsDolby[])
          .concat(...res)
          .filter((participant) => participant.streams[0]?.type !== 'ScreenShare')
          .sort()
      )
    );
  }
  /**
   * Gets all of stage participants active in the conference
   *
   * @returns Observable<ParticipantFsDolby[]>
   */
  getStageParticipants() {
    const allParticipants = [this.prioritySpeakersArray$, this.nonPrioritySpeakersArray$];
    return combineLatest(allParticipants).pipe(
      debounce(() => interval(500)),
      map((res) =>
        ([] as ParticipantFsDolby[])
          .concat(...res)
          .filter((participant) => participant.streams[0]?.type !== 'ScreenShare')
          .sort()
      )
    );
  }

  /**
   * @param conferenceID provide conferenceID to get all recordings from a conference
   * @param conferenceAlias provide conferenceAlias to get all recordings from a session
   * @returns
   */
  fetchCloudRecordings(idToken, conferenceID = '', conferenceAlias = '') {
    if (conferenceID === '' && conferenceAlias === '') {
      console.error('No conference ID or alias provided');
      return;
    }
    const headers = new HttpHeaders().set('idToken', idToken);

    return this.http.post(
      `${environment.microservices.distatone}/api/v5/recordings`,
      {
        conferenceID,
        conferenceAlias,
      },
      { headers }
    );
  }

  masterAudio(payload: {
    idToken: string;
    showID: string;
    sessionID: string;
    recordingID: string;
    wav?: string;
    mp4?: string;
    fileName: string;
    take: number;
    migrated: boolean;
    audioSettings?: string; // EnhanceSettings
  }) {
    return this.http.post(`${this.distatone}/api/v5/enhance`, payload);
  }

  resetDefaults() {
    if (this.dummyVideoStream) this.dummyVideoStream.getTracks().forEach((track) => track.stop());
    this.equipmentService.sessionActive = false;
    this.dolbyConversation$.next(null);
    this.screenshare$.next(null);
    this.autoplayBlocked$.next(false);
    this.localParticipant$.next(null);
    this.stageParticipants$.next(new Map());
    this.nonPrioritySpeakersArray$.next([]);
    this.nonPrioritySpeakersArrayObjectFit$.next([]);
    this.prioritySpeakersArray$.next([]);
    this.prioritySpeakersArrayObjectFit$.next([]);
    this.activeParticipants$.next(null);
    this.backstageParticipants$.next([]);
    this.backstageParticipantsArray$.next([]);
  }
}
