import { Component, Input } from '@angular/core';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { SupportArticles, SupportTopics } from '@sc/types';

@Component({
  selector: 'sc-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
})
export class LearnMoreComponent {
  @Input() topic: SupportTopics;

  articles = SupportArticles;
  constructor(private analyticsService: AnalyticsService, private supportCenterService: SupportCenterService) {}

  async open(e) {
    e.stopPropagation();
    this.supportCenterService.topic(this.topic);
    await this.analyticsService.track('opened learn more article', { topic: this.topic });
  }
}
