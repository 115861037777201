import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { switchMap } from 'rxjs';

import { SCSubject } from '../../util/sc-subject.class';
import { OrganizationsService } from '../organizations/organizations.service';
import { SessionsService } from '../sessions/sessions.service';
import { RoleDisplayNames, Roles } from '@sc/types';
import { ShowsService } from '../shows/shows.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  role$ = new SCSubject<number>();
  inStudio = false;

  participantRole: Roles;
  guestRole: Roles;
  orgRole: Roles;
  dashboardShowRole: Roles;
  availableRoles: number[];

  constructor(
    private organizationsService: OrganizationsService,
    private router: Router,
    private sessionsService: SessionsService,
    private userService: UserService,
    private showsService: ShowsService
  ) {
    this.setupRoute();
    this.setupRoles();
    this.availableRoles = Object.keys(RoleDisplayNames)
      .map((key) => Number(key))
      .sort()
      .reverse();
  }

  setupRoute() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) return;

      this.guestRole = event.url.includes('/location/backstage') ? Roles.VIEWER : Roles.GUEST;
      this.inStudio = event.url.includes('studio');

      this.setRole();
    });
  }

  async setupRoles() {
    await this.userService.activeUser$.toPromise();

    this.sessionsService.participant$.subscribe((participant) => {
      this.participantRole = participant?.role;
      this.setRole();
    });

    this.organizationsService.orgRole$.subscribe((orgRole) => {
      this.orgRole = orgRole;
      this.setRole();
    });

    this.showsService.dashboardShowID$
      .pipe(
        switchMap((showID) => {
          return this.showsService.getShowMember(showID);
        })
      )
      .subscribe((member) => {
        this.dashboardShowRole = member?.role;
        this.setRole();
      });
  }

  display(role: number) {
    if (!role) return RoleDisplayNames[Roles.GUEST];
    if (!RoleDisplayNames[role]) role = this.availableRoles.find((availableRole) => role >= availableRole);
    return RoleDisplayNames[role];
  }

  private setRole() {
    if (this.inStudio) {
      this.role$.next(this.participantRole ?? this.guestRole);
      return;
    }

    if (this.dashboardShowRole && this.orgRole < Roles.ORG_ADMIN) {
      this.role$.next(this.dashboardShowRole);
      return;
    }

    this.role$.next(this.orgRole);
  }
}
