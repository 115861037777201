import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, switchMap } from 'rxjs';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { UserService } from '../../services/user/user.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { CropPhotoPage } from '../../modals/crop-photo/crop-photo.page';
import { UserModel } from '@sc/types';
import { Organization } from '@sc/types';
import { Roles } from '@sc/types';
import { ServerResponse } from '@sc/types';
import { AmplionService } from '../../services/amplion/amplion.service';
import { stripHTML } from '../../util/stripHTML';

@Component({
  selector: 'sc-add-organization',
  templateUrl: './add-organization.page.html',
  styleUrls: ['./add-organization.page.scss'],
})
export class AddOrganizationPage implements OnInit, OnDestroy {
  user: UserModel;
  newOrg: Organization;
  orgName: string;
  imageSrc: string;
  croppedImageBase64: string;
  imageExists = false;
  subs: Array<Subscription> = [];

  editing = false;
  editingOrg: Organization;
  orgID: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplionService: AmplionService,
    private analyticsService: AnalyticsService,
    private lafayetteService: LafayetteService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.editing = this.router.url.includes('edit-organization');

    this.user = await this.userService.activeUser$.toPromise();

    if (this.editing) this.setupEditOrg();
  }

  setupEditOrg() {
    const sub = this.activatedRoute.params
      .pipe(
        switchMap((params) => {
          if (params.id) this.orgID = params.id;
          return this.organizationsService.dashboardOrg$;
        })
      )
      .subscribe((org) => {
        if (this.orgID === org.orgID) {
          this.editingOrg = org;
          this.orgName = org.orgName;
          this.imageSrc = org.orgLogo;
          this.imageExists = true;
        }
      });

    this.subs.push(sub);
  }

  /**
   * Creates an Organization by calling the Lafayette Service
   */
  async createOrg(): Promise<void> {
    this.orgName = stripHTML(this.orgName);
    if (this.editing && this.editingOrg) {
      this.editOrg();
      return;
    }

    const name = this.orgName;

    this.newOrg = {
      orgName: this.orgName,
      billingEmail: this.user.email,
      orgOwner: this.user.uid,
      orgMembers: [{ uid: this.user.uid, role: Roles.ORG_OWNER }],
    };

    await this.lafayetteService
      .createOrg(this.newOrg)
      .then(async (resp: ServerResponse) => {
        this.newOrg.orgID = resp.ID;
        if (this.croppedImageBase64) {
          await this.organizationsService.uploadOrgImage(this.newOrg.orgID, this.croppedImageBase64);
        }
      })
      .then(async () => {
        this.toastrService.success('Successfully created your new organization!', `${name} created!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        await this.analyticsService.track('created organization', {
          orgName: this.orgName,
          billingEmail: this.user.email,
          orgOwner: this.user.uid,
          orgID: this.newOrg.orgID,
          success: true,
        });

        await this.router.navigate(['/dashboard']);
        this.organizationsService.setDashboardOrgID(this.newOrg.orgID);
        await this.userService.saveActiveOrg(this.newOrg.orgID);
      })
      .catch(async (err) => {
        this.toastrService.error('Opps! Unable to create organization', `Organization Failure!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        await this.analyticsService.track('created organization', {
          orgName: this.orgName,
          billingEmail: this.user.email,
          orgOwner: this.user.uid,
          orgID: this.newOrg.orgID,
          success: false,
        });
        throw err;
      });
  }

  /**
   * Edits an Organization by calling the Lafayette Service
   */
  async editOrg(): Promise<void> {
    const name = this.orgName;

    this.editingOrg.orgName = this.orgName;

    await this.lafayetteService
      .updateOrg(this.editingOrg)
      .then(async (resp: ServerResponse) => {
        if (this.croppedImageBase64) {
          const img = await this.organizationsService.uploadOrgImage(this.editingOrg.orgID, this.croppedImageBase64);
          if (this.editingOrg.providerIDs) {
            this.organizationsService.updateProviderImages(this.editingOrg.providerIDs, img);
          }
        }
        if (this.editingOrg.customerID) {
          await this.amplionService.updateCustomer({ description: this.orgName }, this.editingOrg.customerID);
        }
      })
      .then(() => {
        this.toastrService.success('Successfully edited your organization!', `${name} edited!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        this.analyticsService.track('edited organization', { success: true });

        this.router.navigate(['/dashboard']);
      })
      .catch((err) => {
        this.toastrService.error('Opps! Unable to edit organization', `Organization Failure!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });
        this.analyticsService.track('edited organization', { success: false });
        throw err;
      });
  }

  /**
   * Event triggered when user selects an image file
   */
  async photoInputChange(event: Event) {
    if (!(event.target instanceof HTMLInputElement) || event.target.files.length === 0) return;
    await this.openCropPhotoModal(event);
    event.target.value = ''; // If the user closes the modal and selects the same file, this will fix modal not re-appearing.
  }

  /**
   * Creates the modal for cropping the image
   */
  async openCropPhotoModal(event: any) {
    const cropOptions = {
      roundCropper: false,
    };
    const modal = await this.modalController.create({
      component: CropPhotoPage,
      componentProps: { user: this.user, event, options: cropOptions },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'crop-photo-modal',
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data?.base64) {
        this.imageSrc = data.base64;
        this.croppedImageBase64 = data.base64;
        this.imageExists = true;
      }
    });

    await modal.present();
  }

  cancel() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
