import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { AmplionService } from '../../services/amplion/amplion.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'sc-add-recording-time',
  templateUrl: 'add-recording-time.page.html',
  styleUrls: ['add-recording-time.page.scss'],
})
export class AddRecordingTimePage implements OnInit {
  plan$ = this.walletService.dashboardPlan$;
  wallet$ = this.walletService.wallet$;
  max: number;
  hours = 2;
  belowMin = false;
  aboveMax = false;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private amplionService: AmplionService,
    private walletService: WalletService
  ) {}

  async ngOnInit() {
    await this.plan$.toPromise();
    await this.wallet$.nextExistingValue();

    this.max =
      this.plan$.value.recordingHours !== 'Unlimited'
        ? this.plan$.value.recordingHours * 2 -
          this.walletService.convertTimeCodeToSeconds(this.wallet$.value.available) / 60 / 60
        : null;
  }

  handleRangeValidation() {
    if (!this.hours) {
      this.aboveMax = true;
      this.belowMin = true;
    }

    if (this.hours >= 1) {
      this.belowMin = false;
    } else {
      this.belowMin = true;
    }

    if (this.hours <= this.max) {
      this.aboveMax = false;
    } else {
      this.aboveMax = true;
    }
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  async purchaseRecordingTime() {
    const amount = this.hours * 500;
    const descriptionAmount = `$${this.hours * 5}.00`;

    try {
      const response: any = await this.amplionService.chargeCustomer({
        amount,
        customerID: this.walletService.customerID$.value,
        description: `One-time purchase of ${this.hours} recording hour for ${descriptionAmount}`,
        metadata: { hours: this.hours },
      });

      if (response.charge.paid) {
        this.toastrService.success('Thank You!', `Successfully added Recording Time`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        this.analyticsService.track('purchased recording time', {
          success: true,
          hours: this.hours,
          amount,
          descriptionAmount,
        });
      } else {
        this.handleFailedCharge();

        this.analyticsService.track('purchased recording time', {
          success: false,
          hours: this.hours,
          amount,
          descriptionAmount,
        });
      }

      await this.modalController.dismiss();
    } catch (error) {
      this.handleFailedCharge();

      this.analyticsService.track('purchased recording time', {
        success: false,
        hours: this.hours,
        amount,
        descriptionAmount,
        error: error.toString(),
      });
    }
  }

  handleFailedCharge() {
    const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
      `You may want to update your Payment Info`,
      `Failed to add Recording Time`,
      {
        progressBar: false,
        closeButton: true,
        tapToDismiss: false,
        timeOut: null,
        toastComponent: GeneralToastComponent,
      }
    );

    toast.toastRef.componentInstance.buttons = [
      {
        label: 'Update Payment Info',
        handler: this.navigateToPaymentInfo.bind(this),
      },
    ];
  }

  async navigateToPaymentInfo() {
    await this.router.navigate(['account', 'billing']);
    this.toastrService.clear();
  }
}
