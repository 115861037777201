import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { PromptsService } from '../../services/prompts/prompts.service';
import { SessionsService } from '../../services/sessions/sessions.service';
import { WindowToken } from '../../services/window/window';

import { Prompt, Recording } from '@sc/types';
import { SupportTopics } from '@sc/types';

@Component({
  selector: 'sc-general-toast',
  templateUrl: './general-toast.component.html',
  styleUrls: ['./general-toast.component.scss'],
  preserveWhitespaces: false,
})
export class GeneralToastComponent extends Toast implements OnInit, OnDestroy {
  @Input() buttons: Array<{
    label: string;
    handler;
    class?: string;
    icon?: string;
  }> = [];

  @Input() prompt: Prompt;

  @Input() learnMoreTopic: SupportTopics;
  @Input() link: string;

  progressValue = 0;
  progressInterval: NodeJS.Timeout = null;
  learnMore: { label: string; handler };
  recordings: Recording[] = [];

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private promptService: PromptsService,
    private sessionsService: SessionsService,
    private analyticsService: AnalyticsService,
    @Inject(WindowToken) private window: Window
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    // SETUP PROMPTS IF EXIST
    if (this.prompt) {
      this.message = this.prompt.message;
      this.title = this.prompt.title;
      this.buttons = this.promptService.getPromptActions(this.prompt.type);
    }

    if (this.link) {
      this.buttons.push({
        label: 'Learn More',
        handler: () => {
          this.window.open(this.link, '_blank');
        },
      });
    }

    // SETUP BUTTONS
    this.buttons?.map((button, index) => {
      if (button.label === 'Learn More' && !this.learnMore && !this.options.enableHtml) {
        this.learnMore = button;

        this.buttons.splice(index, 1);
      }
    });

    if (this.toastPackage.config.payload?.recordings) {
      this.recordings = this.toastPackage.config.payload.recordings;
    }

    this.progressInterval = setInterval(() => {
      this.progressValue = this.width * 0.01;
    }, 50);
  }

  clickAction(button: { label: string; handler }) {
    button.handler(this.prompt);
    this.remove();

    this.analyticsService.track('clicked button toast', {
      clickedButtonLabel: button.label,
      label: this.title,
      message: this.message,
      buttons: this.buttons,
      promptType: this.prompt?.type,
      promptCreator: this.prompt?.creator,
      topic: this.learnMoreTopic,
      url: this.link,
    });
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();

    this.analyticsService.track('toast event', {
      event,
      label: this.title,
      message: this.message,
      buttons: this.buttons,
      promptType: this.prompt?.type,
      promptCreator: this.prompt?.creator,
    });

    return false;
  }

  async close() {
    if (this.prompt) await this.sessionsService.deletePrompt(this.prompt);

    this.remove();

    this.analyticsService.track('closed toast', {
      label: this.title,
      message: this.message,
      buttons: this.buttons,
      promptType: this.prompt?.type,
      promptCreator: this.prompt?.creator,
    });
  }

  ngOnDestroy() {
    clearInterval(this.progressInterval);

    if (this.prompt) this.sessionsService.deletePrompt(this.prompt);
  }
}
