import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Sale, SaleDetail } from '@sc/types';

@Component({
  selector: 'sc-sales-info',
  templateUrl: 'sales-info.page.html',
  styleUrls: ['sales-info.page.scss'],
})
export class SalesInfoPage implements OnInit {
  @Input() sale: Sale;

  constructor(private modalController: ModalController, private analyticsService: AnalyticsService) {}

  ngOnInit() {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed sales info', { sale: this.sale.saleName });
  }

  /*
   * Prevents flickering/flashing in the list
   *
   * @param index - number
   * @param saleDetail - SaleDetail
   * @returns - number
   */
  trackSaleDetailsBy(index: number, saleDetail: SaleDetail) {
    return saleDetail.id;
  }
}
