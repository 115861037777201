import { Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import { SCNotification } from '@sc/types';
import { Subscription, firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { APP_VERSION } from '../../../environments/app.version';
import { ModalController } from '@ionic/angular';
import { NotificationsPage } from '../../modals/notifications/notifications.page';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { DescriptService } from '../../services/descript/descript.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'sc-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  @Input() searchBar: string;
  @Input() accountPage: string;
  mobileNavOpen$ = this.responsiveService.mobileNavOpen$;
  mobileProfileNavOpen$ = this.responsiveService.mobileProfileNavOpen$;
  banner: SCNotification;
  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private notificationsService: NotificationsService,
    private responsiveService: ResponsiveService,
    private userService: UserService,
    private organizationsService: OrganizationsService
  ) {}

  @HostBinding('class.has-banner') hasBanner = false;

  ngOnInit() {
    this.setupNotifications();
  }

  async setupNotifications() {
    const user = await this.userService.activeUser$.nextExistingValue();
    if (user.guest) return;

    const readNotifications = this.notificationsService.readNotifications$.value;

    const sub = this.notificationsService.getNotifications('banner').subscribe((bannerNotifications) => {
      const updateBanner = bannerNotifications.find((n) => n.version);
      const connectDescriptBanner = bannerNotifications.find(
        (b) => b.bannerOptions.buttonTitle === 'Connect To Descript'
      );
      if (updateBanner && updateBanner.version > APP_VERSION.packageVersion) {
        this.banner = updateBanner;
      } else if (connectDescriptBanner && !this.organizationsService.dashboardOrg$.value.driveID) {
        this.banner = connectDescriptBanner;
      } else {
        this.banner = bannerNotifications.filter((notification) => {
          return (
            !readNotifications?.find(v => v.id == notification.id) &&
            !notification.version &&
            notification.bannerOptions.buttonTitle !== 'Connect To Descript'
          );
        })?.[0];
      }
      if (this.banner) this.hasBanner = true;
      else this.hasBanner = false;
    });
    this.subs.push(sub);

    const modalNotifications = (await firstValueFrom(this.notificationsService.getNotifications('modal')))
      .filter((notification) => !readNotifications?.find((v) => v.id == notification.id))
      .sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return +aDate - +bDate;
      });

    if (modalNotifications.length > 0 && !modalNotifications[0].isDescriptAnnouncement) {
      this.showNotificationsModal(modalNotifications);
    }
  }

  async showNotificationsModal(notifications: SCNotification[]) {
    // Filters the descript announcement from organizations that have a driveID connected.
    if (this.organizationsService.dashboardOrg$.value.driveID)
      notifications = notifications.filter((v) => !v.isDescriptAnnouncement);

    const modal = await this.modalController.create({
      component: NotificationsPage,
      componentProps: {
        notifications,
      },
      cssClass: 'notifications-modal',
    });

    await modal.present();
    this.analyticsService.track('opened notifications modal', { notifications });
    return modal.onDidDismiss();
  }

  closeBanner() {
    this.notificationsService.setRead(this.banner.id);
    this.banner = null;
    this.hasBanner = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
