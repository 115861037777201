import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonRange } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { DolbyService } from '../../services/dolby/dolby.service';
import { MuteService } from '../../services/mute/mute.service';
import { VideoOffService } from '../../services/video-off/video-off.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { EquipmentService } from '../../services/equipment/equipment.service';
import { LiveDailyParticipant, LiveDolbyParticipant, VideoResolutionsForDisplay } from '@sc/types';
import { DailyService } from '../../services/daily/daily.service';
@Component({
  selector: 'sc-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('mediaStreamPlayer') mediaStreamPlayer: { nativeElement: HTMLVideoElement };
  @ViewChild('urlVideoPlayer') urlVideoPlayer: { nativeElement: HTMLVideoElement };
  @ViewChild('urlAudioPlayer') urlAudioPlayer: { nativeElement: HTMLAudioElement };
  @ViewChild('seekBarContainer') seekBarContainer: { nativeElement: HTMLElement };
  @ViewChild(IonRange) range: IonRange;

  @Output() width = new EventEmitter<number>();

  // MEDIA STREAM PLAYER STUFF
  @Input() mediaStream: MediaStream;
  @Input() audioStream: MediaStream;
  @Input() muted: boolean;
  @Input() videoOff: boolean;
  @Input() mirrored: boolean;
  @Input() showControls = false;
  @Input() isScreenShare: boolean;
  @Input() isLocalParticipant = true;
  @Input() dolbyParticipant?: LiveDolbyParticipant;
  @Input() dailyParticipant?: LiveDailyParticipant;
  @Input() placeholderPhotoURL = '';
  @Input() placeholderName = '';

  // URL VIDEO PLAYER STUFF
  @Input() url: string;
  @Input() autoplay = false;
  @Input() hideSeekBar = false;
  @Input() type: 'video' | 'audio' = null;
  mediaType: 'video' | 'audio' = this.type ?? 'audio';
  urlPlayer: HTMLVideoElement | HTMLAudioElement;
  playing = false;
  progress = 0;
  currentDurationWithFormat = '00:00:00';
  totalDurationWithFormat = '00:00:00';
  seekBarDisabled = false;
  rangePressedTimeout: NodeJS.Timeout;
  noCamera = false;
  isDolbyVoice = false;
  isSpeaking = false;
  isSpeakingTimeout: NodeJS.Timeout;
  resizeObserver: ResizeObserver;
  audioSettings: MediaTrackSettings;
  videoSettings: MediaTrackSettings;
  videoResolution: VideoResolutionsForDisplay;

  // OTHER
  videoOff$ = this.videoOffService.videoOff$;
  mute$ = this.muteService.mute$;
  dolbyConversation$ = this.dolbyService.dolbyConversation$;
  dailyCall$ = this.dailyService.dailyCall$;

  viewInitialized = false;
  appleDevice = false;
  deviceInfo: DeviceInfo;

  subs: Array<Subscription> = [];

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private dolbyService: DolbyService,
    private dailyService: DailyService,
    private equipmentService: EquipmentService,
    private muteService: MuteService,
    private videoOffService: VideoOffService,
    private analyticsService: AnalyticsService,
    private host: ElementRef
  ) {}

  ngOnInit() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.appleDevice = !(
      this.deviceInfo &&
      this.deviceInfo.browser !== 'Safari' &&
      this.deviceInfo.device !== 'iPhone' &&
      this.deviceInfo.device !== 'iPad'
    );
    this.setupEquipment();
    this.setupConvo();
    this.setupActiveSpeakers();

  }

  ngAfterViewInit() {
    this.viewInitialized = true;
    this.setupResize();
  }

  ngOnChanges(changes) {
    if (changes.mediaStream?.currentValue) this.setupMediaStreamPlayer();
    if (changes.audioStream?.currentValue) this.audioSettings = this.audioStream.getAudioTracks()[0]?.getSettings();

    if (changes.url?.currentValue) this.setupUrlPlayer();

    if (changes.muted) this.onAudioLevel(null);
  }

  setupResize() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.width.emit(entries[0].contentRect.width);
    });

    const element = this.host.nativeElement.querySelector('.media-container');
    this.resizeObserver.observe(element);
  }

  setupActiveSpeakers() {
    if (!this.dolbyParticipant || this.isLocalParticipant) return;
    const sub = this.dolbyService.activeSpeakers$.subscribe((speakers) => {
      this.isSpeaking = !!speakers.find((s) => s === this.dolbyParticipant.id);
    });
    this.subs.push(sub);
  }

  setupEquipment() {
    if (this.showControls) {
      const sub = this.equipmentService.constraints$.subscribe((constraints) => {
        this.noCamera = !constraints?.video;
        this.setupMediaStreamPlayer(); // Forces a player to rerender if the constraints have changed and not been picked up by ngOnChanges
      });

      this.subs.push(sub);
    }
  }

  setupConvo() {
    const sub = this.dolbyConversation$.subscribe((conversation) => {
      if (!conversation) return;
      this.isDolbyVoice = this.dolbyService.conferenceParams.dolbyVoice;
    });

    this.subs.push(sub);
  }

  onAudioLevel(e) {
    if (this.dolbyParticipant) {
      if (e > 0) {
        clearTimeout(this.isSpeakingTimeout);
        this.isSpeakingTimeout = null;
        if (!this.dolbyService.activeSpeakers$.value.includes(this.dolbyParticipant.id)) {
          this.isSpeaking = true;
          this.dolbyService.activeSpeakers$.next([
            ...this.dolbyService.activeSpeakers$.value,
            this.dolbyParticipant.id,
          ]);
        }
      }
      if (this.muted || (e <= 0 && this.dolbyService.activeSpeakers$.value.includes(this.dolbyParticipant?.id))) {
        if (this.isSpeakingTimeout) return;
        this.isSpeakingTimeout = setTimeout(() => {
          this.isSpeaking = false;
          this.dolbyService.activeSpeakers$.next(
            this.dolbyService.activeSpeakers$.value.filter((s) => s !== this.dolbyParticipant.id)
          );
        }, 500);
      }
    }
  }

  setupMediaStreamPlayer() {
    if (!this.mediaStream) return;
    if (!this.viewInitialized) {
      setTimeout(() => {
        this.setupMediaStreamPlayer();
      }, 100);
      return;
    }
    if (this.mediaStreamPlayer?.nativeElement && this.mediaStream) {
      const elm = this.mediaStreamPlayer.nativeElement;
      elm.muted = this.isLocalParticipant ? true : false;
      elm.autoplay = true;
      elm.playsInline = true;
      elm.srcObject = this.mediaStream;
    }
    this.videoSettings = this.mediaStream?.getVideoTracks()[0]?.getSettings();
    if (this.videoSettings) {
      setTimeout(() => {
        this.videoResolution =
          VideoResolutionsForDisplay[this.equipmentService.getVideoTrackResolution(this.videoSettings)];
      }, 0);
    }
  }

  setupUrlPlayer() {
    if (!this.url) return;
    if (!this.viewInitialized) {
      setTimeout(() => {
        this.setupUrlPlayer();
      }, 100);
      return;
    }

    if (this.urlPlayer) this.urlPlayer.removeEventListener('loadedmetadata', this.urlPlayerLoadedMetadata.bind(this));

    this.mediaType = this.type ?? (this.url.includes('.mp4') || this.url.includes('.webm') ? 'video' : 'audio');
    if (this.mediaType === 'video' && this.urlVideoPlayer?.nativeElement) {
      this.urlVideoPlayer.nativeElement.src = this.url;
      this.urlPlayer = this.urlVideoPlayer.nativeElement;
    }
    if (this.mediaType === 'audio' && this.urlAudioPlayer?.nativeElement) {
      this.urlAudioPlayer.nativeElement.src = this.url;
      this.urlPlayer = this.urlAudioPlayer.nativeElement;
    }

    this.urlPlayer.controls = false;
    this.urlPlayer.ontimeupdate = this.updateProgress.bind(this);

    if (this.mediaType === 'video') {
      const video = this.urlPlayer as HTMLVideoElement;
      video.disablePictureInPicture = true;
      video.playsInline = true;
    }

    this.urlPlayer.addEventListener('loadedmetadata', this.urlPlayerLoadedMetadata.bind(this), false);
  }

  urlPlayerLoadedMetadata() {
    this.totalDurationWithFormat = this.formatTime(this.urlPlayer.duration);
  }

  updateProgress() {
    this.progress = (this.urlPlayer.currentTime / this.urlPlayer.duration) * 100 || 0;

    if (this.urlPlayer.duration === Number.POSITIVE_INFINITY) {
      this.progress = 100;
      this.seekBarDisabled = true;
    } else this.seekBarDisabled = false;

    this.currentDurationWithFormat = this.formatTime(this.urlPlayer.currentTime);
    this.totalDurationWithFormat = this.formatTime(this.urlPlayer.duration);
  }
  formatTime(milliseconds) {
    if (!milliseconds) {
      return '00:00:00';
    }
    if (milliseconds === Number.POSITIVE_INFINITY) {
      return '--:--:--';
    }
    return new Date(milliseconds * 1000).toISOString().substring(11, 19);
  }
  seek() {
    this.setSeekBarTimeout();
    if (this.seekBarDisabled) return;
    const newTime = this.urlPlayer.duration * (+this.range.value / 100);
    this.currentDurationWithFormat = this.formatTime(newTime);
    if (isFinite(newTime)) {
      this.urlPlayer.currentTime = newTime;
    } else {
      this.urlPlayer.currentTime = 0;
    }
  }
  togglePlay(play?: boolean) {
    this.setSeekBarTimeout();
    if (this.urlPlayer.paused || play === true) {
      this.urlPlayer.play();
      this.playing = true;
    } else {
      this.urlPlayer.pause();
      this.playing = false;
    }

    this.analyticsService.track('toggled audio playback', {
      playing: !this.urlPlayer.paused,
    });
  }
  setSeekBarTimeout() {
    this.seekBarContainer.nativeElement.classList.add('show-seek-bar');
    clearTimeout(this.rangePressedTimeout);
    this.rangePressedTimeout = setTimeout(() => {
      this.seekBarContainer.nativeElement.classList.remove('show-seek-bar');
    }, 1000);
  }

  toggleVideo() {
    this.videoOffService.toggleVideo();
    this.analyticsService.track('toggled video off', { enabled: !this.videoOffService.videoOff$.value });
  }

  toggleMicrophone() {
    this.muteService.toggleMute();
    this.analyticsService.track('toggled mute', { enabled: !this.muteService.mute$.value });
  }

  playEnded() {
    this.playing = false;
  }
  playStarted() {
    this.playing = true;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });

    if (this.mediaStreamPlayer?.nativeElement) this.mediaStreamPlayer.nativeElement.srcObject = null;
    this.resizeObserver.disconnect();
  }
}
