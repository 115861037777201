<ion-row>
  <!-- <ion-fab (click)="cancel()" tooltip="Cancel" vertical="top" horizontal="end" id="leaveButton">
    <ion-fab-button size="small">
      <ion-icon src="/assets/icons/24px/close.svg"> </ion-icon>
    </ion-fab-button>
  </ion-fab> -->

  <ion-col>
    <h3 class="ion-text-center">Are You Sure?</h3>

    <h5 class="ion-text-center">The Recording will be Permanently Deleted</h5>

    <ion-list>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap">
          <p>
            <span class="bold">
              #{{ !recording.fileName.includes('mix') ? recording.fileName.split('_')[1] :
              recording.fileName.split('_')[2] }} {{ recording.fileName.split('_')[0] }}
            </span>

            <span class="regular">
              {{ (recording.fileName.split('_')[0].includes('enhanced') ? recording.fileName.split('_')[3] :
              recording.fileName.split('_')[2] ) | date: 'M/d/yy' }} {{
              (recording.fileName.split('_')[0].includes('enhanced') ? recording.fileName.split('_')[4] :
              recording.fileName.split('_')[3] ) | date: 'h:mm a' }}
            </span>
          </p>
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-chip *ngIf="recording.mp4">
          <ion-icon src="/assets/icons/24px/video-playback.svg"> </ion-icon>

          <ion-label color="lighter"> Mp4 </ion-label>
        </ion-chip>

        <ion-chip *ngIf="recording.webm">
          <ion-icon src="/assets/icons/24px/video-playback.svg"> </ion-icon>

          <ion-label color="lighter"> Webm </ion-label>
        </ion-chip>

        <ion-chip *ngIf="recording.wav">
          <ion-icon src="/assets/icons/32px/recordings-bold.svg"> </ion-icon>

          <ion-label color="lighter"> Wav </ion-label>
        </ion-chip>

        <ion-chip *ngIf="recording.mp3">
          <ion-icon src="/assets/icons/32px/recordings-bold.svg"> </ion-icon>

          <ion-label color="lighter"> Mp3 </ion-label>
        </ion-chip>
      </ion-item>
    </ion-list>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-button (click)="cancel()" expand="block" shape="round" class="grey"> Cancel </ion-button>
  </ion-col>

  <ion-col>
    <ion-button (click)="deleteRecording()" expand="block" shape="round" id="delete-button">
      Delete Recording
    </ion-button>
  </ion-col>
</ion-row>
