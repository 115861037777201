import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AuthService } from '../../services/auth/auth.service';
import { WindowToken } from '../../services/window/window';
import { RoleLimits, Roles } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss'],
})
export class AccountSidebarComponent {
  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;
  roles = Roles;
  mobileProfileNavOpen$ = this.responsiveService.mobileProfileNavOpen$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  activeUser$ = this.userService.activeUser$;

  constructor(
    public router: Router,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService,
    private rolesService: RolesService,
    private responsiveService: ResponsiveService,
    private userService: UserService,
    @Inject(WindowToken) private window: Window
  ) {}

  @HostListener('keyup', ['$event'])
  async Esc(event: KeyboardEvent) {
    if (this.mobileProfileNavOpen$.value && event.key === 'Escape') {
      this.mobileProfileNavOpen$.next(false);
    }
  }

  async logOut() {
    await this.authService.logout();

    const protocol = this.window.location.protocol;
    const hostName = this.window.location.host;
    this.window.location.href = `${protocol}//${hostName}/auth`;

    await this.analyticsService.track('logged out');
  }
}
