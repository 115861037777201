export enum Workflows {
  DOLBY = 'dolby',
  DROPBOX = 'dropbox',
  EDDY = 'eddy',
  HINDENBURG = 'hindenburg',
  GOOGLEDRIVE = 'googleDrive',
  TRINT = 'trint',
  MIX = 'mix',
  DELETE = 'delete',
}

export type WorkflowActions = {
  [key in Workflows]: WorkflowAction;
};

export type WorkflowAction = {
  title: string;
  description: string;
  icon: string;
  handler: (sessionID?: string) => Promise<void>;
};
