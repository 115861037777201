import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { UserService } from '../../services/user/user.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { TeamMember } from '@sc/types';
import { UserModel } from '@sc/types';

@Component({
  selector: 'sc-transfer-organization',
  templateUrl: './transfer-organization.component.html',
  styleUrls: ['./transfer-organization.component.scss'],
})
export class TransferOrganizationComponent implements OnInit {
  @Input() member: TeamMember;

  activeUser$ = this.userService.activeUser$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  resetPayment = false;
  loading = false;

  constructor(
    private userService: UserService,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService,
    private cfs: CloudFunctionsService,
    private walletService: WalletService
  ) {}

  ngOnInit() {}

  async transferOrganization() {
    this.loading = true;
    await this.cfs.post('organization-transfer', {
      organizationId: this.organizationsService.dashboardOrg$.value.orgID,
      userId: this.member.uid,
      resetPayment: this.resetPayment,
    });
    this.walletService.refreshStripeCustomer();
    this.modalController.dismiss();
  }

  cancel() {
    this.modalController.dismiss();
    this.analyticsService.track('closed move session modal');
  }
}
