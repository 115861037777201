import { Component, OnInit } from '@angular/core';
import { DescriptService } from '../../services/descript/descript.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { DescriptPlanNames, Organization, Plan } from '@sc/types';
import { WalletService } from '../../services/wallet/wallet.service';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CanceledService } from '../../services/canceled/canceled.service';

@Component({
  selector: 'sc-descript-link',
  templateUrl: './descript-link.page.html',
  styleUrls: ['./descript-link.page.scss'],
})
export class DescriptLinkPage implements OnInit {
  linkPlan$ = this.descriptService.linkPlan$;
  linkDrive$ = this.descriptService.linkDrive$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  dashboardPlan$ = this.walletService.dashboardPlan$;

  state: 'no-plan' | 'claim' | 'upgrade';
  recommendedDescriptPlan: Plan;

  constructor(
    private analyticsService: AnalyticsService,
    private descriptService: DescriptService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private walletService: WalletService,
    private canceledService: CanceledService
  ) {}

  async ngOnInit() {
    this.setupState();
    this.setupRecommendedPlan();
  }

  async setupState() {
    combineLatest([this.dashboardOrg$, this.linkDrive$]).subscribe(async ([org, drive]) => {
      if (drive && !drive.entitlements) return;

      const status = await this.canceledService.canceledStatus$.toPromise();
      const wallet = await this.walletService.wallet$.toPromise();
      if (!org.planID || status?.closed || wallet?.frozen) this.state = 'no-plan';
      else if (
        drive?.entitlements.type === DescriptPlanNames.FREE &&
        this.recommendedDescriptPlan?.recommendedDescriptPlan !== DescriptPlanNames.FREE
      ) {
        this.state = 'upgrade';
      } else this.state = 'claim';
    });
    const drive = await this.linkDrive$.nextExistingValue();
    await this.analyticsService.track('descript link modal shown', {
      linkInfo: {
        plan: this.linkPlan$.value.recommendedDescriptPlan,
        driveID: drive.driveID,
        state: this.state,
      },
    });
  }

  setupRecommendedPlan() {
    this.walletService.dashboardPlan$.subscribe((plan) => {
      if (plan?.recommendedDescriptPlanID) {
        this.recommendedDescriptPlan = this.descriptService.descriptPlans$.value.find(
          (p) => p.id === plan.recommendedDescriptPlanID
        );
        if (this.recommendedDescriptPlan.recommendedDescriptPlan === DescriptPlanNames.FREE) this.state = 'claim';
      }
    });
  }

  async claim() {
    if (this.state === 'upgrade') {
      const driveID = this.linkDrive$.value.driveID;
      const orgID = this.organizationsService.dashboardOrg$.value.orgID;

      const planMap = {
        [DescriptPlanNames.CREATOR]: 'creator',
        [DescriptPlanNames.PRO]: 'pro',
        [DescriptPlanNames.FREE]: 'free',
        [DescriptPlanNames.BUSINESS]: 'business',
      };
      const descriptPlan = planMap[this.dashboardPlan$.value.recommendedDescriptPlan] ?? 'pro';
      await this.analyticsService.track('descript link modal upgrade', {
        linkInfo: { driveID, orgID, plan: descriptPlan },
      });
      const queryParams = `&squadcast_connect=true&driveId=${driveID}&squadcast_pre_approved_org_id=${orgID}&plan=${descriptPlan}&utm_source=squadcast`;
      window.location.href = `${environment.descript.webDomain}/view/settings/account?active=subscription${queryParams}`; // TODO: Update URL
    } else {
      this.descriptService.claimDrive();
      this.modalController.dismiss();
    }
  }

  cancel() {
    this.modalController.dismiss({ cancelled: true });
  }
}
