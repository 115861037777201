<div id="not-found-page">
  <a href="https://app.squadcast.fm/">
    <img
      [src]="
        (theme$ | async) === 'dark-theme'
          ? 'assets/images/SC_D_Logo_Horizontal-LM.svg'
          : '/assets/images/SC_D_Logo_Horizontal-DM.svg'
      "
      alt="SquadCast Logo"
      id="logo" />
  </a>

  <div id="not-found-container">
    <div id="logo-404">
      <span> 4 </span>
      <object data="/assets/images/SC_LogoMark-Circle_Animated.svg"></object>
      <span> 4 </span>
    </div>
    <ion-row id="not-found-text">
      <h3 class="bold regular">Wrong Studio...</h3>
      <p class="ion-text-center">
        It seems you may have the wrong link to your recording session. Please check with the Show Manager to get the
        correct link.
      </p>
      <a href="https://app.squadcast.fm/" alt="Home"> SquadCast Home </a>
    </ion-row>
  </div>
</div>
