import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SCSubject } from '../../util/sc-subject.class';

@Injectable({
  providedIn: 'root',
})
export class GifService {
  baseURL = 'https://api.giphy.com/v1/gifs';
  production = '';
  development = 'lOlyJSGGoTBhrpSxhMLmiXtd98RlKL1I';
  environment = this.development;
  rating = 'pg';

  constructor(private http: HttpClient) {}

  getRandom() {
    return this.http.get(`${this.baseURL}/random?api_key=${this.environment}&rating=${this.rating}`);
  }

  getTrending(limit: number = 6) {
    return this.http.get(`${this.baseURL}/trending?api_key=${this.environment}&limit=${limit}&rating=${this.rating}`);
  }

  search(term: string, limit: number) {
    // term = term.replace(/[\W_]+/g,'');
    term = term.split(' ').join('+');
    return this.http.get(
      `${this.baseURL}/search?api_key=${this.environment}&q=${term}&limit=${limit}&rating=${this.rating}`
    );
  }
}
