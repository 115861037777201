<h5>Master Audio with Dolby</h5>
<ion-list *ngIf="(walletService.dashboardPlan$ | async)?.dolbyMastering">
  <ion-item aria-label="loudness">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Loudness </strong>

          <p>Apply loudness correction to your media to match a target profile.</p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button
              class="expand-button"
              (click)="toggleExpandedLoudness()"
              aria-label="Expand section"
              color="lighter">
              <ion-icon
                src="/assets/icons/24px/chevron-down.svg"
                *ngIf="!expandedLoudness; else upIcon"
                slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedLoudness" id="expand">
        <ion-card>
          <ion-list>
            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Enable </strong>

                <p>Apply loudness correction enhancements to your media.</p>
              </ion-label>
              <ion-toggle
                [checked]="currentEnhanceSettings.loudness.enable"
                (ionChange)="toggleEnableLoudness($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>
            <ion-item id="target-level">
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Target Level </strong>

                <p>Set the loudness level target to conform to.</p>
              </ion-label>
              <ion-input
                [(ngModel)]="currentEnhanceSettings.loudness.target_level"
                (ionBlur)="setLoudnessTargetLevel($event)"
                placeholder="{{ currentEnhanceSettings.loudness.target_level }}"
                min="-20"
                max="-13"
                type="number"
                color="primary"
                mode="ios">
              </ion-input>
            </ion-item>
            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Dialog Intelligence </strong>

                <p>
                  Apply to use Dolby Dialog Intelligence as a gating technique. Learn more in documentation about Gating
                  Techniques.
                </p>
              </ion-label>
              <ion-toggle
                [checked]="currentEnhanceSettings.loudness.dialog_intelligence"
                (ionChange)="toggleDialogIntelligence($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>
            <ion-item lines="none">
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Speech Threshold </strong>

                <p>
                  When using Dolby Dialog Intelligence, the speech threshold is a percentage of speech content in the
                  audio signal, to classify a section on dialogue.
                </p>
              </ion-label>
              <ion-input
                [(ngModel)]="currentEnhanceSettings.loudness.speech_threshold"
                (ionBlur)="setLoudnessSpeechThreshold($event)"
                placeholder="{{ currentEnhanceSettings.loudness.speech_threshold }}"
                max="100"
                min="0"
                type="number"
                color="primary"
                mode="ios">
              </ion-input>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="dynamics">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Dynamics </strong>

          <p>
            Speech dynamics provide basic corrections for troublesome recordings where the tonality has been skewed by
            bad microphone technique or mixing.
          </p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button (click)="toggleExpandedDynamics()" aria-label="Expand section" color="lighter">
              <ion-icon
                src="/assets/icons/24px/chevron-down.svg"
                *ngIf="!expandedDynamics; else upIcon"
                slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedDynamics" id="expand">
        <ion-card>
          <ion-list>
            <ion-item aria-label="dynamicsRangeContol" lines="none">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Range Control </strong>

                    <p>Control over the range for dynamics processing.</p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button
                        (click)="toggleExpandedDynamicsRangeControl()"
                        aria-label="Expand section"
                        color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedDynamicsRangeControl; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedDynamicsRangeControl" id="expand">
                  <ion-list>
                    <ion-item lines>
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>Option to disable Dynamics Processing entirely if not achieving desired results.</p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.dynamics.range_control.enable"
                        (ionChange)="toggleEnableDynamicsRangeControl($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Amount </strong>

                        <p>Set the amount of dynamic leveling to apply.</p>
                      </ion-label>
                      <ion-select
                        class="select-controls"
                        [interfaceOptions]="selectEnhanceOptions"
                        [(ngModel)]="currentEnhanceSettings.dynamics.range_control.amount"
                        (ionChange)="setDynamicsRangeControlAmount($any($event.target).value)"
                        [value]="level"
                        placeholder="currentEnhanceSettings.dynamics.range_control.amount"
                        interface="popover"
                        slot="end">
                        <ion-select-option
                          class="select-enhance-option"
                          *ngFor="let level of rangeControlLevels"
                          [value]="level"
                          >{{ level }}</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="noise">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Noise </strong>

          <p>
            Noise is considered as any unwanted sounds or nuisances discovered when analyzing your content. This
            intelligent noise management solution blends two approaches: noise reduction and speech isolation.
          </p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button (click)="toggleExpandedNoise()" aria-label="Expand section" color="lighter">
              <ion-icon src="/assets/icons/24px/chevron-down.svg" *ngIf="!expandedNoise; else upIcon" slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedNoise" id="expand">
        <ion-card>
          <ion-list>
            <ion-item aria-label="noiseReduction" lines="none">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Reduction </strong>

                    <p>Noise Reduction is a feature to suppress static background sounds.</p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedNoiseReduction()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedNoiseReduction; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedNoiseReduction" id="expand">
                  <ion-list>
                    <ion-item lines>
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>Option to disable Noise Reduction.</p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.noise.reduction.enable"
                        (ionChange)="toggleEnableNoiseReduction($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Amount </strong>

                        <p>
                          Set the amount of noise reduction to apply. This amount refers to how aggressive the
                          processing will be. With a high or max amount, there may be certain media where this could
                          disrupt the content, so you may need to adjust this setting accordingly.
                        </p>
                      </ion-label>
                      <ion-select
                        class="select-controls"
                        [interfaceOptions]="selectEnhanceOptions"
                        [(ngModel)]="currentEnhanceSettings.noise.reduction.amount"
                        (ionChange)="setNoiseReductionAmount($any($event.target).value)"
                        [value]="level"
                        interface="popover"
                        slot="end">
                        <ion-select-option
                          class="ion-select-enhance"
                          *ngFor="let level of rangeControlLevels"
                          [value]="level"
                          >{{ level }}</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="filter">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Filter </strong>

          <p>Use to cut out any undesirable content and noises to make speech recordings sound better.</p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button (click)="toggleExpandedFilter()" aria-label="Expand section" color="lighter">
              <ion-icon src="/assets/icons/24px/chevron-down.svg" *ngIf="!expandedFilter; else upIcon" slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedFilter" id="expand">
        <ion-card>
          <ion-list>
            <ion-item aria-label="filterDynamicEQ">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Dynamic EQ </strong>

                    <p>
                      The Dynamic EQ filter will actively adjust frequency, gain, and bandwidth to equalize and compress
                      the overall spectral balance.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedFilterDynamicEQ()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedFilterDynamicEQ; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedFilterDynamicEQ" id="expand">
                  <ion-list>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>
                          Enabled by default, but can be switched off in cases such as musical content or audio that
                          already has a desirable spectral balance.
                        </p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.filter.dynamic_eq.enable"
                        (ionChange)="toggleEnableFilterDynamicEQ($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>

            <ion-item aria-label="filterHighPass">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> High Pass </strong>

                    <p>
                      The High Pass filter removes mechanical thumps and hums that come from low frequencies by removing
                      them and only letting signals above a threshold pass through. This can make speech recordings
                      sound better.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedFilterHighPass()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedFilterHighPass; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedFilterHighPass" id="expand">
                  <ion-list>
                    <ion-item>
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>
                          Enabled by default, but you can turn it off in cases such as musical content that may have
                          undesirable results.
                        </p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.filter.high_pass.enable"
                        (ionChange)="toggleEnableFilterHighPass($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Frequency </strong>

                        <p>
                          The frequency value you want to use for filtering. Anything that falls below this threshold
                          will be removed.
                        </p>
                      </ion-label>
                      <ion-input
                        [(ngModel)]="currentEnhanceSettings.filter.high_pass.frequency"
                        (ionBlur)="setFilterHighPassFrequency($event)"
                        placeholder="{{ currentEnhanceSettings.filter.high_pass.frequency }}"
                        max="120"
                        min="50"
                        type="number"
                        color="primary"
                        mode="ios">
                      </ion-input>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>

            <ion-item aria-label="filterHum" lines="none">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Hum </strong>

                    <p>
                      Hum Reduction detects and removes hum sounds by looking at peaks in the average amplitude of
                      frequencies per time. This works particularly well for static hum, such as oscillating mains hum,
                      constant background tones, or soft/low frequency humming.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedFilterHum()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedFilterHum; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedFilterHum" id="expand">
                  <ion-list>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>
                          Enabled by default, but can be turned off in cases where a constant hum is desirable in the
                          media content.
                        </p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.filter.hum.enable"
                        (ionChange)="toggleEnableFilterHum($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="speech">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Speech </strong>

          <p>
            Enhance the quality of dialogue in media through controls for sibilance and speech isolation. Should any
            unwanted noise be discovered while analyzing your content, the intelligent noise management solution will
            blend static noise reduction with a more aggressive speech isolation to drop any nuisances not attributable
            to speech. If your content has music, you may want to disable this enhancement.
          </p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button (click)="toggleExpandedSpeech()" aria-label="Expand section" color="lighter">
              <ion-icon src="/assets/icons/24px/chevron-down.svg" *ngIf="!expandedSpeech; else upIcon" slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedSpeech" id="expand">
        <ion-card>
          <ion-list>
            <ion-item aria-label="speechIsolation">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Isolation </strong>

                    <p>
                      Speech Isolation is enabled by default as part of the intelligent noise management. This type of
                      noise refers to any nuisance sounds not attributable to speech or dialogue. This can be disabled
                      if you only want static noise reduction to allow more background context sounds to remain in the
                      content.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedSpeechIsolation()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedSpeechIsolation; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedSpeechIsolation" id="expand">
                  <ion-list>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>Option to enable speech isolation.</p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.speech.isolation.enable"
                        (ionChange)="toggleEnableSpeechIsolation($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>

            <ion-item aria-label="speechSibilance">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Sibilance </strong>

                    <p>
                      Sibilance is the characteristic of harsh consonant sounds like “s,” “sh,” “x,” “ch,” “t,” and
                      “th”.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedSpeechSibilance()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedSpeechSibilance; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedSpeechSibilance" id="expand">
                  <ion-list>
                    <ion-item aria-label="speechSibilanceReduction" lines="none">
                      <ion-col>
                        <ion-row class="enhance-category">
                          <ion-label color="lightest" class="ion-text-wrap">
                            <strong> Reduction </strong>

                            <p>Reduce the severity of sibilant over pronunciation.</p>
                          </ion-label>

                          <ion-col size-xs="1" size-sm="1">
                            <ion-buttons class="expand-section">
                              <ion-button
                                (click)="toggleExpandedSpeechSibilanceReduction()"
                                aria-label="Expand section"
                                color="lighter">
                                <ion-icon
                                  src="/assets/icons/24px/chevron-down.svg"
                                  *ngIf="!expandedSpeechSibilanceReduction; else upIcon"
                                  slot="icon-only">
                                </ion-icon>

                                <ng-template #upIcon>
                                  <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                                </ng-template>
                              </ion-button>
                            </ion-buttons>
                          </ion-col>
                        </ion-row>

                        <div *ngIf="expandedSpeechSibilanceReduction" id="expand">
                          <ion-list>
                            <ion-item>
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Enable </strong>

                                <p>Option to enable sibilance reduction.</p>
                              </ion-label>
                              <ion-toggle
                                [checked]="currentEnhanceSettings.speech.sibilance.reduction.enable"
                                (ionChange)="toggleEnableSpeechSibilanceReduction($event)"
                                color="primary"
                                mode="ios"
                                slot="end">
                              </ion-toggle>
                            </ion-item>
                            <ion-item lines="none">
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Amount </strong>

                                <p>
                                  The amount of effect intended by the enhancement. Dial this up or down, depending on
                                  your media characteristics.
                                </p>
                              </ion-label>
                              <ion-select
                                class="select-controls"
                                [interfaceOptions]="selectEnhanceOptions"
                                [(ngModel)]="currentEnhanceSettings.speech.sibilance.reduction.amount"
                                (ionChange)="setSpeechSibilanceReductionAmount($any($event.target).value)"
                                [value]="level"
                                interface="popover"
                                slot="end">
                                <ion-select-option *ngFor="let level of rangeControlLevels" [value]="level">{{
                                  level
                                }}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-list>
                        </div>
                      </ion-col>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>

            <ion-item aria-label="speechPlosive">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Plosive </strong>

                    <p>Plosives are "pops" caused by sounds like "p" being spoken too close to the microphone.</p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedSpeechPlosive()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedSpeechPlosive; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedSpeechPlosive" id="expand">
                  <ion-list>
                    <ion-item aria-label="speechPlosiveReduction" lines="none">
                      <ion-col>
                        <ion-row class="enhance-category">
                          <ion-label color="lightest" class="ion-text-wrap">
                            <strong> Reduction </strong>

                            <p>Reduce the intensity of plosives in speech.</p>
                          </ion-label>

                          <ion-col size-xs="1" size-sm="1">
                            <ion-buttons class="expand-section">
                              <ion-button
                                (click)="toggleExpandedSpeechPlosiveReduction()"
                                aria-label="Expand section"
                                color="lighter">
                                <ion-icon
                                  src="/assets/icons/24px/chevron-down.svg"
                                  *ngIf="!expandedSpeechPlosiveReduction; else upIcon"
                                  slot="icon-only">
                                </ion-icon>

                                <ng-template #upIcon>
                                  <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                                </ng-template>
                              </ion-button>
                            </ion-buttons>
                          </ion-col>
                        </ion-row>

                        <div *ngIf="expandedSpeechPlosiveReduction" id="expand">
                          <ion-list>
                            <ion-item>
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Enable </strong>

                                <p>Option to enable plosive reduction.</p>
                              </ion-label>
                              <ion-toggle
                                [checked]="currentEnhanceSettings.speech.plosive.reduction.enable"
                                (ionChange)="toggleEnableSpeechPlosiveReduction($event)"
                                color="primary"
                                mode="ios"
                                slot="end">
                              </ion-toggle>
                            </ion-item>
                            <ion-item lines="none">
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Amount </strong>

                                <p>
                                  The amount of aggressiveness you want the enhancement to make. You can dial this up or
                                  down depending on your media characteristics.
                                </p>
                              </ion-label>
                              <ion-select
                                class="select-controls"
                                [interfaceOptions]="selectEnhanceOptions"
                                [(ngModel)]="currentEnhanceSettings.speech.plosive.reduction.amount"
                                (ionChange)="setSpeechPlosiveReductionAmount($any($event.target).value)"
                                [value]="level"
                                interface="popover"
                                slot="end">
                                <ion-select-option *ngFor="let level of rangeControlLevels" [value]="level">{{
                                  level
                                }}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-list>
                        </div>
                      </ion-col>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>

            <ion-item aria-label="speechClick" lines="none">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Click </strong>

                    <p>
                      Clicks are sounds produced by movement of the mouth before, during or after speech that may be
                      picked up by a microphone during recording.
                    </p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedSpeechClick()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedSpeechClick; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedSpeechClick" id="expand">
                  <ion-list>
                    <ion-item aria-label="speechClickReduction" lines="none">
                      <ion-col>
                        <ion-row class="enhance-category">
                          <ion-label color="lightest" class="ion-text-wrap">
                            <strong> Reduction </strong>

                            <p>
                              Reduce the severity of clicks in your media. This can make speech recordings sound better.
                            </p>
                          </ion-label>

                          <ion-col size-xs="1" size-sm="1">
                            <ion-buttons class="expand-section">
                              <ion-button
                                (click)="toggleExpandedSpeechClickReduction()"
                                aria-label="Expand section"
                                color="lighter">
                                <ion-icon
                                  src="/assets/icons/24px/chevron-down.svg"
                                  *ngIf="!expandedSpeechClickReduction; else upIcon"
                                  slot="icon-only">
                                </ion-icon>

                                <ng-template #upIcon>
                                  <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                                </ng-template>
                              </ion-button>
                            </ion-buttons>
                          </ion-col>
                        </ion-row>

                        <div *ngIf="expandedSpeechClickReduction" id="expand">
                          <ion-list>
                            <ion-item>
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Enable </strong>

                                <p>Option to enable click reduction.</p>
                              </ion-label>
                              <ion-toggle
                                [checked]="currentEnhanceSettings.speech.click.reduction.enable"
                                (ionChange)="toggleEnableSpeechClickReduction($event)"
                                color="primary"
                                mode="ios"
                                slot="end">
                              </ion-toggle>
                            </ion-item>
                            <ion-item lines="none">
                              <ion-label color="lightest" class="ion-text-wrap">
                                <strong> Amount </strong>

                                <p>Controls how strongly click reduction will be applied to the recorded signal.</p>
                              </ion-label>
                              <ion-select
                                class="select-controls"
                                [interfaceOptions]="selectEnhanceOptions"
                                [(ngModel)]="currentEnhanceSettings.speech.click.reduction.amount"
                                (ionChange)="setSpeechClickReductionAmount($any($event.target).value)"
                                [value]="level"
                                interface="popover"
                                slot="end">
                                <ion-select-option *ngFor="let level of rangeControlLevels" [value]="level">{{
                                  level
                                }}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-list>
                        </div>
                      </ion-col>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="music" lines="none">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Music </strong>

          <p>Features that can be used to enhance the quality of music found in media.</p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button (click)="toggleExpandedMusic()" aria-label="Expand section" color="lighter">
              <ion-icon src="/assets/icons/24px/chevron-down.svg" *ngIf="!expandedMusic; else upIcon" slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedMusic" id="expand">
        <ion-card>
          <ion-list>
            <ion-item aria-label="musicDetection" lines="none">
              <ion-col>
                <ion-row class="enhance-category">
                  <ion-label color="lightest" class="ion-text-wrap">
                    <strong> Detection </strong>

                    <p>Automatically detect music and adjust processing to preserve music.</p>
                  </ion-label>

                  <ion-col size-xs="1" size-sm="1">
                    <ion-buttons class="expand-section">
                      <ion-button (click)="toggleExpandedMusicDetection()" aria-label="Expand section" color="lighter">
                        <ion-icon
                          src="/assets/icons/24px/chevron-down.svg"
                          *ngIf="!expandedMusicDetection; else upIcon"
                          slot="icon-only">
                        </ion-icon>

                        <ng-template #upIcon>
                          <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
                        </ng-template>
                      </ion-button>
                    </ion-buttons>
                  </ion-col>
                </ion-row>

                <div *ngIf="expandedMusicDetection" id="expand">
                  <ion-list>
                    <ion-item lines="none">
                      <ion-label color="lightest" class="ion-text-wrap">
                        <strong> Enable </strong>

                        <p>Option to disable music detector.</p>
                      </ion-label>
                      <ion-toggle
                        [checked]="currentEnhanceSettings.music.detection.enable"
                        (ionChange)="toggleEnableMusicDetection($event)"
                        color="primary"
                        mode="ios"
                        slot="end">
                      </ion-toggle>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col size-xs="12" size-sm="12" offset-md="1" size-md="10" offset-lg="2" size-lg="8" offset-xl="3" size-xl="6">
    <ion-button
      (click)="resetToDefaultMasteringSettings()"
      *ngIf="(walletService.dashboardPlan$ | async)?.dolbyMastering"
      id="reset-button"
      oncontextmenu="false"
      class="primary"
      expand="block">
      Reset to System Defaults
    </ion-button>

    <ion-button
      *ngIf="!(walletService.dashboardPlan$ | async)?.dolbyMastering"
      [upgrade]="!(walletService.dashboardPlan$ | async)?.dolbyMastering"
      [class.disabled]="!(walletService.dashboardPlan$ | async)?.dolbyMastering"
      tooltip="Upgrade To Unlock"
      [routerLink]="['/account/subscription']"
      (click)="modalController.dismiss()"
      oncontextmenu="false"
      class="primary"
      expand="block">
      <ion-icon src="/assets/icons/24px/dolby.svg" slot="start"> </ion-icon>
      Master Audio With Dolby Settings
    </ion-button>
  </ion-col>
</ion-row>
