import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

import { SessionsService } from '../../services/sessions/sessions.service';
import { NameService } from './../name/name.service';
import { FileCountService } from './../file-count/file-count.service';
import { UserService } from './../user/user.service';
import { UserModel } from '@sc/types';
import { Session } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';

@Injectable({
  providedIn: 'root',
})
export class FilenameService {
  user: UserModel;
  session: Session;
  name: string;
  fileCount: number;
  fileName$: SCSubject<string> = new SCSubject<string>();

  constructor(
    private userService: UserService,
    private sessionsService: SessionsService,
    private nameService: NameService,
    private fileCountService: FileCountService
  ) {
    this.setupUser();
    this.setupSession();
    this.init();
  }

  async init() {
    this.user = await this.userService.activeUser$.toPromise();
    this.session = await this.sessionsService.studioSession$.toPromise();
    this.setupName();
    this.setupFileCount();
  }

  clearFileName() {
    this.fileName$.next(null);
  }

  async setupFileName() {
    this.fileCount++;
    const now = dayjs().format('MM-DD-YYYY_HHmmss');
    this.fileName$.next(`${this.name}_${this.fileCount}_${now}`);
    await this.fileCountService.setFileCount(this.session.sessionID, this.user.uid, this.fileCount);
  }

  setupUser() {
    this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });
  }

  setupSession() {
    this.sessionsService.studioSession$.subscribe((session) => {
      this.session = session;
    });
  }

  async setupName() {
    if (!this.session) return;
    this.nameService.getName(this.session.sessionID, this.user.uid).subscribe((name: any) => {
      this.name = name?.replace(/[^a-z0-9]/gi, '-').toLowerCase();
      this.verifyDuplicateParticipantNames(); // Check if other participants have the same name
    });
  }

  async verifyDuplicateParticipantNames() {
    // Check if other participants have the same name
    if (!this.session?.memberNames) return;
    this.session?.memberNames.forEach((name) => {
      if (name === this.name && this.user.guest) {
        this.name += `-guest${Math.floor(Math.random() * 1000)}`; // If the user is a guest, append '-guest' and a random number to the name
      } else if (name === this.name && !this.user.guest) {
        this.name += `-host${Math.floor(Math.random() * 1000)}`; // If the user is a host, append '-host' to the name and a random number
      }
    });
  }

  async setupFileCount() {
    if (!this.session) return;
    this.fileCountService.getFileCount(this.session.sessionID, this.user.uid).subscribe((fileCount) => {
      this.fileCount = fileCount || 0;
    });
  }
}
