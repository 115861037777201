import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { DescriptService } from '../../services/descript/descript.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { Organization } from '@sc/types';

@Component({
  selector: 'sc-drive-select',
  templateUrl: './drive-select.component.html',
  styleUrls: ['./drive-select.component.scss'],
})
export class DriveSelectComponent {
  @Input() showOrgSelect = false;

  linkingDriveName$ = this.descriptService.linkingDriveName$;
  availableOrgs$ = this.organizationsService.availableOrgs$;
  loading$ = this.descriptService.loading$;

  selectedOrg: Organization;
  redirectUrl = `${environment.descript.webDomain}/view/settings/account?active=subscription&utm_source=squadcast`;

  constructor(
    private analyticsService: AnalyticsService,
    private descriptService: DescriptService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.selectedOrg = this.availableOrgs$.value?.find((org) => !org.driveID);
    this.changeOrg();
  }

  changeDrive(event) {
    if (event === 'backToDescript') {
      window.location.href = this.redirectUrl;
      this.analyticsService.track('descript select different drive clicked');
    }
  }

  changeOrg() {
    if (!this.selectedOrg || !this.showOrgSelect) return;
    this.organizationsService.setDashboardOrgID(this.selectedOrg.orgID);
    this.analyticsService.track('descript link org change');
  }
}
