import { Injectable } from '@angular/core';
import { Firestore, collection, doc, docData, setDoc } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { SCSubject } from '../../util/sc-subject.class';
import { Equipment, SessionsCollection } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class MuteService {
  mute$ = new SCSubject<boolean>();
  sessionsCol = collection(this.firestore, 'sessions');

  constructor(
    private firestore: Firestore,
    private sessionsService: SessionsService,
    private userService: UserService
  ) {
    this.setupMute();
  }

  setupMute() {
    this.sessionsService.participantEquipment$.subscribe((equipment) => {
      this.mute$.next(equipment?.mute ?? false);
    });
  }

  setMute(sessionID: string, castMemberID: string, mute: boolean) {
    if (castMemberID === this.userService.activeUser$.value.uid) this.mute$.next(mute);
    const equipmentRef = doc(
      this.sessionsCol,
      sessionID,
      'participants',
      castMemberID,
      SessionsCollection.ENV,
      'equipment'
    );
    return setDoc(equipmentRef, { mute }, { merge: true });
  }

  getMute(sessionID: string, castMemberID: string) {
    const equipmentRef = doc(
      this.sessionsCol,
      sessionID,
      'participants',
      castMemberID,
      SessionsCollection.ENV,
      'equipment'
    );
    return docData<Equipment>(equipmentRef).pipe(map((equipment) => equipment?.mute));
  }

  toggleMute() {
    const sessionID = this.sessionsService.studioSession$.value.sessionID;
    const uid = this.userService.activeUser$.value.uid;
    return this.setMute(sessionID, uid, !this.mute$.value);
  }
}
