import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { RecordingInfo, RecordingIssue, SessionStats, TrackSettings } from '@sc/types';
import { FirebaseApp } from '@angular/fire/app';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  getDoc,
  limit,
  orderBy,
  query,
} from '@angular/fire/firestore';
import { UserService } from '../../services/user/user.service';
import { StatsService } from '../../services/stats/stats.service';
import { DeviceInfo } from 'ngx-device-detector';
import { SessionsService } from '../../services/sessions/sessions.service';

@Component({
  selector: 'sc-test-equipment-result',
  templateUrl: './test-equipment-result.page.html',
  styleUrls: ['./test-equipment-result.page.scss'],
})
export class TestEquipmentResultPage implements OnInit, OnDestroy {
  subs: Array<Subscription> = [];
  videoURL: string;
  lastTestRecording: {
    deviceInfo: DeviceInfo;
    recordingInfo: RecordingInfo;
    warnings: RecordingIssue[];
  };
  sessionType: 'video' | 'audio' = this.sessionsService.studioSession$.value?.videoEnabled ? 'video' : 'audio';
  expandedResultTitle: string;

  connectionStats$ = this.statsService.connectionStats$;

  stats: {
    networkScore?: number;
    ping?: number;
    audioJitter?: number;
    audioPacketsLost?: number;
    videoJitter?: number;
    videoPacketsLost?: number;
    qualityLimitationReason?: string;
    videoResolution?: string;
    videoFrameRate?: number;
  } = {};

  resultsList: Array<{
    title: string;
    description: string;
    icon: string;
    state: 'success' | 'warning' | 'danger';
  }> = [];

  constructor(
    private modalController: ModalController,
    private firstore: Firestore,
    private userService: UserService,
    private statsService: StatsService,
    private sessionsService: SessionsService,
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.grabTestResults();
    this.element.nativeElement.addEventListener('click', () => {
      this.expand('');
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  expand(title: string, event?: Event) {
    event?.stopPropagation();
    this.expandedResultTitle = title;
  }

  async grabTestResults() {
    const diagnosticsDoc = doc(this.firstore, 'diagnostics', this.userService.activeUser$.value.uid);
    const sub = docData(diagnosticsDoc).subscribe((doc) => {
      setTimeout(() => {
        this.videoURL = doc?.rawURL;
      }, 1000);
    });

    // grab a "testRecording" collection doc inside the user's diagnostics doc, it should be the latest one when sorted by timestamp
    const testRecordingCollection = collection(
      this.firstore,
      'diagnostics',
      this.userService.activeUser$.value.uid,
      'testRecordings'
    );
    const sub2 = collectionData(query(testRecordingCollection, orderBy('timestamp', 'desc'), limit(1))).subscribe(
      (docs) => {
        this.lastTestRecording = docs[0] as {
          deviceInfo: DeviceInfo;
          recordingInfo: RecordingInfo;
          warnings: RecordingIssue[];
          connectionStats: SessionStats;
        };
        this.addDeviceInfoResult(this.lastTestRecording.deviceInfo);
        this.addTrackSettingsResult(
          {
            ...this.lastTestRecording?.recordingInfo?.stats?.audio,
            ...this.lastTestRecording?.recordingInfo?.stats?.video,
          }.trackSettings
        );
        this.addWarningsResults(this.lastTestRecording.warnings);
      }
    );

    // grab connection stats
    const stats = await firstValueFrom(this.connectionStats$);
    this.stats.ping = stats.network.currentRoundTripTime * 1000;

    if (stats.scores?.video) this.stats.networkScore = Math.round(stats.scores.video);
    else if (stats.scores?.audio) this.stats.networkScore = Math.round(stats.scores.audio);

    Object.keys(stats.audio).forEach((ssrc) => {
      this.stats.audioJitter = stats.audio[ssrc].remote?.jitter * 1000;
      this.stats.audioPacketsLost = stats.audio[ssrc].remote?.packetsLost;
    });
    Object.keys(stats.video).forEach((ssrc) => {
      const vidStat = stats.video[ssrc];
      this.stats.videoJitter = vidStat.remote?.jitter * 1000;
      this.stats.videoPacketsLost = vidStat.remote?.packetsLost;
      this.stats.videoResolution = `${vidStat.frameWidth}x${vidStat.frameHeight}`;
      this.stats.videoFrameRate = vidStat.framesPerSecond;
      if (vidStat.qualityLimitationReason) this.stats.qualityLimitationReason = vidStat.qualityLimitationReason;
    });
    this.addNetworkStatsResult();

    this.subs.push(sub);
    this.subs.push(sub2);
  }

  addDeviceInfoResult(deviceInfo: DeviceInfo) {
    if (deviceInfo.browser === 'Firefox') {
      this.addResult(
        'Browser',
        'It is important to keep Firefox in focus when starting the recording!',
        'assets/icons/16px/exclamation-outline.svg',
        'warning'
      );
    } else if (
      deviceInfo.browser !== 'Chrome' &&
      deviceInfo.browser !== 'Safari' &&
      deviceInfo.browser !== 'Opera' &&
      deviceInfo.browser !== 'Edge'
    ) {
      this.addResult(
        'Browser',
        `Pelase be cautios when using ${deviceInfo.browser}. We recommend using Chrome, Safari, Opera, Firefox, or Edge.`,
        'assets/icons/16px/exclamation-outline.svg',
        'warning'
      );
    } else {
      this.addResult('Browser', 'You are using a supported browser!', 'assets/icons/16px/check.svg', 'success');
    }

    if (deviceInfo.deviceType !== 'desktop') {
      this.addResult(
        'Mobile Device',
        `Mobile devices have power saving features that can cause issues when recording. Make sure to keep your browser in focus at all times.`,
        'assets/icons/16px/exclamation-outline.svg',
        'warning'
      );
    } else {
      this.addResult(
        'Device',
        'Desktops and Laptops are more reliable! You are not using a mobile device!',
        'assets/icons/16px/check.svg',
        'success'
      );
    }
  }

  addTrackSettingsResult(trackSettings: TrackSettings) {
    let audioSettingsDescription = '';
    let videoSettingsDescription = '';

    if (trackSettings?.audio) {
      audioSettingsDescription += `Auto Gain Control ${trackSettings.audio.autoGainControl ? 'Enabled' : 'Disabled'}, `;
      audioSettingsDescription += `Echo Cancellation ${
        trackSettings.audio.echoCancellation ? 'Enabled' : 'Disabled'
      }, `;
      audioSettingsDescription += `Noise Suppression ${
        trackSettings.audio.noiseSuppression ? 'Enabled' : 'Disabled'
      }, `;
      audioSettingsDescription += `Sample Rate ${trackSettings.audio.sampleRate ?? 'Unknown'}, `;
      audioSettingsDescription += `Sample Size ${trackSettings.audio.sampleSize ?? 'Unknown'}, `;
      audioSettingsDescription += `Latency ${trackSettings.audio.latency ?? 'Unknown'}, `;

      audioSettingsDescription = audioSettingsDescription.substring(0, audioSettingsDescription.length - 2);
      this.addResult('Audio Settings', audioSettingsDescription, 'assets/icons/16px/check.svg', 'success');
    }
    if (trackSettings?.video) {
      videoSettingsDescription += `Frame Rate ${
        trackSettings.video.frameRate ? trackSettings.video.frameRate.toFixed(0) : 'Unknown'
      }, `;
      videoSettingsDescription += `Resolution ${
        trackSettings.video.width && trackSettings.video.height
          ? `${trackSettings.video.width}x${trackSettings.video.height}`
          : 'Unknown'
      }, `;
      videoSettingsDescription += `Aspect Ratio ${
        trackSettings.video.aspectRatio ? trackSettings.video.aspectRatio.toFixed(2) : 'Unknown'
      }, `;

      videoSettingsDescription = videoSettingsDescription.substring(0, videoSettingsDescription.length - 2);
      this.addResult('Video Settings', videoSettingsDescription, 'assets/icons/16px/check.svg', 'success');
    }

    if (!trackSettings?.audio && !trackSettings?.video) {
      this.addResult(
        'Equipment Settings',
        'We could not find your equipment configuration!',
        'assets/icons/16px/exclamation-outline.svg',
        'warning'
      );
    }
  }

  addWarningsResults(warnings: RecordingIssue[]) {
    if (warnings?.length) {
      warnings.forEach((warning) => {
        switch (warning) {
          case RecordingIssue.RECORDING_START_DELAYED:
            this.addResult(
              'Recording Start Delayed',
              'It took longer than expected to start the recording. Make sure your browser is in focus when starting the recording. If issue persists, please try using a different browser.',
              'assets/icons/24px/clock.svg',
              'warning'
            );
            break;
          case RecordingIssue.RECORDING_START_FAILED:
            this.addResult(
              'Recording Start Failed',
              'We were unable to start recording. Please check your browser permissions and restart your browser.',
              'assets/icons/32px/mic-off-bold.svg',
              'danger'
            );
            break;
          case RecordingIssue.FIRESTORE_CONNECTION_FAILED:
            this.addResult(
              'Database Connection Failed',
              'We were unable to connect to the database. Please check your network firewall settings. If issue persists, please try using a different network or VPN.',
              'assets/icons/16px/exclamation-outline.svg',
              'danger'
            );
            break;
          case RecordingIssue.RECORDER_ERRORED:
            this.addResult(
              'Recorder Error',
              'We encountered an error while recording. Please refresh your page and try again.',
              'assets/icons/16px/exclamation-outline.svg',
              'danger'
            );
            break;
          case RecordingIssue.FIRST_CHUNK_UPLOAD_DELAYED:
            this.addResult(
              "Recording Upload Couldn't Start",
              'It is taking longer than expected to start uploading files. Please check your network firewall settings. If issue persists, please try using a different network or VPN.',
              'assets/icons/16px/exclamation-outline.svg',
              'danger'
            );
            break;
          case RecordingIssue.CHUNK_UPLOAD_FAILED:
            this.addResult(
              'Upload Failed',
              'We were unable to upload a section of your recording. Please check your network settings and try again.',
              'assets/icons/16px/exclamation-outline.svg',
              'warning'
            );
            break;
          case RecordingIssue.GET_USER_MEDIA_FAILED:
            this.addResult(
              'Could Not Access Equipment',
              'We were unable to access your equipment. Please check your browser permissions and try again.',
              'assets/icons/32px/mic-off-bold.svg',
              'danger'
            );
            break;
          case RecordingIssue.GET_MEDIA_RECORDER_FAILED:
            this.addResult(
              'Could Not Create Recorder',
              'We were unable to create a recorder. Please try using a different browser or updating your browser.',
              'assets/icons/32px/mic-off-bold.svg',
              'danger'
            );
            break;
          case RecordingIssue.DATA_UPLOAD_FAILED:
            this.addResult(
              'Upload Failed',
              'We were unable to upload any data.  Please check your network firewall settings. If issue persists, please try using a different network or VPN.',
              'assets/icons/16px/exclamation-outline.svg',
              'danger'
            );
            break;
          case RecordingIssue.DATA_UPLOAD_SLOW:
            this.addResult(
              'Upload Slow',
              'It is taking longer than expected to upload your data. You might get a "stick around" message at the end of your recording. Please try connecting to a faster network if possible.',
              'assets/icons/16px/exclamation-outline.svg',
              'warning'
            );
            break;
          default:
            break;
        }
      });
    }
  }

  addNetworkStatsResult() {
    if (this.stats?.networkScore >= 4 && this.stats.ping < 300) {
      this.addResult(
        'Network Score',
        `Your network score is ${this.stats.networkScore}/5! You are using a good network!`,
        this.stats.networkScore === 5
          ? '/assets/icons/24px/wifi-very-strong.svg'
          : '/assets/icons/24px/wifi-strong.svg',
        'success'
      );
    } else if (this.stats?.networkScore >= 3) {
      this.addResult(
        'Network Score',
        `Your network score is ${this.stats.networkScore}/5! You are using an average network!`,
        '/assets/icons/24px/wifi-weak.svg',
        'warning'
      );
    } else if (this.stats?.networkScore >= 2) {
      this.addResult(
        'Network Score',
        `Your network score is ${this.stats.networkScore}/5! Your connection is weak! You might have to wait for the upload to finish after the recording is done.`,
        '/assets/icons/24px/wifi-very-weak.svg',
        'danger'
      );
    } else if (this.stats?.networkScore >= 1) {
      this.addResult(
        'Network Score',
        `Your network score is ${this.stats.networkScore}/5! Your connection is very weak! Try connecting to a different network.`,
        '/assets/icons/24px/wifi-error.svg',
        'danger'
      );
    } else {
      this.addResult(
        'Network Score',
        `Waiting for the network score...`,
        '/assets/icons/24px/wifi-error.svg',
        'warning'
      );
    }

    if (this.stats.audioJitter > 30 || this.stats.audioPacketsLost > 10) {
      this.addResult(
        'Network Audio Quality',
        `Your audio jitter is ${
          this.stats.audioJitter ? this.stats.audioJitter.toFixed(2) + 'ms' : 'unknown'
        } and you have ${this.stats.audioPacketsLost > 50 ? 'a moderate amount of' : 'some'} lost packets!`,
        '/assets/icons/24px/sound.svg',
        'warning'
      );
    } else {
      this.addResult(
        'Network Audio Quality',
        `You don't have significant audio jitter or lost packets!`,
        '/assets/icons/24px/sound.svg',
        'success'
      );
    }

    const trackSettings = {
      ...this.lastTestRecording?.recordingInfo?.stats?.audio,
      ...this.lastTestRecording?.recordingInfo?.stats?.video,
    }.trackSettings;
    if ((this.sessionType === 'video' && this.stats.videoJitter > 30) || this.stats.videoPacketsLost > 10) {
      this.addResult(
        'Network Video Quality',
        `Your video jitter is ${
          this.stats.videoJitter ? this.stats.videoJitter.toFixed(2) + 'ms' : 'unknown'
        } and you have ${this.stats.videoPacketsLost > 50 ? 'a moderate amount of' : 'some'} lost packets!
        Your conference video resolution is ${
          this.stats.videoResolution ?? 'unknown'
        } but your SquadCast recording video resolution is ${
          trackSettings?.video?.width && trackSettings?.video?.height
            ? `${trackSettings.video.width}x${trackSettings.video.height}`
            : 'unknown'
        }!
        `,
        'assets/icons/24px/camera.svg',
        'warning'
      );
    } else if (this.sessionType === 'video') {
      this.addResult(
        'Network Video Quality',
        `You don't have significant video jitter or lost packets. Your conference video resolution is ${
          this.stats.videoResolution ?? 'unknown'
        } but your SquadCast recording video resolution is ${
          trackSettings?.video?.width && trackSettings?.video?.height
            ? `${trackSettings.video.width}x${trackSettings.video.height}`
            : 'unknown'
        }!`,
        'assets/icons/24px/camera.svg',
        'success'
      );
    }

    if (this.stats.qualityLimitationReason && this.stats.qualityLimitationReason !== 'none') {
      this.addResult(
        'Conference Quality',
        `Your conference quality is limited because: ${this.stats.qualityLimitationReason}. This will not affect local recording quality.`,
        'assets/icons/24px/magic-2.svg',
        'warning'
      );
    } else {
      this.addResult(
        'Conference Quality',
        `Your conference quality is not limited!`,
        'assets/icons/24px/magic-2.svg',
        'success'
      );
    }
  }

  addResult(title: string, description: string, icon: string, state: 'success' | 'warning' | 'danger') {
    const newResults = this.resultsList.filter((result) => result.title !== title);
    newResults.push({ title, description, icon, state });
    this.resultsList = newResults.sort((a, b) => {
      if (a.state === 'success' && b.state !== 'success') return 1;
      else if (a.state !== 'success' && b.state === 'success') return -1;
      else if (a.state === 'warning' && b.state === 'danger') return 1;
      else if (a.state === 'danger' && b.state === 'warning') return -1;
      else return a.title.localeCompare(b.title);
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
