import { Injectable } from '@angular/core';
import { Timezone } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class CalendarLinksService {
  constructor() {}

  getLink(
    platform: 'google' | 'apple' | 'outlook',
    config: {
      title: string;
      description: string;
      email: string;
      start: string;
      end: string;
      timezone: Timezone;
      link: string;
    }
  ) {
    const event =
      `?f=m&e[0][date_start]=${config.start}&e[0]` +
      `[date_end]=${config.end}&e[0]` +
      `[location]=${encodeURIComponent(config.link)}&e[0]` +
      `[timezone]=${config.timezone.tzid}&e[0]` +
      `[organizer]=SquadCast.fm&e[0]` +
      `[organizer_email]=${config.email}&e[0]` +
      `[privacy]=private&e[0]` +
      `[title]=${config.title}&e[0]` +
      `[description]=👇👇👇 Click this link to Join` +
      `%0A${encodeURIComponent(config.link)}`;

    switch (platform) {
      case 'google':
        return encodeURI(`https://addtocalendar.com/atc/google${event}`);

      case 'apple':
        return encodeURI(`https://addtocalendar.com/atc/appleical${event}`);

      case 'outlook':
        return encodeURI(`https://addtocalendar.com/atc/outlook${event}`);

      default:
        break;
    }
  }
}
