<h5>Mix Audio with Auphonic</h5>
<ion-list>
  <ion-item aria-label="individual">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Individual </strong>

          <p>Settings applied to individual tracks before they are mixed.</p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button
              class="expand-button"
              (click)="toggleExpandedIndividual()"
              aria-label="Expand section"
              color="lighter">
              <ion-icon
                src="/assets/icons/24px/chevron-down.svg"
                *ngIf="!expandedIndividual; else upIcon"
                slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedIndividual" id="expand">
        <ion-card>
          <ion-list>
            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Hip Filter </strong>

                <p>
                  Filters unnecessary and disturbing low frequencies depending on the context (speech, music, noise).
                </p>
              </ion-label>
              <ion-toggle
                [checked]="currentMixSettings.individual.hipfilter"
                (ionChange)="toggleIndividualHipFilter($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>
            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> DeNoise </strong>

                <p>Automatic noise reduction in current track.</p>
              </ion-label>
              <ion-toggle
                [checked]="currentMixSettings.individual.denoise"
                (ionChange)="toggleIndividualDeNoise($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>

  <ion-item aria-label="master" lines="none">
    <ion-col>
      <ion-row class="enhance-category">
        <ion-label color="lightest" class="ion-text-wrap">
          <strong> Master </strong>

          <p>Settings applied the the master track after mixing individual tracks together.</p>
        </ion-label>

        <ion-col size-xs="1" size-sm="1">
          <ion-buttons class="expand-section">
            <ion-button
              class="expand-button"
              (click)="toggleExpandedMaster()"
              aria-label="Expand section"
              color="lighter">
              <ion-icon src="/assets/icons/24px/chevron-down.svg" *ngIf="!expandedMaster; else upIcon" slot="icon-only">
              </ion-icon>

              <ng-template #upIcon>
                <ion-icon src="/assets/icons/24px/chevron-up.svg" slot="icon-only"> </ion-icon>
              </ng-template>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>

      <div *ngIf="expandedMaster" id="expand">
        <ion-card>
          <ion-list>
            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Loudness Target </strong>

                <p>Loudness target in LUFS for Loudness Normalization, higher values result in louder audio outputs.</p>
              </ion-label>
              <ion-input
                [(ngModel)]="currentMixSettings.master.loudnesstarget"
                (ionBlur)="setMasterLoudnessTarget($event)"
                placeholder="{{ currentMixSettings.master.loudnesstarget }}"
                min="-31"
                max="-8"
                type="number"
                color="primary"
                mode="ios">
              </ion-input>
            </ion-item>

            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Leveler </strong>

                <p>The Multitrack Adaptive Leveler corrects level differences between and within tracks.</p>
              </ion-label>
              <ion-toggle
                [checked]="currentMixSettings.master.leveler"
                (ionChange)="toggleMasterLeveler($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>

            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Gate </strong>

                <p>The Adaptive Noise Gate decreases background noise if a speaker is not active.</p>
              </ion-label>
              <ion-toggle
                [checked]="currentMixSettings.master.gate"
                (ionChange)="toggleMasterGate($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>

            <ion-item>
              <ion-label color="lightest" class="ion-text-wrap">
                <strong> Crossgate </strong>

                <p>
                  Analyzes which speaker is active and decreases crosstalk/spill, ambience, reverb and noise recorded
                  from other tracks.
                </p>
              </ion-label>
              <ion-toggle
                [checked]="currentMixSettings.master.crossgate"
                (ionChange)="toggleMasterCrossgate($event)"
                color="primary"
                mode="ios"
                slot="end">
              </ion-toggle>
            </ion-item>
          </ion-list>
        </ion-card>
      </div>
    </ion-col>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col size-xs="12" size-sm="12" offset-md="1" size-md="10" offset-lg="2" size-lg="8" offset-xl="3" size-xl="6">
    <ion-button
      id="reset-button"
      oncontextmenu="false"
      aria-label="Reset to Defaults"
      class="primary"
      expand="block"
      (click)="resetToDefaultMixingSettings()">
      Reset to System Defaults
    </ion-button>
  </ion-col>
</ion-row>
