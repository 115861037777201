import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Subject, Subscription, throttleTime } from 'rxjs';

// Inspired by https://stackoverflow.com/questions/51390476/how-to-prevent-double-click-in-angular

@Directive({
  selector: '[scThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input()
  throttleTime = 500;

  @Output()
  throttledClick = new EventEmitter();

  private clicks$ = new Subject();
  private sub: Subscription;

  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }

  ngOnInit() {
    this.sub = this.clicks$.pipe(throttleTime(this.throttleTime)).subscribe((e) => this.emitThrottledClick(e));
  }

  emitThrottledClick(e) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
