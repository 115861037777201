export enum RecordingTags {
  SCREEN = 700,
  MASTERED = 500,
  MIXED = 300,
  FAILED = 100,
  MIGRATED = 0,
}

export const RecordingTagDisplayNames = {
  [RecordingTags.SCREEN]: 'Screen Recording',
  [RecordingTags.MASTERED]: 'Dolby Enhanced',
  [RecordingTags.MIXED]: 'Mixed',
  [RecordingTags.FAILED]: 'Failed',
  [RecordingTags.MIGRATED]: 'Migrated',
};

export const RecordingTagColors = {
  [RecordingTags.SCREEN]: 'accent-1',
  [RecordingTags.MASTERED]: 'accent-10',
  [RecordingTags.MIXED]: 'accent-orange-100',
  [RecordingTags.FAILED]: 'accent-8',
  [RecordingTags.MIGRATED]: 'accent-2',
};

export enum StatsType {
  AUDIO = 'audio',
  VIDEO = 'video',
  SCREEN = 'screen',
}
