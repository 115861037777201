import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Subscription } from 'rxjs';

import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { PaymentInfoComponent } from '../../components/payment-info/payment-info.component';
import { WalletService } from '../../services/wallet/wallet.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { AmplionService } from '../../services/amplion/amplion.service';

@Component({
  selector: 'sc-update-payment-info',
  templateUrl: 'update-payment-info.page.html',
  styleUrls: ['update-payment-info.page.scss'],
})
export class UpdatePaymentInfoPage implements OnInit, OnDestroy {
  @ViewChild('paymentInfo') paymentInfo: PaymentInfoComponent;
  saving = false;
  subs: Array<Subscription> = [];

  constructor(
    private amplionService: AmplionService,
    private lafayetteService: LafayetteService,
    private modalController: ModalController,
    private toastrService: ToastrService,
    private walletService: WalletService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {}

  async save() {
    this.saving = true;
    if (this.paymentInfo.stripeCard) {
      await this.paymentInfo.save();
      const success = await firstValueFrom(this.paymentInfo.saveSuccess$);

      if (success) {
        const card = this.walletService.customer$.value.sources.data[0];
        this.toastrService.success(`••••  ••••  ••••  ${card.last4}`, `Successfully updated your Payment Info`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });
        await this.analyticsService.track('saved payment info', { last4: card.last4 });
      }
    } else {
      await this.paymentInfo.saveData();
      this.toastrService.success(``, `Successfully updated your Billing Info`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('saved billing info', {
        orgName: this.paymentInfo.orgName,
        billingEmail: this.paymentInfo.billingEmail,
      });
    }
    this.close();
  }

  async close() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed payment info');
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
