<ion-row>
  <ion-col>
    <h2 class="bold ion-text-center">Forgot Your Password?</h2>

    <h6 class="medium ion-text-center">
      Enter your email address and we will send you instructions to reset your password.
    </h6>
  </ion-col>
</ion-row>

<ion-row class="modal-content">
  <ion-col>
    <form (ngSubmit)="sendPasswordResetEmail()">
      <ion-row>
        <ion-col>
          <ion-item class="input-large" lines="none">
            <ion-label position="stacked"> Email Address </ion-label>

            <ion-input [(ngModel)]="email" type="email" name="email" placeholder="Enter your email" required>
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row class="ion-text-center">
        <ion-col size="6">
          <ion-button (click)="close()" color="light" shape="round"> Cancel </ion-button>
        </ion-col>

        <ion-col size="6">
          <ion-button color="primary" type="submit" shape="round"> Continue </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-col>
</ion-row>
