import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { NotificationsService } from '../../services/notifications/notifications.service';
import { SCNotification } from '@sc/types';

@Component({
  selector: 'sc-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {
  @Input() notificationPreview: SCNotification = null;

  notifications: Array<SCNotification>;

  subs: Array<Subscription> = [];

  constructor(private popoverController: PopoverController, private notificationsService: NotificationsService) {}

  ngOnInit() {
    this.setupNotifications();
  }

  read(notification: SCNotification) {
    if (notification.link) window.open(notification.link, '_blank');
    this.notificationsService.setRead(notification.id);
    this.popoverController.dismiss();
  }

  clearAll() {
    this.notifications.forEach((notification) => {
      this.notificationsService.setRead(notification.id);
    });
  }

  setupNotifications() {
    if (this.notificationPreview) {
      this.notifications = [this.notificationPreview];
      return;
    }

    const sub = this.notificationsService.getNotifications('event').subscribe((notifications) => {
      if (!notifications) {
        this.notifications = [];
        return;
      }

      const read = this.notificationsService.readNotifications$.value;

      this.notifications = notifications.map((notification) => {
        if (read.find((v) => v.id == notification.id)) {
          notification.read = true;
        }
        return notification;
      });

      this.notifications.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return +bDate - +aDate;
      });
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.clearAll();
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
