<ion-item class="input-large input-date" lines="none">
  <ion-input
    [(ngModel)]="displayDate"
    name="displayDate"
    #dateCtrl="ngModel"
    inputmode="numeric"
    debounce="100"
    (ionBlur)="dateInputUpdated($event)"
    [placeholder]="type === 'date' ? 'MM/DD/YY' : type === 'time' ? 'HH:MM' : 'MM/DD/YYY HH:MM'"
    required="{{ required }}">
  </ion-input>
  <ion-popover show-backdrop="false" [side]="popoverSide" class="date-popover" #datePopover>
    <ng-template>
      <ion-datetime
        #popoverDatetime
        class="popover-datetime"
        [value]="selectorDate"
        [presentation]="type === 'date' ? 'date' : type === 'time' ? 'time' : 'date-time'"
        [hourCycle]="format === 'HH:mm' ? 'h23' : format === 'h:mm A' ? 'h12' : null"
        [min]="minDateISO"
        [max]="maxDateISO"
        [minuteValues]="timePickerMinutes"
        (ionChange)="selectedDate($event)"></ion-datetime>
    </ng-template>
  </ion-popover>
  <ion-buttons slot="end">
    <ion-button
      *ngIf="type === 'time'"
      aria-label="Open Time Picker"
      fill="clear"
      (click)="openDatePicker($event)"
      tooltip="Open Time Picker">
      <ion-icon src="assets/icons/24px/clock.svg" slot="icon-only"></ion-icon>
    </ion-button>

    <ion-button
      *ngIf="type !== 'time'"
      aria-label="Open Calendar"
      fill="clear"
      (click)="openDatePicker($event)"
      tooltip="Open Calendar">
      <ion-icon icon="calendar" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-item>
<div class="validation-error" *ngIf="dateCtrl.invalid">
  <p *ngIf="dateCtrl.errors?.['required']">Date is required</p>
</div>
