<ion-row>
  <ion-col>
    <h1 class="ion-text-center">Update Password</h1>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="10" offset="1">
    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Email </ion-label>

      <ion-input [(ngModel)]="email" name="current" required="true" type="email" readonly> </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Current Password </ion-label>

      <ion-input
        [(ngModel)]="currentPassword"
        #current="ngModel"
        name="current"
        required="true"
        type="password"
        minlength="6">
      </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> New Password </ion-label>

      <ion-input [(ngModel)]="newPassword" #new="ngModel" name="new" required="true" type="password" minlength="6">
      </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Confirm New Password </ion-label>

      <ion-input
        [(ngModel)]="confirmNewPassword"
        #confirm="ngModel"
        (keyup.enter)="savePassword()"
        pattern="{{ newPassword }}"
        name="confirm"
        required="true"
        minlength="6"
        type="password">
      </ion-input>
    </ion-item>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="close()" class="grey"> Cancel </ion-button>
  <ion-button (click)="savePassword()" [disabled]="!current.valid || !new.valid || !confirm.valid" class="primary">
    Confirm
  </ion-button>
</div>
