import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { SquadCastComponentsModule } from '../../components/squadcast.components.module';
import { ActionConfirmPage } from './action-confirm.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: ActionConfirmPage,
      },
    ]),
    SquadCastComponentsModule,
  ],
  declarations: [ActionConfirmPage],
})
export class ActionConfirmModule {}
