import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '../../services/settings/settings.service';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '@sc/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { EnhanceSettings } from '@sc/types';
import { Show } from '@sc/types';
import { ShowsService } from '../../services/shows/shows.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sc-dolby-settings',
  templateUrl: './dolby-settings.component.html',
  styleUrls: ['./dolby-settings.component.scss'],
})
export class DolbySettingsComponent implements OnInit, OnDestroy {
  @Input() color = 'medium';

  user$ = this.userService.activeUser$;
  enhancedSettings$ = this.settingsService.dashboardShowEnhanceSettings$;

  currentEnhanceSettings: EnhanceSettings;

  level: any;
  user: UserModel;
  show: Show;
  expandedLoudness = false;
  expandedDynamics = false;
  expandedNoise = false;
  expandedFilter = false;
  expandedNoiseReduction = false;
  expandedFilterDynamicEQ = false;
  expandedFilterHighPass = false;
  expandedFilterHum = false;
  expandedSpeech = false;
  expandedSpeechIsolation = false;
  expandedSpeechSibilance = false;
  expandedSpeechSibilanceReduction = false;
  expandedSpeechPlosive = false;
  expandedSpeechPlosiveReduction = false;
  expandedSpeechClick = false;
  expandedSpeechClickReduction = false;
  expandedMusic = false;
  expandedMusicDetection = false;
  rangeControlLevels: Array<string> = ['low', 'medium', 'high', 'max'];
  expandedDynamicsRangeControl = false;
  loading = true;
  selectEnhanceOptions: any = { cssClass: 'select-enhance' };
  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private userService: UserService,
    public walletService: WalletService,
    public modalController: ModalController
  ) {}

  async ngOnInit() {
    this.setupUser();
    this.setupEnhanceSettings();
    this.setupDashboardShow();
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe(async (user) => {
      this.user = user;
      this.loading = false;
    });
    this.subs.push(sub);
  }

  setupEnhanceSettings() {
    const sub = this.enhancedSettings$.subscribe((enhanceSettings) => {
      this.currentEnhanceSettings = enhanceSettings;
    });
    this.subs.push(sub);
  }

  setupDashboardShow() {
    const sub = this.showsService.dashboardShow$.subscribe((show) => {
      this.show = show;
    });

    this.subs.push(sub);
  }

  //DOLBY
  async toggleExpandedLoudness() {
    this.expandedLoudness = !this.expandedLoudness;
  }

  async toggleEnableLoudness(event: ICustomEvent) {
    this.currentEnhanceSettings.loudness.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: loudness`, { enabled: event.detail.checked });
  }

  async setLoudnessTargetLevel(event) {
    if (this.currentEnhanceSettings.loudness.target_level > -13) {
      this.currentEnhanceSettings.loudness.target_level = -13;
    } else if (this.currentEnhanceSettings.loudness.target_level < -20) {
      this.currentEnhanceSettings.loudness.target_level = -20;
    }
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: loudness - target level`, {
      targetLevel: this.currentEnhanceSettings.loudness.target_level,
    });
  }

  async setLoudnessSpeechThreshold(event) {
    if (this.currentEnhanceSettings.loudness.speech_threshold > 100) {
      this.currentEnhanceSettings.loudness.speech_threshold = 100;
    } else if (this.currentEnhanceSettings.loudness.speech_threshold <= 0) {
      // Needs to be <=0 to match null
      this.currentEnhanceSettings.loudness.speech_threshold = 0;
    }

    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: loudness - speech threshold`, {
      speechThreshold: this.currentEnhanceSettings.loudness.speech_threshold,
    });
  }

  toggleExpandedDynamics() {
    this.expandedDynamics = !this.expandedDynamics;
  }

  toggleExpandedDynamicsRangeControl() {
    this.expandedDynamicsRangeControl = !this.expandedDynamicsRangeControl;
  }

  async toggleEnableDynamicsRangeControl(event: ICustomEvent) {
    this.currentEnhanceSettings.dynamics.range_control.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: dynamics - range control`, {
      enabled: event.detail.checked,
    });
  }

  async setDynamicsRangeControlAmount(event: 'low' | 'medium' | 'high' | 'max') {
    this.currentEnhanceSettings.dynamics.range_control.amount = event;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: dynamics - range control amount`, {
      amount: event,
    });
  }

  toggleExpandedNoise() {
    this.expandedNoise = !this.expandedNoise;
  }

  toggleExpandedNoiseReduction() {
    this.expandedNoiseReduction = !this.expandedNoiseReduction;
  }

  async toggleEnableNoiseReduction(event: ICustomEvent) {
    this.currentEnhanceSettings.noise.reduction.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: noise - reduction`, {
      enabled: event.detail.checked,
    });
  }

  async setNoiseReductionAmount(event: 'low' | 'medium' | 'high' | 'max' | 'auto') {
    this.currentEnhanceSettings.noise.reduction.amount = event;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: noise - reduction amount`, {
      amount: event,
    });
  }

  toggleExpandedFilter() {
    this.expandedFilter = !this.expandedFilter;
  }

  toggleExpandedFilterDynamicEQ() {
    this.expandedFilterDynamicEQ = !this.expandedFilterDynamicEQ;
  }

  async toggleEnableFilterDynamicEQ(event: ICustomEvent) {
    this.currentEnhanceSettings.filter.dynamic_eq.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: filter - dynamic eq`, {
      enabled: event.detail.checked,
    });
  }

  async setFilterHighPassFrequency(event) {
    if (this.currentEnhanceSettings.filter.high_pass.frequency > 120) {
      this.currentEnhanceSettings.filter.high_pass.frequency = 120;
    } else if (this.currentEnhanceSettings.filter.high_pass.frequency < 50) {
      this.currentEnhanceSettings.filter.high_pass.frequency = 50;
    }

    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: filter - high pass frequency`, {
      frequency: this.currentEnhanceSettings.filter.high_pass.frequency,
    });
  }

  toggleExpandedFilterHighPass() {
    this.expandedFilterHighPass = !this.expandedFilterHighPass;
  }

  async toggleEnableFilterHighPass(event: ICustomEvent) {
    this.currentEnhanceSettings.filter.high_pass.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: filter - high pass`, {
      enabled: event.detail.checked,
    });
  }

  toggleExpandedFilterHum() {
    this.expandedFilterHum = !this.expandedFilterHum;
  }

  async toggleEnableFilterHum(event: ICustomEvent) {
    this.currentEnhanceSettings.filter.hum.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: filter - hum`, {
      enabled: event.detail.checked,
    });
  }

  toggleExpandedSpeech() {
    this.expandedSpeech = !this.expandedSpeech;
  }

  toggleExpandedSpeechIsolation() {
    this.expandedSpeechIsolation = !this.expandedSpeechIsolation;
  }

  async toggleEnableSpeechIsolation(event: ICustomEvent) {
    this.currentEnhanceSettings.speech.isolation.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - isolation`, {
      enabled: event.detail.checked,
    });
  }

  toggleExpandedSpeechSibilance() {
    this.expandedSpeechSibilance = !this.expandedSpeechSibilance;
  }

  toggleExpandedSpeechSibilanceReduction() {
    this.expandedSpeechSibilanceReduction = !this.expandedSpeechSibilanceReduction;
  }

  async toggleEnableSpeechSibilanceReduction(event: ICustomEvent) {
    this.currentEnhanceSettings.speech.sibilance.reduction.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - sibilance reduction`, {
      enabled: event.detail.checked,
    });
  }

  async setSpeechSibilanceReductionAmount(event: 'low' | 'medium' | 'high' | 'max') {
    this.currentEnhanceSettings.speech.sibilance.reduction.amount = event;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - sibilance reduction amount`, {
      amount: event,
    });
  }

  toggleExpandedSpeechPlosive() {
    this.expandedSpeechPlosive = !this.expandedSpeechPlosive;
  }

  toggleExpandedSpeechPlosiveReduction() {
    this.expandedSpeechPlosiveReduction = !this.expandedSpeechPlosiveReduction;
  }

  async toggleEnableSpeechPlosiveReduction(event: ICustomEvent) {
    this.currentEnhanceSettings.speech.plosive.reduction.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - plosive reduction`, {
      enabled: event.detail.checked,
    });
  }

  async setSpeechPlosiveReductionAmount(event: 'low' | 'medium' | 'high' | 'max') {
    this.currentEnhanceSettings.speech.plosive.reduction.amount = event;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - plosive reduction amount`, {
      amount: event,
    });
  }

  toggleExpandedSpeechClick() {
    this.expandedSpeechClick = !this.expandedSpeechClick;
  }

  toggleExpandedSpeechClickReduction() {
    this.expandedSpeechClickReduction = !this.expandedSpeechClickReduction;
  }

  async toggleEnableSpeechClickReduction(event: ICustomEvent) {
    this.currentEnhanceSettings.speech.click.reduction.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - click reduction`, {
      enabled: event.detail.checked,
    });
  }

  async setSpeechClickReductionAmount(event: 'low' | 'medium' | 'high' | 'max') {
    this.currentEnhanceSettings.speech.click.reduction.amount = event;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: speech - click reduction amount`, {
      amount: event,
    });
  }

  toggleExpandedMusic() {
    this.expandedMusic = !this.expandedMusic;
  }

  toggleExpandedMusicDetection() {
    this.expandedMusicDetection = !this.expandedMusicDetection;
  }

  async toggleEnableMusicDetection(event: ICustomEvent) {
    this.currentEnhanceSettings.music.detection.enable = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: music - detection`, {
      enabled: event.detail.checked,
    });
  }

  async toggleDialogIntelligence(event: ICustomEvent) {
    this.currentEnhanceSettings.loudness.dialog_intelligence = event.detail.checked;
    await this.settingsService.setShowEnhanceSettings(this.currentEnhanceSettings, this.show.showID);
    await this.analyticsService.track(`toggled dolby mastering setting: loudness - dialog intelligence`, {
      enabled: event.detail.checked,
    });
  }

  async resetToDefaultMasteringSettings() {
    const audioSettings: EnhanceSettings = {
      loudness: {
        enable: true,
        target_level: -16,
        dialog_intelligence: true,
        speech_threshold: 15,
        peak_limit: -1,
        peak_reference: 'true_peak',
      },
      dynamics: {
        range_control: {
          enable: true,
          amount: 'medium',
        },
      },
      noise: {
        reduction: {
          enable: true,
          amount: 'medium',
        },
      },
      filter: {
        high_pass: {
          enable: true,
          frequency: 80,
        },
        dynamic_eq: {
          enable: true,
        },
        hum: {
          enable: true,
        },
      },
      speech: {
        isolation: {
          enable: true,
          amount: 0,
        },
        sibilance: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
        plosive: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
        click: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
      },
      music: {
        detection: {
          enable: false,
        },
      },
    };

    await this.settingsService.setShowEnhanceSettings(audioSettings, this.show.showID);
    this.currentEnhanceSettings = audioSettings;

    await this.analyticsService.track(`reset master with dolby setting to default`);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
