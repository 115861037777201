import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'sc-action-confirm',
  templateUrl: './action-confirm.page.html',
  styleUrls: ['./action-confirm.page.scss'],
})
export class ActionConfirmPage implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() img: string;
  @Input() icon: string;
  @Input() iconSrc: string;
  @Input() buttons: { label: string; handler: () => void; type?: string }[];
  @Input() enableHTML = false;

  constructor() {}

  ngOnInit() {}
}
