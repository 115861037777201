<div
  class="sales-info"
  [style.backgroundImage]="'url(' + sale.style.backgroundPattern + ')'"
  [style.backgroundSize]="'cover'">
  <ion-row>
    <ion-col>
      <h4 class="bold ion-text-center" [style.color]="sale.style.textColor">{{ sale.title }}</h4>
      <h3 class="bold ion-text-center" [style.color]="sale.style.textColor">Thank you for redeeming this sale!</h3>
      <p class="regular ion-text-center" style="color: white">
        You can find more information in your E-Mail! This sale includes the following:
      </p>
      <ion-list>
        <ion-item
          *ngFor="let saleDetail of sale.saleDetails; trackBy: trackSaleDetailsBy; last as last"
          [lines]="'none'"
          class="condensed">
          <ion-avatar slot="start">
            <ion-img src="{{ saleDetail.saleDetailIcon }}"></ion-img>
          </ion-avatar>

          <ion-label class="ion-text-wrap" [innerHtml]="saleDetail.saleDetailText"> <br /> </ion-label>
        </ion-item>
      </ion-list>
      <ion-row id="partners">
        <img src="/assets/images/Holiday_Partners_SquadCast-White.png" alt="SquadCast logo" />
        <img src="/assets/images/Holiday_Partners_Cavna-White.png" alt="Canva logo" />
        <img src="/assets/images/Holiday_Partners_Headliner-White.png" alt="Headliner logo" />
        <img src="/assets/images/Holiday_Partners_Alitu-White.png" alt="Alitu logo" />
        <img src="/assets/images/Holiday_Partners_Transistor-White.png" alt="Transistor logo" />
      </ion-row>
      <p class="ion-text-wrap micro-regular ion-text-center" color="lightest">
        **Limited number of full packages available while supplies last. <br />
        Coupons will be emailed within 48 hours of purchase.**
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="buttons">
      <ion-button (click)="cancel()" expand="block" class="grey"> Cancel </ion-button>
      <ion-button
        (click)="cancel()"
        expand="block"
        style="--background: {{ sale.style.buttonColor }} !important"
        [style.color]="sale.style.buttonTextColor">
        <ion-img src="assets/images/Icon-HolidayTag.png"></ion-img>
        Confirm
      </ion-button>
    </ion-col>
  </ion-row>
</div>
