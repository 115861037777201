<div
  class="vu-meeter-container"
  [tooltip]="dBFSVisible ? 'Audio Decibels' : 'VU Meter'"
  (click)="dBFSVisible = !dBFSVisible">
  <ion-row *ngIf="!dBFSVisible">
    <div [class.red]="meeterLevel >= 8" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.red]="meeterLevel >= 7" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.yellow]="meeterLevel >= 6" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.yellow]="meeterLevel >= 5" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.yellow]="meeterLevel >= 4" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.green]="meeterLevel >= 3" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.green]="meeterLevel >= 2" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="!dBFSVisible">
    <div [class.green]="meeterLevel >= 1" class="vu-indicator"></div>
  </ion-row>

  <ion-row *ngIf="dBFSVisible" class="decibels">
    <ion-badge color="medium"> {{ dBFSDisplayValue }} dBFS </ion-badge>
  </ion-row>
</div>
