export interface Chat {
  datetime: number;
  message: string;
  name: string;
  uid: string;
  id?: string;
  icon?: string;
  type?: string;
}

import { Observable } from 'rxjs';

export interface ChatGroup {
  name?: string;
  chatGroupID?: string;
  color?: string;
  messages?: Array<ChatMessage>;
  users?: Array<string>;
  unread?: boolean;
}

export interface ChatMessage {
  chatMessageID?: string;
  uid: string;
  name: string;
  role: number;
  datetime: string;
  isToday?: boolean;
  message: string;
  color$?: Observable<string>;
  reactions$?: Observable<ChatReaction[]>;
  reactionsVisible?: boolean;
}

export interface ChatReaction {
  chatReactionID?: string;
  emoji: string;
  users: Array<string>;
}

export enum DefaultChatGroups {
  EVERYONE = 'everyone',
  STAGE = 'the stage',
  BACKSTAGE = 'backstage',
}
