import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  doc,
  docData,
  query,
  setDoc,
  where,
  DocumentReference,
  collectionSnapshots,
} from '@angular/fire/firestore';
import { switchMap, of, firstValueFrom, first } from 'rxjs';

import { Organization, OrgMember, OrgNotification } from '@sc/types';
import { StorageService } from '../storage/storage.service';
import { UserModel } from '@sc/types';
import { UserService } from '../user/user.service';
import { Roles } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { SupportCenterService } from '../support-center/support-center.service';
import { LafayetteService } from '../lafayette/lafayette.service';
import { ZapierService } from '../zapier/zapier.service';
import { ProviderInfo } from '@sc/types';
import { AmplionService } from '../amplion/amplion.service';
import { ActiveCampaignService } from '../active-campaign/active-campaign.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  public dashboardOrg$ = new SCSubject<Organization>();
  public dashboardOrgID$ = new SCSubject<string>();
  public availableOrgs$ = new SCSubject<Organization[]>();
  public orgNotifications$ = new SCSubject<OrgNotification[]>();
  public orgRole$ = new SCSubject<Roles>();
  public searchKey$ = new SCSubject<string>();
  public missingOrg$ = new SCSubject<boolean>(false);
  public lastOrgWithPlan$ = new SCSubject<Organization>();

  public DEFAULT_IMG =
    'https://storage.googleapis.com/squadcast-7fa10.appspot.com/squadcast-media/SC_V5_Org-Default.png';

  private user$ = this.userService.activeUser$;
  private authUser$ = this.userService.authUser$;
  private orgCol = collection(this.firestore, 'organizations') as CollectionReference<Organization>;
  private providerCol = collection(this.firestore, 'idproviders') as CollectionReference<ProviderInfo>;

  constructor(
    private firestore: Firestore,
    private storageService: StorageService,
    private router: Router,
    private userService: UserService,
    private lafayetteService: LafayetteService,
    private supportCenterService: SupportCenterService,
    private titleService: Title,
    private zapierService: ZapierService,
    private amplionService: AmplionService,
    private activeCampaignService: ActiveCampaignService
  ) {
    this.asyncInit();
  }

  async asyncInit() {
    this.checkClaims();
    this.setupDashboardOrg();
    this.setupOrgRole();
    this.setupOrgNotifications();
    this.setupAvailableOrgs();
    this.setupSearchKey();
  }

  async checkClaims() {
    const creds = await this.authUser$.toPromise();
    if (!creds) return;
    const decodedToken = await creds.getIdTokenResult();
    const orgClaims = decodedToken.claims.organizations as string[];
    if (orgClaims?.find((org) => org.length === 1)) {
      this.lafayetteService.fixUser(creds.uid);
    }
  }

  setupDashboardOrg(): void {
    this.dashboardOrgID$
      .pipe(
        switchMap((orgID) => {
          if (!orgID) return of(null);
          return this.getOrg(orgID);
        })
      )
      .subscribe((org: Organization) => {
        if (!org) this.missingOrg$.next(true);
        else {
          this.missingOrg$.next(false);
          if (!org.orgLogo) org.orgLogo = this.DEFAULT_IMG;
          this.dashboardOrg$.next(org);
          this.supportCenterService.supportData.orgID = org.orgID;
          if (org.customerID) this.supportCenterService.supportData.customerID = org.customerID;
          this.titleService.setTitle(`${org.orgName} - SquadCast`);
          if (org.planID) this.lastOrgWithPlan$.next(org);
        }
      });
  }

  setupOrgRole(): void {
    this.dashboardOrg$
      .pipe(
        switchMap((org: Organization) => {
          if (this.user$.value.guest) {
            return of(null);
          }
          return this.getOrgMembers(org.orgID);
        })
      )
      .subscribe((team: OrgMember[]) => {
        if (team && team.length) {
          let role = team.find((member) => member.uid === this.user$.value.uid)?.role;

          // If we are at this stage and the member is not found, the org is no longer available to them.
          if (!role) this.setDashboardOrgID(null);

          this.orgRole$.next(role);
        }
      });
  }

  setupAvailableOrgs() {
    this.user$
      .pipe(
        switchMap((user: UserModel) => {
          const dashboardOrg = user.activeOrg || user.organizations[0];
          if (!this.dashboardOrgID$.value && dashboardOrg) this.setDashboardOrgID(dashboardOrg);
          return this.getOrgs(user.uid);
        })
      )
      .subscribe((orgs: Organization[]) => {
        if (!orgs?.length) orgs = [];

        orgs = orgs.map((org) => {
          if (!org.orgLogo) org.orgLogo = this.DEFAULT_IMG;
          return org;
        });

        this.availableOrgs$.next(orgs);
        const orgWithPlan = orgs.find((org) => org.planID);
        if (orgWithPlan && !this.lastOrgWithPlan$.value) this.lastOrgWithPlan$.next(orgWithPlan);
      });
  }

  setupSearchKey() {
    this.dashboardOrgID$.subscribe(async (orgID) => {
      if (!this.user$.value?.searchKeys) return;
      const key = this.user$.value.searchKeys[orgID];
      if (key) this.searchKey$.next(key);
    });
  }

  setupOrgNotifications() {
    this.dashboardOrg$
      .pipe(
        switchMap((org: Organization) => {
          return this.getOrgNotifications(org.orgID);
        })
      )
      .subscribe((notifications: OrgNotification[]) => {
        this.orgNotifications$.next(notifications);
      });
  }

  getOrg(orgID: string) {
    return docData(doc(this.orgCol, orgID), { idField: 'orgID' });
  }

  getOrgs(userID: string) {
    return collectionData(query(this.orgCol, where('memberIDs', 'array-contains', userID)), { idField: 'orgID' });
  }

  getOrgMembers(orgID: string) {
    const membersRef = collection(this.orgCol, orgID, 'orgMembers') as CollectionReference<OrgMember>;
    return collectionData(membersRef, { idField: 'uid' });
  }

  getOrgMember(orgID: string, userID: string) {
    const membersRef = collection(this.orgCol, orgID, 'orgMembers') as CollectionReference<OrgMember>;
    return docData(doc(membersRef, userID), { idField: 'uid' });
  }

  getOrgNotifications(orgID: string) {
    const orgNotificationsRef = collection(
      this.orgCol,
      orgID,
      'orgNotifications'
    ) as CollectionReference<OrgNotification>;
    return collectionData(orgNotificationsRef, { idField: 'id' });
  }

  async getOrgRole(orgID: string, userID?: string) {
    const uid = userID || this.userService.activeUser$.value.uid;
    const orgMember = await firstValueFrom(this.getOrgMember(orgID, uid));

    return orgMember ? orgMember.role : Roles.GUEST;
  }

  setDashboardOrgID(orgID: string) {
    this.dashboardOrgID$.next(orgID);
  }

  editOrg(orgID: string) {
    this.setDashboardOrgID(orgID);
    this.router.navigate(['dashboard/edit-organization', orgID], { replaceUrl: true });
  }

  deleteOrg(orgID: string) {
    this.lafayetteService.deleteOrg(orgID, this.user$.value);
  }

  async setOrgHasRecorded(orgID: string) {
    const orgDoc = doc(this.orgCol, orgID);
    const orgData = await firstValueFrom(docData(orgDoc));
    const orgOwner = await firstValueFrom(this.userService.getUserData(orgData.orgOwner));
    this.activeCampaignService.addTagToSquadcaster('App: Recorded Session', orgOwner.uid);
    return setDoc(orgDoc, { hasRecorded: true }, { merge: true });
  }

  setOrgNotification(orgID: string, notification: OrgNotification) {
    const orgNotificationsRef = doc(
      this.orgCol,
      orgID,
      'orgNotifications',
      notification.id
    ) as DocumentReference<OrgNotification>;

    return setDoc(orgNotificationsRef, { ...notification }, { merge: true });
  }

  async orgHasSubscription(orgID: string) {
    const org = await firstValueFrom(this.getOrg(orgID));
    if (!org?.customerID) return false;
    const res = await this.amplionService.getCustomer(org.customerID);
    return res.customer?.subscriptions?.data?.length > 0;
  }

  orgsOwnedByUser(userID: string) {
    const orgCol = collection(this.firestore, 'organizations') as CollectionReference<Organization>;
    return collectionSnapshots(query(orgCol, where('orgOwner', '==', userID)));
  }

  async canDeleteUser() {
    await this.userService.activeUser$.toPromise();

    // get orgs owned by user and skip weird ass firestore cache 🤢
    const orgsOwnedByUserColSnapshots = await this.orgsOwnedByUser(this.user$.value.uid);
    const orgs = await firstValueFrom(
      orgsOwnedByUserColSnapshots.pipe(first((orgs) => orgs.every((org) => org.metadata.fromCache === false)))
    );
    const userOrgs = orgs.map((org) => ({
      orgID: org.id,
      ...org.data(),
    }));

    if (!userOrgs.length) return true;

    for (const org of userOrgs) {
      const orgHasSub = await this.orgHasSubscription(org.orgID);
      if (orgHasSub) return false;
    }

    return true;
  }

  async uploadOrgImage(orgID: string, image: string) {
    const url = await this.storageService.uploadImage(orgID, 'orgLogo', image);
    await setDoc(doc(this.orgCol, orgID), { orgLogo: url }, { merge: true });
    return url;
  }

  async updateProviderImages(providerIDs: string[], image: string) {
    providerIDs.forEach(async (providerID) => {
      await setDoc(doc(this.providerCol, providerID), { orgLogo: image }, { merge: true });
    });
  }

  navigateOrgWithNoPlanToInfoPage() {
    this.router.navigate(['/descript-info'], {
      state: {
        title: `SquadCast is now a part of Descript`,
        description: `In order to continue, you must sign into or create a Descript account.`,
        buttons: [
          {
            text: 'Already have a Descript account?',
            class: 'secondary',
            link: `${environment.descript.webDomain}/use-squadcast?redirect_origin=squadcast-app&utm_source=squadcast`,
          },
          {
            text: 'View pricing',
            class: 'primary',
            link: 'https://squadcast.fm/pricing/',
          },
        ],
      },
    });
  }
}
