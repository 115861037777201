<ion-row>
  <ion-col>
    <ion-col size="6" offset="3">
      <ion-img id="sctodropbox" src="/assets/images/SC-DropBox.png"></ion-img>
    </ion-col>
    <h1 class="ion-text-center">Transfer Your Recordings with Dropbox</h1>

    <ion-row>
      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
        <ion-item lines="none">
          <ion-icon src="/assets/icons/24px/check.svg" slot="start" color="secondary"> </ion-icon>
          <ion-label color="lightest" class="ion-text-wrap"> Move files to your personal Dropbox </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
        <ion-item lines="none">
          <ion-icon src="/assets/icons/24px/check.svg" slot="start" color="secondary"> </ion-icon>
          <ion-label color="lightest" class="ion-text-wrap"> Keep your own backups in your Dropbox </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
        <ion-item lines="none">
          <ion-icon src="/assets/icons/24px/check.svg" slot="start" color="secondary"> </ion-icon>
          <ion-label color="lightest" class="ion-text-wrap"> Cloud to Cloud Background Transfers </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
        <ion-item lines="none">
          <ion-icon src="/assets/icons/24px/check.svg" slot="start" color="secondary"> </ion-icon>
          <ion-label color="lightest" class="ion-text-wrap"> Share recordings with your Team fast </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6" offset="3">
        <ion-button
          *ngIf="(dropbox$ | async) === undefined || (dropbox$ | async) === null"
          (click)="connectDropbox()"
          color="lighter"
          oncontextmenu="false"
          fill="outline"
          shape="round"
          aria-label="Connect to Dropbox"
          expand="block">
          <ion-icon src="/assets/icons/24px/dropbox.svg" slot="start"> </ion-icon>
          Connect to Dropbox
        </ion-button>
        <ion-button
          *ngIf="dropbox$ | async"
          (click)="disconnectDropbox()"
          color="lighter"
          oncontextmenu="false"
          fill="outline"
          shape="round"
          aria-label="Disconnet Dropbox"
          expand="block">
          <ion-icon src="/assets/icons/24px/dropbox.svg" slot="start"> </ion-icon>
          Disconnect Dropbox
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
