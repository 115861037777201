import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private readonly documentIsAccessible: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>
  ) {
    this.documentIsAccessible = isPlatformBrowser(this.platformId);
  }

  check(name: string) {
    if (!this.documentIsAccessible) {
      return false;
    }

    name = encodeURIComponent(name);

    const regExp: RegExp = this.getCookieRegExp(name);
    const exists: boolean = regExp.test(this.document.cookie);

    return exists;
  }

  set(name: string, value: string) {
    if (!this.documentIsAccessible) {
      return;
    }

    const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; max-age=15780000`;

    this.document.cookie = cookieString;
  }

  get(name: string) {
    if (this.documentIsAccessible && this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.document.cookie);

      return this.safeDecodeURIComponent(result[1]);
    } else {
      return '';
    }
  }

  getAnalyticsClientID() {
    // console.log(`🐛 🐞 document.cookie ➡ ${JSON.stringify(document.cookie, null, 2)} 🐞 🐛 `);
    try {
      return document.cookie
        .match(/_ga=(.+?);/)[1]
        .split('.')
        .slice(-2)
        .join('.');
    } catch {
      return '';
    }
  }

  private getCookieRegExp(name: string) {
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');

    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }

  private safeDecodeURIComponent(encodedURIComponent: string) {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      // probably it is not uri encoded. return as is
      return encodedURIComponent;
    }
  }
}
