<ion-row>
  <ion-col>
    <h1 class="ion-text-center">Update Email</h1>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="10" offset="1">
    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Current Email 
        <ion-icon *ngIf="emailVerified" src="/assets/icons/24px/check.svg" tooltip="Email Verified!"></ion-icon>
      </ion-label>

      <ion-input [(ngModel)]="currentEmail" name="current" required type="email" readonly> </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> New Email </ion-label>

      <ion-input [(ngModel)]="newEmail" #new="ngModel" name="new" required type="email" email> </ion-input>
    </ion-item>

    <ion-item class="input-large" lines="none">
      <ion-label position="stacked"> Password </ion-label>

      <ion-input
        [(ngModel)]="password"
        (keyup.enter)="saveEmail()"
        #pass="ngModel"
        name="pass"
        minlength="6"
        required
        type="password">
      </ion-input>
    </ion-item>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="close()" class="grey"> Cancel </ion-button>
  <ion-button (click)="saveEmail()" [disabled]="!new.valid || !pass.valid" class="primary"> Confirm </ion-button>
</div>
