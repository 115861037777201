export interface Tour {
  isCompleted?: boolean;
  id?: string;
}

export enum ToursCollections {
  DASHBOARD = 'dashboardTour',
  STUDIO = 'studioTour',
  GREENROOM = 'greenroomTour',
  GETTING_RECORDING = 'gettingRecordingTour',
}
