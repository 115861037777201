import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AmplionService } from '../../services/amplion/amplion.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CanceledService } from '../../services/canceled/canceled.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Plan } from '@sc/types';
import { WalletService } from '../../services/wallet/wallet.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-reactivate-subscription',
  templateUrl: 'reactivate-subscription.page.html',
  styleUrls: ['reactivate-subscription.page.scss'],
})
export class ReactivateSubscriptionPage {
  reason = '';
  reasons: Array<string> = ['Started podcasting again', 'Best features', 'Theres no better alternative'];

  bestFeatures: Array<string> = [
    'Audio + Video Recording',
    'Easy Guest Experience',
    'Conversations with Dolby Voice',
    'Screen Recording',
    'Master Audio with Dolby',
    'Dedicated support team',
    'Data Ownership and Security Policies',
  ];
  bestFeature = '';

  constructor(
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private walletService: WalletService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {}

  async close() {
    await this.modalController.dismiss();
  }

  async submitFeedback() {
    this.close();
    this.analyticsService.track('subscription reactivation feedback', {
      hostID: this.userService.activeUser$.value.uid,
      hostEmail: this.userService.activeUser$.value.email,
      reason: this.reason,
      bestFeature: this.bestFeature,
      plan: this.walletService.dashboardPlan$.value.id,
    });

    this.toastrService.success(`Feedback Submitted`, `Thank you for your feedback. Welcome back to Squadcast!`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
  }

  async selectReason(event: Event) {
    const eventTarget: IEventTarget = event.target;
    this.bestFeature = '';
    this.reason = eventTarget.value;
    await this.analyticsService.track('selected subscription reactivation reason', { reason: this.reason });
  }

  async selectBestFeature(event: Event) {
    const eventTarget: IEventTarget = event.target;
    this.bestFeature = eventTarget.value;
    await this.analyticsService.track('selected subscription reactivation best feature', {
      bestFeature: this.bestFeature,
    });
  }
}
