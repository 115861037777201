import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { Timezone } from '@sc/types';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  timezones: Array<Timezone> = [
    {
      name: 'International Date Line West',
      tzid: 'Pacific/Midway',
    },
    {
      name: 'American Samoa',
      tzid: 'Pacific/Pago_Pago',
    },
    {
      name: 'Hawaii',
      tzid: 'Pacific/Honolulu',
    },
    {
      name: 'Alaska',
      tzid: 'America/Juneau',
    },
    {
      name: 'Pacific Time (US & Canada)',
      tzid: 'America/Los_Angeles',
    },
    {
      name: 'Tijuana',
      tzid: 'America/Tijuana',
    },
    {
      name: 'Mountain Time (US & Canada)',
      tzid: 'America/Denver',
    },
    {
      name: 'Arizona',
      tzid: 'America/Phoenix',
    },
    {
      name: 'Chihuahua',
      tzid: 'America/Chihuahua',
    },
    {
      name: 'Mazatlan',
      tzid: 'America/Mazatlan',
    },
    {
      name: 'Central Time (US & Canada)',
      tzid: 'America/Chicago',
    },
    {
      name: 'Saskatchewan',
      tzid: 'America/Regina',
    },
    {
      name: 'Mexico City',
      tzid: 'America/Mexico_City',
    },
    {
      name: 'Monterrey',
      tzid: 'America/Monterrey',
    },
    {
      name: 'Central America',
      tzid: 'America/Guatemala',
    },
    {
      name: 'Eastern Time (US & Canada)',
      tzid: 'America/New_York',
    },
    {
      name: 'Bogota',
      tzid: 'America/Bogota',
    },
    {
      name: 'Lima',
      tzid: 'America/Lima',
    },
    {
      name: 'Atlantic Time (Canada)',
      tzid: 'America/Halifax',
    },
    {
      name: 'Caracas',
      tzid: 'America/Caracas',
    },
    {
      name: 'La Paz',
      tzid: 'America/La_Paz',
    },
    {
      name: 'Santiago',
      tzid: 'America/Santiago',
    },
    {
      name: 'Newfoundland',
      tzid: 'America/St_Johns',
    },
    {
      name: 'Brasilia',
      tzid: 'America/Sao_Paulo',
    },
    {
      name: 'Buenos Aires',
      tzid: 'America/Argentina/Buenos_Aires',
    },
    {
      name: 'Montevideo',
      tzid: 'America/Montevideo',
    },
    {
      name: 'Georgetown',
      tzid: 'America/Guyana',
    },
    {
      name: 'Greenland',
      tzid: 'America/Godthab',
    },
    {
      name: 'Mid-Atlantic',
      tzid: 'Atlantic/South_Georgia',
    },
    {
      name: 'Azores',
      tzid: 'Atlantic/Azores',
    },
    {
      name: 'Cape Verde Is.',
      tzid: 'Atlantic/Cape_Verde',
    },
    {
      name: 'Dublin',
      tzid: 'Europe/Dublin',
    },
    {
      name: 'Lisbon',
      tzid: 'Europe/Lisbon',
    },
    {
      name: 'London',
      tzid: 'Europe/London',
    },
    {
      name: 'Casablanca',
      tzid: 'Africa/Casablanca',
    },
    {
      name: 'Monrovia',
      tzid: 'Africa/Monrovia',
    },
    {
      name: 'UTC',
      tzid: 'Etc/UTC',
    },
    {
      name: 'Belgrade',
      tzid: 'Europe/Belgrade',
    },
    {
      name: 'Bratislava',
      tzid: 'Europe/Bratislava',
    },
    {
      name: 'Budapest',
      tzid: 'Europe/Budapest',
    },
    {
      name: 'Ljubljana',
      tzid: 'Europe/Ljubljana',
    },
    {
      name: 'Prague',
      tzid: 'Europe/Prague',
    },
    {
      name: 'Sarajevo',
      tzid: 'Europe/Sarajevo',
    },
    {
      name: 'Skopje',
      tzid: 'Europe/Skopje',
    },
    {
      name: 'Warsaw',
      tzid: 'Europe/Warsaw',
    },
    {
      name: 'Zagreb',
      tzid: 'Europe/Zagreb',
    },
    {
      name: 'Brussels',
      tzid: 'Europe/Brussels',
    },
    {
      name: 'Copenhagen',
      tzid: 'Europe/Copenhagen',
    },
    {
      name: 'Madrid',
      tzid: 'Europe/Madrid',
    },
    {
      name: 'Paris',
      tzid: 'Europe/Paris',
    },
    {
      name: 'Amsterdam',
      tzid: 'Europe/Amsterdam',
    },
    {
      name: 'Berlin',
      tzid: 'Europe/Berlin',
    },
    {
      name: 'Rome',
      tzid: 'Europe/Rome',
    },
    {
      name: 'Stockholm',
      tzid: 'Europe/Stockholm',
    },
    {
      name: 'Vienna',
      tzid: 'Europe/Vienna',
    },
    {
      name: 'West Central Africa',
      tzid: 'Africa/Algiers',
    },
    {
      name: 'Bucharest',
      tzid: 'Europe/Bucharest',
    },
    {
      name: 'Cairo',
      tzid: 'Africa/Cairo',
    },
    {
      name: 'Helsinki',
      tzid: 'Europe/Helsinki',
    },
    {
      name: 'Kyiv',
      tzid: 'Europe/Kiev',
    },
    {
      name: 'Riga',
      tzid: 'Europe/Riga',
    },
    {
      name: 'Sofia',
      tzid: 'Europe/Sofia',
    },
    {
      name: 'Tallinn',
      tzid: 'Europe/Tallinn',
    },
    {
      name: 'Vilnius',
      tzid: 'Europe/Vilnius',
    },
    {
      name: 'Athens',
      tzid: 'Europe/Athens',
    },
    {
      name: 'Istanbul',
      tzid: 'Europe/Istanbul',
    },
    {
      name: 'startMinsk',
      tzid: 'Europe/Minsk',
    },
    {
      name: 'Jerusalem',
      tzid: 'Asia/Jerusalem',
    },
    {
      name: 'Harare',
      tzid: 'Africa/Harare',
    },
    {
      name: 'Pretoria',
      tzid: 'Africa/Johannesburg',
    },
    {
      name: 'Kaliningrad',
      tzid: 'Europe/Kaliningrad',
    },
    {
      name: 'Moscow',
      tzid: 'Europe/Moscow',
    },
    {
      name: 'Volgograd',
      tzid: 'Europe/Volgograd',
    },
    {
      name: 'Samara',
      tzid: 'Europe/Samara',
    },
    {
      name: 'Kuwait',
      tzid: 'Asia/Kuwait',
    },
    {
      name: 'Riyadh',
      tzid: 'Asia/Riyadh',
    },
    {
      name: 'Nairobi',
      tzid: 'Africa/Nairobi',
    },
    {
      name: 'Baghdad',
      tzid: 'Asia/Baghdad',
    },
    {
      name: 'Tehran',
      tzid: 'Asia/Tehran',
    },
    {
      name: 'Abu Dhabi',
      tzid: 'Asia/Muscat',
    },
    {
      name: 'Baku',
      tzid: 'Asia/Baku',
    },
    {
      name: 'Tbilisi',
      tzid: 'Asia/Tbilisi',
    },
    {
      name: 'Yerevan',
      tzid: 'Asia/Yerevan',
    },
    {
      name: 'Kabul',
      tzid: 'Asia/Kabul',
    },
    {
      name: 'Ekaterinburg',
      tzid: 'Asia/Yekaterinburg',
    },
    {
      name: 'Islamabad',
      tzid: 'Asia/Karachi',
    },
    {
      name: 'Tashkent',
      tzid: 'Asia/Tashkent',
    },
    {
      name: 'New Delhi',
      tzid: 'Asia/Kolkata',
    },
    {
      name: 'Kathmandu',
      tzid: 'Asia/Kathmandu',
    },
    {
      name: 'Dhaka',
      tzid: 'Asia/Dhaka',
    },
    {
      name: 'Sri Jayawardenepura',
      tzid: 'Asia/Colombo',
    },
    {
      name: 'Almaty',
      tzid: 'Asia/Almaty',
    },
    {
      name: 'Novosibirsk',
      tzid: 'Asia/Novosibirsk',
    },
    {
      name: 'Rangoon',
      tzid: 'Asia/Rangoon',
    },
    {
      name: 'Bangkok',
      tzid: 'Asia/Bangkok',
    },
    {
      name: 'Jakarta',
      tzid: 'Asia/Jakarta',
    },
    {
      name: 'Krasnoyarsk',
      tzid: 'Asia/Krasnoyarsk',
    },
    {
      name: 'Beijing',
      tzid: 'Asia/Shanghai',
    },
    {
      name: 'Chongqing',
      tzid: 'Asia/Chongqing',
    },
    {
      name: 'Hong Kong',
      tzid: 'Asia/Hong_Kong',
    },
    {
      name: 'Urumqi',
      tzid: 'Asia/Urumqi',
    },
    {
      name: 'Kuala Lumpur',
      tzid: 'Asia/Kuala_Lumpur',
    },
    {
      name: 'Singapore',
      tzid: 'Asia/Singapore',
    },
    {
      name: 'Taipei',
      tzid: 'Asia/Taipei',
    },
    {
      name: 'Perth',
      tzid: 'Australia/Perth',
    },
    {
      name: 'Irkutsk',
      tzid: 'Asia/Irkutsk',
    },
    {
      name: 'Ulaanbaatar',
      tzid: 'Asia/Ulaanbaatar',
    },
    {
      name: 'Seoul',
      tzid: 'Asia/Seoul',
    },
    {
      name: 'Tokyo',
      tzid: 'Asia/Tokyo',
    },
    {
      name: 'Yakutsk',
      tzid: 'Asia/Yakutsk',
    },
    {
      name: 'Darwin',
      tzid: 'Australia/Darwin',
    },
    {
      name: 'Adelaide',
      tzid: 'Australia/Adelaide',
    },
    {
      name: 'Melbourne',
      tzid: 'Australia/Melbourne',
    },
    {
      name: 'Sydney',
      tzid: 'Australia/Sydney',
    },
    {
      name: 'Brisbane',
      tzid: 'Australia/Brisbane',
    },
    {
      name: 'Hobart',
      tzid: 'Australia/Hobart',
    },
    {
      name: 'Vladivostok',
      tzid: 'Asia/Vladivostok',
    },
    {
      name: 'Guam',
      tzid: 'Pacific/Guam',
    },
    {
      name: 'Port Moresby',
      tzid: 'Pacific/Port_Moresby',
    },
    {
      name: 'Magadan',
      tzid: 'Asia/Magadan',
    },
    {
      name: 'Srednekolymsk',
      tzid: 'Asia/Srednekolymsk',
    },
    {
      name: 'Solomon Is.',
      tzid: 'Pacific/Guadalcanal',
    },
    {
      name: 'New Caledonia',
      tzid: 'Pacific/Noumea',
    },
    {
      name: 'Fiji',
      tzid: 'Pacific/Fiji',
    },
    {
      name: 'Kamchatka',
      tzid: 'Asia/Kamchatka',
    },
    {
      name: 'Marshall Is.',
      tzid: 'Pacific/Majuro',
    },
    {
      name: 'Auckland',
      tzid: 'Pacific/Auckland',
    },
    {
      name: "Nuku'alofa",
      tzid: 'Pacific/Tongatapu',
    },
    {
      name: 'Tokelau Is.',
      tzid: 'Pacific/Fakaofo',
    },
    {
      name: 'Chatham Is.',
      tzid: 'Pacific/Chatham',
    },
    {
      name: 'Samoa',
      tzid: 'Pacific/Apia',
    },
    {
      name: 'Puerto Rico',
      tzid: 'America/Puerto_Rico',
    },
    {
      name: 'Aleutian Islands',
      tzid: 'America/Adak',
    },
  ];

  constructor(private firestore: Firestore) {
    this.timezones = this.timezones
      .map((timezone) => {
        const offset = dayjs.tz(Date.now(), timezone.tzid).utcOffset();
        const offsetString = `(UTC${offset >= 0 ? '+' : '-'}${Math.floor(Math.abs(offset) / 60)}${
          Math.abs(offset % 60) ? ':' + Math.abs(offset % 60) : ''
        })`;

        return {
          ...timezone,
          name: timezone.name + ' ' + offsetString,
          offset: offset,
        };
      })
      .sort((a, b) => {
        return a.offset - b.offset;
      });
  }

  setCastMemberTimezone(hostID: string, sessionID: string, castMemberID: string, timezone: string) {
    const docRef = doc(this.firestore, `sessions/${sessionID}/participants/${castMemberID}`);
    return setDoc(docRef, { timezone }, { merge: true });
  }

  getCastMemberTimezone(hostID: string, sessionID: string, castMemberID: string) {
    return docData(doc(this.firestore, `sessions/${sessionID}/participants/${castMemberID}`));
  }

  getTimezones(): Promise<Array<Timezone>> {
    return new Promise((resolve) => {
      resolve(this.timezones);
    });
  }

  convertToTZID(name: string) {
    return new Promise((resolve, reject) => {
      this.timezones.forEach((timezone) => {
        if (timezone.name === name) {
          resolve(timezone.tzid);
        }
      });
    });
  }

  convertToName(tzid: string) {
    return new Promise((resolve, reject) => {
      this.timezones.forEach((timezone) => {
        if (timezone.tzid === tzid) {
          resolve(timezone.name);
        }
      });
    });
  }
}
