import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { APIKey } from '@sc/types';

@Component({
  selector: 'sc-revoke-api-key',
  templateUrl: 'revoke-api-key.page.html',
  styleUrls: ['revoke-api-key.page.scss'],
})
export class RevokeApiKeyPage implements OnInit {
  @Input() dashboardOrg: string;
  @Input() key: APIKey;

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private califoneService: CalifoneService
  ) {}

  ngOnInit() {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed revoke api key', { apiKeyID: this.key.id });
  }

  async revoke() {
    try {
      await this.califoneService.revokeApiKey(this.key.id, true, this.dashboardOrg);

      this.toastrService.success(null, `Successfully revoked access to the API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('revoked api key', { apiKeyID: this.key.id, success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to revoke access to the API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('revoked api key', { apiKeyID: this.key.id, success: false });
    }
  }
}
