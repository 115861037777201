<ion-row>
  <ion-col>
    <h1 class="bold ion-text-center">Are you Sure?</h1>

    <h2 class="ion-text-center">
      Cancelling is a big step...<br />
      <strong> Everything will be deleted at the end of your billing cycle </strong>
    </h2>
  </ion-col>
</ion-row>

<ion-list>
  <ion-item id="reason">
    <ion-label color="white" class="text-light-dark"> Help us understand why you're cancelling </ion-label>

    <ion-select [value]="reason" (ionChange)="selectReason($event)" placeholder="Choose a reason" interface="popover">
      <ion-select-option *ngFor="let reason of reasons" [value]="reason"> {{ reason }} </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item *ngIf="reason === 'Missing features'" id="missing-features">
    <ion-label color="white" class="text-light-dark"> What feature is most important to you? </ion-label>

    <ion-select
      [value]="missingFeature"
      (ionChange)="selectMissingFeature($event)"
      placeholder="Choose a feature"
      interface="popover">
      <ion-select-option *ngFor="let missingFeature of missingFeatures" [value]="missingFeature">
        {{ missingFeature }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col>
    <ion-button (click)="close()" color="light" expand="block" shape="round"> Back </ion-button>
  </ion-col>

  <ion-col>
    <ion-button (click)="scheduleCancel()" [disabled]="waiting" color="danger" expand="block" shape="round">
      Cancel Subscription
    </ion-button>
  </ion-col>
</ion-row>
