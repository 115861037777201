<div class="descript-info-page">
  <div class="message">
    <h1 *ngIf="title">{{title}}</h1>
    <p *ngIf="description">{{description}}</p>

    <sc-drive-select></sc-drive-select>

    <div class="buttons">
      <ion-button *ngFor="let button of buttons" [class]="button.class" (click)="goToLink(button.link)">
        {{button.text}}
      </ion-button>
    </div>

    <p *ngIf="!(loading$ | async)?.length" class="action-small link-container">
      Here by mistake?
      <a href="javascript:;" (click)="logout()" class="sc-color-black-25" alt="Logout">Logout</a>
      <span *ngIf="lastOrgWithPlan$ | async">
        or <a href="javascript:;" (click)="backToOrg()">Back to Dashboard</a></span
      >
      <!-- <span *ngIf="canDeleteUser && showDeleteAccount">
        or <a href="javascript:;" (click)="showDeleteUserModal()" class="delete-account">Delete Account</a></span
      > -->
    </p>

    <p *ngIf="(loading$ | async)?.length" class="action-small">Please wait while we load your data.</p>
  </div>
</div>
