import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShowsPage } from './shows.page';
import { SquadCastComponentsModule } from '../../components/squadcast.components.module';
import { SquadCastPipesModule } from '../../pipes/squadcast.pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SquadCastComponentsModule,
    SquadCastPipesModule,
  ],
  declarations: [ShowsPage],
})
export class ShowsPageModule {}
