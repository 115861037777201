import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import pLimit from 'p-limit';
import { WorkflowActions, Workflows } from '@sc/types';
import { FileService } from '../file/file.service';
import { IdTokenService } from '../id-token/id-token.service';
import { RecordingsService } from '../recordings/recordings.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Recording } from '@sc/types';
import { DescriptService } from '../descript/descript.service';
import { DolbyService } from '../dolby/dolby.service';
import { DropboxService } from '../dropbox/dropbox.service';
import { SessionsService } from '../sessions/sessions.service';
import { AuphonicFile } from '@sc/types';
import { environment } from '../../../environments/environment';
import { SettingsService } from '../settings/settings.service';
import { AuphonicService } from '../auphonic/auphonic.service';
import { UserService } from '../user/user.service';
import { DescriptFile, DescriptImport } from '@sc/types';
import { AnalyticsService } from '../analytics/analytics.service';
import { WebhookEvent, WebhookEventNames } from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';

import * as dayjs from 'dayjs';
import { CalifoneService } from '../califone/califone.service';
import Rollbar from 'rollbar';
import { RollbarService } from '../rollbar/rollbar.service';
@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  selectedRecordings$ = this.recordingsService.selectedRecordings$;
  user$ = this.userService.activeUser$;
  limit = pLimit(1);

  actions: WorkflowActions = {
    [Workflows.DOLBY]: {
      title: 'Master Audio With Dolby',
      description: 'Automatically Perfect Your Audio',
      icon: '/assets/icons/24px/dolby.svg',
      handler: this.masterAudioWithDolby.bind(this),
    },
    [Workflows.EDDY]: {
      title: 'Edit in Eddy',
      description: 'Edit your podcast like you edit your show notes',
      icon: '/assets/icons/24px/headliner.svg',
      handler: this.editInEddy.bind(this),
    },
    [Workflows.HINDENBURG]: {
      title: 'Edit in Hindenburg',
      description: 'Full-featured audio editing for professional producers',
      icon: '/assets/icons/24px/hindenburg.svg',
      handler: this.editInHindenburg.bind(this),
    },
    [Workflows.GOOGLEDRIVE]: {
      title: 'Send to Google Drive',
      description: 'Store, share, and collaborate on files from anywhere',
      icon: '/assets/icons/24px/google-drive.svg',
      handler: this.sendToGoogleDrive.bind(this),
    },
    [Workflows.DROPBOX]: {
      title: 'Send to Dropbox',
      description: 'Keep Life Organized and Work Moving All in One Place',
      icon: '/assets/icons/24px/dropbox.svg',
      handler: this.sendToDropbox.bind(this),
    },
    [Workflows.TRINT]: {
      title: 'Transcribe in Trint',
      description: 'Turn audio and video into searchable and accessible content',
      icon: '/assets/icons/24px/trint.svg',
      handler: this.transcribeInTrint.bind(this),
    },
    [Workflows.MIX]: {
      title: 'Mix Audio',
      description: 'Combine Audio Recordings Together',
      icon: '/assets/icons/24px/mixer-2.svg',
      handler: this.mixAudio.bind(this),
    },
    [Workflows.DELETE]: {
      title: 'Delete Recordings',
      description: 'Permanently Delete Recordings',
      icon: '/assets/icons/24px/delete.svg',
      handler: this.deleteRecordings.bind(this),
    },
  };

  constructor(
    private idTokenService: IdTokenService,
    private modalController: ModalController,
    private toastrService: ToastrService,
    private recordingsService: RecordingsService,
    private fileService: FileService,
    private dolbyService: DolbyService,
    private descriptService: DescriptService,
    private dropboxService: DropboxService,
    private sessionsService: SessionsService,
    private califoneService: CalifoneService,
    private settingsService: SettingsService,
    private auphonicService: AuphonicService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  async masterAudioWithDolby() {
    if (this.selectedRecordings$.value.some((recording) => recording?.screenRecording === true)) {
      this.toastrService.info(null, `Screen Recordings Can Not Be Mastered`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    } else if (this.selectedRecordings$.value.length > 10) {
      this.toastrService.warning(null, `Only 10 Recordings Can Be Mastered At Once`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    } else {
      try {
        const idToken = await this.idTokenService.getFreshIdToken();
        const showID = this.selectedRecordings$.value[0].showID;
        this.toastrService.info(
          `Mastering your file. When your file is ready it will appear in the recording list for the selected Take`,
          `Starting Dolby Mastering Process.`,
          {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 5 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );
        const masterSettings = await firstValueFrom(this.settingsService.getEnhanceSettings(showID));
        const masterAudioWithDolbyPromises = this.selectedRecordings$.value.map((recording: Recording) => {
          return firstValueFrom(
            this.dolbyService.masterAudio({
              idToken,
              showID: recording?.migrated ? recording.uid : recording.showID,
              sessionID: recording.sessionID,
              wav: recording?.wav ? recording?.wav : null,
              mp4: recording?.mp4 ? recording?.mp4 : null,
              fileName: recording.fileName,
              recordingID: recording.recordingID,
              take: recording.take,
              migrated: recording?.migrated,
              audioSettings: JSON.stringify(masterSettings),
            })
          );
        });

        await Promise.all(masterAudioWithDolbyPromises);
      } catch (error) {
        this.toastrService.error(null, `Failed to Master Audio with Dolby`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      }
    }
  }

  async editInEddy() {
    try {
      await this.modalController.dismiss();

      this.toastrService.info(null, `Not Wired Up`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      this.toastrService.error(null, `Failed to Edit in Eddy`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async editInHindenburg() {
    try {
      await this.modalController.dismiss();

      this.toastrService.info(null, `Not Wired Up`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      this.toastrService.error(null, `Failed to Edit in Hindenburg`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async sendToGoogleDrive() {
    try {
      await this.modalController.dismiss();

      this.toastrService.info(null, `Not Wired Up`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      this.toastrService.error(null, `Failed to Transfer to Google Drive`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async sendToDropbox() {
    try {
      const idToken = await this.idTokenService.getFreshIdToken();
      const formats = ['wav', 'mp3', 'mp4', 'webm'];
      const sendToDropboxPromises = [];
      const transferFileEvents: WebhookEvent[] = [];
      this.selectedRecordings$.value.map(async (recording: Recording) => {
        const session = await firstValueFrom(this.sessionsService.getSessionByID(recording.sessionID));

        const event: WebhookEvent = {
          name: WebhookEventNames.RECORDING_TRANSFERRED,
          orgID: this.dashboardOrg$.value.orgID,
          showID: session.showID,
          showName: session.showTitle,
          sessionID: session.sessionID,
          mp3URL: recording?.mp3 ? recording.mp3 : null,
          wavURL: recording?.wav ? recording.wav : null,
          mp4URL: recording?.mp4 ? recording.mp4 : null,
          webmURL: recording?.webm ? recording.webm : null,
        };
        await this.califoneService.emitWebhookEvent(event, this.dashboardOrg$.value.orgID);

        formats.map((format) => {
          if (recording[format]) {
            sendToDropboxPromises.push(
              this.dropboxService.sendToDropbox(idToken, {
                uid: this.user$.value.uid,
                showID: recording?.migrated ? recording.uid : recording.showID,
                sessionTitle: session.sessionTitle,
                sessionID: recording.sessionID,
                orgID: recording.orgID,
                fileName: `${recording.fileName}.${format}`,
                path: recording[format],
              })
            );
          }
        });
      });

      await Promise.all(sendToDropboxPromises);

      await this.analyticsService.track('transfered files to dropbox', { success: true });

      this.toastrService.success(null, `Successfully Sent to Dropbox`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      await this.analyticsService.track('transfered files to dropbox', { success: false });
      this.toastrService.error(null, `Failed to Send to Dropbox`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async transcribeInTrint() {
    try {
      await this.modalController.dismiss();

      this.toastrService.info(null, `Not Wired Up`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      this.toastrService.error(null, `Failed to Transfer to Google Drive`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async mixAudio() {
    if (this.selectedRecordings$.value.length < 2) {
      this.toastrService.info(null, `Select 2 or More Recordings to Mix`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (
      !this.selectedRecordings$.value.every(
        (recording) => recording.sessionID === this.selectedRecordings$.value[0].sessionID
      )
    ) {
      this.toastrService.info(null, `Only Recordings from the Same Session Can be Mixed`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (
      !this.selectedRecordings$.value.every((recording) => recording.take === this.selectedRecordings$.value[0].take)
    ) {
      this.toastrService.info(null, `Only Recordings from the Same Take Can be Mixed`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (this.selectedRecordings$.value.some((recording) => recording.screenRecording === true)) {
      this.toastrService.info(null, `Screen Recordings Can Not be Mixed`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (this.selectedRecordings$.value.some((recording) => recording.fileName.includes('mix_') === true)) {
      this.toastrService.info(null, `Previously Mixed Recordings Can Not be re-mixed.`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      return;
    }

    if (this.selectedRecordings$.value.length > 8) {
      // Auphonic limits the number of files to 8 anymore will be rejected
      this.toastrService.warning(null, `Select 8 or less Recordings to Mix`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      const idToken = await this.idTokenService.getFreshIdToken();
      const recordingInfo = this.selectedRecordings$.value[0];
      const showID = recordingInfo.showID;
      const sessionID = recordingInfo.sessionID;
      const title = recordingInfo.migrated ? `${recordingInfo.uid}/${sessionID}` : `${showID}/${sessionID}`;
      const fileName = `mix_${showID}_${dayjs().format('MM-DD-YYYY_HHmmss')}`;
      const mixSettings = await firstValueFrom(this.settingsService.getMixSettings(showID));

      const files: Array<AuphonicFile> = this.selectedRecordings$.value.map((recording) => {
        const pathHeader = recording.migrated ? recording.uid : recording.showID;
        return {
          id: recording.recordingID,
          service: environment.auphonic.googleCloudStorage,
          input_file: `${pathHeader}/${sessionID}/${recording.fileName}.wav`,
          type: 'multitrack',
          offset: 0,
          //add individual settings here
          algorithms: mixSettings.individual,
        };
      });

      this.toastrService.info(
        `Mixing your files. When your file is ready it will appear in the recording list for the selected Take.`,
        `Starting the Mixing Process.`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      const multiTrackProductionResponse: any = await firstValueFrom(
        this.auphonicService.createMultiTrackProduction({
          idToken,
          title,
          showID: recordingInfo.migrated ? recordingInfo.uid : showID,
          sessionID,
          orgID: recordingInfo.orgID,
          take: recordingInfo.take,
          files,
          filename: fileName,
          //add master settings here
          algorithms: mixSettings.master,
        })
      );

      if (!multiTrackProductionResponse.data) {
        return Promise.reject('failed to create multitrack production');
      }

      const productionID = multiTrackProductionResponse.data.uuid;

      await firstValueFrom(this.auphonicService.startProduction(idToken, productionID, title, showID, sessionID));

      await this.fileService.addFile(sessionID, productionID, {
        fileName,
        take: recordingInfo.take,
      });
    } catch (error) {
      this.rollbar.error('Failed to Mix Recordings', error);
      this.toastrService.error(null, `Failed to Mix Recordings`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }

  async deleteRecordings(sessionID: string) {
    try {
      const idToken = await this.idTokenService.getFreshIdToken();

      const deleteRecordingsPromises = this.selectedRecordings$.value.map(async (recording: Recording) => {
        // Adds a Concurreny Limit to Promise.All so that Takes can be sequentially deleted.
        this.limit(() => this.recordingsService.removeRecordingFromTake(recording.recordingID));
        this.recordingsService.removeSelectedRecording(recording);
        return this.fileService.deleteFile(recording.showID, recording.sessionID, recording, idToken);
      });

      await Promise.all(deleteRecordingsPromises);
      this.recordingsService.selectedRecordings = [];
      this.recordingsService.selectedRecordings$.next([]);

      this.toastrService.success(null, `Successfully deleted Recordings`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    } catch (error) {
      this.toastrService.error(null, `Failed to delete Recordings`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
  }
}
