<ion-list lines="true">
  <ion-item (click)="dismiss()" button="true" lines="none">
    <ion-icon src="/assets/icons/24px/chevron-left.svg" slot="start"> </ion-icon>
    <ion-label> Back </ion-label>
  </ion-item>

  <ion-item *ngFor="let tour of tourGroups" button="true" (click)="startTour(tour.id)">
    <ion-label>
      {{ tour.name }}
      <p class="ion-text-wrap">{{ tour.subtext }}</p>
    </ion-label>
  </ion-item>
</ion-list>
