import { Component, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sc-stick-around-toast',
  templateUrl: './stick-around-toast.component.html',
  styleUrls: ['./stick-around-toast.component.scss'],
  preserveWhitespaces: false,
})
export class StickAroundToastComponent extends Toast implements OnInit, OnDestroy {
  recordingID: string;
  screenID: string;

  subs: Array<Subscription> = [];

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private analyticsService: AnalyticsService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    this.recordingID = this.toastPackage.config.payload.recordingID;
    this.screenID = this.toastPackage.config.payload.screenID;
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();

    this.analyticsService.track('toast event', {
      event,
      label: this.title,
      message: this.message,
    });

    return false;
  }

  async close() {
    this.remove();

    this.analyticsService.track('closed toast', {
      label: this.title,
      message: this.message,
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
