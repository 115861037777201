import { Injectable } from '@angular/core';

import {
  Auth,
  authState,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  User as FireUser,
} from '@angular/fire/auth';
import { Firestore, collection, CollectionReference, doc, setDoc, docData } from '@angular/fire/firestore';
import { Storage, getStorage, uploadString, ref, getDownloadURL } from '@angular/fire/storage';
import { getApp } from '@angular/fire/app';

import { UserModel, UserData, UserProfile, AnonymousUser } from '@sc/types';
import { SupportCenterService } from '../support-center/support-center.service';
import { environment } from '../../../environments/environment';
import { SCSubject } from '../../util/sc-subject.class';
// import { SupportIdentity } from '@sc/types';
import { SupportIdentity } from '@sc/types';

import { switchMap, of, firstValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public authUser$ = new SCSubject<FireUser>();
  public userData$ = new SCSubject<UserData>();
  public activeUser$ = new SCSubject<UserModel>();
  public activeUserID$ = new SCSubject<string>();
  public activeUserEmail$ = new SCSubject<string>();
  public deletingUser$ = new SCSubject<boolean>(false);
  public appFocused$ = new SCSubject<boolean>();

  public DEFAULT_IMG = '/assets/images/DefaultProfile.png';

  private imageBucket = this.storageService.imageBucket;
  private scCol: CollectionReference = collection(this.firestore, 'SquadCasters');
  private anonData$ = new SCSubject<UserData>({});

  constructor(
    public firestore: Firestore,
    public fireAuth: Auth,
    private supportCenterService: SupportCenterService,
    private storageService: StorageService
  ) {
    this.setupAuth();
  }

  setupAuth(): void {
    this.fireAuth.onAuthStateChanged(async (credentials: FireUser) => {
      this.authUser$.next(credentials);
    });
    this.fireAuth.onIdTokenChanged(async (credentials: FireUser) => {
      this.authUser$.next(credentials);
    });

    authState(this.fireAuth)
      .pipe(
        switchMap((user: FireUser) => {
          if (user && !user.isAnonymous) {
            return this.getUserData(user.uid);
          } else if (user) {
            return this.anonData$;
          } else return of(null);
        })
      )
      .subscribe((data: UserData) => {
        if (data && !this.deletingUser$.value) {
          this.userData$.next(data);
          this.buildUser();
        }
      });
  }

  async buildUser(scData?: UserData, authData?: FireUser): Promise<void> {
    if (!authData) authData = this.authUser$.value;
    if (!scData) scData = this.userData$.value;
    const newUser = await this.combineUserData(authData, scData);
    this.activeUser$.next(newUser);
    this.activeUserID$.next(newUser.uid);
    this.activeUserEmail$.next(newUser.email);
  }

  setAnonData(data: UserData) {
    this.anonData$.next({ ...this.anonData$.value, ...data });
    this.buildUser(this.anonData$.value);
  }

  async combineUserData(credentials: FireUser, scUser: UserData): Promise<UserModel> {
    let user = new UserModel();
    if (credentials) {
      user.uid = credentials.uid;
      user.guest = credentials.isAnonymous;
      user.fullName = credentials.displayName;
      user.email = credentials.email;
      user.photoURL = credentials.photoURL;
      user.providers = credentials.providerData;
      user.emailVerified = credentials.emailVerified;
      // user.creationTime = credentials.metadata.creationTime;
      // user.lastSignInTime = credentials.metadata.lastSignInTime;
      user.organizations = [];
      await credentials.getIdTokenResult().then((result) => {
        if (Array.isArray(result.claims.organizations)) {
          user.organizations = result.claims.organizations;
        }
      });

      const supportIdentity: SupportIdentity = {
        email: user.email,
        name: scUser.fullName || scUser.displayName || user.email,
      };

      this.supportCenterService.identify(supportIdentity);
    }

    if (user.email && scUser.email !== user.email) {
      // check if email exists in fireauth - fixes the weird infinite loop if there are two browsers open while changing email
      const methods = await fetchSignInMethodsForEmail(this.fireAuth, user.email);
      if (!methods.length) window.location.reload();

      delete scUser.email;
    }

    if (scUser.uid) {
      user = { ...user, ...scUser };
      this.syncData(user, scUser);
    }
    return user;
  }

  syncData(user: UserModel, scUser: UserData) {
    const userDoc = doc(this.scCol, user.uid);
    const newData: UserProfile = {};
    if (user.email && !scUser.email) newData.email = user.email;
    if (user.fullName && !scUser.fullName) newData.fullName = user.fullName;
    if (user.photoURL && !scUser.photoURL) newData.photoURL = user.photoURL;
    if (Object.keys(newData).length) setDoc(userDoc, newData, { merge: true });
  }

  getUser() {
    return this.activeUser$.toPromise();
  }

  async setUserProfile(value: UserProfile) {
    const userDoc = doc(this.scCol, this.activeUser$.value.uid);
    await setDoc(userDoc, { ...value }, { merge: true });
  }

  getUserData(uid: string) {
    return docData<UserData>(doc(this.scCol, uid), { idField: 'uid' });
  }

  saveActiveOrg(orgID: string) {
    return setDoc(doc(this.scCol, this.authUser$.value.uid), { activeOrg: orgID }, { merge: true });
  }

  setActiveRecordingsTake(take: number) {
    if (!this.activeUser$.value || this.activeUser$.value.guest) {
      this.setAnonData({ activeRecordingsTake: take });
      return;
    }
    return setDoc(doc(this.scCol, this.activeUser$.value.uid), { activeRecordingsTake: take }, { merge: true });
  }

  async uploadUserProfilePicture(photoBase64) {
    const storageRef = ref(this.imageBucket, this.fireAuth.currentUser.uid);
    const profileRef = ref(storageRef, 'profile-image');
    await uploadString(profileRef, photoBase64, 'data_url');
    return await getDownloadURL(profileRef);
  }

  async uploadBetaFeedbackScreenshot(photoBase64) {
    const storageRef = ref(this.imageBucket, this.fireAuth.currentUser.uid);
    const feedbackRef = ref(storageRef, 'beta-feedback-image' + Math.floor(Math.random() * 100000));
    await uploadString(feedbackRef, photoBase64, 'data_url');
    return await getDownloadURL(feedbackRef);
  }

  async verifyEmail() {
    const protocol = window.location.protocol;
    const domain = window.location.host;
    const actionCodeSettings = {
      url: `${protocol}//${domain}?verifyEmail=true&email=${this.fireAuth.currentUser.email}`,
      handleCodeInApp: true,
    };
    if (this.fireAuth.currentUser.emailVerified) return;
    return sendEmailVerification(this.fireAuth.currentUser, actionCodeSettings);
  }

  checkUserVerification() {
    return this.fireAuth.currentUser.emailVerified;
  }
}
