import { Injectable } from '@angular/core';
import { Firestore, DocumentReference, doc, docData, setDoc } from '@angular/fire/firestore';
import { map } from 'rxjs';

import { SCSubject } from '../../util/sc-subject.class';
import { Equipment, SessionsCollection } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class VideoOffService {
  videoOff$ = new SCSubject<boolean>();
  constructor(
    private firestore: Firestore,
    private sessionsService: SessionsService,
    private userService: UserService
  ) {
    this.setupVideoOff();
  }

  setupVideoOff() {
    this.sessionsService.participantEquipment$.subscribe((equipment) => {
      this.videoOff$.next(equipment?.videoOff ?? false);
    });
  }

  setVideoOff(sessionID: string, castMemberID: string, videoOff: boolean) {
    if (castMemberID === this.userService.activeUser$.value.uid) this.videoOff$.next(videoOff);
    const docRef = doc(
      this.firestore,
      `sessions/${sessionID}/${SessionsCollection.PARTICIPANTS}/${castMemberID}/${SessionsCollection.ENV}/equipment`
    );
    setDoc(docRef, { videoOff }, { merge: true });
  }

  getVideoOff(sessionID: string, castMemberID: string) {
    const docRef = doc(
      this.firestore,
      `sessions/${sessionID}/${SessionsCollection.PARTICIPANTS}/${castMemberID}/${SessionsCollection.ENV}/equipment`
    );
    return docData<Equipment>(docRef).pipe(map((equipment) => equipment?.videoOff));
  }

  toggleVideo() {
    const sessionID = this.sessionsService.studioSession$.value.sessionID;
    const uid = this.userService.activeUser$.value.uid;
    return this.setVideoOff(sessionID, uid, !this.videoOff$.value);
  }
}
