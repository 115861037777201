import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Firestore,
  CollectionReference,
  collection,
  doc,
  addDoc,
  setDoc,
  collectionData,
  deleteDoc,
} from '@angular/fire/firestore';

import { environment } from '../../../environments/environment';
import { firstValueFrom, lastValueFrom, map, of, Subscription, switchMap } from 'rxjs';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { IdTokenService } from '../id-token/id-token.service';
import { ZapierService } from '../zapier/zapier.service';
import {
  ApiCollections,
  APIKey,
  DescriptFile,
  DescriptImport,
  Webhook,
  WebhookEvent,
  WebhookEventDescriptions,
  WebhookEventNames,
  WebhookPayload,
} from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class CalifoneService {
  califone = environment.microservices.califone;
  subs: Array<Subscription> = [];

  public availableWebhooks = [
    {
      name: WebhookEventNames.SESSION_CREATED,
      description: WebhookEventDescriptions.SESSION_CREATED,
    },
    {
      name: WebhookEventNames.SESSION_UPDATED,
      description: WebhookEventDescriptions.SESSION_UPDATED,
    },
    {
      name: WebhookEventNames.SESSION_DELETED,
      description: WebhookEventDescriptions.SESSION_DELETED,
    },
    {
      name: WebhookEventNames.SESSION_ENDED,
      description: WebhookEventDescriptions.SESSION_ENDED,
    },
    {
      name: WebhookEventNames.SESSION_STARTED,
      description: WebhookEventDescriptions.SESSION_STARTED,
    },
    {
      name: WebhookEventNames.AUDIO_RENDERED,
      description: WebhookEventDescriptions.AUDIO_RENDERED,
    },
    {
      name: WebhookEventNames.AUDIO_MIXED,
      description: WebhookEventDescriptions.AUDIO_MIXED,
    },
    {
      name: WebhookEventNames.AUDIO_MASTERED,
      description: WebhookEventDescriptions.AUDIO_MASTERED,
    },
    {
      name: WebhookEventNames.VIDEO_RENDERED,
      description: WebhookEventDescriptions.VIDEO_RENDERED,
    },
    {
      name: WebhookEventNames.PARTICIPANT_JOIN,
      description: WebhookEventDescriptions.PARTICIPANT_JOIN,
    },
    {
      name: WebhookEventNames.PARTICIPANT_LEFT,
      description: WebhookEventDescriptions.PARTICIPANT_LEFT,
    },
    {
      name: WebhookEventNames.RECORDING_STARTED,
      description: WebhookEventDescriptions.RECORDING_STARTED,
    },
    {
      name: WebhookEventNames.RECORDING_STOPPED,
      description: WebhookEventDescriptions.RECORDING_STOPPED,
    },
    {
      name: WebhookEventNames.RECORDING_TRANSFERRED,
      description: WebhookEventDescriptions.RECORDING_TRANSFERRED,
    },
    {
      name: WebhookEventNames.SQUADSHOT_TAKEN,
      description: WebhookEventDescriptions.SQUADSHOT_TAKEN,
    },
    {
      name: WebhookEventNames.SHOW_CREATED,
      description: WebhookEventDescriptions.SHOW_CREATED,
    },
    {
      name: WebhookEventNames.SHOW_DELETED,
      description: WebhookEventDescriptions.SHOW_DELETED,
    },
    {
      name: WebhookEventNames.SHOW_UPDATED,
      description: WebhookEventDescriptions.SHOW_UPDATED,
    },
  ];
  private orgCol: CollectionReference = collection(this.firestore, 'organizations');

  constructor(
    private http: HttpClient,
    private firestore: Firestore,
    private idTokenService: IdTokenService,
    private zapierService: ZapierService,
    private userService: UserService,
    private organizationsService: OrganizationsService
  ) {}

  /**
   * Sends Multiple Selected Files to Descript to generate an Import URL
   *
   * @param files - DescriptFile Array
   * @param is_video - True if files contain video
   * @returns
   */
  async editInDescript(files: DescriptFile[], is_video?: boolean) {
    const idToken = await this.idTokenService.getFreshIdToken();
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers };
    return await lastValueFrom<DescriptImport>(
      this.http.post(`${this.califone}/v2/descript`, { files, is_video }, options)
    );
  }

  /**
   * Loops through all webhooks setup in the active organizations and emits any webhook that matches the event name.
   *
   * @param event - WebhookEvent
   */
  async emitWebhookEvent(event: WebhookEvent, orgID: string) {
    const orgWebhooks = await firstValueFrom(this.getWebhooks(orgID));
    const idToken = await this.idTokenService.getFreshIdToken();
    const appWebhooksPromises = orgWebhooks.map(async (webhook: Webhook) => {
      const webhookSelected = webhook.events.find((eventName) => eventName.name === event.name) ? true : false;
      if (webhookSelected) {
        const webhookPayload: WebhookPayload = {
          webhook,
          event,
        };

        this.zapierService.saveExample(orgID, event);
        return await lastValueFrom(this.emitEventToCalifone(idToken, webhookPayload));
      }
    });

    await Promise.all(appWebhooksPromises);
  }

  createApiKey(cert: object, idToken: string) {
    return this.http.post(`${this.califone}/v2/keys`, { ...cert, idToken });
  }

  /**
   * Returns the snapshot changes for the Organization's api keys
   *
   * @param orgID : Organization ID
   * @returns Observable<APIKey>
   */
  getApiKeys(orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    const keyRef = collection(
      this.orgCol,
      orgID,
      'settings',
      ApiCollections.API,
      'keys'
    ) as CollectionReference<APIKey>;
    return collectionData(keyRef, {
      idField: 'id',
    });
  }

  /**
   * Generates a New API Key to the Organization API collection
   *
   * @param key : APIKey
   * @returns Promise<DocumentData>
   */
  async addApiKey(key: APIKey, orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    const keysRef = collection(this.orgCol, orgID, 'settings', ApiCollections.API, 'keys');
    return addDoc(keysRef, key).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(keysRef, error.message.split('/').at(-1));
    });
  }

  updateApiKey(key: APIKey, orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    return setDoc(doc(this.orgCol, orgID, 'settings', ApiCollections.API, 'keys', key.id), key, { merge: true });
  }

  revokeApiKey(keyID: string, revoked: boolean, orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    return setDoc(doc(this.orgCol, orgID, 'settings', ApiCollections.API, 'keys', keyID), { revoked }, { merge: true });
  }

  getWebhooks(orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    return collectionData<Webhook>(collection(this.orgCol, orgID, 'settings', 'webhooks', 'app'), { idField: 'id' });
  }

  /**
   * Given an Event, returns the webhook for that event the Organization has set up.
   *
   * @param specificEvent
   * @param orgID
   * @returns
   */
  getSpecificWebhooks(specificEvent: string, orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;

    // FIXME: Needs finished, not currently used though
    return collectionData<Webhook>(collection(this.orgCol, orgID, 'settings', 'webhooks', 'app'), {
      idField: 'id',
    }).pipe(
      map((webhooks) => {
        // console.log('WEBHOOKS: ', webhooks);
      })
    );

    // OLD CODE
    // const webhooksCol = this.orgCol
    //   .doc(orgID)
    //   .collection('settings')
    //   .doc('webhooks')
    //   .collection<Webhook>('app')
    //   .valueChanges({ idField: 'id' });

    // return webhooksCol.pipe(
    //   switchMap((webhooks) => {
    //     const specificWebhooks = webhooks
    //       ? Object.keys(webhooks)
    //           .map((webhookID: string) => {
    //             const webhook: Webhook = webhooks[webhookID];
    //             webhook.id = webhookID;
    //             webhook.events = webhook.events.filter((event: WebhookEvent) => event.name === specificEvent);

    //             return webhook;
    //           })
    //           .filter((webhook) => webhook.events.length > 0)
    //       : [];

    //     console.log(`🐛 🐞 specificWebhooks ➡ ${JSON.stringify(specificWebhooks, null, 2)} 🐞 🐛 `);
    //     return specificWebhooks;
    //   })
    // );
  }

  async addWebhook(webhook: Webhook, orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    const appRef = collection(this.orgCol, orgID, 'settings', 'webhooks', ApiCollections.APP);
    return addDoc(appRef, webhook).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(appRef, error.message.split('/').at(-1));
    });
  }

  async updateWebhook(orgID: string, webhook: Webhook) {
    const webhookID = webhook.id;
    return setDoc(doc(this.orgCol, orgID, 'settings', 'webhooks', ApiCollections.APP, webhookID), webhook, {
      merge: true,
    });
  }

  deleteWebhook(orgID: string, webhookID: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    return deleteDoc(doc(this.orgCol, orgID, 'settings', 'webhooks', ApiCollections.APP, webhookID));
  }

  getExampleWebhooks(orgID?: string) {
    if (!orgID) orgID = this.userService.activeUser$.value.activeOrg;
    const webhookRef = collection(
      this.orgCol,
      orgID,
      'settings',
      'webhooks',
      ApiCollections.EXAMPLE
    ) as CollectionReference<WebhookEvent>;
    return collectionData(webhookRef, { idField: 'id' });
  }

  async generateExampleWebhooks(orgID: string) {
    const webhooksRef = collection(this.orgCol, orgID, 'settings', 'webhooks', ApiCollections.EXAMPLE);
    return this.availableWebhooks.forEach((event) => {
      addDoc(webhooksRef, {
        event: [{ name: event }],
      }).catch((error) => {
        // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
        return doc(webhooksRef, error.message.split('/').at(-1));
      });
    });
  }

  emitEvent(body: { uid: string; id: string; event: string; url: string; payload: any; idToken: string }) {
    return this.http.post(`${this.califone}/v2/events`, body);
  }

  emitEventToCalifone(idToken: string, payload: WebhookPayload) {
    return this.http.post(`${this.califone}/v2/events`, { ...payload, idToken });
  }
}
