import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { PromptsService } from '../../services/prompts/prompts.service';
import { Prompt } from '@sc/types';

@Component({
  selector: 'sc-prompt',
  templateUrl: 'prompt.page.html',
  styleUrls: ['prompt.page.scss'],
})
export class PromptPage implements OnInit {
  @Input() prompt: Prompt;

  constructor(private promptsService: PromptsService) {}

  ngOnInit() {
    if (this.prompt) {
      this.prompt.buttons = this.promptsService.getPromptActions(this.prompt.type);

      // console.log('prompt modal', this.prompt);
    }
  }
}
