<ion-item *ngIf="details" class="recording-header no-hover">
  <ion-label>Primary Recordings</ion-label>
</ion-item>
<ion-spinner *ngIf="details && !recordingID && !screenID"></ion-spinner>
<div class="audio-progress" *ngIf="audioStats" tooltip="Audio upload progress">
  <ion-icon src="/assets/icons/32px/recordings-bold.svg"></ion-icon>
  <ion-progress-bar [value]="audioUploadProgress" [buffer]="audioUploadProgress"></ion-progress-bar>
  <ion-icon src="/assets/icons/24px/upload.svg"></ion-icon>
  <span class="progress-percentage">{{ (audioUploadProgress * 100).toFixed(1) }}%</span>
  <span class="time-estimate" *ngIf="audioUploadProgress < 1 && audioEstimate && !details">
    ~ {{ audioEstimate | date : 'mm:ss' }}
  </span>
</div>
<div *ngIf="details && audioStats">
  <ion-badge *ngIf="audioStats.trackSettings?.audio.sampleRate" tooltip="Recorded sample rate">
    {{ audioStats.trackSettings?.audio.sampleRate }} kHz
  </ion-badge>
  <ion-badge *ngIf="audioAvgBitrate" tooltip="Approximate bitrate">
    ~ {{ bitrateForDisplay(audioAvgBitrate) }}
  </ion-badge>
  <ion-badge *ngIf="audioStats.bytesRecorded" tooltip="Recorded size">
    {{ bytesForDisplay(audioStats.bytesRecorded) }}
  </ion-badge>
</div>
<div class="video-progress" *ngIf="videoStats" tooltip="Video upload progress">
  <ion-icon src="/assets/icons/24px/video-playback.svg"></ion-icon>
  <ion-progress-bar [value]="videoUploadProgress" [buffer]="videoUploadProgress"></ion-progress-bar>
  <ion-icon src="/assets/icons/24px/upload.svg"></ion-icon>
  <span class="progress-percentage">{{ (videoUploadProgress * 100).toFixed(1) }}%</span>
  <span class="time-estimate" *ngIf="videoUploadProgress < 1 && videoEstimate && !details">
    ~ {{ videoEstimate | date : 'mm:ss' }}
  </span>
</div>
<div *ngIf="details && videoStats">
  <ion-badge *ngIf="videoStats.trackSettings?.video.width" tooltip="Recorded resolution">
    {{ videoStats.trackSettings?.video.width }} x {{ videoStats.trackSettings?.video.height }}
  </ion-badge>
  <ion-badge *ngIf="videoStats.trackSettings?.video.frameRate" tooltip="Recorded frame rate">
    {{ videoStats.trackSettings?.video.frameRate }} fps
  </ion-badge>
  <ion-badge *ngIf="videoAvgBitrate" tooltip="Approximate bitrate">
    ~ {{ bitrateForDisplay(videoAvgBitrate) }}
  </ion-badge>
  <ion-badge *ngIf="videoStats.bytesRecorded" tooltip="Recorded size">
    {{ bytesForDisplay(videoStats.bytesRecorded) }}
  </ion-badge>
</div>
<div class="screen-progress" *ngIf="screenStats" tooltip="Screenshare upload progress">
  <ion-icon src="/assets/icons/24px/screenshare.svg"></ion-icon>
  <ion-progress-bar [value]="screenUploadProgress" [buffer]="screenUploadProgress"></ion-progress-bar>
  <ion-icon src="/assets/icons/24px/upload.svg"></ion-icon>
  <span class="progress-percentage">{{ (screenUploadProgress * 100).toFixed(1) }}%</span>
  <span class="progress-size" *ngIf="details">{{ bytesForDisplay(screenStats.bytesRecorded) }}</span>
  <span class="time-estimate" *ngIf="screenUploadProgress < 1 && screenEstimate && !details">
    ~ {{ screenEstimate | date : 'mm:ss' }}
  </span>
</div>
<div *ngIf="details && screenStats">
  <ion-badge *ngIf="screenStats.trackSettings?.video.width" tooltip="Recorded resolution">
    {{ screenStats.trackSettings?.video.width }} x {{ screenStats.trackSettings?.video.height }}
  </ion-badge>
  <ion-badge *ngIf="screenStats.trackSettings?.video.frameRate" tooltip="Recorded frame rate">
    {{ screenStats.trackSettings?.video.frameRate }} fps
  </ion-badge>
  <ion-badge *ngIf="screenAvgBitrate" tooltip="Approximate bitrate">
    ~ {{ bitrateForDisplay(screenAvgBitrate) }}
  </ion-badge>
  <ion-badge *ngIf="screenStats.bytesRecorded" tooltip="Recorded size">
    {{ bytesForDisplay(screenStats.bytesRecorded) }}
  </ion-badge>
</div>
