import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent, LoadedImage, CropperOptions } from 'ngx-image-cropper';
import { UserModel } from '@sc/types';

import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'sc-crop-photo',
  templateUrl: 'crop-photo.page.html',
  styleUrls: ['crop-photo.page.scss'],
})
export class CropPhotoPage implements OnInit {
  @Input() event: any;
  @Input() options: CropperOptions;

  user$ = this.userService.activeUser$;

  imageChangedEvent: any;
  imageCroppedEvent: ImageCroppedEvent;

  // https://github.com/Mawi137/ngx-image-cropper
  cropPhotoOptions = {
    maintainAspectRatio: true,
    aspectRatio: 1 / 1,
    resizeToWidth: 300,
    resizeToHeight: 300,
    roundCropper: true,
    containWithinAspectRatio: false, // will add padding to fit the aspect ratio
  };

  constructor(
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.imageChangedEvent = this.event;
    if (this.options) {
      this.cropPhotoOptions = { ...this.cropPhotoOptions, ...this.options };
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedEvent = event;
  }

  loadImageFailed() {
    this.toastrService.error(null, `Failed to crop photo, please try again or use a different photo.`, {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
    this.closeModal(false);
  }

  async closeModal(crop: boolean) {
    await this.analyticsService.track('closed crop photo');
    if (crop) await this.modalController.dismiss(this.imageCroppedEvent);
    else await this.modalController.dismiss();
  }
}
