import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  Auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  User as FireUser,
  UserCredential,
  AuthProvider,
} from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../services/auth/auth.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ProviderInfo } from '@sc/types';

@Component({
  selector: 'sc-verify-auth',
  templateUrl: 'verify-auth.page.html',
  styleUrls: ['verify-auth.page.scss'],
})
export class VerifyAuthPage implements OnInit {
  email: string;
  password: string;
  subs: Array<Subscription> = [];
  ssoLogin = false;
  emailLogin = false;
  activeProvider: string;
  creds: UserCredential;
  providerInfo: Record<string, ProviderInfo> = {
    'google.com': {
      name: 'Google',
      icon: 'assets/images/g-logo.png',
    },
    'facebook.com': {
      name: 'Facebook',
      icon: 'assets/icons/24px/facebook.svg',
    },
  };
  private userProvider: AuthProvider;
  private currentUser: FireUser;
  constructor(
    private fireAuth: Auth,
    private modalController: ModalController,
    private toastrService: ToastrService,
    private authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.currentUser = this.fireAuth.currentUser;
    this.activeProvider = this.currentUser.providerData[0].providerId;
    if (this.activeProvider !== 'password') {
      this.ssoLogin = true;
      if (!this.providerInfo.activeProvider) this.loadProviderInfo();
    }
    this.emailLogin = !!this.currentUser.providerData.find((provider) => provider.providerId === 'password');
    this.userProvider = this.authService.getProvider(this.activeProvider);
  }

  async loadProviderInfo() {
    this.providerInfo[this.activeProvider] = await this.authService.getProviderInfo(this.activeProvider);
  }

  verifySSO() {
    reauthenticateWithPopup(this.currentUser, this.userProvider)
      .then((userCred) => {
        this.close({ success: true, userCred });

        this.toastrService.success(null, `Successfully verified Account`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        this.analyticsService.track(`verified ${this.activeProvider} authentication`, { success: true });
      })
      .catch((error: Error) => {
        this.toastrService.error(error.message ? error.message : null, `Failed to verify Account`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        this.analyticsService.track(`verified ${this.activeProvider} authentication`, { success: false });
      });
  }

  async verifyEmailAuth() {
    if (!this.email || !this.password) {
      this.toastrService.warning(null, `Double-check your Email and Password`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      const provider = EmailAuthProvider;
      const authCred = provider.credential(this.email, this.password);
      const userCred = await reauthenticateWithCredential(this.currentUser, authCred);
      this.close({ success: true, userCred });
      this.toastrService.success(null, `Successfully verified Account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('verified email password authentication', { success: true });
    } catch (error) {
      this.toastrService.error(error.message ? error.message : null, `Failed to verify Account`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('verified email password authentication', { success: false });
    }
  }

  async close(outcome: { success: boolean; userCred: UserCredential }) {
    this.email = '';
    this.password = '';
    this.modalController.dismiss(outcome);
    await this.analyticsService.track('closed verify authentication', { success: outcome.success });
  }
}
