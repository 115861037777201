import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';

import { WalletService } from '../../services/wallet/wallet.service';
import { UserModel } from '@sc/types';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { CanceledStatus } from '@sc/types';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CanceledService {
  canceledStatus$: SCSubject<CanceledStatus> = new SCSubject();

  constructor(private walletService: WalletService, private router: Router) {
    this.setupDaysLeft();
  }

  setupDaysLeft() {
    this.walletService.customer$.subscribe((customer) => {
      if (!customer?.metadata?.orgID) {
        this.canceledStatus$.next(null);
        return;
      }

      const subscription = customer?.subscriptions.data.filter((sub) => !sub.plan.name.includes('Shadow'))[0];
      if (subscription) {
        if (subscription?.cancel_at === null) {
          this.canceledStatus$.next({
            daysLeft: 0,
            canceled: false,
            paymentMethodMissing: !customer?.default_source,
          });
          return;
        }
        const today = dayjs().startOf('day');
        const end = dayjs.unix(subscription.cancel_at);
        const daysLeft = end.diff(today, 'days');
        this.canceledStatus$.next({
          daysLeft: daysLeft < 0 ? 0 : daysLeft,
          canceled: true,
        });
      } else {
        this.canceledStatus$.next({
          daysLeft: 0,
          canceled: true,
          closed: true,
        });

        if (!this.router.url.includes('studio') && environment.production)
          this.router.navigate(['account/subscription']);
      }
    });
  }
}
