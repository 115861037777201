import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AmplionService } from '../../services/amplion/amplion.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CanceledService } from '../../services/canceled/canceled.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Plan } from '@sc/types';
import { UserModel } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { WalletService } from '../../services/wallet/wallet.service';

import dayjs from 'dayjs';
import { StripeSubscriptionResponse } from '@sc/types';

@Component({
  selector: 'sc-cancel-subscription-confirm',
  templateUrl: 'cancel-subscription-confirm.page.html',
  styleUrls: ['cancel-subscription-confirm.page.scss'],
})
export class CancelSubscriptionConfirmPage implements OnInit, OnDestroy {
  @Input() plan: Plan;

  user: UserModel;
  customerID: string;

  reason = '';
  reasons: Array<string> = [
    'No longer podcasting',
    'Missing features',
    'Using an alternative',
    'Reliability concerns',
    'Subscription pricing',
    'Guest experience',
  ];

  missingFeatures: Array<string> = ['Mobile apps', 'Live streaming', 'AV editing', 'Publishing'];
  missingFeature = '';

  waiting = false;
  subscriptions: StripeSubscriptionResponse[] = [];
  subs: Array<Subscription> = [];

  constructor(
    private amplionService: AmplionService,
    private analyticsService: AnalyticsService,
    private canceledService: CanceledService,
    private modalController: ModalController,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService
  ) {}

  ngOnInit() {
    this.setupUser();
    this.setupCustomerID();

    this.reasons = this.shuffle(this.reasons);
    this.missingFeatures = this.shuffle(this.missingFeatures);
  }

  async close() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed cancel subscription');
  }

  scheduleCancel() {
    this.waiting = true;
    let cancelTime = dayjs().unix();

    this.subscriptions.forEach((sub) => {
      if (sub.current_period_end > cancelTime) {
        cancelTime = sub.current_period_end;
      }
    });

    const updates = this.subscriptions.map((subscription) => {
      if (subscription.plan.amount) {
        return this.amplionService.updateSubscription({ cancel_at_period_end: true }, subscription.id);
      } else return this.amplionService.updateSubscription({ cancel_at: cancelTime }, subscription.id);
    });

    Promise.all(updates).then(async () => {
      this.analyticsService.track('scheduled subscription cancellation', { reason: this.reason });
      await this.walletService.refreshStripeCustomer();
      this.toastrService.success(
        `Your organization will stay open until then`,
        `Successfully scheduled the deletion of your Organization including its Shows, Recordings, and Data`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
      this.close();
      this.analyticsService.track('scheduled organization deletion', {
        hostID: this.user.uid,
        hostEmail: this.user.email,
        reason: this.reason,
        missingFeature: this.missingFeature,
        plan: this.plan.id,
      });
      this.waiting = false;
      //updated subscription email is triggered from stripe to zapier
    });
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });

    this.subs.push(sub);
  }

  setupCustomerID() {
    const sub = this.walletService.customerID$.subscribe((id) => {
      if (!id) {
        return;
      }

      this.customerID = id;
      this.setupSubscription();
    });
    this.subs.push(sub);
  }

  setupSubscription() {
    const sub = this.walletService.customer$.subscribe((customer) => {
      this.subscriptions = customer.subscriptions.data;
    });

    this.subs.push(sub);
  }

  async selectReason(event: Event) {
    const eventTarget: IEventTarget = event.target;
    this.missingFeature = '';

    this.reason = eventTarget.value;
    await this.analyticsService.track('selected cancellation reason', { reason: this.reason });
  }

  async selectMissingFeature(event: Event) {
    const eventTarget: IEventTarget = event.target;
    this.missingFeature = eventTarget.value;
    await this.analyticsService.track('selected missing features', { missingFeatures: this.missingFeatures });
  }

  // Fisher-Yates (aka Knuth) Shuffle
  shuffle(array: Array<string>) {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
