<div class="container">
  <h3>Move Session to Another Show</h3>

  <p>Moving session to another show will result in the following:</p>
  <ul>
    <li>The session will be visible under the new show rather than the current one.</li>
    <li>Only the members of the new show will be able to view the session in their dashboard.</li>
    <li>Past session invite links will no longer be valid.</li>
  </ul>

  <p>Transferring:</p>
  <div class="shows">
    <ng-select [(ngModel)]="moveFromShow" [readonly]="true" appearance="outline">
      <ng-option [value]="moveFromShow">
        <div class="moving-from-show">
          <img [src]="moveFromShow.showImg ?? defaultShowImage" slot="start" />

          <ion-label>
            <p class="pica-regular">{{ moveFromShow.showName }}</p>
            <p class="micro-bold">{{ session.sessionTitle }}</p>
          </ion-label>
        </div>
        <!-- <sc-show-list-item [show]="moveFromShow"></sc-show-list-item> -->
      </ng-option>
    </ng-select>

    <ion-icon src="assets/icons/24px/line-arrow-right.svg"></ion-icon>

    <ng-select
      *ngIf="availableShows.length > 0"
      [(ngModel)]="moveToShow"
      [clearable]="false"
      placeholder="Select a Show"
      appendTo="body"
      appearance="outline">
      <ng-option *ngFor="let show of availableShows" [value]="show">
        <sc-show-list-item [show]="show"></sc-show-list-item>
      </ng-option>
    </ng-select>

    <ng-select
      *ngIf="availableShows.length === 0"
      class="disabled"
      [readonly]="true"
      placeholder="No Shows Available"
      appearance="outline">
    </ng-select>
  </div>

  <div class="buttons">
    <ion-button (click)="moveSession()" [disabled]="!moveToShow">Move Session</ion-button>
    <ion-button (click)="cancel()" class="secondary">Cancel</ion-button>
  </div>
</div>
