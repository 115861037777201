import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AudioRecorderService } from '../../services/audio-recorder/audio-recorder.service';
import { RecordingService } from '../../services/recording/recording.service';
import { ScreenRecorderService } from '../../services/screen-recorder/screen-recorder.service';
import { StatsService } from '../../services/stats/stats.service';
import { VideoRecorderService } from '../../services/video-recorder/video-recorder.service';
import { Recording, RecordingStats } from '@sc/types';
import { StatsType } from '@sc/types';

@Component({
  selector: 'sc-upload-stats',
  templateUrl: './upload-stats.component.html',
  styleUrls: ['./upload-stats.component.scss'],
})
export class UploadStatsComponent implements OnInit, OnDestroy {
  @Input() recordingID: string;
  @Input() screenID: string;
  @Input() details = false;

  recording: Recording;
  screen: Recording;
  audioStats: RecordingStats;
  videoStats: RecordingStats;
  screenStats: RecordingStats;
  audioUploadProgress = 0;
  videoUploadProgress = 0;
  screenUploadProgress = 0;

  audioAvgBitrate = 0;
  videoAvgBitrate = 0;
  screenAvgBitrate = 0;

  audioLastUpdate: number;
  audioEstimate: number;
  videoLastUpdate: number;
  videoEstimate: number;
  videoEstimateArray = [];
  screenLastUpdate: number;
  screenEstimate: number;
  hasLocalStats = false;

  subs: Array<Subscription> = [];
  recordingSubs: Array<Subscription> = [];
  screenSubs: Array<Subscription> = [];

  constructor(
    private recordingService: RecordingService,
    private videoRecorderService: VideoRecorderService,
    private audioRecorderService: AudioRecorderService,
    private screenRecorderService: ScreenRecorderService,
    private statsService: StatsService
  ) {}

  ngOnInit() {
    this.setupChunkQueue();
    if (this.recordingID) this.setupRecording();
    if (this.screenID) this.setupScreen();
    if (!this.recordingID && !this.screenID) this.setupLocalRecording();
  }

  setupLocalRecording() {
    const sub = this.recordingService.localRecordingID$.subscribe((id) => {
      if (id !== this.recordingID) {
        this.recordingID = id;
        this.setupRecording();
      }
    });
    this.subs.push(sub);

    const sub2 = this.recordingService.localScreenID$.subscribe((id) => {
      if (id !== this.screenID) {
        this.screenID = id;
        this.setupScreen();
      }
    });
    this.subs.push(sub2);
  }

  setupRecording() {
    this.clearRecording();
    if (this.recordingID) {
      const sub = this.recordingService.getRecording(this.recordingID).subscribe((recording) => {
        this.recording = recording;
      });
      this.recordingSubs.push(sub);

      const audioSub = this.statsService.getRecordingStats(this.recordingID, StatsType.AUDIO).subscribe((stats) => {
        if (!stats) return;
        const avg = this.getAvgBitrate(stats);
        if (avg) this.audioAvgBitrate = avg;
        this.audioStats = stats;
        if (stats.totalChunks && !this.hasLocalStats)
          this.audioUploadProgress = 1 - stats.chunkQueueSize / stats.totalChunks;
      });
      this.recordingSubs.push(audioSub);

      const videoSub = this.statsService.getRecordingStats(this.recordingID, StatsType.VIDEO).subscribe((stats) => {
        if (!stats) return;
        const avg = this.getAvgBitrate(stats);
        if (avg) this.videoAvgBitrate = avg;
        this.videoStats = stats;
        if (stats.totalChunks && !this.hasLocalStats)
          this.videoUploadProgress = 1 - stats.chunkQueueSize / stats.totalChunks;
      });
      this.recordingSubs.push(videoSub);
    }
  }

  setupScreen() {
    this.clearScreen();
    if (this.screenID) {
      const sub = this.recordingService.getRecording(this.screenID).subscribe((recording) => {
        this.screen = recording;
      });
      this.screenSubs.push(sub);

      const screenSub = this.statsService.getRecordingStats(this.screenID, StatsType.SCREEN).subscribe((stats) => {
        if (!stats) return;
        const avg = this.getAvgBitrate(stats);
        if (avg) this.screenAvgBitrate = avg;
        this.screenStats = stats;
        if (stats.totalChunks && !this.hasLocalStats)
          this.screenUploadProgress = 1 - stats.chunkQueueSize / stats.totalChunks;
      });
      this.screenSubs.push(screenSub);
    }
  }

  setupChunkQueue() {
    const videoSub = this.videoRecorderService.recordingUpdate$.subscribe((recordingID) => {
      if (recordingID !== this.recordingID || !this.videoRecorderService.recordingInfo[recordingID]?.stats) return;
      const chunks = this.videoRecorderService.recordingInfo[recordingID].queue;
      const totalVideoChunks = this.videoRecorderService.recordingInfo[recordingID].stats.video.totalChunks;
      let progress = (totalVideoChunks - chunks.size) / totalVideoChunks;
      const initialProgress = progress;
      const timestamp = performance.now();
      chunks.forEach((partialProgress) => {
        progress += (partialProgress / totalVideoChunks) * 0.01;
      });
      if (initialProgress === progress && progress !== 1) return;
      if (this.videoLastUpdate) {
        const remainingPercent = 1 - progress;
        const timeSinceLastUpdate = timestamp - this.videoLastUpdate;
        const lastProgress = progress - this.videoUploadProgress;
        const newEstimate = (remainingPercent * timeSinceLastUpdate) / lastProgress;

        this.videoEstimateArray = this.videoEstimateArray.map((estimate) => estimate - timeSinceLastUpdate);
        this.videoEstimateArray.push(newEstimate);
        if (this.videoEstimateArray.length > 10) {
          this.videoEstimateArray.shift();
        }
        this.videoEstimate = this.videoEstimateArray.reduce((a, b) => a + b, 0) / this.videoEstimateArray.length;
      }
      if (progress) this.videoUploadProgress = progress;
      this.videoLastUpdate = timestamp;
      this.hasLocalStats = true;
    });

    this.subs.push(videoSub);

    const audioSub = this.audioRecorderService.recordingUpdate$.subscribe((recordingID) => {
      if (recordingID !== this.recordingID) return;
      const chunks = this.audioRecorderService.recordingInfo[recordingID]?.queue;
      if (!chunks) return;
      const totalAudioChunks = this.audioRecorderService.recordingInfo[recordingID].stats.audio.totalChunks;
      const progress = (totalAudioChunks - chunks.size) / totalAudioChunks;
      if (progress === this.audioUploadProgress && progress !== 1) return;
      const timestamp = performance.now();
      if (this.audioLastUpdate) {
        const remainingPercent = 1 - progress;
        const timeSinceLastUpdate = timestamp - this.audioLastUpdate;
        const lastProgress = progress - this.audioUploadProgress;
        const newEstimate = (remainingPercent * timeSinceLastUpdate) / lastProgress;
        this.audioEstimate = this.audioEstimate
          ? (this.audioEstimate - timeSinceLastUpdate + newEstimate) / 2
          : newEstimate;
      }
      if (progress) this.audioUploadProgress = progress;
      this.audioLastUpdate = timestamp;
      this.hasLocalStats = true;
    });

    this.subs.push(audioSub);

    const screenSub = this.screenRecorderService.recordingUpdate$.subscribe((recordingID) => {
      if (!recordingID || recordingID !== this.screenID) return;
      const chunks = this.screenRecorderService.recordingInfo[recordingID].queue;
      const totalScreenChunks = this.screenRecorderService.recordingInfo[recordingID].stats.screen.totalChunks;
      const progress = (totalScreenChunks - chunks.size) / totalScreenChunks;
      if (progress === this.screenUploadProgress && progress !== 1) return;
      const timestamp = performance.now();
      if (this.screenLastUpdate) {
        const remainingPercent = 1 - progress;
        const timeSinceLastUpdate = timestamp - this.screenLastUpdate;
        const lastProgress = progress - this.screenUploadProgress;
        if (lastProgress <= 0) return;
        const newEstimate = (remainingPercent * timeSinceLastUpdate) / lastProgress;
        this.screenEstimate = this.screenEstimate
          ? (this.screenEstimate - timeSinceLastUpdate + newEstimate) / 2
          : newEstimate;
      }
      if (progress) this.screenUploadProgress = progress;
      this.screenLastUpdate = timestamp;
      this.hasLocalStats = true;
    });

    this.subs.push(screenSub);
  }

  getAvgBitrate(stats: RecordingStats) {
    if (!this.details || !stats || !stats.chunkMap) return;
    const secondLastChunk = stats.chunkMap[stats.totalChunks - 1];
    const lastChunk = stats.chunkMap[stats.totalChunks];
    if (!secondLastChunk || !lastChunk) return;
    const bits = lastChunk.size * 8;
    const seconds = (lastChunk.timestamp - secondLastChunk.timestamp) / 1000;
    return bits / seconds;
  }

  bytesForDisplay(size) {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  bitrateForDisplay(bitrate) {
    if (bitrate > 999994) return (bitrate / 1000000).toFixed(2) + ' Mbit/s';
    return (bitrate / 1000).toFixed(2) + ' kbit/s';
  }

  clearRecording() {
    this.recordingSubs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.recordingSubs = [];
    this.recording = null;
    this.audioStats = null;
    this.videoStats = null;
    this.audioUploadProgress = 0;
    this.videoUploadProgress = 0;
  }

  clearScreen() {
    this.screenSubs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.screenSubs = [];
    this.screen = null;
    this.screenStats = null;
    this.screenUploadProgress = 0;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.recordingSubs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.screenSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
