export interface SupportIdentity {
  email: string;
  name: string;
  orgID?: string;
  customerID?: string;
  sessionID?: string;
}

export const SupportArticles = {
  fullHD: { article: '23103756955149' },
  backstageJoin: { article: '23104909801357' },
  recordingCountdown: { article: '23165978435213' },
  confTypes: { article: '23106197315853' },
  networkStruggling: { article: '23104878563981' },
  appFocus: { article: '23104851242253' },
  netConnSecurity: { article: '23103438304781' },
  selectEquipment: { article: '23106416046861' },
  wearHeadphones: { article: '23162667761293' },
  micSampleRate: { article: '23162775743629' },
  hideIncVideo: { article: '23104888133773' },
  echoCancellation: { article: '23162754997517' },
  recordingTimeRollover: { article: '23168148349453' },
  integrateDropbox: { article: '23163698509965' },
  integrateDescript: { article: '23163679156749' },
  integrateCaptivate: { article: '23163649387661' },
  integrateSavvyCal: { article: '23163505963277' },
  integrateZapier: { article: '23167611818509' },
  apiCompatability: { article: '23163014815629' },
  confQualityDifference: { article: '23103756955149' },
  systemRequirements: { article: '23103357808141' },
  cloudRecordings: { article: '23106234545933' },
  browserPermissions: { article: '23161935991437' },
  pilotProgram: { article: '23167638285837' },
  statsForNerds: { article: '23104278165773' },
  qualityStandards: { article: '23103533895309' },
  createRecordingSession: { article: '23106431721613' },
  recordingAudioTone: { article: '23165862190861' },
  joinLeaveTone: { article: '23165950689805' },
  enableChatInShow: { article: '23165762749709' },
  teamMembers: { article: '23167795916685' },
};

export type SupportTopics = keyof typeof SupportArticles;
