import { Injectable } from '@angular/core';

import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class IdTokenService {
  constructor(public userService: UserService) {
    this.log();
  }

  async log() {
    // console.log(await this.getFreshIdToken());
  }

  async getFreshIdToken() {
    const authUser = await this.userService.authUser$.toPromise();
    return authUser?.getIdToken(true);
  }
}
