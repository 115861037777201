import { Injectable } from '@angular/core';

import { SupportArticles, SupportIdentity, SupportTopics } from '@sc/types';

import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

const Articles = SupportArticles;

@Injectable({
  providedIn: 'root',
})
export class SupportCenterService {
  public supportData: SupportIdentity = { name: 'n/a', email: 'n/a' };
  public identified = false;

  ZENDESK_URL = 'https://help.descript.com/hc/en-us/articles/';

  constructor(private zenDesk: NgxZendeskWebwidgetService) {
    this.setupWidget();
  }

  async setupWidget() {
    await this.zenDesk.initZendesk();
    this.zenDesk.zE('webWidget', 'hide');
    this.zenDesk.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          ticketForms: [{ id: 18381828515853, title: false }],
        },
        chat: { suppress: true },
      },
    });
    this.zenDesk.zE('webWidget:on', 'close', () => {
      this.zenDesk.zE('webWidget', 'hide');
      this.zenDesk.zE('webWidget', 'updateSettings', { webWidget: { helpCenter: { suppress: false } } });
    });

    // Removes the label hint from the Summary field and hides custom Fields
    this.zenDesk.zE('webWidget:on', 'open', () => {
      const style = document.createElement('style');
      style.innerHTML = `
          #Embed .styles__Fields-sc-1lc3t9l-1.bVKEnC > div:nth-child(3) .Hint-sc-1qen11l-0,
          #Embed .styles__Fields-sc-1lc3t9l-1.bVKEnC > div:nth-child(5),
          #Embed .styles__Fields-sc-1lc3t9l-1.bVKEnC > div:nth-child(6),
          #Embed .styles__Fields-sc-1lc3t9l-1.bVKEnC > div:nth-child(7) {
            display: none;
          }`;
      const waitForWidget = setInterval(() => {
        const webWidget = parent.document.getElementById('webWidget') as HTMLIFrameElement;
        if (webWidget) {
          webWidget.contentDocument.head.appendChild(style);
          clearInterval(waitForWidget);
        }
      }, 100);
    });
  }

  /**
   * Opens the widget
   */
  show() {
    this.prefill();
    this.zenDesk.zE('webWidget', 'show');
    this.zenDesk.zE('webWidget', 'open');
  }

  /**
   * Opens the widget directly to the contact form
   */
  showContactForm() {
    this.zenDesk.zE('webWidget', 'updateSettings', { webWidget: { helpCenter: { suppress: true } } });
    this.show();
  }


  /**
   * Opens the given topic in a new tab
   *
   * @param topic - SupportTopics
   */
  topic(topic: SupportTopics) {
    window.open(this.ZENDESK_URL + Articles[topic].article, '_blank');
  }

  /**
   * Prefills the widget contact form from our data
   */
  prefill() {
    const fields = [];
    // Field IDs can be found in Zendesk Admin > Object and rules > Tickets > Fields
    if (this.supportData.orgID) fields.push({ id: 18381664228493, prefill: { '*': this.supportData.orgID } });
    if (this.supportData.customerID) fields.push({ id: 18383029165837, prefill: { '*': this.supportData.customerID } });
    if (this.supportData.sessionID) fields.push({ id: 18383207360909, prefill: { '*': this.supportData.sessionID } });

    this.zenDesk.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: { fields },
      },
    });
  }

  /**
   * Updates the identity metadata of the widget
   *
   * @param identity - SupportIdentity
   */
  identify(identity: SupportIdentity) {
    if (this.identified || !this.zenDesk.zE) return;
    this.supportData.email = identity.email;
    this.supportData.name = identity.name;
    this.zenDesk.zE('webWidget', 'identify', identity);
    this.zenDesk.zE('webWidget', 'prefill', {
      name: { value: identity.name, readOnly: false },
      email: { value: identity.email, readOnly: false },
    });
    this.identified = true;
  }
}
