import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { UserModel } from '@sc/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { environment } from '../../../environments/environment';
import { Webhook, WebhookEvent } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

@Component({
  selector: 'sc-new-webhook',
  templateUrl: 'new-webhook.page.html',
  styleUrls: ['new-webhook.page.scss'],
})
export class NewWebhookPage implements OnInit {
  @Input() user: UserModel;
  user$ = this.userService.activeUser$;

  url = '';
  events: WebhookEvent[];

  constructor(
    private userService: UserService,
    private modalController: ModalController,
    private toastrService: ToastrService,
    public califoneService: CalifoneService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.events = this.califoneService.availableWebhooks;
  }

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('cancelled create webhook');
  }

  async create() {
    const selectedEvents: Array<{ name: string }> = this.events
      .filter((event) => event.isChecked)
      .map((event) => {
        return { name: event.name };
      });

    if (!selectedEvents.length || !this.url) {
      this.toastrService.error(`Select an Event and provide a URL`, `Failed to create Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (environment.production && (this.url.includes('localhost') || this.url.includes('127.0.0.1'))) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
        `Consider using Localtunnel instead of localhost for testing`,
        `Failed to create Webhook`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.link = 'https://localtunnel.github.io/www';

      return;
    }

    if (environment.production && !this.url.includes('https')) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
        `HTTPS is required`,
        `Failed to create Webhook`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.link = 'https://www.eff.org/encrypt-the-web';

      return;
    }

    try {
      const newWebhook: Webhook = {
        url: this.url,
        events: selectedEvents,
      };

      await this.califoneService.addWebhook(newWebhook);

      this.toastrService.success(null, `Successfully created Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('created webhook', { success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to create Webhook`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('created webhook', { success: false });
    }
  }
}
