import { Directive, ElementRef, AfterContentInit, Input } from '@angular/core';

@Directive({
  selector: '[scAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public appAutoFocus: boolean;

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    const focusTimeout = setTimeout(() => {
      clearInterval(focusInterval);
    }, 3 * 1000);
    const focusInterval = setInterval(() => {
      const inputEl = this.el.nativeElement.children[0];
      if (inputEl?.tagName === 'INPUT') {
        inputEl.focus();
      } else {
        this.el.nativeElement.focus();
      }
      if (document.activeElement.tagName !== 'BODY') {
        clearInterval(focusInterval);
        clearTimeout(focusTimeout);
      }
    }, 200);
  }
}
