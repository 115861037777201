<h3 class="reuglar">{{ title }}</h3>

<div class="body">
  <img *ngIf="img" [src]="img" [tooltip]="title" />
  <ion-icon
    *ngIf="icon || iconSrc"
    [src]="iconSrc ?? '/assets/icons/24px/' + icon + '.svg'"
    [tooltip]="title"></ion-icon>

  <p *ngIf="enableHTML" [innerHTML]="message"></p>
  <p *ngIf="!enableHTML">{{ message }}</p>
</div>

<div class="buttons">
  <ion-button
    *ngFor="let button of buttons"
    scThrottleClick
    (throttledClick)="button.handler()"
    [class]="button.type ? [button.type] : 'secondary'">
    {{ button.label }}
  </ion-button>
</div>
