import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { environment } from './../../../environments/environment';
import { Link } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  headers: any = { headers: { 'Content-Type': 'application/json' }, responseType: 'text' };
  image = 'https://squadcast.fm/wp-content/uploads/2021/01/SC_V3SocialImage.png';

  constructor(private http: HttpClient, private sessionsService: SessionsService) {}

  getShortLink(link: Link) {
    link.url = encodeURIComponent(link.url);
    link.imageUrl = encodeURIComponent(link.imageUrl);

    return firstValueFrom(
      this.http.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebase.apiKey}`,
        {
          longDynamicLink: `https://${environment.firebase.dynamicLinksURL}/?link=${link.url}&st=${link.title}&sd=${
            link.description
          }&si=${link.imageUrl ?? this.image}`,
          suffix: { option: 'UNGUESSABLE' },
        },
        this.headers
      )
    );
  }

  async getSquadshotShareLink(url: string, sessionID: string) {
    const session = await firstValueFrom(this.sessionsService.getSessionByID(sessionID));
    const shortLinkResponse: any = await this.getShortLink({
      url,
      title: `${session.showTitle} | ${session.sessionTitle} | Squadshot`,
      description: `Recorded on SquadCast.fm`,
      imageUrl: url,
    });
    return JSON.parse(shortLinkResponse).shortLink;
  }
}
