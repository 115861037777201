import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { APIKey } from '@sc/types';
import { RoleLimits } from '@sc/types';
import { Show } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';

@Component({
  selector: 'sc-set-default-show-api',
  templateUrl: 'set-default-show-api.page.html',
  styleUrls: ['set-default-show-api.page.scss'],
})
export class SetDefaultShowAPIPage {
  @Input() dashboardOrg: string;
  @Input() shows: Show[];
  @Input() key: APIKey;

  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;
  showSelectToggled = false;
  show: Show;

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private rolesService: RolesService,
    private califoneService: CalifoneService
  ) {}

  async cancel() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed revoke api key', { apiKeyID: this.key.id });
  }

  async confirm() {
    try {
      await this.califoneService.updateApiKey(this.key, this.dashboardOrg);

      this.toastrService.success(null, `Successfully set backwards compatibility show to the API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('set backwards compatibility show', { apiKeyID: this.key.id, success: true });
    } catch (error) {
      this.toastrService.error(null, `Failed to set backwards compatibility show to the API Secret Key`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.modalController.dismiss();
      await this.analyticsService.track('set backwards compatibility show', { apiKeyID: this.key.id, success: false });
    }
  }

  async changeShow() {
    this.key.defaultShowID = this.show.showID;
  }
}
