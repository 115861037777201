import { Timestamp } from 'firebase-admin/firestore';

// FireStore Document for Descript Drive
export interface DescriptDrive {
  driveID?: string; // Descript Drive ID
  lastSynced: Timestamp; // Last time the drive was checked against Descript
  drivePlan?: DescriptDriveEntitlement; // Descript Drive Plan
  created?: Timestamp; // Date Drive was created
  claimed?: boolean; // Drive Claimed Status
  orgID?: string; // Organization ID that was linked to this Drive
  linkStatus?: 'NOT_STARTED' | 'STARTED' | 'COMPLETE';
  entitlements?: DescriptDriveEntitlement; // first setup does not yet include entitlements
  driveName?: string; // Descript Drive Name
}
export interface DescriptBillingQueryResponse {
  driveID?: string; // Descript Drive ID
  drivePlan?: DescriptDriveEntitlement; // Descript Drive Plan
  drive_name?: string; // Descript Drive Name
  product_group?: string; // Descript Product Group
  plan_refresh_date?: number; // Date of next billing cycle
  monthly_transcription_hours?: number; // Total Hours
  error: { statusCode?: number; error?: string; message?: string }; // Error Message from err.request.data
}

export interface DescriptLinkResponse {
  driveID: string; // Descript Drive ID
  isDriveOwner: boolean; // Is the user the owner of the Descript Drive
  claimed: boolean; // Drive Claimed Status
  preApprovedOrgID?: string; // Organization ID to auto link with
  compositionName?: string; // Descript Composition Name
}

export interface DescriptDriveEntitlement {
  type?: DescriptPlanNames; // Plan Type
  billingCycleDate?: number; // Date of next billing cycle
  totalMonthlyHours?: number; // Total Hours
  planID?: string; // Descript Plan ID
}

export enum DescriptPlanNames {
  FREE = 'descript_free',
  CREATOR = 'descript_creator',
  PRO = 'descript_pro',
  ENTERPRISE = 'descript_enterprise',
  EDU = 'descript_education_orgs',
  BUSINESS = 'descript_business',
}

export interface DescriptFile {
  name: string;
  uri: string;
  start_offset: StartOffsetWithSeconds;
}

export interface StartOffsetWithSeconds {
  seconds: number;
}

export interface DescriptImport {
  url?: string;
}
