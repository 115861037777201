import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IPadService {
  constructor() {}

  check() {
    // https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
    if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return true;
    } else {
      return false;
    }
  }
}
