<div
  *ngIf="plan"
  class="plan"
  [class.disabled]="disabled"
  [class.highlight]="(plan.popular || appearance === 'compact') && appearance !== 'account'"
  [class.no-extra-cost]="compactState === 'no-plan'"
  [class.descript]="plan.isDescriptPlan">
  <ion-badge
    *ngIf="
      plan.popular && plan.isDescriptPlan && appearance !== 'compact' && appearance !== 'account' && sale === undefined
    ">
    MOST POPULAR
  </ion-badge>
  <ion-badge *ngIf="plan.isDescriptPlan && appearance === 'compact'"> New </ion-badge>
  <ion-badge *ngIf="!plan.isDescriptPlan" class="legacy"> Legacy plan </ion-badge>
  <ion-badge *ngIf="plan.discount && sale === undefined" class="savings"> ${{ plan.discount }} Savings </ion-badge>
  <ion-badge
    *ngIf="sale !== undefined && sale.plans.includes(plan.id)"
    id="sale-badge"
    [style.backgroundColor]="sale.style.backgroundColor"
    [style.color]="'white'">
    ON SALE!
  </ion-badge>

  <h4 class="bold" *ngIf="appearance !== 'compact'">{{ plan.name }}</h4>
  <h4 class="bold" *ngIf="['claim', 'upgrade'].includes(compactState)">{{ plan.name }} + SquadCast</h4>
  <h4 class="bold" *ngIf="['no-plan', 'account'].includes(compactState)">SquadCast + Descript</h4>
  <p *ngIf="appearance === 'upgrade'">[v{{ plan.version }}]</p>

  <p class="micro-regular" *ngIf="appearance !== 'compact'">
    {{ plan.isDescriptPlan ? plan.description : 'Legacy SquadCast Plan' }}
  </p>

  <h1 *ngIf="!plan.isDescriptPlan" class="regular">
    <span [class.strike]="salePrice && appearance !== 'account'">
      {{ disabled ? 'Unavailable' : '$' + plan.price }}
    </span>

    <span *ngIf="salePrice && appearance !== 'account'"> ${{ salePrice }} </span>
  </h1>

  <h1
    *ngIf="
      plan.isDescriptPlan &&
      !plan.enterprise &&
      (appearance !== 'compact' || ['claim', 'upgrade'].includes(compactState))
    "
    class="regular">
    <span> {{ plan.price === 0 ? 'Free' : '$' + plan.price }} </span>
  </h1>
  <h1 *ngIf="plan.isDescriptPlan && ['no-plan', 'account'].includes(compactState)" class="regular">
    <span>No Extra Cost</span>
  </h1>

  <small *ngIf="plan.isDescriptPlan && !plan.enterprise && compactState !== 'no-plan' && plan.price > 0">
    per editor / month
  </small>
  <small *ngIf="plan.interval === 'Monthly' && !plan.isDescriptPlan">USD MONTHLY</small>
  <small *ngIf="plan.interval === 'Yearly' && !plan.isDescriptPlan">USD YEARLY</small>

  <ion-list *ngIf="!plan.isDescriptPlan">
    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>{{ plan.recordingHours }} Hours</strong> of <span *ngIf="!plan.videoRecording">Audio</span
        ><span *ngIf="plan.videoRecording">Video</span> Recording
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>{{ plan.shows }} Show{{ plan.shows === 'Unlimited' || plan.shows > 1 ? 's' : '' }}</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none" *ngIf="plan.seats > 1">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>{{ plan.seats }} Team Member</strong> Seats
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none"
      >6
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>{{ plan.inStudio }} In Studio</strong> Participants
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none" *ngIf="plan.backstage > 1">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>{{ plan.backstage }} Backstage</strong> Participants
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>{{ plan.integrations }} Integrations</strong> + Zapier
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon
        [src]="plan.audioMixing ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
        [class.grey]="!plan.audioMixing"
        slot="start"></ion-icon>

      <ion-label [class.strikeout]="!plan.audioMixing"> <strong>Mix Audio</strong> with Auphonic </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon
        [src]="plan.dolbyVoice ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
        [class.grey]="!plan.dolbyVoice"
        slot="start"></ion-icon>

      <ion-label [class.strikeout]="!plan.dolbyVoice"> <strong>Dolby Voice</strong> Conferencing </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label> <strong>SquadCast API</strong> Access </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon
        [src]="plan.videoRecording ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
        [class.grey]="!plan.videoRecording"
        slot="start"></ion-icon>

      <ion-label [class.strikeout]="!plan.videoRecording"> <strong>Video + Screen</strong> Recording </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon
        [src]="plan.dolbyMastering ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
        [class.grey]="!plan.dolbyMastering"
        slot="start"></ion-icon>

      <ion-label [class.strikeout]="!plan.dolbyMastering"> <strong>Master Audio</strong> with Dolby </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="plan.isDescriptPlan && appearance !== 'compact'">
    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong *ngIf="plan.price === 0">1 hour of Remote recording</strong>
        <strong *ngIf="plan.price > 0">{{ plan.recordingHours }} hours of Remote recording per editor</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong *ngIf="plan.price === 0">1 hour of transcription / month</strong>
        <strong *ngIf="plan.price > 0">{{ plan.recordingHours }} hours of transcription per editor</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong *ngIf="plan.price === 0">1 watermark-free export / month</strong>
        <strong *ngIf="plan.price > 0">Unlimited watermark-free export</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong *ngIf="plan.price === 0">720p video export resolution</strong>
        <strong *ngIf="plan.price > 0">4K video export resolution</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none" *ngIf="plan.backstage > 1">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>Filter word removal</strong>
      </ion-label>
    </ion-item>

    <ion-item class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>Studio sound</strong>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="plan.isDescriptPlan && appearance === 'compact'">
    <ion-item *ngIf="compactState !== 'account'" class="condensed" lines="none">
      <strong>Everything in your Descript plan, and:</strong>
    </ion-item>
    <ion-item *ngIf="compactState !== 'no-plan'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/line-arrow-up.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>More video recording hours</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="['account', 'upgrade'].includes(compactState)" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>Fast, accurate transcriptions</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="['account', 'upgrade'].includes(compactState)" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>Text-based editor</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="['account', 'upgrade'].includes(compactState)" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label>
        <strong>AI editing features to look & sound better</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="compactState === 'claim'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/line-arrow-up.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>More recording participants</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="compactState === 'claim'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>Keep all existing SquadCast features</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="compactState === 'no-plan'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap"> <strong>Remote recording</strong> (up to 10 people) </ion-label>
    </ion-item>

    <ion-item *ngIf="compactState === 'no-plan'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>Main and backstage participants</strong>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="compactState === 'no-plan'" class="condensed" lines="none">
      <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

      <ion-label class="ion-text-wrap">
        <strong>High quality local recordings with backups</strong>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-button *ngIf="appearance === 'compact'" (click)="redeemOffer()" expand="block" class="primary">
    Redeem Offer
  </ion-button>

  <div *ngIf="appearance === 'compact' && ['claim', 'upgrade'].includes(compactState)" class="sub-text">
    Once redeemed, you'll no longer be billed by SquadCast.
  </div>

  <ion-button
    *ngIf="appearance === 'upgrade'"
    (click)="select(plan)"
    [disabled]="disabled"
    class="primary"
    expand="block"
    size="large">
    <span *ngIf="!disabled && appearance === 'upgrade'">
      <span class="sr-only"
        >Change to the {{ plan.name }} plan. Includes {{ plan.recordingHours }} hours of
        {{ plan.videoRecording ? 'video' : 'audio' }} recording every month, {{ plan.shows }} show{{
          plan.shows > 1 || plan.shows === 'Unlimited' ? 's' : ''
        }}, {{ plan.seats }} team member seats, {{ plan.inStudio }} in studio participants,
        {{ plan.backstage }} backstage participants, and much more! Price is {{ plan.price }} U.S. dollars
        {{ plan.interval }}.
      </span>
      Select Plan
    </span>
    <span *ngIf="disabled">Unavailable</span>
  </ion-button>

  <ion-row *ngIf="appearance === 'account'" id="subscription-buttons" class="ion-no-padding">
    <ion-col
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE && !(canceledStatus$ | async)?.canceled && plan.isDescriptPlan"
      class="ion-no-padding">
      <ion-button
        aria-label="Update Subscription"
        (click)="manageSubscription()"
        expand="block"
        class="primary sc-colors">
        Manage Subscription
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="(role$ | async) >= roleLimits.ORG_UPDATE && !(canceledStatus$ | async)?.canceled && plan.isDescriptPlan"
      class="ion-no-padding">
      <ion-button aria-label="Sync Subscription" (click)="syncSubscription()" expand="block" class="line sc-colors">
        Sync Subscription
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="(role$ | async) >= roleLimits.ORG_DELETE && !(canceledStatus$ | async)?.canceled && !plan.isDescriptPlan"
      class="ion-no-padding ion-text-center">
      <ion-button aria-label="Cancel Subscription" (click)="openCancelSubscriptionModal()" class="line">
        Cancel Subscription
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="
        (role$ | async) >= roleLimits.ORG_CREATE &&
        (canceledStatus$ | async)?.canceled &&
        !(canceledStatus$ | async)?.closed
      "
      class="ion-no-padding">
      <ion-button
        aria-label="Reactivate Subscription"
        (click)="reactivateSubscription()"
        class="sc-colors"
        [disabled]="reactivatingSubscription"
        expand="block">
        Reactivate Subscription
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="(role$ | async) >= roleLimits.ORG_CREATE && (canceledStatus$ | async)?.closed"
      class="ion-no-padding">
      <ion-button
        aria-label="Reactivate Subscription"
        (click)="reactivateSubscription()"
        expand="block"
        class="sc-colors">
        Reactivate Subscription
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="(role$ | async) >= roleLimits.ORG_DELETE && (canceledStatus$ | async)?.closed"
      class="ion-no-padding">
      <ion-button
        aria-label="Delete Organization"
        (click)="deleteOrganization()"
        class="danger sc-colors"
        expand="block">
        Delete Organization
      </ion-button>
    </ion-col>

    <ion-col
      *ngIf="
        (role$ | async) >= roleLimits.ORG_DELETE &&
        (canceledStatus$ | async)?.canceled &&
        !(canceledStatus$ | async)?.closed
      "
      class="ion-no-padding">
      <ion-button aria-label="Remove Benefits Now" (click)="openRemoveBenefitsModal()" expand="block" class="secondary">
        Remove Benefits Now
      </ion-button>
    </ion-col>
  </ion-row>
</div>
