import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { updatePassword } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { VerifyAuthPage } from '../verify-auth/verify-auth.page';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { FireAuthErrors } from '@sc/types';
import { IdTokenService } from '../../services/id-token/id-token.service';

@Component({
  selector: 'sc-edit-password',
  templateUrl: 'edit-password.page.html',
  styleUrls: ['edit-password.page.scss'],
})
export class EditPasswordPage implements OnInit, OnDestroy {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  subs: Array<Subscription> = [];

  constructor(
    private modalController: ModalController,
    private toastrService: ToastrService,
    private userService: UserService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private idTokenService: IdTokenService
  ) {}

  ngOnInit() {
    this.setupUser();
  }

  async savePassword() {
    if (!this.email || !this.currentPassword || !this.newPassword) {
      this.toastrService.warning(null, `Double-check your Email, current Password, and new Password`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.toastrService.warning(null, `New Password and Confirmation must match`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      return;
    }

    try {
      await this.idTokenService.getFreshIdToken();
      const login = await this.authService.loginWithEmail(this.email, this.currentPassword, false);
      await updatePassword(login.user, this.newPassword);
      this.close();

      this.toastrService.success(null, `Successfully updated Password`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
      await this.analyticsService.track('saved password', { success: true });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        this.openVerifyAuthModal();
      } else {
        const niceError = FireAuthErrors[error.code] || error.message;
        this.toastrService.error(niceError, `Failed to update Password`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });

        await this.analyticsService.track('saved password', { success: false });
      }
    }
  }

  close() {
    this.email = '';
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';

    this.modalController.dismiss();
  }

  async openVerifyAuthModal() {
    const modal = await this.modalController.create({
      component: VerifyAuthPage,
      componentProps: {},
      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      cssClass: 'verify-auth-modal',
    });
    await modal.present();
    await this.analyticsService.track('opened verify authentication');
    await modal.onDidDismiss().then((resp) => {
      if (resp.data.success) this.savePassword();
    });
  }

  setupUser() {
    const sub = this.userService.activeUser$.subscribe((user) => {
      this.email = user.email;
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
