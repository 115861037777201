<ion-button aria-label="Close" (click)="close()" *ngIf="options.closeButton" id="close-button" class="text">
  <ion-icon src="assets/icons/24px/close.svg" slot="icon-only"> </ion-icon>
</ion-button>

<ion-progress-bar [value]="100" class="accent-progress-bar"> </ion-progress-bar>

<div id="content">
  <div class="left-col">
    <ion-icon
      id="thumbs-up-button"
      (click)="thumbsUp = true"
      src="/assets/icons/24px/thumbs-up.svg"
      [class.active]="thumbsUp">
    </ion-icon>
    <ion-icon
      id="thumbs-down-button"
      (click)="thumbsUp = false"
      src="/assets/icons/24px/thumbs-down.svg"
      [class.active]="!thumbsUp">
    </ion-icon>
  </div>
  <div class="right-col">
    <p class="action-medium">Please rate the quality of your session</p>
    <textarea
      [(ngModel)]="feedback"
      (keyup.enter)="submitFeedback()"
      placeholder="Provide a little feedback to help us improve your experience."></textarea>
    <div class="feedback-buttons">
      <ion-item class="feedback-checkbox ion-no-padding" lines="none">
        <ion-label>Don't ask again</ion-label>
        <ion-checkbox slot="start" mode="md" (ionChange)="dontAskAgain($event)"></ion-checkbox>
      </ion-item>

      <ion-button aria-label="Submit" (click)="submitFeedback()" class="primary" size="small"> Submit </ion-button>
    </div>
  </div>
</div>

<ion-progress-bar class="option-progress-bar" [value]="progressValue" *ngIf="options.progressBar"> </ion-progress-bar>
