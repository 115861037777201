<div class="video-container">
  <sc-player *ngIf="videoURL" [url]="videoURL" [type]="sessionType"></sc-player>
  <div *ngIf="!videoURL" class="no-video">
    <ion-progress-bar type="indeterminate" color="secondary"></ion-progress-bar>
    <h6>Waiting for the media...</h6>
  </div>
</div>

<div class="results">
  <div
    class="result"
    *ngFor="let result of resultsList"
    [class]="result.state"
    (click)="expand(result.title, $event)"
    [class.expanded]="expandedResultTitle === result.title">
    <ion-icon [src]="result.icon"></ion-icon>
    <h6>{{result.title}}</h6>
    <p>{{result.description}}</p>
  </div>
</div>

<ion-button (click)="dismiss()">Dismiss</ion-button>
