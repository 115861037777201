<h1 *ngIf="state === 'no-plan'">You're getting Remote Recording for free</h1>
<h1 *ngIf="state === 'claim'">Why pay double?</h1>
<h1 *ngIf="state === 'upgrade'">Get more out of your subscription</h1>

<sc-plan
  *ngIf="state !== 'upgrade'"
  [plan]="linkPlan$ | async"
  [overrideClaim]="true"
  (claimCallback)="claim()"
  [compactState]="state"
  appearance="compact"></sc-plan>

<sc-plan
  *ngIf="state === 'upgrade'"
  [plan]="recommendedDescriptPlan"
  [overrideClaim]="true"
  (claimCallback)="claim()"
  [compactState]="state"
  appearance="compact"></sc-plan>

<a class="compare-plans" href="https://www.descript.com/pricing?utm_source=squadcast" target="_blank">
  <strong>Compare all Descript Plans</strong>
</a>

<sc-drive-select [showOrgSelect]="true"></sc-drive-select>
