import { Injectable } from '@angular/core';
import { Firestore, CollectionReference, collection, doc, setDoc, collectionSnapshots } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import { Organization, Tour, ToursCollections } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';

import Shepherd from 'shepherd.js';
import { ShowsService } from '../shows/shows.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { SessionDTO } from '@sc/types';

import * as dayjs from 'dayjs';
import { IonAccordion, IonAccordionGroup, PopoverController } from '@ionic/angular';
import { SessionsService } from '../sessions/sessions.service';
import { Router } from '@angular/router';
import { WalletService } from '../wallet/wallet.service';
import { RecordingsService } from '../recordings/recordings.service';
import { RolesService } from '../roles/roles.service';
import { Roles } from '@sc/types';
import { AnalyticsService } from '../analytics/analytics.service';
import { ResponsiveService } from '../responsive/responsive.service';
import { StudioSidebarService } from '../studio-sidebar/studio-sidebar.service';
import { ChatService } from '../chat/chat.service';
import { firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ToursService {
  tours$ = new SCSubject<Tour[]>();

  private defaultStepOptions: Shepherd.Step.StepOptions = {
    modalOverlayOpeningPadding: 2,
    modalOverlayOpeningRadius: 4,
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  };

  private activeUser$ = this.userService.activeUser$;
  private gettingRecordingTour: Shepherd.Tour;
  private studioTour: Shepherd.Tour;
  private dashboardTour: Shepherd.Tour;
  private greenroomTour: Shepherd.Tour;

  private scCol: CollectionReference = collection(this.firestore, 'SquadCasters');

  constructor(
    private firestore: Firestore,
    private organizationsService: OrganizationsService,
    private popoverController: PopoverController,
    private sessionsService: SessionsService,
    private showsService: ShowsService,
    private walletService: WalletService,
    private analyticsService: AnalyticsService,
    private recordingsService: RecordingsService,
    private router: Router,
    private rolesService: RolesService,
    private userService: UserService,
    private responsiveService: ResponsiveService,
    private studioSidebarService: StudioSidebarService,
    private chatService: ChatService
  ) {
    this.setupTours();
  }

  /**
   * Will reset all the tours and will call startTours to start touring.
   */
  async resetTours(tours?: ToursCollections[]) {
    await this.activeUser$.toPromise();
    if (tours?.length) {
      for (const tour of tours) {
        this.resetTour(tour);
      }
    } else {
      this.resetTour(ToursCollections.GETTING_RECORDING);
      this.resetTour(ToursCollections.DASHBOARD);
      this.resetTour(ToursCollections.GREENROOM);
      this.resetTour(ToursCollections.STUDIO);
    }
    this.startTours();
  }

  /**
   * Will trigger tours that are not completed to start.
   */
  async startTours() {
    await this.organizationsService.dashboardOrg$.nextExistingValue((org: Organization) => org.planID);
    await this.tours$.toPromise();
    if (!this.tours$.value || !this.tours$.value.length) return;
    if (
      this.router.url.includes('checkout') ||
      this.router.url.includes('auth') ||
      this.router.url.includes('descript-info')
    )
      return;

    if (this.router.url.includes('studio')) {
      if (
        this.tours$.value.find((t) => t.id === ToursCollections.STUDIO).isCompleted === false &&
        this.activeUser$.value.guest === false &&
        this.rolesService.role$.value >= Roles.SHOW_MANAGER &&
        document.querySelector<HTMLButtonElement>('#studio-controls-record-button')
      ) {
        Shepherd.activeTour?.cancel();
        this.studioTour.start();
        this.analyticsService.track(`started tour - ${ToursCollections.STUDIO}`);
        return;
      }

      if (
        this.tours$.value.find((t) => t.id === ToursCollections.GREENROOM).isCompleted === false &&
        this.activeUser$.value.guest === false &&
        document.querySelector<HTMLButtonElement>('sc-green-room #join')
      ) {
        Shepherd.activeTour?.cancel();
        this.greenroomTour.start();
        this.analyticsService.track(`started tour - ${ToursCollections.GREENROOM}`);
        return;
      }

      return;
    }

    if (this.tours$.value.find((t) => t.id === ToursCollections.GETTING_RECORDING).isCompleted === false) {
      await this.router.navigate(['/dashboard']);
      await new Promise((resolve) => setTimeout(resolve, 0));

      Shepherd.activeTour?.cancel();
      this.gettingRecordingTour.start();
      this.analyticsService.track(`started tour - ${ToursCollections.GETTING_RECORDING}`);
      return;
    }

    if (this.tours$.value.find((t) => t.id === ToursCollections.DASHBOARD).isCompleted === false) {
      await this.router.navigate(['/dashboard']);
      await new Promise((resolve) => setTimeout(resolve, 0));

      Shepherd.activeTour?.cancel();
      this.dashboardTour.start();
      this.analyticsService.track(`started tour - ${ToursCollections.DASHBOARD}`);
      return;
    }
  }

  /**
   * Will reset an individual tour.
   *
   * @param tourDoc The tour to reset.
   */
  async resetTour(tourDoc: ToursCollections) {
    if (this.activeUser$.value?.guest) return;
    const tour: Tour = {
      isCompleted: false,
    };
    const docRef = doc(this.scCol, `${this.activeUser$.value.uid}/tours/${tourDoc}`);
    setDoc(docRef, tour);
  }

  private async setupTours() {
    (await this.getTours()).subscribe((tours) => {
      const fromServer = !tours.some((tour) => tour.metadata.fromCache || tour.metadata.hasPendingWrites);
      if (fromServer) {
        const tourData = tours.map((tour) => ({ ...tour.data(), id: tour.id }));
        this.tours$.next(tourData);
      }
    });
    // this.tours$.subscribe((tours) => {
    //   this.startTours();
    // });

    this.setupGettingRecordingTour();
    this.setupStudioTour();
    this.setupDashboardTour();
    this.setupGreenroomTour();
  }

  private backButton(tour: Shepherd.Tour) {
    return {
      action: tour.back,
      classes: 'sc grey ion-float-left',
      text: 'Back',
    };
  }

  private nextButton(tour: Shepherd.Tour) {
    return {
      action: () => {
        tour.next();
      },
      classes: 'sc primary ion-float-left',
      text: 'Next',
    };
  }

  private finishButton(tour: Shepherd.Tour) {
    return {
      action: () => {
        tour.next();
      },
      classes: 'sc primary ion-float-left',
      text: 'Finish Tour',
    };
  }

  private skipButton(tour: Shepherd.Tour) {
    return {
      action: tour.complete,
      classes: 'sc text ion-float-right',
      text: 'Skip Tour',
    };
  }

  private addCustomHeader(tour: Shepherd.Tour, header = null, offset = 0) {
    const shepherdContainer = tour.getCurrentStep().getElement().querySelector('.shepherd-content');

    const currentStep = tour.steps.indexOf(tour.getCurrentStep()) + 1 - offset;
    const totalSteps = tour.steps.length - offset;

    if (header) {
      const title = document.createElement('p');
      title.innerText = header;
      title.classList.add('shepherd-tour-name');
      shepherdContainer.prepend(title);
    }

    if (currentStep && totalSteps > 1) {
      const progress = document.createElement('div');
      progress.classList.add('shepherd-progress-bar');
      progress.style.width = `calc(${(currentStep / totalSteps) * 100}% - 2px)`;

      const steps = document.createElement('span');
      steps.classList.add('shepherd-progress-steps');
      steps.innerText = `${currentStep}/${totalSteps}`;

      shepherdContainer.prepend(progress);
      shepherdContainer.append(steps);
    }
  }

  // Tour docs: https://shepherdjs.dev/docs/Step.html

  /**
   * Sets up the getting recording tour
   */
  private setupGettingRecordingTour() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: { ...this.defaultStepOptions, canClickTarget: true },
    });
    tour.addStep({
      buttons: [
        {
          action: tour.next,
          classes: 'sc primary ion-float-left',
          text: 'Start Recording',
        },
        this.skipButton(tour),
      ],
      title: `Let's Get Recording`,
      text: [
        `SquadCast is the Cloud Recording Studio that makes every show look and sound better.  Try it out for yourself and let's record your show's intro!`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        await this.setupIntroSession();
        document.querySelector<HTMLButtonElement>('#upcoming-sessions-tab-header')?.click();
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: '.join-session',
        on: 'bottom',
      },
      advanceOn: { selector: '.join-session', event: 'click' },
      title: `Join your Session`,
      text: [`Here is a session to get you started, join now!`],
    });
    tour.on('cancel', () => {
      this.completeTour(ToursCollections.GETTING_RECORDING);
    });
    tour.on('complete', () => {
      this.completeTour(ToursCollections.GETTING_RECORDING);
    });
    this.gettingRecordingTour = tour;
  }

  /**
   * Creates a special tour session for recording an intro
   */
  private async setupIntroSession() {
    const upcoming = await this.sessionsService.upcomingSessions$.toPromise();
    if (upcoming.length) return;
    const shows = await this.showsService.availableShows$.toPromise();
    const show = shows[0];
    const orgID = this.organizationsService.dashboardOrg$.value.orgID;
    const dateTime = dayjs();
    const unixDate = dateTime.unix();
    const startTime = dateTime.format('hh:mm A');
    const endTime = dateTime.add(15, 'minutes').format('hh:mm A');
    const introSession: SessionDTO = {
      sessionTitle: 'Show Intro',
      showID: show.showID,
      showTitle: show.showName,
      orgID,
      startTimestamp: unixDate,
      startTime,
      endTime,
      videoEnabled: this.walletService.dashboardPlan$.value.videoRecording,
      date: dateTime.toDate(),
      take: 0,
    };
    await this.sessionsService.saveSessionNew(introSession);
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
  }

  /**
   * Sets up the studio tour
   */
  private setupStudioTour() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        ...this.defaultStepOptions,
        when: {
          show: () => this.addCustomHeader(tour, 'Studio Tour'),
        },
      },
    });
    tour.addStep({
      attachTo: {
        element: '#studio-controls-record-button',
        on: 'top',
      },
      advanceOn: { selector: '#studio-controls-record-button', event: 'click' },
      title: `Hit Record to get started!`,
      text: [
        `Record a quick take, you will be able to review your recording almost immediately once you have finished.`,
      ],
      buttons: [this.skipButton(tour)],
      modalOverlayOpeningPadding: 9999,
      canClickTarget: true,
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('recordings', true);
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: 'sc-recordings-takes',
        on: 'left',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Your Recordings`,
      text: [
        `Here you can see all of your recordings, and actively monitor their status. The progress bar will appear around 8 second mark and will tell you how much of the recording has been uploaded. You can also see the progress bar for your guests' recordings.`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('participants', true);
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: 'sc-participants',
        on: 'left',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Participants`,
      text: [
        `Here you can see all of the participants, and their equipment. You can even toggle their echo cancellation or prompt them to change their equipment!`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('participants', true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        const accordion = document.querySelector('sc-participant-equipment ion-accordion') as HTMLElement;
        if (
          !accordion?.classList?.contains('accordion-expanded') &&
          !accordion?.classList?.contains('accordion-expanding')
        ) {
          accordion?.querySelector('ion-item')?.click();
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        await new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: 'sc-participant-equipment',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Participant Equipment`,
      text: [
        `Now you can see the equipment of the participant, what browser they are using, their audio bitrate and video properties, and if they have echo cancellation on.`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('chat', true);
        this.studioSidebarService.openChatGroup();
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: 'sc-chat',
        on: 'left',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Chat Groups`,
      text: [
        `A show manager can create chat groups and add participants to them. There are also general chat groups that all participants can see, "Everyone", "The Stage", and "Backstage".`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('chat', true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        try {
          const chatGroups = await firstValueFrom(
            this.chatService.getParticipantChatGroups(
              this.sessionsService.studioSessionID$.value,
              this.activeUser$.value.uid
            )
          );
          if (chatGroups.length === 0) {
            const group = await this.chatService.createChatGroup(
              this.sessionsService.studioSessionID$.value,
              'My Chat Group',
              true
            );
            this.studioSidebarService.openChatGroup(group.id);
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
          if (chatGroups.length > 0) {
            this.studioSidebarService.openChatGroup(chatGroups[0].chatGroupID);
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
        } catch (e) {
          console.error(e);
          tour.next();
        }
      },
      attachTo: {
        element: 'sc-chat-group #chat-box #chat-box-tour-container',
        on: 'top',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Chat`,
      text: [
        `Here, you can send messages to the chat! You can also mention other participants. To mention someone, type "@" and then their name. You can also use commands in the chat. To see a list of commands, type "/".`,
      ],
      canClickTarget: true,
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar('settings', true);
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: 'sc-name-menu',
        on: 'right',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: `Settings Menu`,
      text: [
        `Here, you can change your name, equipment and echo cancellation. Show managers can also change session related settings here like "Conference Type" or "All Participants Echo Cancellation".`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.studioSidebarService.toggleSidebar();
        return new Promise((resolve) => setTimeout(resolve, 200));
      },
      attachTo: {
        element: '#studio-controls-record-button',
        on: 'top',
      },
      advanceOn: { selector: '#studio-controls-record-button', event: 'click' },
      buttons: [this.backButton(tour), this.skipButton(tour)],
      title: `Stop Recording`,
      text: [`Don't forget to stop recording when you're done!`],
      canClickTarget: true,
    });
    tour.addStep({
      attachTo: {
        element: '#studio-controls-squadshot-button',
        on: 'top',
      },
      advanceOn: { selector: '#studio-controls-squadshot-button', event: 'click' },
      buttons: [this.finishButton(tour)],
      title: `Finally, the SquadShot`,
      text: [`Take a SquadShot with your guest when you're done and share it with us on our socials!`],
      canClickTarget: true,
    });
    tour.on('cancel', () => {
      this.completeTour(ToursCollections.STUDIO);
    });
    tour.on('complete', () => {
      this.completeTour(ToursCollections.STUDIO);
    });
    this.studioTour = tour;
  }

  /**
   * Sets up the dashboard tour
   */
  private setupDashboardTour() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        ...this.defaultStepOptions,
        when: {
          show: () => this.addCustomHeader(tour, 'Dashboard Tour', 1),
        },
      },
    });
    tour.addStep({
      showOn: () => this.tours$.value.find((t) => t.id === ToursCollections.STUDIO).isCompleted,
      buttons: [this.nextButton(tour), this.skipButton(tour)],
      title: `You've Experienced the Studio!`,
      text: [`You are almost a pro, why not explore the rest of the SquadCast dashboard?`],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.responsiveService.mobileNavOpen$.next(true);
        return new Promise((resolve) => setTimeout(() => resolve(true), 200));
      },
      attachTo: {
        element: '#shows-list',
        on: 'right',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Shows',
      text: [
        `The shows panel allow you to manage and control multiple shows directly from the dashboard. You can create new shows by clicking the "+" button.`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        this.responsiveService.mobileNavOpen$.next(false);
        return new Promise((resolve) => setTimeout(() => resolve(true), 200));
      },
      attachTo: {
        element: '.search-container',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Search',
      text: [`Search your recordings, sessions, and shows directly from here!`],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        document.querySelector<HTMLButtonElement>('#upcoming-sessions-tab-header')?.click();
        await new Promise((resolve) => setTimeout(() => resolve(true), 200));
      },
      attachTo: {
        element: '.dashboard-left',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Sessions',
      text: [`You can find your Upcoming Sessions and mark your favorite sessions here.`],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        const recent = document.querySelector<HTMLElement>('#recent-recordings-tab-header');
        if (recent.clientHeight || recent.clientWidth) {
          recent?.click();
          await new Promise((resolve) => setTimeout(() => resolve(true), 200));
        }
      },
      attachTo: {
        element: '.recent-recordings-panel',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Recent Recordings',
      text: [
        `Any new recordings will appear here. Click on the session to view all recordings for that session. The "See All" button will take you to the full list of past sessions.`,
      ],
    });
    tour.addStep({
      attachTo: {
        element: '.recent-filter',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Filter Recent Recordings',
      text: [`Click here to switch between viewing Primary HD Recordings, Conference Backups, & SquadShots.`],
    });
    tour.addStep({
      attachTo: {
        element: '.profile-button',
        on: 'bottom',
      },
      buttons: [this.backButton(tour), this.finishButton(tour)],
      title: 'Profile and Settings',
      text: [`Lastly, be sure to check your Profile and Settings for your account here.`],
    });
    tour.on('complete', () => {
      this.completeTour(ToursCollections.DASHBOARD);
      this.responsiveService.mobileNavOpen$.next(false);
    });
    this.dashboardTour = tour;
  }

  /**
   * Sets up the green-room tour
   */
  private setupGreenroomTour() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        ...this.defaultStepOptions,
        when: {
          show: () => this.addCustomHeader(tour),
        },
      },
    });
    tour.addStep({
      buttons: [this.nextButton(tour), this.skipButton(tour)],
      title: `This is the Greenroom`,
      text: [`Here you can test your equipment and make sure everything is working properly.`],
    });
    tour.addStep({
      attachTo: {
        element: 'sc-green-room #wearing-headphones-toggle',
        on: 'right',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Headphones Improve Quality',
      text: [
        `Make sure to set this toggle to ON if you are wearing headphones and OFF if you are not wearing headphones. This will set up echo cancellation for you in order to achieve the best quality.`,
      ],
    });
    tour.addStep({
      beforeShowPromise: async () => {
        if (
          !document.querySelector('sc-green-room #change-equipment-accordion')?.classList.contains('accordion-expanded')
        ) {
          document.querySelector<HTMLElement>('sc-green-room #change-equipment-accordion ion-item')?.click();
          await new Promise((resolve) => setTimeout(() => resolve(true), 200));
        }
      },
      attachTo: {
        element: 'sc-green-room #change-equipment-accordion',
        on: 'right',
      },
      buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
      title: 'Using the Right Equipment',
      text: [`Click here to adjust your equipment to make sure you are using the correct equipment.`],
    });
    // tour.addStep({
    //   attachTo: {
    //     element: 'sc-green-room #test-equipment-button',
    //     on: 'right',
    //   },
    //   buttons: [this.backButton(tour), this.nextButton(tour), this.skipButton(tour)],
    //   title: 'Testing your Equipment',
    //   text: [
    //     `Here you can test your equipment with a short 7 second recording to make sure everything is working properly.`,
    //   ],
    // });
    tour.addStep({
      attachTo: {
        element: 'sc-green-room .joinButtonContainer',
        on: 'right',
      },
      advanceOn: {
        selector: 'sc-green-room .joinButtonContainer',
        event: 'click',
      },
      buttons: [this.backButton(tour), this.finishButton(tour)],
      title: 'Ready to Go?',
      text: [`Click here when you are ready to start the session.`],
      canClickTarget: true,
    });
    tour.on('complete', () => {
      this.completeTour(ToursCollections.GREENROOM);
    });
    this.greenroomTour = tour;
  }

  /**
   * Get all tour documents for the user
   */
  private async getTours() {
    await this.activeUser$.toPromise();
    const colRef = collection(this.scCol, `${this.activeUser$.value.uid}/tours`);
    return collectionSnapshots<Tour>(colRef);
  }

  /**
   * Sets the setting for a given tour
   */
  private completeTour(tourDoc: ToursCollections) {
    this.analyticsService.track(`completed tour - ${tourDoc}`);
    const docRef = doc(this.scCol, `${this.activeUser$.value.uid}/tours/${tourDoc}`);
    return setDoc(
      docRef,
      {
        isCompleted: true,
      },
      { merge: true }
    );
  }
}
