<h1 class="ion-text-center">Crop Image</h1>

<!-- https://www.npmjs.com/package/ngx-image-cropper -->
<image-cropper
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="cropPhotoOptions.maintainAspectRatio"
  [aspectRatio]="cropPhotoOptions.aspectRatio"
  format="png"
  (imageCropped)="imageCropped($event)"
  [resizeToWidth]="cropPhotoOptions.resizeToWidth"
  [resizeToHeight]="cropPhotoOptions.resizeToHeight"
  (loadImageFailed)="loadImageFailed()"
  [roundCropper]="cropPhotoOptions.roundCropper"
  [containWithinAspectRatio]="cropPhotoOptions.containWithinAspectRatio"></image-cropper>

<div class="button-container">
  <ion-button (click)="closeModal(false)" expand="block" fill="clear" shape="round"> Cancel </ion-button>
  <ion-button (click)="closeModal(true)" expand="block" fill="clear" shape="round" class="primary"> Done </ion-button>
</div>
