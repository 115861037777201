import { Injectable } from '@angular/core';
import { Firestore, setDoc, collection } from '@angular/fire/firestore';
import { doc } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { IdTokenService } from '../id-token/id-token.service';

import { SessionsService } from '../sessions/sessions.service';
import { SettingsService } from '../settings/settings.service';
import { SlackService } from '../slack/slack.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private feedbackCol = collection(this.firestore, 'feedback');

  constructor(
    private firestore: Firestore,
    private userService: UserService,
    private settingsService: SettingsService,
    private sessionsServcie: SessionsService,
    private idTokenService: IdTokenService,
    private slackService: SlackService
  ) {}

  setAskFeedback(value: boolean) {
    this.settingsService.setUserAppSettings({ askFeedback: value });
  }

  async submitFeedback(thumbsUp = true, feedback = '', email: string = '') {
    const feedbackObject = {
      thumbsUp,
      feedback,
      date: new Date().toISOString(),
      email,
      user: { ...this.userService.activeUser$.value } || 'Guest',
      session: this.sessionsServcie.studioSession$.value || 'Guest',
    };

    setDoc(doc(collection(this.firestore, 'feedback')), feedbackObject, { merge: true });

    const feedbackMessage = {
      thumbsUp,
      feedback,
      date: new Date().toISOString(),
      email,
      user: this.userService.activeUser$.value,
      session: this.sessionsServcie.studioSession$.value,
    };

    if (feedback) {
      const idToken = await this.idTokenService.getFreshIdToken();
      await firstValueFrom(this.slackService.sendMessage(feedbackMessage, idToken));
    }
  }
}
