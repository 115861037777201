import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';
import { IdTokenService } from '../id-token/id-token.service';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private idTokenService: IdTokenService,
    private cookiesService: CookiesService
  ) {}

  async buildHeaders() {
    const idToken = await this.idTokenService.getFreshIdToken();
    const clientID = this.cookiesService.getAnalyticsClientID();
    return new HttpHeaders().set('idToken', idToken).set('clientid', clientID);
  }

  async get<T>(url: string) {
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.get<T>(url, { headers }));
  }

  async patch<T>(url: string, body: object) {
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.patch<T>(url, body, { headers }));
  }

  async post<T>(url: string, body: object) {
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.post<T>(url, body, { headers }));
  }

  async put<T>(url: string, body: object) {
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.put<T>(url, body, { headers }));
  }

  async delete<T>(url: string, body: object) {
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.delete<T>(url, { headers, body }));
  }
}
