import { Component, OnInit, OnDestroy, HostListener, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';
import { ItemReorderEventDetail, PopoverController } from '@ionic/angular';

import { Show } from '@sc/types';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { ShowsService } from '../../services/shows/shows.service';
import { OrganizationsMenuComponent } from '../../menus/organizations-menu/organizations-menu.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { RoleLimits } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { SettingsService } from '../../services/settings/settings.service';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'sc-shows-nav',
  templateUrl: './shows-nav.component.html',
  styleUrls: ['./shows-nav.component.scss'],
})
export class ShowsNavComponent implements OnInit, OnDestroy {
  @Input() bannerShown: boolean;
  @ViewChild('reorderGroup') reorderGroup: { el: HTMLElement };
  dashboardShow$ = this.showsService.dashboardShow$;
  shows$ = this.showsService.availableShows$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  dashboardPlan$ = this.walletService.dashboardPlan$;
  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;
  mobileNavOpen$ = this.responsiveServcie.mobileNavOpen$;
  reorderDisabled = true;
  reorderTimeout: NodeJS.Timeout;
  lockTimeout: NodeJS.Timeout;
  selectedAt: number;
  shows: Show[] = [];
  showOrder = [];
  browser = this.deviceDetectorService.browser;

  subs: Array<Subscription> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private deviceDetectorService: DeviceDetectorService,
    private organizationsService: OrganizationsService,
    private popoverController: PopoverController,
    private responsiveServcie: ResponsiveService,
    private rolesService: RolesService,
    private router: Router,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private supportCenterService: SupportCenterService,
    private walletService: WalletService
  ) {}

  @HostListener('document:click', ['$event'])
  click(event: Event) {
    if (!this.reorderGroup.el.contains(event.target as Node)) {
      this.reorderGroup.el.classList.remove('head-shake');
      this.reorderDisabled = true;
      clearTimeout(this.lockTimeout);
      clearTimeout(this.reorderTimeout);
    }
  }

  @HostListener('keyup', ['$event'])
  async Esc(event: KeyboardEvent) {
    if (this.mobileNavOpen$.value && event.key === 'Escape') {
      this.mobileNavOpen$.next(false);
    }
  }

  ngOnInit() {
    this.setupShows();
    this.setupShowOrder();
  }

  pointerDown(e: PointerEvent, show: Show) {
    if (e.button !== 0) return;
    clearTimeout(this.lockTimeout);
    clearTimeout(this.reorderTimeout);
    this.selectedAt = e.timeStamp;
    this.reorderTimeout = setTimeout(() => {
      if (this.reorderDisabled) this.reorderGroup.el.classList.add('head-shake');
      this.reorderDisabled = false;
    }, 1000);
    this.lockTimeout = setTimeout(() => {
      this.reorderGroup.el.classList.remove('head-shake');
      this.reorderDisabled = true;
    }, 10000);
  }

  pointerUp(e: PointerEvent, show: Show) {
    if (e.button !== 0) return;
    if (e.timeStamp - this.selectedAt < 1000) {
      clearTimeout(this.reorderTimeout);
      this.selectShow(show);
    }
    clearTimeout(this.lockTimeout);
    this.lockTimeout = setTimeout(() => {
      this.reorderGroup.el.classList.remove('head-shake');
      this.reorderDisabled = true;
    }, 5000);
  }

  // Only allow left-click to drag ion-reorder
  mouseDown(e: MouseEvent) {
    if (e.button !== 0) e.stopPropagation();
  }

  doReorder(ev: Event) {
    const customEvent = ev as CustomEvent<ItemReorderEventDetail>;
    const shows = this.shows$.value;
    this.reorderGroup.el.classList.remove('head-shake');
    const target = this.reorderGroup.el.children[customEvent.detail.from].children[0];

    target.classList.add('border-highlight');
    const newOrder = customEvent.detail.complete(shows);
    this.settingsService.setUserOrgSettings(this.dashboardOrg$.value.orgID, {
      showOrder: newOrder.map((show) => show.showID),
    });
    setTimeout(() => {
      target.classList.remove('border-highlight');
    }, 500);
  }

  toggleReorder() {
    this.reorderDisabled = !this.reorderDisabled;
  }

  setupShows() {
    const sub = this.shows$.subscribe((shows) => {
      if (this.showOrder) {
        this.shows = shows.sort((a, b) => {
          return this.showOrder.indexOf(a.showID) - this.showOrder.indexOf(b.showID);
        });
      } else {
        this.shows = shows;
      }
    });
    this.subs.push(sub);
  }

  noShows() {
    this.supportCenterService.topic('teamMembers');
  }

  async setupShowOrder() {
    const sub = this.dashboardOrg$
      .pipe(
        switchMap((org) => {
          return this.settingsService.getUserOrgSettings();
        })
      )
      .subscribe((settings) => {
        if (settings?.showOrder) {
          this.showOrder = settings.showOrder;
          this.shows.sort((a, b) => {
            return settings.showOrder.indexOf(a.showID) - settings.showOrder.indexOf(b.showID);
          });
        }
      });
    this.subs.push(sub);
  }

  async toggleOrganizationsView(event) {
    const organizationsMenu = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: OrganizationsMenuComponent,
      cssClass: 'organizations-menu',
      event,
    });

    await organizationsMenu.present();
    await this.analyticsService.track('opened organizations');
  }

  async goHome() {
    this.showsService.setDashboardShow(null);
    this.mobileNavOpen$.next(false);
    await this.router.navigate(['dashboard'], { replaceUrl: true });
    await this.analyticsService.track('clicked home');
  }

  async goSearch() {
    this.showsService.setDashboardShow(null);
    this.mobileNavOpen$.next(false);
    await this.router.navigate(['search']);
    await this.analyticsService.track('clicked recordings');
  }

  async selectShow(show: Show) {
    if (!this.reorderDisabled) return;
    this.mobileNavOpen$.next(false);
    this.showsService.setDashboardShow(show.showID);
    this.router.navigate(['dashboard'], { replaceUrl: true });
    await this.analyticsService.track('selected show', {
      showID: show.showID,
      showName: show.showName,
      showOwner: show.showOwner,
    });
  }

  async openAddShow() {
    await this.router.navigate(['dashboard/add-show'], { replaceUrl: true });
    await this.analyticsService.track('clicked add show');
  }

  async logo() {
    await this.goHome();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
