<div (window:resize)="onResize($event)">
  <ion-app>
    <ion-router-outlet id="main-content"> </ion-router-outlet>
  </ion-app>
</div>
<span class="sr-only" role="alert" aria-live="assertive" aria-atomic="true" id="aria-alert"></span>
<sc-descript-info
  *ngIf="(loading$ | async)?.length"
  [title]="loading$ | async"
  description="This should only take a moment"></sc-descript-info>
