<svg
  *ngIf="role >= roles.ORG_ADMIN"
  width="14"
  height="14"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0.8915 1.413L5 0.5L9.1085 1.413C9.21953 1.43768 9.31883 1.49948 9.39001 1.5882C9.46118 1.67692 9.49998 1.78726 9.5 1.901V6.8945C9.49996 7.38837 9.378 7.87459 9.14494 8.31001C8.91188 8.74542 8.57493 9.11657 8.164 9.3905L5 11.5L1.836 9.3905C1.42514 9.11662 1.08823 8.74555 0.855174 8.31023C0.622118 7.8749 0.500118 7.38878 0.5 6.895V1.901C0.50002 1.78726 0.538819 1.67692 0.609995 1.5882C0.681171 1.49948 0.780468 1.43768 0.8915 1.413ZM1.5 2.302V6.8945C1.50001 7.22373 1.58129 7.54787 1.73663 7.83814C1.89197 8.12842 2.11658 8.37586 2.3905 8.5585L5 10.2985L7.6095 8.5585C7.88335 8.37591 8.10791 8.12855 8.26325 7.83836C8.41859 7.54818 8.49991 7.22415 8.5 6.895V2.302L5 1.525L1.5 2.302ZM5 5.5C4.66848 5.5 4.35054 5.3683 4.11612 5.13388C3.8817 4.89946 3.75 4.58152 3.75 4.25C3.75 3.91848 3.8817 3.60054 4.11612 3.36612C4.35054 3.1317 4.66848 3 5 3C5.33152 3 5.64946 3.1317 5.88388 3.36612C6.1183 3.60054 6.25 3.91848 6.25 4.25C6.25 4.58152 6.1183 4.89946 5.88388 5.13388C5.64946 5.3683 5.33152 5.5 5 5.5ZM2.7635 8C2.82411 7.4492 3.08584 6.94014 3.49854 6.57036C3.91124 6.20059 4.44588 5.99611 5 5.99611C5.55412 5.99611 6.08876 6.20059 6.50146 6.57036C6.91416 6.94014 7.17589 7.4492 7.2365 8H2.7635Z"
    fill="#A06DFA" />
</svg>

<div *ngIf="role < roles.ORG_ADMIN" [style.backgroundColor]="'var(--sc-color-' + roleColors[role] + '-hex)'">
  {{ roleNames[role] }}
</div>
