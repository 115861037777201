import { Injectable, OnDestroy } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  doc,
  addDoc,
  setDoc,
  docData,
  collectionData,
  deleteDoc,
} from '@angular/fire/firestore';
import Stripe from 'stripe';
import { of, Subscription, switchMap } from 'rxjs';

import { AmplionService } from '../amplion/amplion.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { PricingService } from '../pricing/pricing.service';
import { UserService } from '../user/user.service';

import { Transaction } from '@sc/types';
import { Plan } from '@sc/types';
import { Wallet, Ledger, StripeCustomer, StripeSubscriptionResponse, Coupon } from '@sc/types';

import * as dayjs from 'dayjs';
import * as dayjsDuration from 'dayjs/plugin/duration';
import { Router } from '@angular/router';
import { SCSubject } from '../../util/sc-subject.class';
dayjs.extend(dayjsDuration);

@Injectable({
  providedIn: 'root',
})
export class WalletService implements OnDestroy {
  user$ = this.userService.activeUser$;
  dashboardOrg$ = this.organizationsService.dashboardOrg$;
  dashboardOrgID$ = this.organizationsService.dashboardOrgID$;

  dashboardPlanID$ = new SCSubject<string>();
  studioPlanID$ = new SCSubject<string>();
  cardOnFile$ = new SCSubject<Stripe.Card>();
  dashboardPlan$ = new SCSubject<Plan>(); // in plan collection
  studioPlan$ = new SCSubject<Plan>(); // in plan collection
  studioWallet$ = new SCSubject<Wallet>();
  wallet$ = new SCSubject<Wallet>();
  ledger$ = new SCSubject<Ledger[]>();
  customerID$ = new SCSubject<string>();
  customer$ = new SCSubject<StripeCustomer>();
  primarySub$ = new SCSubject<StripeSubscriptionResponse>();
  shadowSub$ = new SCSubject<StripeSubscriptionResponse>();

  billingCol: CollectionReference;
  subs: Array<Subscription> = [];

  constructor(
    private firestore: Firestore,
    private amplionService: AmplionService,
    private organizationsService: OrganizationsService,
    private pricingService: PricingService,
    private userService: UserService,
    private router: Router
  ) {
    this.setupDashboardPlan();
    this.setupStudioPlan();
    this.setupWallet();
    this.setupLedger();
    this.setupDashboardOrg();
    this.setupStripeCustomer();
    this.setupCardOnFile();
  }

  async setupStripeCustomer() {
    this.dashboardPlanID$
      .pipe(
        switchMap(async (planID) => {
          if (!planID) return Promise.resolve(null);
          return this.amplionService.getCustomer(this.customerID$.value);
        })
      )
      .subscribe(async (res) => {
        if (res === null) {
          this.customer$.next(null);
          this.cardOnFile$.next(null);
          this.primarySub$.next(null);
          this.shadowSub$.next(null);
          return;
        }
        if (!res.customer.metadata.orgID && this.dashboardOrg$.value.orgID) {
          const orgID = this.dashboardOrg$.value.orgID;
          await this.amplionService.updateCustomer({ metadata: { orgID } }, res.customer.id);
          this.refreshStripeCustomer();
        } else this.customer$.next(res.customer);

        const numSubs = res.customer.subscriptions.data.length;
        if (numSubs === 1) {
          this.primarySub$.next(res.customer.subscriptions.data[0]);
          this.shadowSub$.next(null);
        } else if (numSubs > 1) {
          this.primarySub$.next(res.customer.subscriptions.data.find((sub) => sub.plan.interval === 'year'));
          this.shadowSub$.next(res.customer.subscriptions.data.find((sub) => sub.plan.interval === 'month'));
        } else {
          this.primarySub$.next(null);
          this.shadowSub$.next(null);
        }
      });
  }

  async refreshStripeCustomer(customerID = this.customerID$.value) {
    if (!customerID) {
      this.customer$.next(null);
      return Promise.resolve(null);
    }
    const res = await this.amplionService.getCustomer(customerID);
    this.customer$.next(res.customer);
    return res.customer;
  }

  setupCardOnFile() {
    this.customer$.subscribe((customer) => {
      if (customer?.sources.data.length) {
        this.cardOnFile$.next(customer.sources.data[0]);
      } else {
        this.cardOnFile$.next(null);
      }
    });
  }

  setupDashboardOrg() {
    this.dashboardOrg$.subscribe(async (org) => {
      this.billingCol = collection(this.firestore, `organizations/${org.orgID}/billing`);
      this.customerID$.next(org.customerID);
      if (!org.customerID) {
        this.cardOnFile$.next(null);
        this.customer$.next(null);
        this.dashboardPlan$.next(null);
      }
      // if (org.customerID) this.refreshStripeCustomer();

      // Only needed until dev is wiped
      this.dashboardPlanID$.next(org.planID, true);
    });
  }

  setupDashboardPlan() {
    this.dashboardPlanID$
      .pipe(
        switchMap((planID) => {
          if (!planID) return of(null);
          return this.pricingService.getPlan(planID);
        })
      )
      .subscribe((plan) => {
        this.dashboardPlan$.next(plan);
      });
  }

  setupStudioPlan() {
    this.studioPlanID$
      .pipe(
        switchMap((planID) => {
          if (!planID) return of(null);
          return this.pricingService.getPlan(planID);
        })
      )
      .subscribe((plan) => {
        this.studioPlan$.next(plan);
      });
  }

  setStudioPlan(planID: string) {
    this.studioPlanID$.next(planID);
  }

  async setupWallet() {
    let previousOrgID = null;
    this.dashboardOrgID$
      .pipe(
        switchMap((orgID) => {
          this.billingCol = collection(this.firestore, `organizations/${orgID}/billing`);
          return this.getWallet();
        })
      )
      .subscribe((wallet) => {
        if (wallet?.frozen && previousOrgID !== this.dashboardOrg$.value) {
          previousOrgID = this.dashboardOrg$.value;
          this.router.navigate(['account/subscription']);
        }
        this.wallet$.next(wallet ?? null);
      });
  }

  async setupLedger() {
    this.dashboardOrgID$
      .pipe(
        switchMap((orgID) => {
          this.billingCol = collection(this.firestore, `organizations/${orgID}/billing`);
          return this.getLedger();
        })
      )
      .subscribe((ledger) => {
        this.ledger$.next(ledger);
      });
  }

  convertSecondsToTimeCode(seconds: number) {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  }

  convertTimeCodeToSeconds(timeCode: string) {
    if (!timeCode) return 0;
    const hms = timeCode.split(':');
    const seconds = +hms[0] * 60 * 60 + +hms[1] * 60 + +hms[2];

    return seconds;
  }

  setAvailable(available: string) {
    return setDoc(doc(this.billingCol, 'wallet'), { available }, { merge: true });
  }

  setUsed(used: string) {
    return setDoc(doc(this.billingCol, 'wallet'), { used }, { merge: true });
  }

  setOverdrawn(overdrawn: boolean) {
    return setDoc(doc(this.billingCol, 'wallet'), { overdrawn }, { merge: true });
  }

  deposit(deposit: Transaction) {
    const ledgerRef = collection(this.billingCol, `wallet/ledger`);
    return addDoc(ledgerRef, deposit).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(ledgerRef, error.message.split('/').at(-1));
    });
  }

  withdraw(transactionID: string, withdraw: Transaction) {
    const ledgerRef = collection(this.billingCol, `wallet/ledger`);
    return setDoc(doc(ledgerRef, transactionID), withdraw);
  }
  getLedger() {
    const ledgerRef = collection(this.billingCol, `wallet/ledger`) as CollectionReference<Ledger>;
    return collectionData(ledgerRef, { idField: 'id' });
  }

  getWallet() {
    return docData<Wallet>(doc(this.billingCol, 'wallet'));
  }

  getWalletForOrg(orgID: string) {
    const orgWalletRef = doc(this.firestore, `organizations/${orgID}/billing/wallet`);
    return docData<Wallet>(orgWalletRef);
  }

  deleteWallet() {
    return deleteDoc(doc(this.billingCol, 'wallet'));
  }

  setCustomerID(customerID: string) {
    const orgRef = doc(this.firestore, `organizations/${this.dashboardOrg$.value.orgID}`);
    return setDoc(orgRef, { customerID }, { merge: true });
  }

  setPlan(planID: string) {
    const orgRef = doc(this.firestore, `organizations/${this.dashboardOrg$.value.orgID}`);
    return setDoc(orgRef, { planID }, { merge: true });
  }

  deletePlan() {
    const orgRef = doc(this.firestore, `organizations/${this.dashboardOrg$.value.orgID}`);
    return setDoc(orgRef, { planID: null }, { merge: true });
  }

  getCoupon() {
    const couponsRef = collection(this.billingCol, `wallet/coupons`) as CollectionReference<Coupon>;
    return collectionData(couponsRef, { idField: 'id' });
  }
  //amount & endDate
  setCoupon(payload: any) {
    this.billingCol = collection(this.firestore, `organizations/${this.dashboardOrg$.value.orgID}/billing`);
    const couponRef = collection(this.billingCol, `wallet/coupons`);
    const newDoc = doc(couponRef);
    return setDoc(newDoc, payload, { merge: true });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
