<ion-row>
  <ion-col>
    <ion-row>
      <ion-col size="2">
        <ion-icon [src]="action.icon"></ion-icon>
      </ion-col>

      <ion-col>
        <h3>{{ action.title }}</h3>

        <h5>{{ action.description }}</h5>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <ion-button aria-label="Cancel" (click)="cancel()" class="grey" expand="block"> Cancel </ion-button>
  </ion-col>

  <ion-col>
    <ion-button
      aria-label="Action"
      [upgrade]="
        (workflow === 'dolby' && !(plan$ | async)?.dolbyMastering) ||
        (workflow === 'mix' && !(plan$ | async)?.audioMixing) ||
        (workflow === 'dropbox' && !(dropbox$ | async)?.id)
      "
      [tooltip]="
        (workflow === 'dolby' && !(plan$ | async)?.dolbyMastering) ||
        (workflow === 'mix' && !(plan$ | async)?.audioMixing) ||
        (workflow === 'dropbox' && !(dropbox$ | async)?.id)
          ? 'Upgrade To Unlock'
          : ''
      "
      (click)="handler()"
      class="primary"
      [class.disabled]="
        (workflow === 'dolby' && !(plan$ | async)?.dolbyMastering) ||
        (workflow === 'mix' && !(plan$ | async)?.audioMixing) ||
        (workflow === 'dropbox' && !(dropbox$ | async)?.id)
      "
      [class.delete]="action.title.includes('Delete')"
      expand="block">
      {{ action.title }}
    </ion-button>
  </ion-col>
</ion-row>
