import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { SupportCenterService } from '../../services/support-center/support-center.service';
import { environment } from '../../../environments/environment';

import { SupportTopics } from '@sc/types';
import { SCNotification } from '@sc/types';
import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'sc-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit, AfterViewInit {
  @Input() notifications: SCNotification[];

  viewDescriptAnnouncment = false;
  activeIndex = 0;

  constructor(
    private notificationsService: NotificationsService,
    private modalController: ModalController,
    private supportCenterService: SupportCenterService,
    private analyticsService: AnalyticsService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.setActiveIndex(0);
    this.setDescriptAnnouncementView();
  }

  setDescriptAnnouncementView() {
    this.viewDescriptAnnouncment = this.organizationsService.dashboardOrg$.value.driveID ? true : false;
  }

  ngAfterViewInit() {
    this.setupHelpArticleLinks();
  }

  done() {
    this.modalController.dismiss();
  }

  dismissAll() {
    this.notifications.forEach((notification) => this.notificationsService.setRead(notification.id));
    this.modalController.dismiss();
  }

  async claimDescriptOffer() {
    await this.analyticsService.track('clicked descript claim modal');
    const queryParams = `&squadcast_connect=false&squadcast_pre_approved_org_id=${this.organizationsService.dashboardOrgID$.value}&utm_source=squadcast`;
    window.location.href = `${environment.descript.webDomain}/view/settings/account?active=subscription${queryParams}`;
  }

  setActiveIndex(idx: number) {
    this.notificationsService.setRead(this.notifications[idx].id);
    this.activeIndex = idx;
  }

  setupHelpArticleLinks() {
    const helpArticleLinks = document.querySelectorAll('a.help-article');

    helpArticleLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.openHelpArticle(link.getAttribute('value').split(',') as SupportTopics[]);
      });
    });
  }

  openHelpArticle(topics: SupportTopics[]) {
    // No longer able to suggest, so just show the first article
    this.supportCenterService.topic(topics[0]);
    this.analyticsService.track('opened learn more article', topics);
  }
}
