<ion-row>
  <ion-col>
    <h3 class="ion-text-center">Create Webhook</h3>

    <ion-row>
      <ion-col>
        <ion-list lines="none">
          <ion-item lines="none" class="input-large">
            <ion-label position="stacked"> Webhook URL </ion-label>

            <ion-input
              [(ngModel)]="url"
              (keyup.enter)="create()"
              type="text"
              name="url"
              class="text-light-dark"
              required>
            </ion-input>
          </ion-item>

          <!-- <ion-item lines="none">
            <ion-icon src="/assets/icons/24px/future.svg" slot="start">
            </ion-icon>
    
            <ion-label class="ion-text-wrap">
                <ion-text color="lighter">
                    <p>
                        A request will be sent to this URL whenever this event happens in your account. If the request does not receive a status 200 response, it will retry up to 3 times and wait longer between each attempt.
                    </p>
                </ion-text>
            </ion-label>
          </ion-item> -->
        </ion-list>

        <ion-row>
          <ion-col *ngFor="let event of events" [sizeLg]="6" [sizeXl]="6" class="ion-no-padding">
            <ion-item lines="none">
              <ion-checkbox mode="md" slot="start" [(ngModel)]="event.isChecked"> </ion-checkbox>

              <ion-label>
                <strong> {{ event.name }} </strong><br />
                {{ event.description }}
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="cancel()" class="text" type="button" expand="block"> Cancel </ion-button>
  <ion-button (click)="create()" class="primary" type="submit" expand="block"> Confirm </ion-button>
</div>
