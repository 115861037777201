import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Roles } from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';
import { RolesService } from '../roles/roles.service';
import { UserService } from './../../services/user/user.service';

@Injectable()
export class SquadCasterService implements CanActivate {
  constructor(
    public userService: UserService,
    private router: Router,
    private rolesService: RolesService,
    private organizationsService: OrganizationsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const minRole: Roles = route.data.minRole ?? 0;
    const user = await this.userService.authUser$.toPromise();
    if (user && !user.isAnonymous) {
      if (minRole === 0) return true;

      await this.organizationsService.orgRole$.toPromise();
      const role = await this.rolesService.role$.toPromise();
      if (role >= minRole) {
        return true;
      }
    }
    this.router.navigate(['/auth']);
    return false;
  }
}
