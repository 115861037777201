import { Injectable } from '@angular/core';
import { DocumentReference, Firestore, collection, doc, docData, CollectionReference } from '@angular/fire/firestore';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { OrganizationsService } from '../organizations/organizations.service';
import { CurrentPilotProgramFeatures, PilotProgram, PilotProgramEnabled } from '@sc/types';
import { setDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class PilotProgramService {
  public dashboardOrgID$ = this.organizationsService.dashboardOrgID$;
  public pilotProgram$ = new SCSubject<PilotProgram>();
  public activeUser$ = this.userService.activeUser$;

  private orgCol: CollectionReference = collection(this.firestore, 'organizations');
  private pilotCol: CollectionReference = collection(this.firestore, 'pilotprogram');

  constructor(
    private userService: UserService,
    private firestore: Firestore,
    private organizationsService: OrganizationsService
  ) {
    this.setupPilotProgram();
  }

  setupPilotProgram() {
    this.dashboardOrgID$
      .pipe(
        switchMap((orgID) => {
          if (!orgID) return of(undefined);
          return this.getPilotToggle(orgID);
        })
      )
      .subscribe(async (pilotToggle: PilotProgram) => {
        if (!pilotToggle) return;
        const companyPilotProgramEnabled = await firstValueFrom(this.getCompanyPilotProgram());
        if (companyPilotProgramEnabled?.enablePilotProgram === false) {
          await this.togglePilotProgram(false);
          this.pilotProgram$.next(pilotToggle);
        } else {
          if (pilotToggle === undefined || null) {
            this.togglePilotProgram(false);
          }
          this.pilotProgram$.next(pilotToggle);
        }
      });
  }

  /**
   * Checks the Organization Pilot Program
   *
   * @param orgID : User ID
   * @returns Observable
   */
  getPilotToggle(orgID?: string) {
    if (!orgID) orgID = this.activeUser$.value.activeOrg;
    return docData(doc(this.orgCol, orgID, 'settings', 'pilotProgram') as DocumentReference<PilotProgram>);
  }

  /**
   * Sets the Organization Pilot Program
   *
   * @param toggle : Boolean
   * @param orgID : User ID
   * @returns
   */
  togglePilotProgram(toggle: boolean, orgID?: string) {
    if (!orgID) orgID = this.dashboardOrgID$.value;
    return setDoc(doc(this.orgCol, orgID, 'settings', 'pilotProgram'), { toggle }, { merge: true });
  }

  /**
   * Checks to see if we're allowing customers to enable Pilot Program across the application.
   * If this is disabled no one will be able to enabled or disable Pilot Features.
   *
   * @returns
   */
  getCompanyPilotProgram() {
    const companyPilotProgramEnabled = doc(this.pilotCol, 'enabled') as DocumentReference<PilotProgramEnabled>;
    return docData(companyPilotProgramEnabled);
  }

  /**
   * Returns the list of current beta pilot features we are offering.
   * We need to update this field manually.
   *
   * @returns
   */
  getCurrentFeatures() {
    const currentPilotFeatures = doc(
      this.pilotCol,
      'currentFeatures'
    ) as DocumentReference<CurrentPilotProgramFeatures>;
    return docData(currentPilotFeatures);
  }
}
