<ion-row>
  <ion-col>
    <h3 class="ion-text-center">Revoke API Secret Key</h3>

    <p class="ion-text-center">
      API Requests will be Blocked while Revoked.<br />
      You can always Reactivate it later.
    </p>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="cancel()" expand="block" class="grey"> Cancel </ion-button>
  <ion-button (click)="revoke()" expand="block" class="red"> Confirm </ion-button>
</div>
