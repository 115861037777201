import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  addDoc,
  collection,
  collectionData,
  doc,
  query,
  serverTimestamp,
  where,
  setDoc,
} from '@angular/fire/firestore';

import { SCSubject } from '../../util/sc-subject.class';
import { OrganizationsService } from '../organizations/organizations.service';
import { GuestInvite, TeamMemberInvite, UserData } from '@sc/types';
import { combineLatest, of, switchMap } from 'rxjs';
import { UserService } from '../user/user.service';
import { TeamInvite } from '@sc/types';
import { TeamMember } from '@sc/types';
import { ShowsService } from '../shows/shows.service';
import { WalletService } from '../wallet/wallet.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { Plan } from '@sc/types';
import { CanceledService } from '../canceled/canceled.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  team$ = new SCSubject<UserData[]>();

  dashboardShowMembers$ = new SCSubject<TeamMember[] | null>();
  dashboardOrgMembers$ = new SCSubject<TeamMember[]>();

  myInvites$ = new SCSubject<TeamInvite[]>();
  orgInvites$ = new SCSubject<TeamInvite[]>();

  members$ = this.team$;
  activeInvites$ = this.orgInvites$;
  plan$ = this.walletService.dashboardPlan$;
  shows$ = this.showsService.availableShows$;
  canceledStatus$ = this.canceledService.canceledStatus$;

  user$ = this.userService.activeUser$;
  constructor(
    private router: Router,
    private firestore: Firestore,
    private analyticsService: AnalyticsService,
    private canceledService: CanceledService,
    private organizationsService: OrganizationsService,
    private showsService: ShowsService,
    private toastrService: ToastrService,
    private walletService: WalletService,
    private userService: UserService
  ) {
    this.setupTeam();
    this.setupOrgMembers();
    this.setupDashboardShowMembers();

    this.setupMyInvites();
    this.setupOrgInvites();
  }

  setupTeam() {
    this.organizationsService.dashboardOrg$
      .pipe(
        switchMap((org) => {
          if (org?.orgID) return this.getTeamData(org.orgID);
          else return of(null);
        })
      )
      .subscribe((team) => {
        this.team$.next(team);
      });
  }

  setupOrgMembers(): void {
    this.organizationsService.dashboardOrg$
      .pipe(
        switchMap((org) => {
          return combineLatest([this.organizationsService.getOrgMembers(org.orgID), this.team$]);
        })
      )
      .subscribe(async ([members, teamUserData]) => {
        if (teamUserData && members) {
          const teamMembers = members.map((member) => {
            const data = teamUserData.find((u) => u.uid === member.uid);
            const teamMember: TeamMember = { ...member, ...data };
            return teamMember;
          });
          this.dashboardOrgMembers$.next(teamMembers);
        } else this.dashboardOrgMembers$.next([]);
      });
  }

  setupDashboardShowMembers() {
    this.showsService.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (show) return combineLatest([this.showsService.getShowMembers(show.showID), this.team$]);
          else return of([null]);
        })
      )
      .subscribe(async ([members, teamUserData]) => {
        if (teamUserData && members) {
          const teamMembers = members.map((member) => {
            const data = teamUserData.find((u) => u.uid === member.uid);
            const teamMember: TeamMember = { ...member, ...data };
            return teamMember;
          });
          this.dashboardShowMembers$.next(teamMembers);
        } else this.dashboardShowMembers$.next(null);
      });
  }

  setupMyInvites() {
    this.userService.activeUserEmail$
      .pipe(
        switchMap((email) => {
          return this.getInvitesByEmail(email);
        })
      )
      .subscribe((invites) => {
        this.myInvites$.next(invites);
      });
  }

  setupOrgInvites() {
    this.organizationsService.dashboardOrg$
      .pipe(
        switchMap((org) => {
          return this.getInvitesByOrg(org.orgID);
        })
      )
      .subscribe((invites) => {
        const inv = invites.map((invite) => {
          if (!invite.inviteTimestamp) return invite;
          const date = dayjs(invite.inviteTimestamp.toDate());
          if (dayjs().diff(date, 'days') > 7) invite.status = 'expired';
          return invite;
        });
        this.orgInvites$.next(inv);
      });
  }

  getTeamData(orgID: string) {
    const scRef = collection(this.firestore, 'SquadCasters');
    return collectionData<UserData>(query(scRef, where('organizations', 'array-contains', orgID)), { idField: 'uid' });
  }

  getInvitesByEmail(email: string) {
    const invitesRef = collection(this.firestore, 'invites') as CollectionReference<TeamInvite>;
    return collectionData(query(invitesRef, where('email', '==', email), where('status', '==', 'pending')), {
      idField: 'inviteID',
    });
  }

  getInvitesByOrg(orgID?: string) {
    if (!orgID) orgID = this.organizationsService.dashboardOrg$.value.orgID;
    const invitesRef = collection(this.firestore, 'invites') as CollectionReference<TeamInvite>;
    return collectionData(query(invitesRef, where('orgID', '==', orgID), where('status', '==', 'pending')), {
      idField: 'inviteID',
    });
  }

  getInvitesByShow(showID?: string) {
    if (!showID) showID = this.showsService.dashboardShow$.value?.showID;
    if (!showID) return of([]);
    const invitesRef = collection(this.firestore, 'invites') as CollectionReference<TeamInvite>;
    return collectionData(query(invitesRef, where('showID', '==', showID), where('status', '==', 'pending')), {
      idField: 'inviteID',
    });
  }

  async createInvite(email: string, role: number, showID?: string) {
    const newInvite: TeamInvite = {
      orgID: this.organizationsService.dashboardOrg$.value.orgID,
      orgName: this.organizationsService.dashboardOrg$.value.orgName,
      email,
      role,
      invitedBy: this.userService.activeUser$.value.uid,
      status: 'pending',
      inviteTimestamp: serverTimestamp(),
    };
    if (showID) newInvite.showID = showID;
    if (this.organizationsService.dashboardOrg$.value.orgLogo)
      newInvite.orgLogo = this.organizationsService.dashboardOrg$.value.orgLogo;
    return addDoc(collection(this.firestore, 'invites'), newInvite).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(collection(this.firestore, 'invites'), error.message.split('/').at(-1));
    });
  }

  updateInvite(invite: TeamInvite | TeamMemberInvite | GuestInvite) {
    const inviteRef = doc(this.firestore, 'invites', invite?.inviteID);
    return setDoc(inviteRef, invite, { merge: true });
  }

  updateInviteDate(inviteID: string) {
    const inviteRef = doc(this.firestore, 'invites', inviteID);
    return setDoc(inviteRef, { inviteTimestamp: serverTimestamp() }, { merge: true });
  }

  cancelInvite(inviteID: string) {
    const inviteRef = doc(this.firestore, 'invites', inviteID);
    return setDoc(inviteRef, { status: 'canceled' }, { merge: true });
  }

  emailIsOrgMember(email) {
    return !!this.dashboardOrgMembers$.value.find((teamMember) => teamMember.email === email);
  }

  async checkLimits(plan: Plan) {
    if (this.plan$.value && this.plan$.value.id === plan.id && !this.canceledStatus$.value?.canceled) {
      this.toastrService.info(``, `This is your current plan`, {
        closeButton: true,
        toastComponent: GeneralToastComponent,
        progressBar: true,
        progressAnimation: 'decreasing',
        tapToDismiss: false,
        timeOut: 5 * 1000,
      });

      return false;
    }
    if (plan.shows === 'Unlimited') return;
    if (
      this.shows$.value?.length > plan.shows ||
      this.activeInvites$.value?.length + this.members$.value?.length > plan.seats
    ) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
        `Currently your plan has ${this.shows$.value?.length} Show${this.shows$.value?.length > 1 ? 's' : ''} and ${
          this.activeInvites$.value?.length + this.members$.value?.length
        } Team Member + Invite${this.activeInvites$.value?.length + this.members$.value?.length > 1 ? 's' : ''}`,
        `The selected plan features ${plan.shows} Show${plan.shows > 1 ? 's' : ''} ` +
          `and ${plan.seats} Team Member${plan.seats > 1 ? 's' : ''}`,
        {
          closeButton: true,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      toast.toastRef.componentInstance.buttons = [];
      if (this.shows$.value?.length > plan.shows) {
        toast.toastRef.componentInstance.buttons.push({
          label: 'Manage Shows',
          handler: () => {
            this.router.navigate(['account/shows']);
          },
        });
      }
      if (this.activeInvites$.value?.length + this.members$.value?.length > plan.seats) {
        toast.toastRef.componentInstance.buttons.push({
          label: 'Manage Team Members',
          handler: () => {
            this.router.navigate(['account/members']);
          },
        });
      }
      await this.analyticsService.track('downgrade plan failed constraints not matched');
      return false;
    }
    return true;
  }
}
