<ion-row>
  <ion-col>
    <h3 class="ion-text-center">Create API Secret Key</h3>

    <ion-row>
      <ion-col>
        <ion-list lines="none">
          <ion-item class="input-large" lines="none">
            <ion-label position="stacked"> Key Name </ion-label>

            <ion-input
              [(ngModel)]="name"
              (keyup.enter)="create()"
              type="text"
              name="name"
              color="lightest"
              class="text-light-dark"
              required>
            </ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-icon src="/assets/icons/24px/lock.svg" slot="start"> </ion-icon>

            <ion-label class="ion-text-wrap">
              <p>
                API keys provide privileged access to data in your SquadCast Account. Never share your API secret keys
                in public places like GitHub, frontend code, etc.
                <a href="https://developers.squadcast.fm" target="_blank">Learn More</a>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<div class="buttons">
  <ion-button (click)="cancel()" class="grey" type="button" expand="block"> Cancel </ion-button>
  <ion-button (click)="create()" class="primary" type="submit" expand="block"> Confirm </ion-button>
</div>
