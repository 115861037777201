export enum AudioCaptureMode {
  /**
   * Disables audio processing to allow transmitting non-voice audio to a conference.
   */
  Unprocessed = 'unprocessed',
  /**
   * Enables audio processing to improve voice quality.
   */
  Standard = 'standard',
}

/**
 * The NoiseReductionLevel model allows selecting the preferred level of noise reduction.
 *
 * This model is available in SDK 3.7 and later.
 */
export enum NoiseReductionLevel {
  /**
   * Removes all background sounds to improve voice quality. Use this mode if you want to send only voice to a conference.
   */
  High = 'high',
  /**
   * Removes stationary background sounds, such as the sound of a computer fan, air conditioning, or microphone hum, from audio transmitted to a conference. In this mode, non-stationary sounds are transmitted to give participants full context of other participants' environments and create a more realistic audio experience. If you want to send only voice to a conference, use the [High](#high) level.
   */
  Low = 'low',
}

/**
 * The VideoProcessorType model allows selecting the type of the video processor to either blur the local participant's background or use a selected image as the background.
 *
 * This model is supported only in SDK 3.7 and later.
 */
export enum VideoProcessorType {
  /**
   * Blurs the local participant's background.
   */
  Bokeh = 'bokeh',
  /**
   * Replaces the local participant's background with the selected static image.
   */
  BackgroundReplacement = 'backgroundreplacement',
  /**
   * @ignore
   * Video stream passes through video processing unit wihtout any modification. Video frames are just
   * copied from input to output.
   */
  None = 'none',
}

/**
 * The AudioBitrate model allows selecting the bitrate of the outgoing audio stream.
 *
 * This model is supported only in SDK 3.8 and later.
 */
export enum AudioBitrate {
  /**
   * Sets the bitrate to 18 kbps.
   */
  Bitrate18k = '18Kbps',
  /**
   * Sets the bitrate to 64 kbps.
   */
  Bitrate64k = '64Kbps',
  /**
   * Sets the bitrate to 80 kbps.
   */
  Bitrate80k = '80Kbps',
  /**
   * Sets the bitrate to 96 kbps.
   */
  Bitrate96k = '96Kbps',
  /**
   * Sets the bitrate to 128 kbps.
   */
  Bitrate128k = '128Kbps',
}
