import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { IdTokenService } from '../id-token/id-token.service';

@Injectable({
  providedIn: 'root',
})
export class FarnsworthService {
  farnsworth = environment.microservices.farnsworth;

  constructor(private http: HttpClient, private idTokenService: IdTokenService) {}

  processRecording(recording: any, idToken: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.post(`${this.farnsworth}/api/v2/files`, recording, { headers });
  }

  async checkDiscontinuity(recordingPayload: any) {
    const idToken = await this.idTokenService.getFreshIdToken();
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.http.post(`${this.farnsworth}/api/v2/check`, recordingPayload, { headers });
  }
}
