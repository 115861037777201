import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { UpgradeDirective } from './upgrade/upgrade.directive';
import { ThrottleClickDirective } from './throttle-click/throttle-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AutoFocusDirective, ThrottleClickDirective, TooltipDirective, UpgradeDirective],
  exports: [AutoFocusDirective, ThrottleClickDirective, TooltipDirective, UpgradeDirective],
})
export class SquadCastDirectivesModule {}
