import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'marked',
})
export class MarkedPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value.length > 0) {
      const markedVal = marked(value);
      const markedValWithClick = markedVal
        .replace(/<a href="helparticle:([^"]*)">/g, `<a class="help-article" value="$1">`)
        .replace(/<a href="external:([^"]*)">/g, `<a href="$1" target="_blank">`);
      return markedValWithClick;
    }
    return value;
  }
}
