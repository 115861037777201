import { AnimationController, Animation } from '@ionic/angular';

export const navigateAnimation = (baseElement: HTMLElement, options: any): Animation => {
  const duration = 250;
  const animationController = new AnimationController();

  const enter = animationController.create();
  const leave = animationController.create();
  const animation = animationController.create();

  enter.addElement(options.enteringEl).duration(duration).easing('ease-out').fromTo('opacity', 0, 1);

  leave.addElement(options.leavingEl).duration(duration).easing('ease-in').fromTo('opacity', 1, 0);

  animation.addAnimation([enter, leave]);

  return animation;
};
