<ion-list lines="none">
  <ion-item lines="none">
    <ion-icon src="assets/icons/24px/role-show-manager.svg" slot="start"> </ion-icon>

    <ion-label class="ion-text-wrap">
      <p class="body-bold">Show Manager</p>

      <p class="body-regular ion-no-margin">Manages the Show and Team</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-icon src="assets/icons/24px/microphone.svg" slot="start"> </ion-icon>

    <ion-label class="ion-text-wrap">
      <p class="body-bold">Talent</p>

      <p class="body-regular ion-no-margin">Hosts who talk on microphone and camera in recording sessions</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-icon src="assets/icons/24px/role-file-manager.svg" slot="start"> </ion-icon>

    <ion-label class="ion-text-wrap">
      <p class="body-bold">File Manager</p>

      <p class="body-regular ion-no-margin">Shared access for recordings and files</p>
    </ion-label>
  </ion-item>
</ion-list>
