import { TeamMemberInvite, GuestInvite } from './invitations.interface';
import { ShowTransferEmail } from './show.interface';

export enum ZapierHookTags {
  SHOWTRANSFER = 'ba6459r',
  TEAMMEMBERINVITE = 'bg6wfaw',
  ONSTAGEINVITE = 'bfuu7b2',
  BACKSTAGEINVITE = 'bfu1kb9',
  TEAMMEMBERSHOWINVITE = 'bfueh25',
}

export interface ZapierRequest {
  zapierUrlKey: ZapierHookTags;
  showTransferEmail: boolean | null;
  inviteID: string;
  inviteType: keyof typeof ZapierHookTags;
}
