import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CanceledService } from '../canceled/canceled.service';
import { WalletService } from '../wallet/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class ClosedService implements CanActivate {
  constructor(private router: Router, private canceledService: CanceledService, private walletService: WalletService) {}

  async canActivate() {
    const status = await this.canceledService.canceledStatus$.toPromise();
    const wallet = await this.walletService.wallet$.toPromise();

    if (status?.closed || wallet?.frozen) {
      this.router.navigate(['account/subscription']);
      return false;
    }
    return true;
  }
}
