<ion-list lines="none">
  <ion-list-header lines="none" mode="md">
    <ion-label>
      <h1>Notifications</h1>
    </ion-label>
  </ion-list-header>

  <div *ngIf="notifications?.length">
    <ion-item
      *ngFor="let notification of notifications; last as last"
      (click)="read(notification)"
      button="true"
      [lines]="last ? 'none' : 'full'">
      <ion-thumbnail slot="start" *ngIf="notification.image">
        <img [src]="notification.image" />
      </ion-thumbnail>

      <ion-label class="ion-text-wrap">
        <p>
          <small>
            {{ notification.date }}
          </small>
        </p>

        <h2>
          {{ notification.title }}
        </h2>

        <p>
          {{ notification.description }}
        </p>
      </ion-label>

      <ion-icon *ngIf="!notification.read" src="assets/icons/24px/record.svg" color="primary"></ion-icon>

      <ion-icon
        *ngIf="notification.link"
        tooltip="Open in new tab"
        [attr.aria-label]="'Open in new tab, ' + notification.title + ' ' + notification.description"
        src="assets/icons/24px/new-tab.svg"
        slot="end">
      </ion-icon>
    </ion-item>
  </div>

  <ion-item *ngIf="notifications?.length === 0" lines="none">
    <ion-label>
      <h2>There are no notifications!</h2>
    </ion-label>
  </ion-item>
</ion-list>

<ion-progress-bar *ngIf="notifications === undefined" type="indeterminate" color="primary"> </ion-progress-bar>
