import { Injectable } from '@angular/core';
import { collection, collectionData, CollectionReference, Firestore, query } from '@angular/fire/firestore';
import dayjs from 'dayjs';
import { deleteDoc, doc, setDoc, where } from 'firebase/firestore';
import { firstValueFrom, map } from 'rxjs';
import { SCSubject } from '../../util/sc-subject.class';
import { NotificiationCookie, SCNotification } from '@sc/types';

import { CookiesService } from '../cookies/cookies.service';
import { docData } from 'rxfire/firestore';
import { OrganizationsService } from '../organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  cookieName = 'read_notifications';
  readNotifications$ = new SCSubject<NotificiationCookie[]>();
  notificationsCol = collection(this.firestore, 'notifications') as CollectionReference<SCNotification>;

  constructor(
    private firestore: Firestore,
    private cookiesService: CookiesService,
    private organizationsService: OrganizationsService
  ) {
    // UNCOMMENT TO RESET COOKIE FOR TESTING
    // this.resetCookie();
    this.readNotifications$.next(this.getRead());
  }

  getNotifications(type?: SCNotification['type']) {
    if (type) {
      return collectionData(query(this.notificationsCol, where('type', '==', type), where('published', '==', true)), {
        idField: 'id',
      }).pipe(map((notifications) => notifications.filter((n) => !dayjs(n.cutoffDate).isBefore(dayjs()))));
    }
    return collectionData(this.notificationsCol, { idField: 'id' });
  }

  getNotification(notificationID: string) {
    const notificationDoc = doc(this.notificationsCol, notificationID);
    return docData(notificationDoc);
  }

  setRead(notificationID: string) {
    const read = this.readNotifications$.value;
    if (read) if (read.find((n) => n.id == notificationID)) return;
    const currentDate = new Date();
    this.readNotifications$.next([...read, { date: currentDate, id: notificationID }]);
    return this.cookiesService.set(this.cookieName, JSON.stringify(this.readNotifications$.value));
  }

  async commitNotifications(notifications: SCNotification[]) {
    const previousNotifications = await firstValueFrom(this.getNotifications());
    const notificationsToRemove = previousNotifications.filter((n) => !notifications.find((nn) => nn.id === n.id));
    notificationsToRemove.forEach((n) => deleteDoc(doc(this.firestore, 'notifications', n.id)));
    notifications.forEach(async (n) => {
      if (n.id) await setDoc(doc(this.firestore, 'notifications', n.id), n);
      else await setDoc(doc(this.notificationsCol), n);
    });
  }

  private getRead() {
    const cookie = this.cookiesService.get(this.cookieName);
    if (!cookie) return [];

    const notifiCookie = JSON.parse(cookie) as NotificiationCookie[];

    // If the customer has NOT linked their descript drive and a week has pasted since their cookiedate
    // We should reshow them the linking modal popup notification again.
    if (!this.organizationsService.dashboardOrg$.value?.driveID) {
      notifiCookie.forEach(async (cookie) => {
        if (!cookie.id || !cookie.date) return;
        const notification = await firstValueFrom(this.getNotification(cookie.id));
        if (notification?.isDescriptAnnouncement) {
          const cookieDateNumber = new Date(cookie.date).getTime();
          const currentTime = Date.now();
          let diff = (currentTime - cookieDateNumber) / 1000;
          diff /= 60 * 60 * 24 * 7;
          const dateDiff = Math.abs(Math.round(diff));
          if (dateDiff > 1) this.resetCookie();
        }
      });
    }
    return notifiCookie;
  }

  private resetCookie() {
    this.cookiesService.set(this.cookieName, JSON.stringify([]));
  }
}
