import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { IonAccordionGroup, ModalController, PopoverController } from '@ionic/angular';
import { firstValueFrom, Subscription } from 'rxjs';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { AmplionService } from '../../services/amplion/amplion.service';
import { PricingService } from '../../services/pricing/pricing.service';
import { TapfiliateService } from '../../services/tapfiliate/tapfiliate.service';
import { CookiesService } from '../../services/cookies/cookies.service';
import { PaymentInfoComponent } from '../../components/payment-info/payment-info.component';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { ZapierService } from '../../services/zapier/zapier.service';

import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

import { Plan } from '@sc/types';
import { StripeSubscriptionDTO, StripeSubscriptionResponse } from '@sc/types';
import { SalesService } from '../../services/sales/sales.service';
import { SaleCoupon } from '@sc/types';
import { TeamsService } from '../../services/teams/teams.service';
import { ActiveCampaignService } from '../../services/active-campaign/active-campaign.service';

declare let fbq: any; // facebook pixel global namespace

@Component({
  selector: 'sc-confirm-subscription',
  templateUrl: 'confirm-subscription.page.html',
  styleUrls: ['confirm-subscription.page.scss'],
})
export class ConfirmSubscriptionPage implements OnInit, OnDestroy {
  @Input() selectedPlan: Plan;
  @Input() page: string; // checkout / upgrade

  @ViewChild('paymentInfo') paymentInfo: PaymentInfoComponent;
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;

  monthlyPlan: Plan;
  yearlyPlan: Plan;

  primarySub: StripeSubscriptionResponse;
  shadowSub: StripeSubscriptionResponse;

  allPlans: {
    monthly: Array<Plan>;
    yearly: Array<Plan>;
  } = {
    monthly: [],
    yearly: [],
  };

  promoCode: string;
  unknownCoupon = false;
  couponDuration: string = null;
  percentOff: string = null;
  amountOff: string = null;
  discountedAmount: number = null;
  discountedPrice: number = null;

  billingInterval = 'Monthly'; // Monthly / Yearly
  confirmingSubscription = false;
  proratedInfo: any;
  trialEndDate: string;
  nextBillDate: string;
  trialAllowed = false;

  customer$ = this.walletService.customer$;
  currentSale$ = this.salesService.currentSale$;
  currentSaleCoupons$ = this.salesService.currentSaleCoupons$;
  planIsOnSale = false;

  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private walletService: WalletService,
    private organizationsService: OrganizationsService,
    private amplionService: AmplionService,
    private pricingService: PricingService,
    private tapfiliateService: TapfiliateService,
    private cookiesService: CookiesService,
    private salesService: SalesService,
    private teamsService: TeamsService,
    private activeCampaignService: ActiveCampaignService
  ) {}

  ngOnInit() {
    this.setupAvailablePlans();
    this.setupAltPlan();
    this.setupCookies();
    this.setupCustomer();
    this.setupSales();

    if (this.page === 'checkout' && this.trialAllowed) {
      this.trialEndDate = dayjs().add(this.selectedPlan.trialDays, 'days').format('MMM D, YYYY');
    }

    this.billingInterval = this.selectedPlan.interval;
    this.discountedPrice = this.selectedPlan.price;
  }

  setupCustomer() {
    const sub = this.customer$.subscribe((customer) => {
      if (customer) {
        const subCount = customer.subscriptions.total_count;
        if (subCount) {
          this.primarySub = customer.subscriptions.data.find((subscription) => subscription.plan.amount);
          this.shadowSub = customer.subscriptions.data.find((subscription) => !subscription.plan.amount);
          if (this.primarySub) {
            this.page = 'upgrade';
            if (this.primarySub.discount?.coupon) this.promoCode = this.primarySub.discount.coupon.id;
            this.checkCoupon();
            if (this.primarySub.status === 'trialing') {
              this.trialEndDate = dayjs(this.primarySub.trial_end * 1000).format('MMM D, YYYY');
            }
          }
        } else {
          if (this.page === 'upgrade') this.trialAllowed = false;
        }
      }
    });

    this.subs.push(sub);
  }

  trackSubscription() {
    this.tapfiliateService.trial(this.customer$.value?.id);
    this.analyticsService.track('subscribed to plan', {
      plan: this.selectedPlan.id,
      customerID: this.customer$.value?.id,
    });
    fbq('trackCustom', 'Checkout');
  }

  handleStripeError(error: any) {
    if (error.error?.raw.code === 'resource_missing' || error.error?.raw.code === 'invoice_upcoming_none') return;
    throw error;
  }

  async confirmSubscription() {
    this.confirmingSubscription = true;
    if (this.paymentInfo.stripeCard) {
      await this.paymentInfo.save();
      const success = await firstValueFrom(this.paymentInfo.saveSuccess$);
      if (!success) {
        this.confirmingSubscription = false;
        return;
      }
    }
    await this.paymentInfo.saveData();
    await this.walletService.refreshStripeCustomer();
    const card = this.customer$.value?.sources.data[0];
    const newSub: StripeSubscriptionDTO = {
      source: card.id,
      plan: this.selectedPlan.id,
      proration_behavior: 'none',
      metadata: { orgID: this.organizationsService.dashboardOrgID$.value },
      coupon: this.promoCode && !this.unknownCoupon ? this.promoCode : null,
    };

    let subResponse;
    if (!this.primarySub) {
      subResponse = await this.newSubscription(newSub);
    } else {
      subResponse = await this.updateSubscription(newSub);
    }
    if (subResponse) {
      if (this.discountedAmount) {
        // Just for our info now
        await this.walletService.setCoupon({
          code: this.promoCode,
          amount: this.discountedAmount,
          endDate: subResponse.current_period_end ? subResponse.current_period_end : null,
        });

        if (
          this.currentSaleCoupons$.value.some((coupon) => coupon.code === this.promoCode) &&
          this.currentSale$.value.activeCampaignTag
        ) {
          await this.activeCampaignService.addTagToSquadcaster(this.currentSale$.value.activeCampaignTag);
        }
      }
      this.trackSubscription();
      this.confirmingSubscription = false;
      this.modalController.dismiss();
    }
  }

  async newSubscription(subscription) {
    const customer = await this.customer$.toPromise();
    subscription.customer = customer.id;
    if (!this.trialAllowed) {
      subscription.trial_end = 'now';
    }

    const newPrimary = await this.amplionService.subscribeCustomer(subscription).catch(this.handleStripeError);
    if (this.shadowSub) {
      this.amplionService.cancelSubscription(this.customer$.value?.id, this.shadowSub.id);
    }
    if (this.selectedPlan.shadowID) {
      subscription.plan = this.selectedPlan.shadowID;
      await this.amplionService.subscribeCustomer(subscription).catch(this.handleStripeError);
    }
    await this.walletService.setPlan(this.selectedPlan.id);
    await this.amplionService.balanceLedger(this.organizationsService.dashboardOrgID$.value);

    return newPrimary;
  }

  async updateSubscription(subscription) {
    if (this.primarySub.status === 'trialing') subscription.trial_end = this.primarySub.trial_end;
    else subscription.trial_end = 'now';

    if (this.primarySub.discount?.coupon) subscription.coupon = this.primarySub.discount.coupon.id;

    const newPrimary = await this.amplionService
      .updateSubscription(subscription, this.primarySub.id)
      .catch(this.handleStripeError);
    if (newPrimary) {
      if (this.selectedPlan.shadowID) {
        subscription.plan = this.selectedPlan.shadowID;
        if (this.shadowSub) {
          await this.amplionService.updateSubscription(subscription, this.shadowSub.id).catch(this.handleStripeError);
        } else {
          subscription.customer = this.customer$.value?.id;
          await this.amplionService.subscribeCustomer(subscription).catch(this.handleStripeError);
        }
      } else {
        if (this.shadowSub) {
          this.amplionService.cancelSubscription(this.customer$.value?.id, this.shadowSub.id);
        }
      }
      await this.walletService.setPlan(this.selectedPlan.id);

      this.router.navigate(['/account/subscription']);
      return newPrimary;
    }
  }

  async selectPlan(selectedPlan: Plan) {
    const passLimits = await this.teamsService.checkLimits(selectedPlan);
    if (!passLimits) return;
    this.accordionGroup.value = undefined;
    this.updateSelectedPlan(selectedPlan);
    this.resetCoupon();
    this.parseCoupons(this.salesService.currentSaleCoupons$.value);

    await this.analyticsService.track('selected plan', { selectedPlanID: selectedPlan.id });
  }

  async updateSelectedPlan(selectedPlan: Plan) {
    const passLimits = await this.teamsService.checkLimits(selectedPlan);
    if (!passLimits) return;
    this.selectedPlan = selectedPlan;
    this.resetCoupon();
    // const checkPlan = this.salesService.checkIfPlanOnSale(this.selectedPlan.id);
    this.parseCoupons(this.salesService.currentSaleCoupons$.value);

    if (this.primarySub) this.updateProratedInvoice();
    if (!this.primarySub && this.trialAllowed)
      this.trialEndDate = dayjs().add(this.selectedPlan.trialDays, 'days').format('MMM D, YYYY');

    this.setupAltPlan();

    this.billingInterval = this.selectedPlan.interval;
    this.discountedPrice = this.selectedPlan.price;
  }

  billingIntervalSelected(interval: string) {
    if (interval === 'Monthly') {
      this.updateSelectedPlan(this.monthlyPlan);
    } else if (interval === 'Yearly') {
      this.updateSelectedPlan(this.yearlyPlan);
    }
  }

  async setupSales() {
    const sub = this.salesService.currentSaleCoupons$.subscribe((coupons) => {
      this.parseCoupons(coupons);
    });
    this.subs.push(sub);
  }

  parseCoupons(coupons: SaleCoupon[]) {
    const coupon = coupons.find((c) => c.plans.includes(this.selectedPlan.id));
    if (!coupon) {
      this.resetCoupon();
      return;
    }
    this.promoCode = coupon.code;
    this.discountedAmount = coupon.discount;
    this.discountedPrice = this.selectedPlan.price - this.discountedAmount;
    this.unknownCoupon = false;
  }

  async checkCoupon() {
    if (!this.promoCode) {
      this.resetCoupon();
      return;
    }
    // if(this.planIsOnSale){
    //   this.promoCode = this.salesService.getCoupon()
    // }
    const coupon = await this.amplionService.getCoupon(this.promoCode);
    if (!coupon.valid) {
      this.resetCoupon(true);
      return;
    }

    if (coupon.amount_off) {
      this.amountOff = (coupon.amount_off / 100).toFixed(2); // 2 decimals
      this.percentOff = null;
      this.discountedAmount = parseInt(this.amountOff, 10);
    }
    if (coupon.percent_off) {
      this.percentOff = String(coupon.percent_off);
      this.amountOff = null;
      const percentOffNumber = parseInt(this.percentOff, 10);
      const percentOffDecimal = percentOffNumber / 100.0;
      this.discountedAmount = this.selectedPlan.price * percentOffDecimal;
    }

    this.unknownCoupon = false;
    this.couponDuration = coupon.duration;
    this.discountedPrice = this.selectedPlan.price - this.discountedAmount;

    this.updateProratedInvoice();
    await this.analyticsService.track('validated promo code', {
      coupon,
      amountOff: this.amountOff,
      percentOff: this.percentOff,
      duration: this.couponDuration,
      discountedPrice: this.discountedPrice,
    });
  }

  resetCoupon(unknown = false) {
    this.amountOff = null;
    this.percentOff = null;
    this.unknownCoupon = unknown;
    this.couponDuration = null;
    this.discountedPrice = null;
    this.promoCode = '';

    this.updateProratedInvoice();
  }

  async close() {
    await this.modalController.dismiss();
    await this.analyticsService.track('closed confirm subscription');
  }

  setupAvailablePlans() {
    const sub = this.pricingService.availablePlans$.subscribe((plans) => {
      const canSeeEnterprise = this.organizationsService.dashboardOrg$.value?.canSeeEnterprisePlans ?? false;

      const monthly = plans
        .filter((plan) => (plan.enterprise || false) === canSeeEnterprise)
        .filter((plan) => plan.interval === 'Monthly');
      const yearly = plans
        .filter((plan) => (plan.enterprise || false) === canSeeEnterprise)
        .filter((plan) => plan.interval === 'Yearly');

      this.allPlans = { monthly, yearly };
    });
    this.subs.push(sub);
  }

  async setupAltPlan() {
    const plans = await this.pricingService.availablePlans$.toPromise();
    if (this.selectedPlan.interval === 'Monthly') {
      this.monthlyPlan = this.selectedPlan;
      this.yearlyPlan = plans.find((plan) => plan.id === this.monthlyPlan.yearlyID);
    } else if (this.selectedPlan.interval === 'Yearly') {
      this.yearlyPlan = this.selectedPlan;
      this.monthlyPlan = plans.find((plan) => plan.id === this.yearlyPlan.monthlyID);
    }
  }

  async updateProratedInvoice() {
    if (!this.primarySub) return;
    const proration_date = Math.floor(Date.now() / 1000);
    const items = [
      {
        id: this.primarySub.items.data[0].id,
        plan: this.selectedPlan.id,
      },
    ];

    const coupon = this.promoCode && !this.unknownCoupon ? this.promoCode : null;
    const preInvoice = {
      subscriptionID: this.primarySub.id,
      items,
      proration_date,
      coupon,
    };

    try {
      const preview = await this.amplionService.previewInvoice(this.customer$.value?.id, preInvoice);

      const credit = (preview.previewInvoice.total / 100) * -1;
      const nextBillDate = preview.previewInvoice.next_payment_attempt;

      this.proratedInfo = {
        amountDue: preview.previewInvoice.amount_due,
        nextBillDate: dayjs(nextBillDate * 1000).format('MMM D, YYYY'),
        dueToday: dayjs(nextBillDate * 1000).isSame(dayjs(), 'day'),
        totalCredit: credit > 0 ? credit.toFixed(2) : null,
        proratedAmount: (preview.previewInvoice.lines.data[0].amount / 100) * -1,
      };
    } catch (error) {
      this.handleStripeError(error);

      this.proratedInfo = {
        amountDue: this.selectedPlan.price,
        nextBillDate: dayjs(this.primarySub.current_period_end * 1000).format('MMM D, YYYY'),
        dueToday: dayjs(this.primarySub.current_period_end * 1000).isSame(dayjs(), 'day'),
        totalCredit: null,
        proratedAmount: null,
      };
    }
  }

  setupCookies() {
    if (!this.cookiesService.check('sc_coupon')) {
      return;
    }

    this.promoCode = this.cookiesService.get('sc_coupon');
    this.checkCoupon();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
